export enum ApptForUser {
   Self = 'self',
   Dependent = 'dependent'
}

export enum ConsultationType {
   PhysicianConsultation = 'Physician Consultation',
   PrescriptionRefill = 'Prescription Refill'
}

export enum ContactType {
   Phone = 'phone',
   Conference = 'conference'
}

export enum ScheduledTimeType {
   Now = 'now',
   Later = 'later'
}
export enum ReasonForVisit {
   Placeholder = 'Placeholder',
   Other = 'Other',
   RefillMedication = 'Refill Medication'
}
