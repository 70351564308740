import * as React from "react";
import { FormGroup, Label, CustomInput } from "reactstrap";

export interface IItem {
    item: any;
    display: string;
    id: string | number;
    checked: boolean;
}
interface IPreviousConditionsModalProps {
    saveData: (items: IItem[]) => any; // as done saving
    cancelModal: () => void;
    items: IItem[];
    previousConditionsStatus?:any;
    onPreviousConditionsStatusChanged:any;
}

export const PreviousConditionsModal: React.FC<IPreviousConditionsModalProps> = (props: IPreviousConditionsModalProps) => {
    const [currentPreviousconditionsStatus, setCurrentPreviousconditionsStatus] = React.useState<boolean>(props.previousConditionsStatus);
    const items = props.items.map((v) => { return { ...v } });
    const cancelPressed = () => {
        props.cancelModal();
    }

    const savePressed = () => {
        props.onPreviousConditionsStatusChanged(currentPreviousconditionsStatus);
        props.saveData(items);

    }
    const rootStyle: React.CSSProperties = { display: "block", paddingRight: "15px" }
    return (
        <>
            <div id="updateMedHistoryModal" data-tabindex="-1" role="dialog" aria-labelledby="updateMedHistoryModalLabel" className="modal fade show" style={rootStyle} aria-modal="true">
                <div role="document" className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 id="updateMedHistoryModalLabel" className="modal-title">Medical Profile &gt; Previous Conditions</h5>
                            <button type="button" data-dismiss="modal" aria-label="Close" className="close" onClick={cancelPressed}><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                        <FormGroup>
                            <div id="intro">
                            <Label for="intro">
                                Do they have any previous conditions:
                            </Label>
                            <CustomInput
                                onChange={(e) => {
                                if (e.currentTarget.checked)
                                    // setPreviousConditionsQuestions(true);
                                    setCurrentPreviousconditionsStatus(true);
                                }}
                                type="radio"
                                id="yes"
                                name="intro"
                                label="Yes"
                                defaultChecked={props.previousConditionsStatus}
                            />
                            <CustomInput
                                onChange={(e) => {
                                if (e.currentTarget.checked)
                                    // setPreviousConditionsQuestions(false);
                                    setCurrentPreviousconditionsStatus(false);
                                }}
                                type="radio"
                                id="no"
                                name="intro"
                                label="No"
                                defaultChecked={props.previousConditionsStatus === undefined? false: !props.previousConditionsStatus}
                            />
                            </div>
                        </FormGroup>
                            {currentPreviousconditionsStatus? 
                            <>
                            <p>Please choose from the list below:</p>
                            <div className="row">
                                {
                                    items.map((item) => {
                                        // let itemRef = React.createRef<HTMLInputElement>();
                                        let id = "InList-" + item.id
                                        return (
                                            <div className="col-sm-6 col-md-6">
                                                <div className="mb-2">
                                                    <div className="custom-control custom-checkbox">
                                                        <input id={id} type="checkbox" className="custom-control-input" value={item.id} defaultChecked={item.checked} onChange={
                                                            (e) => {
                                                                item.checked = e.target.checked;
                                                            }
                                                        } />
                                                        <label htmlFor={id} className="custom-control-label">{item.display}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                }

                            </div>
                            </>:null}
                        </div>
                        <div className="modal-footer">
                            <button onClick={cancelPressed} type="button" data-dismiss="modal" className="btn btn-link">Cancel</button>
                            <button type="button" onClick={savePressed} data-dismiss="modal" className="btn btn-success">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}