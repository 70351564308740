import * as React from 'react';
import { Routes } from '../../config/Routes';
import { Link } from '@reach/router';
import { IBookingProps } from './AppointmentModels';
import Gallery from 'react-photo-gallery';
import { navItem } from '../../App';
import Asterisk from '../../components/commons/Asterisk';
import {
  getConsultationReasons, uploadMultipleImagesForPatient, getImage,
} from '../../core/service/services';
import {
  Card, CardBody, CardTitle, Collapse, Button, Container, Input, Row, Label,
  FormGroup, Modal, ModalHeader, ModalBody, Form, FormFeedback
} from 'reactstrap';
import ImageUploader from 'react-images-upload';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import RadioGroup from '../../components/layout/RadioGroup'
import { IRadioButtonProps, BootstrapTextColor } from '../../components/layout/RadioButton';
import LoadingSpinner from '../../components/commons/LoadingSpinner';
import { useAppDispatch, useAppSelector } from '../../../appredux/hooks';
import { selectAvailableStates } from '../../redux/slices/general/USAStatesSlice'
import {
  stateSelected,
  consultationTypeSelected,
  reasonForVisitSelected,
  symptomsUpdated,
  otherReasonUpdated,
  attachmentAdded,
  displayedImageAdded,
  selectStateId,
  selectConsultationType,
  selectReasonForVisit,
  selectSymptoms,
  selectOtherReason,
  selectAttachments,
  selectDisplayedImages
} from '../../redux/slices/booking/consultationStepSlice'
import { selectContactInfo } from '../../redux/slices/general/patientInfoSlice';
import { selectDependent } from '../../redux/slices/booking/choosePatientStepSlice';
import { ConsultationType, ReasonForVisit } from '../../redux/types/enums/bookingEnums'
import {
  whoSectionUpdated,
  whySectionUpdated,
  nextStepInitiated,
  previousStepInitiated,
  selectPayloadWhoSection
} from '../../redux/slices/booking/bookingSlice'
import { IPhotoToken, IWho, IWhy } from '../../redux/types/interfaces/bookingInterfaces';
import { I_USAState } from '../../redux/types/interfaces/otherInterfaces';
import { IContact, IDependent } from '../../redux/types/interfaces/patientInfoInterfaces';
import { isDefined, isNumber } from '../../core/service/Validators';
import { errorsDisplayed, selectShowErrors } from '../../redux/slices/booking/errorSlice';
import { areThereErrors } from '../../core/service/ErrorService';
import { AddressErrorMessages } from '../../core/service/ErrorMessages';

const BookingConsultationStep = ({
  smallCard
}: IBookingProps) => {
  const availableUSStates: Array<I_USAState> = useAppSelector(selectAvailableStates)
  const stateId: number | undefined = useAppSelector(selectStateId)
  const consultationType: ConsultationType | undefined = useAppSelector(selectConsultationType)
  const reasonForVisit: string | undefined = useAppSelector(selectReasonForVisit)
  const symptoms: string | undefined = useAppSelector(selectSymptoms)
  const otherReason: string | undefined = useAppSelector(selectOtherReason)
  const attachments: Array<string> | undefined = useAppSelector(selectAttachments)
  const displayedImages: Array<string> | undefined = useAppSelector(selectDisplayedImages)
  const who: IWho | undefined = useAppSelector(selectPayloadWhoSection)
  const contactInfo: IContact | undefined = useAppSelector(selectContactInfo)
  const dependent: IDependent | undefined = useAppSelector(selectDependent)
  const showErrors = useAppSelector(selectShowErrors);

  const [isFormDataSectionOpen, setIsFormDataSectionOpen] = React.useState<boolean>(consultationType !== undefined)
  const [uploadImageModalIsOpen, setUploadImageModalIsOpen] = React.useState(false)
  const [isOtherReasonSelected, setIsOtherReasonSelected] = React.useState<boolean>(otherReason ? true : false)
  const [consultationReasons, setConsultationReasons] = React.useState<Array<string>>([])
  const [dataToBeUploaded, setDataToBeUploaded] = React.useState<FormData | undefined>(undefined)
  const [areImagesUploading, setAreImagesUploading] = React.useState<boolean>(false)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getConsultationReasonsForSelect()
  }, [])

  const getConsultationReasonsForSelect = async () => {
    let reasons: string[] = [];
    await getConsultationReasons().then((res) => {
      for (let i = 0; i < res.length; i++) {
        reasons.push(res[i].name);
      }
    });
    setConsultationReasons(reasons)
    return reasons;
  };

  const toggleImageModal = () => {
    setUploadImageModalIsOpen(!uploadImageModalIsOpen)
  }

  const updateCreateApptPayload = () => {
    let updatedWhySection: IWhy = {
      consultationType,
      reason: updateReason(),
      symptoms,
      attachments
    }
    dispatch(whySectionUpdated(updatedWhySection))
    let updatedWhoSection: IWho = {
      ...who,
      stateId
    }
    dispatch(whoSectionUpdated(updatedWhoSection))
  }

  const updateReason = () => {
    if (isOtherReasonSelected) {
      return otherReason ? 'Other: ' + otherReason : 'Other'
    } else {
      return reasonForVisit
    }
  }

  const onDropImage = (files: File[]) => {
    const idx = getFormDataSize(dataToBeUploaded)
    const data = dataToBeUploaded || new FormData();
    for (let i = idx; i < idx + files.length; i++) {
      data.append('file' + i, files[i]);
    }
    setDataToBeUploaded(data)
  };

  const getFormDataSize = (fd?: FormData) => {
    if (!fd) {
      return 0;
    }
    var res = Array.from(fd.entries(), ([key, prop]) => (
      {
        [key]: {
          "ContentLength":
            typeof prop === "string"
              ? prop.length
              : prop.size
        }
      }));
    return res.length
  }

  const uploadFile = async () => {
    if (dataToBeUploaded) {
      setAreImagesUploading(true)
      const photoTokens: IPhotoToken[] = await uploadMultipleImagesForPatient(dataToBeUploaded)
      await fetchBase64Images(photoTokens)
      setAreImagesUploading(false)
      setDataToBeUploaded(undefined)
    }
    toggleImageModal();
  };

  const fetchBase64Images = async (photoTokens?: IPhotoToken[]) => {
    if (photoTokens) {
      photoTokens.forEach(async (photo) => {
        try {
          dispatch(attachmentAdded(photo.photoToken))
          const res = await getImage(photo.photoToken)
          dispatch(displayedImageAdded(res.imageBase64))
        } catch (err) {
          console.log(err)
        }
      })
    }
  }

  const handleReasonForVisitChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === ReasonForVisit.Placeholder) {
      setIsOtherReasonSelected(false)
      dispatch(reasonForVisitSelected(undefined))
      dispatch(otherReasonUpdated(undefined))
    } else if (e.target.value === ReasonForVisit.Other) {
      setIsOtherReasonSelected(true)
      dispatch(reasonForVisitSelected(e.target.value))
    } else {
      setIsOtherReasonSelected(false)
      dispatch(reasonForVisitSelected(e.target.value))
      dispatch(otherReasonUpdated(undefined))
    }
  }

  const getRadioButtonsForConsultationType = (): Array<IRadioButtonProps> => {
    return [
      {
        name: 'consultation',
        id: 'consultTypePhysician',
        checked: isConsultTypePhysiscianConsultation(),
        icon: 'fas fa-user-md fa-stack-1x',
        color: BootstrapTextColor.Primary,
        label: ConsultationType.PhysicianConsultation,
        onChange: handlePhysicianConsultationSelected,
      },
      {
        name: 'consultation',
        id: 'consultTypePrescription',
        checked: isConsultTypePrescriptionRefill(),
        icon: 'fas fa-pills fa-stack-1x',
        color: BootstrapTextColor.Primary,
        label: ConsultationType.PrescriptionRefill,
        onChange: handlePrescriptionRefillSelected,
      }
    ]
  }

  const isConsultTypePhysiscianConsultation = () => {
    return consultationType === ConsultationType.PhysicianConsultation
  }

  const isConsultTypePrescriptionRefill = () => {
    return consultationType === ConsultationType.PrescriptionRefill
  }

  const handlePhysicianConsultationSelected = () => {
    openFormDataSection()
    dispatch(consultationTypeSelected(ConsultationType.PhysicianConsultation))
    dispatch(reasonForVisitSelected(undefined))
  }

  const handlePrescriptionRefillSelected = () => {
    openFormDataSection()
    dispatch(consultationTypeSelected(ConsultationType.PrescriptionRefill))
    dispatch(reasonForVisitSelected(ReasonForVisit.RefillMedication))
    dispatch(otherReasonUpdated(undefined))
    setIsOtherReasonSelected(false)
  }

  const openFormDataSection = () => {
    if (!isFormDataSectionOpen) {
      setIsFormDataSectionOpen(true)
    }
  }
  const handleNextClicked = () => {
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true))
    }
    else {

      updateCreateApptPayload()
      dispatch(errorsDisplayed(false))
      dispatch(nextStepInitiated())
    }
  }
  const renderFlowNav = () => {
    return (
      <div className="flow-nav-tools">
        <Button
          onClick={() => { dispatch(previousStepInitiated()) }}
          size="lg"
          className="control-nav ml-auto"
          outline
          color="secondary"
        >
          Back
        </Button>
        <span className="text-muted mt-2">
          Step 4 of 7
        </span>
        <Button
          color="primary"
          size="lg"
          className="control-nav mr-auto"
          onClick={handleNextClicked}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderTitle = () => {
    let fullName = ''
    if (dependent) {
      fullName = `${dependent.firstname} ${dependent.lastname}`
    } else {
      fullName = `${contactInfo?.firstname} ${contactInfo?.lastname}`
    }
    return `Book an Appointment for ${fullName}`
  }

  const renderAvailableUSStates = () => {
    return availableUSStates.map((state, idx) => {
      return (
        <option key={idx} value={state.id} >
          {state.name}
        </option>
      );
    });
  };

  const renderFormData = () => {
    return (
      <Form>
        <div id="consultationSelected">
          {renderFormTitle()}
          <FormGroup>
            <Label>Reason for Visit:</Label>
            {renderReasonsForVisitInput()}
          </FormGroup>
          <FormGroup style={{ marginBottom: '10px' }}>
            {renderSymptomsAndPrescriptionTitle()}
            <Input type="textarea" name="symptomsExplanationTextbox" className="form-control" rows={3}
              placeholder="Enter your symptoms (ex. sore throat, migraines, etc.)" value={symptoms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(symptomsUpdated(e.target.value))
              }}
            />
          </FormGroup>
          <FormGroup>
            {renderFileTitle()}
            <Button color="link" size="sm" className="ml-auto"
              onClick={toggleImageModal}>
              Upload
            </Button>
            <Modal isOpen={uploadImageModalIsOpen} toggle={toggleImageModal} >
              <ModalHeader toggle={toggleImageModal}>
                <h5 id="templatesModal" className="modal-title">
                  Update picture
                </h5>
              </ModalHeader>
              <ModalBody>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose image"
                  onChange={onDropImage}
                  imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  withPreview={true}
                  singleImage={false}
                />
                {renderLoadingSpinner()}
                <Button color="primary" size="sm" block={true} className="w-50 m-auto" onClick={uploadFile} >
                  Save
                </Button>
              </ModalBody>
            </Modal>
            {renderPhotoGallery()}
          </FormGroup>
        </div>
      </Form>
    );
  };

  const renderFormTitle = () => {
    let title = 'Please enter a brief explanation of your symptoms'
    if (isConsultTypePrescriptionRefill()) {
      title += `, current presription(s)`
    }
    title += ' and upload any related photos:'
    return (
      <Row>
        <CardTitle >
          {title}
        </CardTitle>
      </Row>
    );
  };

  const renderReasonsForVisitInput = () => {
    if (isConsultTypePhysiscianConsultation()) {
      return (
        <Form>
          <Input
            style={{ marginBottom: '10px' }} type="select" className="form-control" name="patientReasonForVisit" value={reasonForVisit}
            onChange={handleReasonForVisitChanged}
            required
          >
            <option value={ReasonForVisit.Placeholder} selected>Please Select</option>
            {consultationReasons.map((reason, index) => {
              return <option key={index}>{reason}</option>;
            })}
          </Input>
          <Input type="textarea" hidden={!isOtherReasonSelected} value={otherReason} name="otherReason" required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(otherReasonUpdated(e.target.value))
            }}
          ></Input>
        </Form>
      );
    } else {
      return (
        <Input type="select" className="form-control" id="patientReasonForVisit" value={reasonForVisit} disabled>
          <option value={ReasonForVisit.RefillMedication}>Refill Medication</option>
        </Input>
      );
    }
  };

  const renderSymptomsAndPrescriptionTitle = () => {
    if (isConsultTypePhysiscianConsultation())
      return <Label>Please explain your current symptoms:</Label>;
    else
      return (
        <Label>Please list your current presription(s) and symptoms:</Label>
      );
  };

  const renderFileTitle = () => {
    if (isConsultTypePhysiscianConsultation())
      return <Label>Feel free to upload any relevant photos</Label>;
    else
      return (
        <Label>
          Take a picture of your presription(s) looking to be refilled
        </Label>
      );
  };

  const renderLoadingSpinner = () => {
    if (areImagesUploading) {
      return <LoadingSpinner text='Uploading Images' />
    }
  }

  const renderPhotoGallery = () => {
    let images: { src: string; width: number; height: number }[] = [];
    displayedImages?.forEach((file: string) => {
      images.push({
        src: generateImage(file),
        width: 1,
        height: 1,
      });
    });
    return (
      <CardBody hidden={images.length === 0}>
        <Container>
          <Gallery photos={images} columns={4} />
        </Container>
      </CardBody>
    );
  };

  const generateImage = (file: string): any => {
    if (file) {
      const imageUrl = 'data:image/jpeg;base64, ' + file;
      return imageUrl;
    }
    return null;
  };

  return (
    <SideCardFrame flowNav={renderFlowNav()} navItem={navItem} bodyClassName="tc-bg" smallCard={smallCard}>
      <CardBody>
        <Form>
          <div className="d-flex px-2">
            <h5 className="mb-0">
              {renderTitle()}
            </h5>
            <Link
              to={Routes.homePatient}
              className="btn btn-link btn-sm ml-auto"
            >
              Cancel
            </Link>
          </div>
          <hr className="mt-2 mb-4"></hr>
          <Container>
            <CardTitle className='mb-3'>
              Choose a US state the doctor should be certified at:<Asterisk />
            </CardTitle>
            <FormGroup style={{ marginBottom: '30px' }}>
              <Label for="state">State  <Asterisk /></Label>
              <Input type="select" id="state" name="state" value={stateId} required hidde
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(stateSelected(Number.parseInt(e.target.value)))
                }}
                invalid={(!isDefined(stateId) || !isNumber(stateId!)) && showErrors}
              >
                <option value={-1} selected disabled>Select state</option>
                {renderAvailableUSStates()}

              </Input>
              <FormFeedback hidden={isDefined(stateId) && isNumber(stateId!)} className='error'>
                {AddressErrorMessages.selectState}
              </FormFeedback>
            </FormGroup>
            <hr className='mb-3'></hr>
            <FormGroup>
              <CardTitle>
                Choose the type of consultation you are looking for:
              </CardTitle>
            </FormGroup>
            <div className="radio-tile-group mb-0">
              <RadioGroup
                radioButtons={getRadioButtonsForConsultationType()}
              />
            </div>
          </Container>
          <div className="flow-nav-tools">
            <Collapse isOpen={isFormDataSectionOpen}>
              <Card style={{ border: 'none', marginTop: '10px' }}>
                <CardBody className='pt-1'>
                  {renderFormData()}
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </Form>
      </CardBody>
    </SideCardFrame>
  );
}

export default BookingConsultationStep