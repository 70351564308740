// import { render } from "@testing-library/react";
import React, { useState, FormEvent } from 'react';
import { TextField } from "../../components/commons/TextField";
import { SmallCardFrame } from "../../components/layout/SmallCardFrame";
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes } from "../../config/Routes";
import { useAdminAuthService } from "../../core/service/services";
import { CardBody, Form, CardTitle } from 'reactstrap';
import { UserConfig } from '../../config/userConfig';
import { LoadingButton } from '../../components/commons/LoadingButton';

interface IProps extends RouteComponentProps {
  targetPath?: string
}

export const AdminLogin: React.FC<IProps> = (props) => {

  const [errorMessage, setErrorMessage] = useState('');

  
  const emailField = new TextField({
    type: "text",
    labelText: "Email address",
    placeholder: "Email address",
    textChanged: () => { },
  });
  const passwordFiled = new TextField({
    type: "password",
    labelText: "Password",
    placeholder: "Password",
    textChanged: () => { },
  });

  const authService = useAdminAuthService();

  const data = {
    email: "",
    password: "",
  };

  React.useEffect(() => {

    if (authService.service.status == "loaded") {
      UserConfig.setToken(authService.service.payload.accessToken);
      UserConfig.setUserInfo(authService.service.payload.userInfo);
      UserConfig.setUserType("admin")
      props.targetPath? navigate(props.targetPath) : navigate(Routes.adminConsole)
    } else if (authService.service.status == "error") {
      // setErrorMessage("Password is wrong or does not exist");
    } else if (authService.service.status == "custormError" ){
      setErrorMessage(authService.service.errorResponse.details);
    }

  });

  const callLoginService = (e: FormEvent) => {
    e.preventDefault()
    data.email = emailField.getValue() || "";
    data.password = passwordFiled.getValue() || "";

    authService
      .callService(data)
      .then((resData) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SmallCardFrame navItem bodyClassName="tc-bg">
      <CardBody>
        <CardTitle className="text-center">Admin Portal</CardTitle>
        <hr />
        <fieldset disabled={authService.service.status == "loading"}>
        <Form className="form-signin" onSubmit={(e) => callLoginService(e)}>
          {emailField.render()}
          {passwordFiled.render()}
          <div className="text-center mb-4 mt-4" style={{color: "red"}}>
            {errorMessage}
          </div>
          
          <LoadingButton type="submit" isLoading={authService.service.status == "loading"} 
            color="primary" block={true} className="w-50 m-auto text-uppercase mt-4">
              Sign In
          </LoadingButton>

          <div className="text-center mb-4 mt-4">
            Forgot Password? {" "}
            <a onClick={(e) => { navigate(Routes.requestReset, { state: { email: emailField.getValue(), userType: "admin" } }); e.preventDefault(); return false; }} href="#" className=" mt-2">
              Reset Here
            </a>
          </div>
        </Form>
        </fieldset>
      </CardBody>
    </SmallCardFrame>
  );
};
