import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap';

interface DateProps {
  selectedDate?: string | Date;
  showDay?: boolean;
  showMonth?: boolean;
  showYear?: boolean;
  showValidation?: boolean;
  onChange?: (date: string) => any;
}

enum DefaultDate {
  Day = -1,
  Month = -2,
  Year = -3,
}

const NewDatePicker = ({
  selectedDate,
  showDay = true,
  showMonth = true,
  showYear = true,
  showValidation = false,
  onChange
}: DateProps) => {
  const initializeDate = (index: number) => {
    if (typeof selectedDate === 'string') {
      return parseInt(selectedDate.split('-')[index]);
    } else {
      return parseInt(moment(selectedDate).format('YYYY-MM-DD').split('-')[index]);
    }
  };
  const selectedDay = selectedDate ? initializeDate(2) : undefined;
  const selectedMonth = selectedDate ? initializeDate(1) : undefined;
  const selectedYear = selectedDate ? initializeDate(0) : undefined;
  const daysList: Array<number> = Array(31)
    .fill(1)
    .map((element, index) => element + index);
  const monthsList = moment.months();
  const startingYear = moment();
  const endingYear = moment().add(-100, 'y');
  const yearsList: Array<number> = [];
  const [validDay, setValidDay] = useState<boolean>(false);
  const [validMonth, setValidMonth] = useState<boolean>(false);
  const [validYear, setValidYear] = useState<boolean>(false);

  while (endingYear.diff(startingYear, 'years') !== 0) {
    yearsList.push(parseInt(startingYear.format('YYYY')));
    startingYear.add(-1, 'year');
  }

  useEffect(() => {
    if (selectedDate) {
      setValidDay(true);
      setValidMonth(true);
      setValidYear(true);
    }
  }, [selectedDate]);

  const renderDaysList = () => {
    return daysList.map((val, index) => {
      return (
        <option key={index} value={val} selected={selectedDay === val}>
          {val}
        </option>
      );
    });
  };

  const renderMonthsList = () => {
    return monthsList.map((monthLabel, index) => {
      const value = moment().month(monthLabel).format("MM")
      return (
        <option key={index} value={value} selected={selectedMonth === index + 1}>
          {monthLabel}
        </option>
      );
    });
  };

  const renderYearsList = () => {
    return yearsList.map((val, index) => {
      return (
        <option key={index} value={val} selected={selectedYear === val}>
          {val}
        </option>
      );
    });
  };

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const data: FormData = new FormData(e.currentTarget);
    const formData: [string, FormDataEntryValue][] = Array.from(data.entries());
    console.log(formData);
    const day = getValue(formData, 'day');
    const month = getValue(formData, 'month');
    const year = getValue(formData, 'year');
    if (onChange && day && month && year) {
      const newDob = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD').format('YYYY-MM-DD');
      onChange(newDob);
    }
  };

  const getValue = (arr: [string, FormDataEntryValue][], field: string) => {
    let value = arr.filter(function (record) {
      return record[0] === field;
    });
    if (!isEmpty(value)) {
      return value[0][1];
    }
  };

  const handleDayChange = () => {
    setValidDay(true);
  };

  const handleMonthChange = () => {
    setValidMonth(true);
  };

  const handleYearChange = () => {
    setValidYear(true);
  };

  return (
    <Form onChange={handleChange}>
      <Row>
        <Col>
          <Input type='select' placeholder='Day' name='day' hidden={!showDay} invalid={!validDay && showValidation} onChange={handleDayChange}>
            <option value={DefaultDate.Day} selected={selectedDate === undefined} disabled>
              Day
            </option>
            {renderDaysList()}
          </Input>
          <FormFeedback className='error' hidden={validDay}>
            Select Day
          </FormFeedback>
        </Col>

        <Col>
          <Input type='select' placeholder='Month' name='month' hidden={!showMonth} invalid={!validMonth && showValidation} onChange={handleMonthChange}>
            <option value={DefaultDate.Month} selected={selectedDate === undefined} disabled>
              Month
            </option>
            {renderMonthsList()}
          </Input>
          <FormFeedback className='error' hidden={validMonth}>
            Select Month
          </FormFeedback>
        </Col>
        <Col>
          <Input type='select' placeholder='Year' name='year' hidden={!showYear} invalid={!validYear && showValidation} onChange={handleYearChange}>
            <option value={DefaultDate.Year} selected={selectedDate === undefined} disabled>
              Year
            </option>
            {renderYearsList()}
          </Input>
          <FormFeedback className='error' hidden={validYear}>
            Select Year
          </FormFeedback>
        </Col>
      </Row>
    </Form>
  );
};

export default NewDatePicker;
