import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { bookingCleared } from '../booking/bookingSlice'
import { ICreditCardListingItem } from './../../types/interfaces/otherInterfaces'

export interface ICreditCardsState {
    creditCards: Array<ICreditCardListingItem>
}

const initialState: ICreditCardsState = {
    creditCards: []
}

const creditCardsSlice = createSlice({
    name: 'creditCards',
    initialState,
    reducers: {
        creditCardsUpdated: (state, action: PayloadAction<Array<ICreditCardListingItem>>) => {
            state.creditCards = action.payload.map(card => card)
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<ICreditCardsState>) => {
        builder.addCase(bookingCleared, () => {
            return initialState
        })
    }
})

export const {
    creditCardsUpdated,
} = creditCardsSlice.actions

export const selectCreditCards = (state: RootState) => state.creditCards.creditCards

export default creditCardsSlice.reducer
