import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { IAppointmentBiller } from "./HomeBiller";

interface IPatientProps {
    appointment?: IAppointmentBiller;
    isOpen: boolean
    toggleModal: any
}
export const PatientModal = (props: IPatientProps) => {

    useEffect(() => {
    }, [])


    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} >
                <ModalHeader toggle={props.toggleModal}>Patient Details</ModalHeader>
                <ModalBody >
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Patient Name" + " : "}</strong>
                            {props.appointment?.patient?.name}
                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"ID" + " : "}</strong>
                            {props.appointment?.patient?.id}
                        </div>
                    </li><br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Date Of Birth" + " : "}</strong>
                            {moment(props.appointment?.patient?.dateOfBirth).format("MM/DD/YYYY")}

                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Email" + " : "}</strong>
                            {props.appointment?.patient?.email}
                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Phone Number" + " : "}</strong>
                            {props.appointment?.patient?.phoneNumber}
                        </div>
                    </li><br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Gender" + " : "}</strong>
                            {props.appointment?.patient?.gender === 'f' ? <><i className="fa fa-female"></i> Female</> : <><i className="fa fa-male"></i>Male</>}
                            { }
                        </div>
                    </li><br />

                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Address" + " : "}</strong>
                            {props.appointment?.patient?.address}
                        </div>
                    </li><br />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.toggleModal}>Close</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}