import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { IPayerPreview } from '../../types/interfaces/bookingInterfaces'
import { bookingCleared } from '../booking/bookingSlice';

export interface IInsuranceDetailsState {
    payersList?: IPayerPreview[];
    payerCodeSelected: string;
    payerNameSelected: string;
    payerSelectedAfterConf: IPayerPreview | undefined;
    insuranceIdSelected: any
}

const initialState: IInsuranceDetailsState = {
    payerCodeSelected: '',
    payerNameSelected: '',
    payerSelectedAfterConf: undefined,
    insuranceIdSelected: 2
}

const insuranceDetailsSlice = createSlice({
    name: 'insuranceDetails',
    initialState,
    reducers: {
        payersListFetched: (state, action: PayloadAction<Array<IPayerPreview>>) => {
            state.payersList = action.payload.map(payer => payer)
        },
        payerCodeSelectedChanged: (state, action: PayloadAction<string>) => {
            state.payerCodeSelected = action.payload
        },
        payerNameSelectedChanged: (state, action: PayloadAction<string>) => {
            state.payerNameSelected = action.payload
        },
        payerSelectedAfterConfChanged: (state, action: PayloadAction<IPayerPreview | undefined>) => {
            state.payerSelectedAfterConf = action.payload
        },
        insuranceIdSelectedChanged: (state, action: PayloadAction<any>) => {
            state.insuranceIdSelected = action.payload
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<IInsuranceDetailsState>) => {
        builder.addCase(bookingCleared, () => {
            return initialState
        })
    }
})

export const {
    payersListFetched,
    payerCodeSelectedChanged,
    payerNameSelectedChanged,
    payerSelectedAfterConfChanged,
    insuranceIdSelectedChanged
} = insuranceDetailsSlice.actions

export const selectPayersList = (state: RootState) => state.insuranceDetails.payersList
export const selectPayerCodeSelected = (state: RootState) => state.insuranceDetails.payerCodeSelected
export const selectPayerNameSelected = (state: RootState) => state.insuranceDetails.payerNameSelected
export const selectInsuranceIdSelected = (state: RootState) => state.insuranceDetails.insuranceIdSelected
export const selectPayerSelectedAfterConf = (state: RootState) => state.insuranceDetails.payerSelectedAfterConf

export default insuranceDetailsSlice.reducer
