import React, { useState, useEffect } from 'react'
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { Button, CardBody, Table, Spinner } from 'reactstrap';
import { Routes } from '../../config/Routes';

import { BigCardFrame } from '../../components/layout/BigCardFrame';
import { navItem } from '../../App';
import { getPayerList, addNewPayer, editPayer, deletePayer } from '../../core/service/services';
import { CustomedPagination } from '../dashboard/admin/Pagination';
import { strings } from '../../Constants'
import AddEditPayerModal from './AddEditPayerModal';


interface IProps extends RouteComponentProps { }
export interface IPayerPreview {
    id: string | null
    name: string;
    description: string | null;
    code: string;

}

export const InsuranceAdmin: React.FC<IProps> = (props) => {

    const [payerList, setPayersList] = useState<IPayerPreview[]>([])

    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(50);
    const [pageCount, setPageCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddNewPayer, setIsAddNewPayer] = useState(true);
    const [currentPayerToEdit, setCurrentPayerToEdit] = useState<IPayerPreview | null>(null);


    const [showAddEditPayer, setShowAddEditPayer] = useState(false);
    const toggleShowAddEditPayer = () => setShowAddEditPayer(!showAddEditPayer);


    useEffect(() => {
        setIsLoading(true)
        fetchPayers(pageIndex, pageSize)


    }, [])

    const fetchPayers = (index: number, size: number) => {
        getPayerList(index, size).then((result) => {
            setPayersList(result.payers);
            result.pageSize != 0 && setPageCount(Math.ceil(result.payers.length / result.pageSize))
            setPageIndex(result.pageIndex)
            setPageSize(result.pageSize)
            setIsLoading(false)


        }).catch(
            (e) => {
                console.log(e)
                setIsLoading(false)

            }
        );

    }
    const flowNav = (
        <div className="flow-nav-tools">
            <Button color="primary" size="lg" onClick={() => navigate(Routes.adminConsole)} className="control-nav">
                Back
            </Button>
        </div>
    );



    const onAddClicked = () => {
        setIsAddNewPayer(true);
        setCurrentPayerToEdit(null)
        toggleShowAddEditPayer();
    }
    const onEditClicked = (payer: IPayerPreview, e: any) => {
        e.preventDefault()
        setIsAddNewPayer(false);
        setCurrentPayerToEdit(payer)
        toggleShowAddEditPayer();
        console.log(e, payer)
    }

    const onDeleteClicked = (payer: IPayerPreview, e: any) => {
        e.preventDefault()
        setIsAddNewPayer(false);
        setIsLoading(true)
        deletePayer(payer).then((result) => {
            console.log("result", result)
        }).catch(
            (e) => {
                console.log(e)


            }
        );
        fetchPayers(pageIndex, pageSize)
        console.log(e, payer)

    }
    const onSaveNewPayer = (e: IPayerPreview) => {
        //props.onEdit(e);
        //backend call to add or edit
        console.log("Payer Info", e)
        if (isAddNewPayer) {
            //backend call to add 
            setIsLoading(true)
            addNewPayer(e).then((result: any) => {
                console.log("result", result)

                const tempList = [...payerList];
                tempList.push(result)
                setPayersList(tempList)

            }).catch(
                (e) => {
                    console.log(e)


                }
            );
            fetchPayers(pageIndex, pageSize)
        } else {
            //backend call to edit 
            setIsLoading(true)
            console.log("object e", e)
            editPayer(e).then((result: any) => {
                console.log("result", result)

                const tempList = [...payerList];
                console.log("result", tempList)
                let index = 0
                console.log("index", index)

                for (let i = 0; i < tempList.length; i++) {
                    if (tempList[i].id === result.id) {
                        index = i
                    }
                }
                const mutatedItem = tempList[index]

                console.log("mutatedItem", mutatedItem)

                mutatedItem.code = result.code
                mutatedItem.name = result.name
                mutatedItem.description = result.description
                setPayersList(tempList)
                console.log("result", tempList)
                console.log("result", result)

            }).catch(
                (e) => {
                    console.log(e)


                }
            );
        }
        toggleShowAddEditPayer();
    }

    const renderLoadingState = () => {
        if (isLoading) {
            return <Spinner color="primary" style={{ alignSelf: 'center' }} />;
        } else {
            return null

        }
        return null;
    };


    return (
        <>


            <AddEditPayerModal modal={showAddEditPayer} data={currentPayerToEdit} toggle={toggleShowAddEditPayer} onSave={(e: IPayerPreview) => { onSaveNewPayer(e); }} isAdd={isAddNewPayer} />
            <BigCardFrame
                flowNav={flowNav}
                navItem={navItem}
                bodyClassName="tc-bg"
                horizontal={true}
            >
                <CardBody>

                    <div className="d-flex px-2">
                        <h5 className="mb-0">{strings.adminConsoleStrings.payer_list}</h5>
                        <Link to={Routes.adminConsole} className="btn-sm ml-auto">
                            Back
                        </Link>
                    </div>
                    <hr className="mt-2 mb-4" />

                    <Table>
                        <thead>
                            <th>{strings.adminConsoleStrings.payer_name}</th>
                            <th>{strings.adminConsoleStrings.payer_description}</th>
                            <th>{strings.adminConsoleStrings.payer_code}</th>
                            <th>{strings.adminConsoleStrings.payer_action}</th>
                        </thead>
                        <tbody>
                            {payerList.map(item => (
                                <tr key={item.id || undefined}>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.code}</td>
                                    <td>
                                        <Button color="link" size="sm" className="pt-0" onClick={(e) => onEditClicked(item, e)}>
                                            <span className="sr-only">Edit</span>{" "}
                                            <i className="fa fa-edit fa-lg"></i>
                                        </Button>
                                        <Button color="link" size="sm" className="pt-0" onClick={(e) => onDeleteClicked(item, e)}>
                                            <span className="sr-only">Edit</span>{" "}
                                            <i className="fa fa-trash fa-lg"></i>
                                        </Button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {(!isLoading) && <Button
                        //   disabled={isLoading === true}

                        data-toggle="modal"
                        onClick={onAddClicked}
                        size="sm"
                        outline
                        color="primary"
                        className="btn-block w-50 m-auto"
                    // alt="Edit template"
                    >
                        {strings.adminConsoleStrings.addPayerButton}
                    </Button>}
                </CardBody>
                {payerList.length > 0 && pageCount &&
                    <CustomedPagination
                        pageCount={pageCount}
                        pageNum={pageIndex}
                        onPaginate={(idx: any) => {
                            setPageIndex(idx);
                            fetchPayers(idx, 10)
                        }}
                    />}
            </BigCardFrame>
        </>
    )
}