import React, { useEffect, useState } from 'react'
import { Button, CardBody, CardTitle, Row, Col, Table, UncontrolledTooltip } from 'reactstrap';
import { IFullAppointment } from '../../book-appointment/AppointmentModels';
import { getPatientAppointmentDetails, getPatientInfo, IPatientInfo, 
        IPatientMeasurment, getSelectedConditions, getPatientMeasurments, 
        getAllConditionsLookup, 
        getImage,
        IAddendum, IPrescription,
        getPhysicianNotesForPatient,
        getFollowupNotesForPatient,
        getAddendumsForPatient,
        getPrescriptionsForPatient,
        getAbsenceNoteForPatient,
        IAbsenceNotesForReport,
        getMedicationAllergies,
        sendAbsenceNoteByEmailByAgent,
        sendAppointmentDetailsByEmailByAgent,
        downloadAbsenceNote} from '../../../core/service/services';
import moment from 'moment';
import { RouteComponentProps, navigate} from '@reach/router';
import { GalleryModal } from '../../../components/commons/GalleryModal';
import { Routes } from '../../../config/Routes';
import { ChatCard } from '../../chat-module/ChatCard';
import { IMessage } from '../../chat-module/MessageModel';
import { ConsecutiveCardFrame } from '../../../components/layout/ConsecutiveCardFrame';
import { AppointmentDocument } from '../../book-appointment/appointment-documents/AppointmentDetailsDocument';
import { usePDF } from '@react-pdf/renderer';
import { initSocket } from '../../../core/socket/SocketInitializer';
import { IFullAllergy } from '../../../components/medicalHistory/MedicationAllergiesDoseSpot';
import { navItem, toastError, toastSuccess } from '../../../App';
import { UserConfig } from '../../../config/userConfig';
import { IPatientForAgents } from '../agent/PatientsOverview';
import { convertToTimezoneWithUsState } from '../../../core/service/TimeZone';


interface IProps extends RouteComponentProps {
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void
}

export const PatientAppointmentDetails: React.FC<IProps> = (props) => {
  const agentAsPatient: IPatientForAgents =
    UserConfig.getPatientLoggedByAgent();
  const patientAsAgent = UserConfig.getPatientLoggedByAgent()
  const [appointmentId, setAppointmentId] = useState<string>()
  const [appointment, setAppointment] = useState<IFullAppointment>()
  const [patientInfo, setPatientInfo] = useState<IPatientInfo>()
  const [requestorInfo, setRequestorInfo] = useState<IPatientInfo>()
  const [medications, setMedications] = useState<IFullAllergy[]>([])
  const [conditions, setConditions] = useState<number[]>([])
  const [measurement, setMeasurement] = useState<IPatientMeasurment>()
  const [allSymptoms, setAllSymptoms] = useState<{ id: number, name: string}[]
  >([])
  const [attachments, setAttachments] = useState<string[]>([])
  const [galleryModal, toggleGalleryModal] = useState<boolean>(false)
  const [imgIdx, setImgIdx] = useState<number>(0)
  const [physicianNotes, setPhysicianNotes] = useState<string>()
  const [followupNotes, setFollowupNotes] = useState<string>()
  const [addendums, setAddendums] = useState<IAddendum[]>([])
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([])
  const [show, setShow] = useState<boolean>(false);
  const [absenceNote, setAbsenceNote] = useState<IAbsenceNotesForReport>()
  const [showApptPdf, setShowApptPdf] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>();
  const [absenceNoteButtonstate, setAbsenceNoteButtonState] = useState<boolean>(false);
  const [appointmentButtonstate, setAppointmentButtonState] = useState<boolean>(false);

  const toggleGallery = () => {
    toggleGalleryModal(!galleryModal)
  }

  const pdfTemplate = (
    <AppointmentDocument
      data={appointment}
      patientInfo={patientInfo}
      physicianNotes={physicianNotes}
      followupNotes={followupNotes}
      measurement={measurement}
      conditions={conditions}
      symptoms={allSymptoms}
      prescriptions={prescriptions}
      medications={medications}
    />
  );

  const [pdfFile, updatePdfFile] = usePDF({ document: pdfTemplate });

  const getData = () => {
    const search = new URLSearchParams(props.location?.search);
    const appointmentId = search.get("appointmentId") || "";
    setAppointmentId(appointmentId)

    async function fetchData() {
      const app = await getPatientAppointmentDetails(appointmentId);
      setAppointment(app)

      //Patient/Requestor Info
      const pInfo = await getPatientInfo(app.who.patientId);

      setPatientInfo(pInfo)
      const rInfo = await getPatientInfo(app.who.requestorId);
        setRequestorInfo(rInfo)

      //Medical Profile
      const meds = await getMedicationAllergies(app.who.patientId)
      setMedications(meds)
      const conds = await getSelectedConditions(app.who.patientId)
      setConditions(conds.symptoms)
      const msrmnts = await getPatientMeasurments(app.who.patientId)
      setMeasurement(msrmnts)
      const allConds = await getAllConditionsLookup()
      setAllSymptoms(allConds)

      //Treatment Plan
      if (app.status === "Archived" || app.status === "Completed") {
        const phNotes = await getPhysicianNotesForPatient(appointmentId)
        setPhysicianNotes(phNotes.value)
        const flNotes = await getFollowupNotesForPatient(appointmentId)
        setFollowupNotes(flNotes.value)
        const addndms = await getAddendumsForPatient(appointmentId)
        setAddendums(addndms.items)
        const prscrptns = await getPrescriptionsForPatient(appointmentId)
        setPrescriptions(prscrptns.items)
      }
      let atts: string[] = []
      if (app.why.attachments) {
        for (const item of app.why.attachments) {
          const res = await getImage(item)
          if (res && res.imageBase64) atts.push(res.imageBase64)
        }
        setAttachments(atts)
      }
      await getAbsenceNote(appointmentId)
      setShowApptPdf(true);
    }
    fetchData()
  }
  
  useEffect(() => {
    getData()
    initSocket(onMessageReceived, ()=>{});
  }
  , [])

  useEffect(() => updatePdfFile( pdfTemplate ) , [
    appointment,
    patientInfo,
    physicianNotes,
    followupNotes,
    measurement,
    conditions,
    allSymptoms,
    prescriptions,
    medications,
  ]);

  const sendAppointmentDetailsByEmail =async()=>{
    setAppointmentButtonState(true);
    appointmentId && sendAppointmentDetailsByEmailByAgent(appointmentId, patientAsAgent.id)
    .then(()=>{
      setAppointmentButtonState(false);
      toastSuccess("Appointment Details Report sent successfully!")
    })
    .catch((error)=>{
      setAppointmentButtonState(false);
      toastError(error);
    })
  }
  const sendAbsenceNoteByEmail =async()=>{
    setAbsenceNoteButtonState(true);
    appointmentId && sendAbsenceNoteByEmailByAgent(appointmentId, patientAsAgent.id)
    .then(()=>{
      setAbsenceNoteButtonState(false);
      toastSuccess("Absence Note Report sent successfully!")
    })
    .catch((error)=>{
      toastError(error);
      setAbsenceNoteButtonState(false);
    })
  }
  const renderFlowNav = () => {
    return (
      <div className="flow-nav-tools">
        <Button
          color="primary"
          size="lg"
          className="control-nav ml-auto"
          onClick={() => navigate(Routes.homePatient)}
        >
          Back
        </Button>
      </div>
    )
  }
  const onMessageReceived = (message: IMessage) => {
    setMessage(message);
  }
  const getSmallCard = () => {
    if (appointment?.status === "Completed" || appointment?.status === "Locked" || appointment?.status === "Started") {
      return (
        <ChatCard appointmentId={appointmentId}  addedMessage={message} 
          onMessageReceived={onMessageReceived} receiverName={appointment?.physician.name + ", " + appointment?.physician.title}/>
      )
    }
  }
  const getAbsenceNote = async (appointmentId:string) => {
    await getAbsenceNoteForPatient(appointmentId).then((res) => {
      if (res.creationDate && res.from && res.to) setAbsenceNote(res);
    }).catch((error) => {
      console.log("error: "+error)
    })
}
  const renderHeaderInfo = () => {
    if (appointment?.physician){
      return (
        <CardTitle>
          {"Below are the appointment details" +
            appointmentPhysicianDetails() +
            " on " +
            appointment.when.patientDateTime?.date +
            " at " +
            appointment.when.patientDateTime?.time}
        </CardTitle>
      );
    }
  }

  const appointmentPhysicianDetails = () => {
    if (appointment?.physician && appointment?.physician.name !== "")
      return ` and treatment plan as per your visit with Dr. ${appointment?.physician.name} ${appointment?.physician.title}`;
    else return "";
  };

  const infoItem = (label: string, value?: string, long?: boolean) => (
    <Col sm={long ? "12" : "6"} className="mb-3">
      <dl>
        <dt>{label}:</dt>
        <dd>{value}</dd>
      </dl>
    </Col>
  )

  const getMedications = () => {
    const meds = medications.map(item => {
      return item.Name
    })
    return meds.join(", ")
  }

  const getConditions = () => {
    const conds: string[] = []
    allSymptoms.forEach(item => {
      if (conditions.includes(item.id)) {
        conds.push(item.name)
      }
    })
    return conds.join(", ")
  }
  const getSelfReportedMedication = () => {
    return appointment?.who.patient.selfReportedMedications?.map(s => s.name).join(", ") || "None"
  }
  const renderPatientDetails = () => {
    return (
      <>
        <h5 className="mb-3">Patient Details</h5>
        <Row className="ml-2">
        {infoItem("Patient Full Name", patientInfo?.contact?.firstname + " " + patientInfo?.contact?.lastname)}
        {infoItem("Requestor Full Name", requestorInfo?.contact?.firstname + " " + requestorInfo?.contact?.lastname)}
        {infoItem("Date of Birth",
                moment(patientInfo?.contact?.dateOfBirth).format('MM-DD-YYYY'))}
        {infoItem("Gender", measurement?.gender === "m" ? "Male" : 
              ("Female (Pregnant: " + (measurement?.pregnant? "Yes" : "No") + 
                      ", BreastFeeding: "+ (measurement?.breastfeeding? "Yes" : "No") + ")"))}
        {infoItem("Email", requestorInfo?.contact?.email)}
        {infoItem("Height", concatinateHeight(measurement))}
        {infoItem("Weight", measurement?.weight + " lbs")}
        {/* {infoItem("Home Medications", "None")} */}
        {infoItem("Medication Allergies", medications.length > 0 ? getMedications() : "None")}
        {infoItem("Medical Conditions", conditions.length > 0 ? getConditions() : "None")}
        {infoItem("Self-Reported Medications", getSelfReportedMedication())}
      </Row>
      </>
    )
  }

  const concatinateHeight=(measurement?:IPatientMeasurment)=>{
    if(measurement?.heightFt && measurement?.heightIn)
      return `${measurement?.heightFt}  Ft. / ${measurement?.heightIn}  In.`
    else if(measurement?.heightFt && !measurement?.heightIn)
      return `${measurement?.heightFt}  Ft.`
    else if(!measurement?.heightFt && measurement?.heightIn) 
      return `${measurement?.heightIn}  In.`
    else 
      return ``
  }
  const generateImages = (): string[] => {
    let result = []
    for (const att of attachments) {
      result.push('data:image/jpeg;base64, ' + att)
    }
    return result;
  };

  const openImg = (idx: number) => {
    setImgIdx(idx)
    toggleGallery()
  }

  const images = generateImages()

  const renderPatientPhotos = () => {
    return (
      <>
      <Col className="sm-12">
        <dl>
          <dt>Patient Photos:</dt>
          <dd>
          <div className="d-flex flex-wrap mb-3">
            {images.map((att, idx) => (
              <div className="p-2">
                <img src={att} style={{maxWidth: 120, cursor: "pointer"}}  onClick={() => openImg(idx)}/>
              </div>
            ))}
          </div>
          </dd>
        </dl>
      </Col>
      </>
    )
  }

  const renderAppointmentDetails = () => {
    
    const dateAndTime = appointment ? `${convertToTimezoneWithUsState(
      appointment.when.time || '',
      appointment.who.requestPatient.info.address.stateName ?? '',
      "ddd MMMM Do YYYY h:mm a"
    )} ${appointment.who.requestPatient.info.address.stateName} time` : '';

    return (
      <>
      <h5 className="mb-3">Appointment Details</h5>
      <Row className="ml-2">
        {infoItem("Appointment Type", appointment?.why.consultationType + " (" + appointment?.contact.type + ")")}
        {infoItem("Date and Time", dateAndTime)}
        {infoItem("Reason for Visit", appointment?.why.reason, true)}
        {infoItem("Patient Notes", appointment?.why.symptoms, true)}
        {renderPatientPhotos()}
      </Row>
      </>
    )
  }

  const getModals = () => {
    if (galleryModal) {
      return (
        <GalleryModal toggleModal={toggleGallery} photoIndex={imgIdx} images={images}/>
      )
    }
  }

  const renderSendAppointmentDetailsByMailButton =()=>{
    return(
      <Button outline color="primary" id="apptReport" onClick={()=>{sendAppointmentDetailsByEmail()}}
        disabled={appointmentButtonstate}
        style={{ marginLeft: '20px', fontSize: '15px',marginBottom:'15px' }}>
        {!appointmentButtonstate ? "Send Report by mail" : "Loading..."}
      </Button>
    )
  }

  const renderTreatmentPlan = () => {
    return (
      <>
      <h5 className="mb-3">Treatment Plan</h5>
      <Row className="ml-2">
        {infoItem("Physician Notes", physicianNotes, true)}
        {infoItem("Follow up Notes", followupNotes, true)}
        <Col sm="12" className="mb-3">
          <dl>
            <dt>Addendums:</dt>
            <dd>
            {addendums.length === 0 ?
            <h6>None</h6>
            :
            addendums.map(item => (
              <>
              <Row>
                <Col sm="12"><small style={{fontSize: "70%"}}>{moment(item.creationDate).format('ddd MMMM Do YYYY h:mm a')}</small></Col>
                <Col sm="12">{item.text}</Col>
              </Row>
              <hr/>
              </>
            ))}
            </dd>
          </dl>
        </Col>
        <Col sm="12" className="mb-3">
          <dl>
            <dt>Prescriptions:</dt>
            <dd>
              {prescriptions.length > 0 ?
                <Table size="sm" className="ml-2" responsive striped>
                  <thead>
                    <th>Prescription Name</th>
                    <th>Amount</th>
                    <th>Refills</th>
                    <th>Notes</th>
                  </thead>
                  <tbody>
                    {prescriptions.map(item => (
                      <tr>
                        <td>{item.medicationName}</td>
                        <td>{item.dispense}</td>
                        <td>{item.refills}</td>
                        <td>{item.directionsForUse}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                :
                <h6>None</h6>
              }
            </dd>
          </dl>
        </Col>
        <Col sm="12" className="mb-3">
        { renderCurrentPharmacy()}
        </Col>
        
      </Row>
      </>
    )
  }

  const isDownloadableDocument=(appointment: IFullAppointment | undefined)=>{
    return (appointment?.status === "Completed" || appointment?.status === "Archived")
  }

  const renderGeneratepdf = () => {
    return (
      <>
       <hr />
      <h5 className="mb-3">Appointment Details Report</h5>
        {pdfFile.loading || !showApptPdf ? (
          <div>Creating PDF...</div>
        ) : (
          <a
            className="btn btn-outline-primary"
            id="apptReport"
            style={{
              marginLeft: "20px",
              fontSize: "15px",
              marginBottom: "15px",
            }}
            href={pdfFile.url ?? ""}
            download={`${patientInfo?.contact?.firstname} ${patientInfo?.contact?.lastname} online clinic visit at ${appointment?.when.patientDateTime?.date} ${appointment?.when.patientDateTime?.time}.pdf`}
          >
            Download as PDF
          </a>
        )}
      {/* <Button outline color="primary" id="apptReport" onClick={() => setShowApptPdf(true)}
          style={{ marginLeft: '20px', fontSize: '15px',marginBottom:'15px' }}>
          Download as PDF
        </Button>
        {showApptPdf && (
          <PDFDownloadLink
            document={
              <AppointmentDocument
                data={appointment}
                patientInfo={patientInfo}
                physicianNotes={physicianNotes}
                followupNotes={followupNotes}
                measurement={measurement}
                conditions={conditions}
                symptoms={allSymptoms}
                prescriptions={prescriptions}
                medications={medications}
              />
            }
            fileName={
              patientInfo?.contact?.firstname +
              " " +
              patientInfo?.contact?.lastname +
              " Doctor Appointment Details.pdf"
            }
            style={{ margin: "right" }}
          >
          {({ url }) => 
            url ? window.open(url, "_blank")&&window.location.reload() : 'Loading'
          }
          </PDFDownloadLink>*/}
      </>)
  }
  const renderCurrentPharmacy=()=>{
    return (
      <>
      <h6 className="mb-3">Pharmacy</h6>
      <dl>
      <dt>
        {appointment?.pharmacy?.name}
      </dt>

      <dd>{appointment?.pharmacy?.address}</dd>
      <dd>{appointment?.pharmacy?.phone}</dd>
    </dl>
      </>
      )
  }
  const onDownloadingAbsenceNote=()=>{
    appointment?.appointmentId &&
    downloadAbsenceNote(appointment?.appointmentId)
      .then((response:any) => response.blob())
      .then(data => {
        // delete data.type
        setTimeout(() => {
          window.open(URL.createObjectURL(data),'_blank')
        }, 100)
        
      })
        .catch((error)=>{
          console.log(error);
        })
  }
  const renderNotesReport = () =>  (
    <>
    <Row className="ml-2">
      <h5 className="mb-3">Absence Notes</h5>
    </Row>
    <Row className="ml-2">
      <Button id="notesReport" outline color="primary" className="far fa-file-pdf"
          onClick={onDownloadingAbsenceNote}
          style={{marginLeft:'20px',fontSize:'25px'}}>
        <UncontrolledTooltip placement="top" target="notesReport">Generate notes report</UncontrolledTooltip>
      </Button>

      {agentAsPatient!==null &&  <Button outline color="primary" id="apptReport"
        onClick={()=>sendAbsenceNoteByEmail()}
        disabled={absenceNoteButtonstate}
        style={{ marginLeft: '20px', fontSize: '15px',marginBottom:'15px' }}>
          {!absenceNoteButtonstate ? "Send Absence Note Report by mail" : "Loading..."}
      </Button>}
    </Row>
      </>
  );

return (
  <ConsecutiveCardFrame flowNav={renderFlowNav()} navItem={navItem} bodyClassName="tc-bg" smallCard={getSmallCard()} modal={getModals()}>
    {appointment && 
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">Appointment Details</h5>
          <Button color="link" size="sm" className="ml-auto" 
            onClick={() => navigate(Routes.homePatient)}>
            Back
          </Button>
        </div>
        <small className="p-2">{"Service ID: " + appointmentId}</small>
        <hr className="mt-2 mb-4"></hr>
        <div>
          {renderHeaderInfo()}
          {renderPatientDetails()}        
          <hr />
            {renderAppointmentDetails()}
          <hr/>
          {(appointment.status === "Archived" || appointment.status === "Completed") && renderTreatmentPlan()}
          </div>
          {isDownloadableDocument(appointment) && renderGeneratepdf()}
          
          {(agentAsPatient!==null && (appointment.status === "Archived" || appointment.status === "Completed")) 
          && renderSendAppointmentDetailsByMailButton()}
          {absenceNote && <div>
            <hr className="mt-2 mb-4"></hr>
          
            {renderNotesReport()}
          </div>}
      </CardBody>
    }
  </ConsecutiveCardFrame>
)
}