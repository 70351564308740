import React, { useState, useEffect } from "react";
import { FormGroup, Button, Label, Form } from "reactstrap";
import { IConfigurationItem } from "../../../core/service/services";
import { TextField } from "../../../components/commons/TextField";
import {AutoCompleteTextArea} from "../../../components/commons/AutoCompleteTextArea"
import Switch from "react-switch";


interface IProps {
  configItem: IConfigurationItem,
  onSave: (key: string, value: string) => void
}

export const ConfigurationItem: React.FC<IProps> = props => {

  const [editMode, setEditMode] = useState<boolean>(false)
  const [value, setValue] = useState<string>(props.configItem.value)
  const [boolValue, setBoolValue] = useState<boolean>(false)
  const [initialValue, setInitialValue] = useState<string>(props.configItem.value)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSave(props.configItem.key, value)
    setInitialValue(value)
    setEditMode(false)
  }

  useEffect(() => {
    if (props.configItem.configurationType === "boolean") {
      setBoolValue(props.configItem.value === "true")
    }
  }, [])

  const handleBoolTypeChange = () => {
    setValue(boolValue? "false" : "true")
    setBoolValue(!boolValue)
  }

  const renderFormButtons = (formId: string) => (
    editMode?
    <>
    <Button color="link" type="submit" form={formId} size="sm" className="pt-0">
      <i className="far fa-save"></i>{" "}
      <span>Save</span>
    </Button>
    <Button color="link" size="sm" className="pt-0" onClick={() => {setEditMode(false); setValue(initialValue)}}>
      <i className="far fa-window-close"></i>{" "}
      <span >Cancel</span>
    </Button>
    </>
    :
    <Button color="link" size="sm" className="pt-0" onClick={(e) => {e.preventDefault(); setEditMode(true)}}>
      <span className="sr-only">Edit</span>{" "}
      <i className="fa fa-edit"></i>
    </Button>
  )

  const getInputField = () => {
    switch(props.configItem.configurationType) {
      case "number":
        return (
          <TextField type="number" textChanged={(val) => setValue(val)} labelText="" name={props.configItem.key} 
            defaultValue={value} disabled={!editMode}/>
        )
      case "text":
      case "textarea":
        return (
          <AutoCompleteTextArea type={props.configItem.configurationType} placeholders={props.configItem.parameters} key={props.configItem.key} 
            value={value} disabled={!editMode} onChange={setValue}/>
        )
      case "boolean":
        return (
          <Switch onChange={handleBoolTypeChange} checked={boolValue} className="react-switch" disabled={!editMode} 
            id="availabilitySwitch" onColor="#007bff"/>
        )
      case "password":
        return (
          <TextField type="password" textChanged={(val) => setValue(val)} labelText="" name={props.configItem.key} 
            defaultValue={"xxxxxxxxx"} disabled={!editMode}/>
        )  
    }
  }

  return (
    <>
    <h6 className="mb-0 pb-0">
      <Label className="mb-0">{props.configItem.title}</Label>
      {renderFormButtons(props.configItem.key)}
    </h6>
    <small>{props.configItem.description}</small>
    <Form id={props.configItem.key} onSubmit={onSubmit}>
      <FormGroup>
        {getInputField()}
      </FormGroup>
    </Form>
    </>
  );
};
