import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { ApptForUser } from '../../types/enums/bookingEnums';
import { IDependent } from '../../types/interfaces/patientInfoInterfaces';
import { bookingCleared } from './bookingSlice';

export interface IChoosePatientStepState {
   apptForUser: ApptForUser | undefined;
   dependent?: IDependent
}

const initialState: IChoosePatientStepState = {
   apptForUser: undefined,
   dependent: undefined
}

const choosePatientStepSlice = createSlice({
   name: 'choosePatientStep',
   initialState,
   reducers: {
      patientChosen: (state, action: PayloadAction<ApptForUser>) => {
         state.apptForUser = action.payload
      },
      dependentUpdated: (state, action: PayloadAction<IDependent | undefined>) => {
         state.dependent = { ...action.payload }
      },
      dependentCleared: (state) => {
         state.dependent = undefined
      }
   },
   extraReducers: (builder: ActionReducerMapBuilder<IChoosePatientStepState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   patientChosen,
   dependentUpdated,
   dependentCleared
} = choosePatientStepSlice.actions

export const selectApptForUser = (state: RootState) => state.choosePatientStep.apptForUser
export const selectDependent = (state: RootState) => state.choosePatientStep.dependent

export default choosePatientStepSlice.reducer
