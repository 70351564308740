import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { useEffect, useState } from 'react'

interface IProps {
  isOpen: boolean,
  toggleOpen: () => void,
  message: string,
  setResponse: (res: boolean) => void
  options: string []
}

export const AlertMessage: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect( () => setIsOpen(props.isOpen), [props.isOpen])

  return (
    <Modal isOpen={isOpen} toggle={props.toggleOpen}>
      <ModalBody>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" size="lg" block className="ml-auto" onClick={() => props.setResponse(true)}>{props.options[0]}</Button>
        <Button color="success" size="lg" block className="ml-auto" onClick={() => props.setResponse(false)}>{props.options[1]}</Button>
      </ModalFooter>
    </Modal>
  )
}