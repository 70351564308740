import React from 'react';

import {
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import Asterisk from '../../../commons/Asterisk';
import { IConfElement } from './ConfigurationsInsuranceModal';

interface IProps {
    confElement: IConfElement;
    errorMessage: string | undefined
}

const TextConfType = (props: IProps) => {

    const renderError = () => {
        if (props.errorMessage) {
            return (
                <>
                    <div className="text-danger">
                        {props.errorMessage}
                    </div>
                </>

            )
        }
    }
    return (
        <>
            <FormGroup>
                <Label>{props.confElement.label}</Label>
                {props.confElement.required && <Asterisk />}

                <Input
                    type="text"
                    defaultValue={props.confElement.defaultSystemValue}
                    required={props.confElement.required}
                    id={props.confElement.keyToBeSent}
                    name={props.confElement.keyToBeSent}

                ></Input>
                {
                    renderError()
                }
            </FormGroup>
        </>
    );
};
export default TextConfType;
