import React from 'react';

import {

    FormGroup,
    Label,
    Input,
} from "reactstrap";
import Asterisk from '../../../commons/Asterisk';
import { IConfElement } from './ConfigurationsInsuranceModal';

interface IProps {
    confElement: IConfElement;
    errorMessage: string | undefined

}

const DropDownConfType = (props: IProps) => {


    const createSelectItems = () => {
        if (props.confElement.values) {
            return props.confElement.values.map((option, idx) => {
                return (
                    <option key={idx} value={option.code} >
                        {option.value}
                    </option>
                );
            });
        }
    };
    const renderError = () => {
        if (props.errorMessage) {
            return (
                <>
                    <div className="text-danger">
                        {props.errorMessage}
                    </div>
                </>

            )
        }
    }
    return (
        <>

            <FormGroup>
                <Label>{props.confElement.label}</Label>
                {props.confElement.required && <Asterisk />}
                <Input
                    type="select"
                    defaultValue={props.confElement.defaultSystemValue}
                    required={props.confElement.required}
                    id={props.confElement.keyToBeSent}
                    name={props.confElement.keyToBeSent}

                >
                    {createSelectItems()}

                </Input>
                {renderError()}
            </FormGroup>
        </>
    );
};
export default DropDownConfType;
