import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import { ISelectedItems } from '../patientSettings/AddHouseholdMemberModal';
import Asterisk from '../commons/Asterisk';

interface IProps {
  conditions?: ISelectedItems[];
  selectedConditions?: number[];
  setSelectedConditions: (selected: number[]) => void;
  previousConditionsStatus?: any;
  onPreviousConditionsChanges: any;
  newPreviousConditionsStatus?:any;
}
const PreviousConditions = (props: IProps) => {
  const [modal, setModal] = useState(false);
  const [prevConditions, setPreviousConditions] = useState(true);
  const [currentPreviousconditionsStatus, setCurrentPreviousconditionsStatus] = useState<boolean>(props.previousConditionsStatus);
  // const [selectedConditionsState, setSelectedConditionsState] = useState(
  let selectedConditionsState = (props.conditions || []).map((c) => {
    return { ...c, checked: props.selectedConditions?.includes(c.id) || false };
  });
  //);
  const toggleModal = () => {
    if (modal) {
      props.setSelectedConditions(
        selectedConditionsState.filter((c) => c.checked).map((c) => c.id)
      );
      if(currentPreviousconditionsStatus !== undefined ) props.onPreviousConditionsChanges(currentPreviousconditionsStatus);
    }
    setModal(!modal);
  };
  const setPreviousConditionsQuestions = (prev: boolean) =>
    setPreviousConditions(prev);
  const renderSelectItems = () => {
    let conditionsNode = selectedConditionsState.map((condition, index) => {
      return (
        <CustomInput
          key={'condition_' + index}
          type="checkbox"
          id={condition.name}
          label={condition.name}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              condition.checked = true;
            } else {
              condition.checked = false;
            }
          }}
          defaultChecked={condition.checked}
        />
      );
    });
    return conditionsNode;
  };
  const renderForm = () => {
    return (
      <Form>
        <FormGroup>
          <Label for="conditions">Please choose from the list below:</Label>

          <div>{renderSelectItems()}</div>
        </FormGroup>
      </Form>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={modal}>
          <ModalHeader>Medical Profile {`>`} Previous Conditions</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <div id="intro">
                  <Label htmlfor="intro">
                    Do they have any previous conditions:
                  </Label>
                  <CustomInput
                    onChange={(e) => {
                      if (e.currentTarget.checked)
                        // setPreviousConditionsQuestions(true);
                        setCurrentPreviousconditionsStatus(true);
                    }}
                    type="radio"
                    id="yes"
                    name="intro"
                    label="Yes"
                    defaultChecked={props.previousConditionsStatus}
                  />
                  <CustomInput
                    onChange={(e) => {
                      if (e.currentTarget.checked)
                        // setPreviousConditionsQuestions(false);
                        setCurrentPreviousconditionsStatus(false);
                    }}
                    type="radio"
                    id="no"
                    name="intro"
                    label="No"
                    defaultChecked={props.previousConditionsStatus=== undefined?false:!props.previousConditionsStatus}
                  />
                </div>
              </FormGroup>
            </Form>

            {currentPreviousconditionsStatus ? renderForm() : null}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggleModal}>
              cancel
            </Button>
            <Button onClick={toggleModal} color="success">
              Save
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  const renderSelectedConditionsUponStatus=()=>{
    if(currentPreviousconditionsStatus === undefined && props.newPreviousConditionsStatus === undefined)
      return getSelectedCondistionsStr();
    else if (currentPreviousconditionsStatus === true && props.newPreviousConditionsStatus === undefined)
      return getSelectedCondistionsStr();
    else if(currentPreviousconditionsStatus === false && props.newPreviousConditionsStatus === undefined)
      return renderSpanNone()
    else if (props.newPreviousConditionsStatus === true)
      return getSelectedCondistionsStr();
    else if(props.newPreviousConditionsStatus === false)
      return renderSpanNone()
  }
  const renderSpanNone=()=>{
    return <span>None</span>;
  }
  const getSelectedCondistionsStr = () => {
    let conditions: string[] = [];
    selectedConditionsState.map((condition) => {
      condition.checked && conditions.push(condition.name);
    });
    return conditions.join(', ');   
  };
  return (
    <h6 className="mb-3">
      {renderModal()}
      Previous Conditions{" "}<Asterisk/>
      <Button color="link" size="sm" className="pt-0" onClick={toggleModal}>
        <span>Add</span>
      </Button>
      <br />
      {renderSelectedConditionsUponStatus()}
    </h6>
  );
};
export default PreviousConditions;
