import React, { useState, useEffect } from "react";
import { IAppointmentInfo } from "../../book-appointment/AppointmentModels";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import { Badge } from "reactstrap";
import { AppointmentActions } from "./AppointmentActions";
import { UserConfig } from "../../../config/userConfig";
import { Routes } from "../../../config/Routes";
import { toastError } from "../../../App";
import { convertToTimezoneWithUsState } from "../../../core/service/TimeZone";
import { IMessage } from "../../chat-module/MessageModel";

interface IProps {
  appointment: IAppointmentInfo,
  hasLockedAppointment?: boolean,
  refresh?: () => void,
  updateItem?: (item: IAppointmentInfo) => void,
  onChatOpened?: (appointmentId: string, receiverName: string) => void;
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
  removeItem?: (itemId: string) => void
  disabled?: boolean
  timeBeforeLock?: number
}

export const AppointmentListingItemCard: React.FC<IProps> = props => {

  const [appointment, setAppointment] = useState<IAppointmentInfo>(props.appointment)

  useEffect(() => {
    setAppointment(props.appointment)
  }, [props.appointment])

  const userType = UserConfig.getUserType()

  const canDoAsPatient = () => {
    return userType === "patient" || userType === "agent"
  }

  const openAppointmentDetails = (e: React.MouseEvent) => {
    e.preventDefault()
    var duration = moment.duration(moment(appointment.time).diff(moment(new Date())));
    var minutes = duration.asMinutes();
    if (userType === "physician" && (appointment.status === "Locked" || appointment.status === "Started" || appointment.status === "Completed" || appointment.status === "Archived")) {
      navigate(Routes.physicianAppointmentDetails + "?appointmentId=" + appointment.appointmentId, {
        state: {
          addedMessage: props.addedMessage,
          onMessageReceived: props.onMessageReceived
        }
      })
    } else if (userType === "physician" && appointment.status === "Waiting") {
      if (minutes <= 15) {
        toastError("Please lock the appointment to access its details")
      } else {
        toastError("Appointment will be available for your action 15 minutes before its scheduled time")
      }
    } else if (canDoAsPatient() ) {
      navigate(Routes.patientAppointmentDetails + "?appointmentId=" + appointment.appointmentId, {
        state: {
          addedMessage: props.addedMessage,
          onMessageReceived: props.onMessageReceived
        }
      })
    }
  }

  const openAppointmentHistory = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(Routes.appointmentHistory + "?appointmentId=" + appointment.appointmentId + "&patient=" + appointment.patientName)
  } 
  const renderPatientAppointmentTitle = () => {
    if(appointment.consultationType)
    {
      return `#${appointment.appointmentId} - ${appointment.consultationType}`
    }
    else{
      return `#${appointment.appointmentId}`

    }
  }
  const renderPhysicianAppointmentTitle = () => {
    if(appointment.consultationType)
    {
      return  "Appointment ("+appointment.consultationType + ") with " + appointment.patientName
    }
    else{
      return "Appointment with " + appointment.patientName
    }
  }
  const renderTitle = () => {
    if (userType === "physician") {
      return (
        <Link onClick={e => openAppointmentDetails(e)} className="mr-auto" to="#">
          {renderPhysicianAppointmentTitle()}
        </Link>
      )
    } else if (canDoAsPatient()) {
      return (
        <Link onClick={e => openAppointmentDetails(e)} className="mr-auto" to="#">
          {renderPatientAppointmentTitle()}
        </Link>
      )
    } else { //admin
      return (
        <Link onClick={e => openAppointmentHistory(e)} className="mr-auto" to="#">
          {appointment.consultationType + " for " + appointment.patientName + 
            (appointment.physicianName ? (" w/Dr. " + appointment.physicianName + ", " + appointment.physicianTitle) : "")}
        </Link>
      )
    }
  }

  if (props.addedMessage) {
    if (!((userType === "physician" && props.addedMessage.senderType === "doctor") || 
        (userType === "patient" && props.addedMessage.senderType === "patient"))) { // my message
      if (props.addedMessage?.appointmentId === appointment.appointmentId) {
        appointment.newMessage = true
        props.addedMessage.appointmentId = undefined //take care of this very dangerous not tested and when we will use the added message in the chat card it might fail 
      }
    }
  }

  const statusBadge = () => {
    let color = ""
    const status = appointment.status;
    switch (status) {
      case "Waiting":
        color = "primary"
        break;
      case "Rescheduled":
        color = "warning"
        break;
      case "Locked":
        color = "success"
        break;
      case "Completed":
        color = "success"
        break;
      case "Started":
        color = "dark"
        break;
      case "Canceled By Patient":
      case "Canceled By System":
      case "Canceled By Physician":
        color = "danger"
        break;
      case "Archived":
        color = "secondary"
        break;
    }
    if (userType === "patient" && (status === "Locked" || status === "Started")) {
      return <Badge color={color} className="p-1 mr-1 ">{appointment.physicianName + 
        (appointment.physicianTitle? (", " + appointment.physicianTitle) : "")}</Badge>
    }
    return (
      <Badge color={color} className="p-1 mr-1 ">{status}</Badge>
    )
  }

  const renderInfo = () => (
    <ul className="list-inline text-muted mb-1">
      <li className="list-inline-item mx-0">
        {statusBadge()}
      </li>
      <li className="list-inline-item mx-1">
        <small>
          <strong>Scheduled at: </strong>
          {!canDoAsPatient() ? moment(appointment.time).format("ddd MMMM Do YYYY h:mm a")
          : `${convertToTimezoneWithUsState(appointment.time, appointment.patientState, "ddd MMMM Do YYYY h:mm a")} ${appointment.patientState} time`}
        </small>
      </li>
      <li className="list-inline-item mx-2">
        {appointment.patientGender === 'f' ? <i className="fa fa-female"></i> : <i className="fa fa-male"></i>}
      </li>
      {appointment.patientAge !== null && <li className="list-inline-item mx-1">
        <small>
          <strong>Age: </strong>
          {appointment.patientAge}
        </small>
      </li>}
      <li className="list-item mx-0">
        <small>
          <strong>Doctor certified in: </strong>
          {`${appointment.appointmentState} - ${appointment.patientCountry}`}
        </small>
      </li>
    </ul>
  )

  return (
    <div>
      <div>
        {renderTitle()}
        {renderInfo()}
      </div>
      {!props.disabled ?
        <AppointmentActions appointment={props.appointment} hasLockedAppointment={props.hasLockedAppointment || false} 
          onChatOpened={props.onChatOpened ? props.onChatOpened : () => null} updateItem={props.updateItem} refresh={props.refresh} removeItem={props.removeItem}
          timeBeforeLock={props.timeBeforeLock}/>
        :
        <div></div>  
      }
    </div>
  );
};
