import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { I_USAState } from '../../types/interfaces/otherInterfaces'
import { bookingCleared } from '../booking/bookingSlice';

export interface I_USAStatesState {
    availableStates: Array<I_USAState>;
    allStates: Array<I_USAState>;
}

const initialState: I_USAStatesState = {
    availableStates: [],
    allStates: []
}

const USAStatesSlice = createSlice({
    name: 'USAStates',
    initialState,
    reducers: {
        availableStatesFetched: (state, action: PayloadAction<Array<I_USAState>>) => {
            state.availableStates = action.payload.map(item => item)
        },
        allStatesFetched: (state, action: PayloadAction<Array<I_USAState>>) => {
            state.allStates = action.payload.map(item => item)
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<I_USAStatesState>) => {
        builder.addCase(bookingCleared, () => {
            return initialState
        })
    }
})
export const {
    availableStatesFetched,
    allStatesFetched
} = USAStatesSlice.actions

export const selectAvailableStates = (state: RootState) => state.USAStates.availableStates
export const selectAllStates = (state: RootState) => state.USAStates.allStates

export default USAStatesSlice.reducer
