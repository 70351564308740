import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import { Input, ListGroup, ListGroupItem } from "reactstrap";

interface IProps {
  placeholders: string[],
  value: string,
  key: string,
  disabled: boolean,
  onChange: (val: string) => void,
  type: "text" | "textarea"
}

export const AutoCompleteTextArea: React.FC<IProps> = props => {

  const [options, setOptions] = useState<string[]>([])
  const [value, setValue] = useState<string>("")
  const [disabled, setDisabled] = useState<boolean>(false)
  const [currentPos, setCurrentPos] = useState<number>(0)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    setDisabled(props.disabled)
    setOptions([])
  }, [props.disabled])
  
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    setValue(e.currentTarget.value)
    props.onChange(e.currentTarget.value)
    const cursorIdx = e.currentTarget.selectionStart == null ? 3 : e.currentTarget.selectionStart
    setCurrentPos(cursorIdx)
    if (val === "##" || val.substring(cursorIdx - 3, cursorIdx) === " ##") {
      setOptions(props.placeholders)
    } else {
      setOptions([])
    }
  }

  const handlePlaceholderSetting = (item : string) => {
    const prefix = value.substring(0, currentPos)
    const suffix = value.substring(currentPos)
    setValue(prefix + item.substr(2) + " " + suffix);
    setOptions([]);
    inputRef.current?.focus()
    // inputRef.current?.setSelectionRange((prefix + item.substr(2)).length + 1, (prefix + item.substr(2)).length + 1)
    
  }
  
  return (
    <>
    <Input id={props.key} onChange={onChange} value={value} type={props.type} name={props.key} disabled={disabled} 
      innerRef={inputRef} style={props.type === "textarea" ? {height: "8rem"} : {}}/>
    <ListGroup> 
      {
        options.map((item, index) => (
          <ListGroupItem tag="button" color="primary" action key={index + item} className="pt-0 pb-0"
            onClick={() => handlePlaceholderSetting(item)}>{item}</ListGroupItem>
        ))
      } 
    </ListGroup>
    </>
  );
};
