import React from "react";
import { navigate } from "@reach/router";
import { UserConfig } from "../config/userConfig";
import { Routes } from "../config/Routes";
import jwt_decode from 'jwt-decode';
import { IPatientForAgents } from "../page/dashboard/agent/PatientsOverview";
import { AgentSessionConfig } from "../config/agentSessionConfig";

export const isLoggedIn = () => {
    const token = UserConfig.getToken()
    if (token != null) {
        const decoded = jwt_decode(token);
        const time = new Date().getTime() / 1000;
        if (time < (decoded as any).exp) {
            return true;
        }
        /// should check the validity of the jwt token
    }
    return false;
};

export const isAllowed = (roles: string[]) => {
    let currentRole = UserConfig.getUserType();
    if (currentRole != null)
        return roles.includes(currentRole);
    else
        return false;
}

export const logInAsPatient = (patient: IPatientForAgents) => {
    UserConfig.setPatientLoggedByAgent(patient);
}
export const logOutAsPatient = () => {
    UserConfig.setPatientLoggedByAgent(null);
    navigate(Routes.homeAgent);
}

export const logOut = () => {
    UserConfig.setUserInfo(null);
    UserConfig.setToken(null);
    const userType = UserConfig.getUserType()
    switch (userType) {
        case "patient":
            navigate(Routes.patientSignin);
            break;
        case "physician":
            navigate(Routes.physicianSignin);
            break;
        case "agent":
            navigate(Routes.agentLogin);
            break;

        case "biller":
            navigate(Routes.billerLogin);
            break;
        case "admin":
            navigate(Routes.adminLogin);
            break;
    }
    UserConfig.setUserType("");
    AgentSessionConfig.setFiltersInfo(null);
    AgentSessionConfig.setPageIndex(null);
    AgentSessionConfig.setIsCollapsed(null);
}

export const goHome = (e: React.MouseEvent) => {
    e.preventDefault()
    const userType = UserConfig.getUserType()
    switch (userType) {
        case "patient":
            navigate(Routes.homePatient);
            break;
        case "physician":
            navigate(Routes.homePhysician);
            break;
        case "admin":
            navigate(Routes.adminConsole);
            break;
        case "biller":
            navigate(Routes.homeBiller);
            break;
        case "agent":
            UserConfig.setPatientLoggedByAgent(null);
            navigate(Routes.homeAgent);
            break;
        default:
            navigate(Routes.home);
    }
}

export const UserContext = React.createContext({
    isLoggedIn: isLoggedIn,
    logOut: logOut,
});
