import * as React from "react";
import { BigCardFrame } from "../components/layout/BigCardFrame";
import {
  CardBody,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { Helmet } from "react-helmet";
export const PrivacyPolicy = () => {
  const renderTitleAndBody = (title: string, body: string) => {
    return (
      <>
        <h5>{title}</h5>
        {body}
        <br />
        <br />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy - TelMDCare</title>
      </Helmet>
      <h3 className="text-white mx-3" style={{ textAlign: "center" }}>
        Privacy Policy
      </h3>
      <BigCardFrame flowNav={<LandingPageHeader />} navItem={undefined} bodyClassName="tc-bg">
        <CardBody>
          TelMDCare respects the privacy concerns of the Users and therefore
          provides this privacy policy to explain what information is gathered
          during a visit to the site and how such information may be used.
          <br />
          <br />
          {renderTitleAndBody(
            "1. Use of Information",
            `As a general policy, no personal information, such as your name, address, or e-mail address, is automatically collected from your visit to our site. However, certain non-personal information is recorded by the standard operation of the TelMDCare internet servers. Information such as the type of browser being used, its operating system, and your IP address is gathered in order to enhance your telemedicine experience.`
          )}
          {renderTitleAndBody(
            "2. Notice of HIPAA Privacy Practices",
            `THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED, AND DISCLOSED, AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.`
          )}
          The Health Insurance Portability & Accountability Act of 1996 (HIPAA)
          is a federal program requiring that all medical records and other
          individually identifiable health information used, or disclosed, by us
          in any form, whether electronically, on paper, or orally, are kept
          properly confidential. This Act gives you, the Patient, significant
          new rights to understand and control how your health information is
          used. HIPAA provides penalties for covered entities that misuse
          personal health information. 
          <br />
          <br />
          We have prepared this "Notice of HIPAA Privacy Practices" to explain
          how we are required to maintain the privacy of your health information
          and how we may use and disclose your health information. <br />
          <br />
          We may use and disclose your medical records for each of the following
          purposes: treatment, payment, and health care operations: <br />
          <br />
          TREATMENT means providing, coordinating, or managing health care and
          related services by one or more health care providers. <br />
          <br />
          PAYMENT means such activities as obtaining payment or reimbursement
          for services, billing or collection activities and utilization review.{" "}
          <br />
          <br />
          HEALTH CARE OPERATIONS include managing your Electronic Medical Record
          to facilitate diagnostic medical consultations with associated
          physicians, as well as conducting quality assessment review and
          service improvement planning activities, auditing functions,
          cost-containment analysis, and customer service. <br />
          <br />
          We may also create and distribute de-identified health information by
          removing all references to individually identifiable information.{" "}
          <br />
          <br />
          We may contact you to provide information about our services or other
          health-related services that may be of interest to you. <br />
          <br />
          Any other uses and disclosures will be made only with your written
          authorization. You may revoke such authorization in writing and we are
          required to honor and abide by that written request, except to the
          extent that we have already taken actions relying on your
          authorization. <br />
          <br />
          {renderTitleAndBody(
            "3. Access to and Control over Personal Information",
            `At any time or as otherwise required under applicable law, users may contact TelMDCare to review the personal information that TelMDCare has collected about you. If you discover any errors, please notify TelMDCare and the information will be corrected. If you wish to delete your user account, please contact us and we shall proceed to delete the account.`
          )}
          {renderTitleAndBody(
            "4. Use of Cookies",
            `Cookies are pieces of information that a website transfers to an individual’s computer hard drive for record keeping purposes. Cookies make using our website easier by, among other things, saving your passwords and preferences for you. These cookies are restricted for use only on our website, and do not transfer any personal information to any other party. Most browsers are initially set up to accept cookies. You can, however, reset your browser to refuse all cookies or indicate when a cookie is being sent. Please consult the technical information relevant to your browser for instructions. If you choose to disable your cookies setting or refuse to accept a cookie, some parts of the site may not function properly or may be considerably slower.
`
          )}
          <h5>5. Compliance with the Law</h5>
          1. We will share information about you if state or federal laws
          require it, including with the Department of Health and Human Services
          if it wants to see that we’re complying with federal privacy law.
          <br />
          <br />
          2. Work with a medical examiner or funeral director. We can share
          health information with a coroner, medical examiner, or funeral
          director when an individual dies.
          <br />
          <br />
          3. Address workers’ compensation, law enforcement, and other
          government requests. We can use or share health information about you:
          <br />
          <ListGroup flush>
            <ListGroupItem>1. For workers’ compensation claims</ListGroupItem>
            <ListGroupItem>
              2. For law enforcement purposes or with a law enforcement official
              with health oversight agencies for activities authorized by law
            </ListGroupItem>
            <ListGroupItem>
              3. For special government functions such as military, national
              security, and presidential protective services
            </ListGroupItem>
            <ListGroupItem>
              4. Respond to lawsuits and legal actions
            </ListGroupItem>
            <ListGroupItem>
              5. We can share health information about you in response to a
              court or administrative order, or in response to a subpoena.
            </ListGroupItem>
          </ListGroup>
          <br />
          <br />
          {renderTitleAndBody(
            "6. Your Acceptance of These Terms",
            `By using our site, you accept the policies set forth in this Privacy Policy. If you do not agree to this policy, please do not use the site. This Privacy Policy may be revised from time to time. You are bound by any such revisions and should therefore periodically visit our site to review the then current Privacy Policy to which you are bound.`
          )}
        </CardBody>
      </BigCardFrame>
    </>
  );
};
