import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export enum LoadingSpinnerAnimation {
  Border = 'border',
  Grow = 'grow'
}

export enum LoadingSpinnerVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark'
}

export enum LoadingSpinnerSize {
  Small = 'sm'
}

interface ILoadingSpinnerProps {
  asElement?: React.ElementType<any>;
  animation?: LoadingSpinnerAnimation;
  variant?: LoadingSpinnerVariant;
  size?: LoadingSpinnerSize;
  text?: string;
  textClassName?: string;
  textStyle?: React.CSSProperties
}

const LoadingSpinner = ({
  asElement = 'div',
  animation = LoadingSpinnerAnimation.Border,
  variant = LoadingSpinnerVariant.Primary,
  size,
  text,
  textClassName,
  textStyle
}: ILoadingSpinnerProps) => {

  const className = textClassName ? textClassName : `text-${variant} mt-2`
  const style = textStyle ? textStyle : { fontSize: "1.1em" }

  return (
    <div className='d-flex flex-column align-items-center'>
      <Spinner
        animation={animation}
        variant={variant}
        size={size}
        as={asElement}
      />
      <p
        className={className}
        style={style}
      >
        {text}
      </p>
    </div>
  )
};

export default LoadingSpinner;
