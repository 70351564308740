import React from "react";
import { FormGroup } from "reactstrap";
import ReactPaginate from "react-paginate";


interface IProps {
  pageNum?: any;
  pageCount: number;
  onPaginate:any
}
export const CustomedPagination = (props: IProps) => {

  const handlePageClick = (selectedItem: { selected: number }) => {
    let selected = selectedItem.selected;
    props.onPaginate(selected);
  }

  return (
    <FormGroup
      className="mb-0"
      style={{ marginLeft: "20%", marginRight: "20%", display: "flex" , cursor: 'default'}}
    >
      <ReactPaginate
        previousLabel={"« Prev"}
        nextLabel={"Next »"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={props.pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={(selectedItem) => handlePageClick(selectedItem)}
        containerClassName={"pagination"}
        pageClassName={"pagination"}
        activeClassName={"active"}
        disabledClassName={"disabled"}
        forcePage={parseInt(props.pageNum)}
        activeLinkClassName={"active disabled"}
      ></ReactPaginate>
    </FormGroup>
  );
};
