import React, { useState, useEffect } from "react";
import { ModalBody, ModalHeader, Button, Modal, Table, ModalFooter, Input } from "reactstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { IRxChangeRequest, IPrescribedMedication, IPrescriptionSubstitution } from "../../book-appointment/AppointmentModels";
import { TextField } from "../../../components/commons/TextField";
import { toastError, toastSuccess } from "../../../App";
import { getRxChangeDenialReasons, approveRxChange, denyRxChange } from "../../../core/service/services";
import moment from "moment";

export interface IProps {
  toggleModal: () => void;
  modalIsOpen: boolean;
  rxChangeRequest: IRxChangeRequest,
  appointmentId: string,
  onRxChangeAction: () => void
}

interface IMed {
  medicationName: string;
  strength: string;
  dispense: string;
  refills: string;
  direcionsForUse: string;
  pharmacyNotes: string;
  prescriptionId: number;
  EffectiveDate: string;
  DaysSupply: number;
  NoSubstitutions: boolean
}

export interface IRxChangeDenialReason {
  reasonId: number;
  reasonMessage: string;
}

export const RxChangeModal: React.FC<IProps> = props => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [prescribedMedication, setPrescribedMedication] = useState<IPrescribedMedication>(props.rxChangeRequest.rxRequest.PrescribedMedication)
  const [prescriptionSubstitutions, setPrescriptionSubstitutions] = useState<IPrescriptionSubstitution[]>(props.rxChangeRequest.rxRequest.RequestedMedications)
  const [commentModal, setCommentModal] = useState<boolean>(false)
  const [chosenSubstitution, setChosenSubstitution] = useState<IMed>()
  const [accept, setAccept] = useState<boolean>(false)
  const [denialReasons, setDenialReasons] = useState<IRxChangeDenialReason[]>([])
  const [chosenDenialReason, setChosenDenialReason] = useState<string>("")
  const [comment, setComment] = useState<string>()
  const [tempSelected, setTempSelected] = useState<IMed>()
  const [priorAuthorization, setPriorAuthorization] = useState<string>("");
  const [isPriorAuth, setIsPriorAuth] = useState<boolean>(false);
  const [commentValidationError, setCommentValidationError] = useState<boolean>(false);
  const [priorAuthValidationError, setPriorAuthValidationError] = useState<boolean>(false);
  const commentLengthErrorMessage = "Your comment must not exceed 75 letters!";
  const priorAuthorizationLengthErrorMessage = "Your Prior Authorization must not exceed 35 letters!"
  const commentNumOfCharacters = 70;
  const priorAuthorizationNumOfCharacters = 35;
  

  useEffect(() => {
    setOpen(props.modalIsOpen)
  }, [props.modalIsOpen])

  useEffect(() => {
    setPrescribedMedication(props.rxChangeRequest.rxRequest.PrescribedMedication)
    setPrescriptionSubstitutions(props.rxChangeRequest.rxRequest.RequestedMedications)
    setIsPriorAuth(props.rxChangeRequest.rxRequest.RxChangeType === "Prior Authorization")
  }, [props.rxChangeRequest])

  useEffect(() => {
    getRxChangeDenialReasons().then(res => {
      setDenialReasons(res.items)
    }).catch(err => console.log(err))
  }, [])

  const toggleCommentModal = () => {
    setCommentModal(!commentModal)
  }

  const approveRxChangeCall=(prescriptionId:number)=>{
    approveRxChange(props.appointmentId, props.rxChangeRequest.id, prescriptionId, comment, priorAuthorization).then(res => {
      closeModalsAndRefetchPrescriptions("RxChange Approved!")
    }).catch(() => toastError("An error has occured, please contact your administrator!"))
  }
  const onPrescriptionAccepted = () => {
    if(!isPriorAuth && tempSelected){
      let prescriptionId = tempSelected.prescriptionId;
      setComment(undefined);
      approveRxChangeCall(prescriptionId);
    }
    setChosenSubstitution(tempSelected)
    setAccept(true)
    setChosenDenialReason("")
    setComment("")
    setPriorAuthorization("")
    toggleCommentModal()
  }

  const onDenyClicked = () => {
    setAccept(false)
    setChosenDenialReason("")
    setChosenSubstitution(undefined)
    setComment("")
    setPriorAuthorization("")
    setTempSelected(undefined)
    toggleCommentModal()
  }

  const onRxChangeResponseSubmit = () => {
    if (!comment || comment.length === 0) {
      toastError("Please type a comment")
      return
    }
    if (!accept && chosenDenialReason === "") {
      toastError("Please choose a denial reason")
      return
    }
    if (accept && priorAuthorization.length === 0) {
      toastError("Please enter the Prior Authorization")
      return
    }
    if(priorAuthValidationError){
      toastError(priorAuthorizationLengthErrorMessage)
      return
    }
    if(commentValidationError){
      toastError(commentLengthErrorMessage);
      return;
    }
    if (accept && chosenSubstitution && !(priorAuthValidationError || commentValidationError)) {
      let prescriptionId: number;
      if (props.rxChangeRequest.rxRequest.RxChangeType === "Prior Authorization") {
        prescriptionId = prescribedMedication.PrescriptionId;
      } else {
        prescriptionId = chosenSubstitution.prescriptionId;
      }
      // approveRxChange(props.appointmentId, props.rxChangeRequest.id, prescriptionId, comment, priorAuthorization).then(res => {
      //   closeModalsAndRefetchPrescriptions("RxChange Approved!")
      // }).catch(() => toastError("An error has occured, please contact your administrator!"))
      approveRxChangeCall(prescriptionId);
    } else if (!accept) {
      const reason = parseInt(chosenDenialReason)
      if (reason > 2 && reason < 19) {
        denyRxChange(props.appointmentId, props.rxChangeRequest.id, reason, comment).then(res => {
          closeModalsAndRefetchPrescriptions("RxChange Denied!")
        }).catch(() => toastError("An error has occured, please contact your administrator!"))
      } else {
        toastError("Invalid denial reason!")
      }
    }
  }

  const closeModalsAndRefetchPrescriptions = (message: string) => {
    toastSuccess(message)
    setCommentModal(false)
    props.onRxChangeAction()
    props.toggleModal()
  }

  const renderCommentModal = () => (
    <Modal isOpen={commentModal} toggle={toggleCommentModal} size="md">
      <ModalHeader toggle={toggleCommentModal}>
        <h5 id="rxChangeModal" className="modal-title">Please {accept? "" : "choose a denial reason and "}add {accept? "an acceptance" : "a rejection"} comment:</h5>
      </ModalHeader>
      <ModalBody>
          {!accept ? 
            <Input required type="select" name="reasons" onChange={e => setChosenDenialReason(e.target.value)} className="mb-2">
              <option value="">Please select denial reason</option>
              {denialReasons.map(item => (
                <option value={item.reasonId}>
                  {item.reasonMessage}
                </option>
              ))}
            </Input>
            :
            <TextField required type="text" labelText="Prior Authorization" name="PriorAuthorization" textChanged={(text) => {
              setPriorAuthValidationError(text.length > priorAuthorizationNumOfCharacters)
              setPriorAuthorization(text)
              }} />
          }
          <TextField required type="text" labelText="Comment" name="comment" textChanged={(text) => {
            setCommentValidationError(text.length > commentNumOfCharacters)
            setComment(text)
             }} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onRxChangeResponseSubmit}>Submit</Button>
      </ModalFooter>
    </Modal>
  )

  const renderMedicationsTable = (items: IMed[], isSuggested: boolean) => (
    <Table size="sm" className="ml-2" responsive hover={isSuggested} >
      <thead>
        {isSuggested && <th></th>}
        <th>Name</th>
        <th>Strength</th>
        <th>Dispense</th>
        <th>Refills</th>
        <th>Directions For Use</th>
        <th>Pharmacy Notes</th>
        <th>Days Supply</th>
        <th>Effective Date</th>
        <th>Allow Substitutions</th>
      </thead>
      <tbody>
        {items?.map((item) => (
          <tr style={{cursor: isSuggested? "pointer" : "", backgroundColor: tempSelected?.prescriptionId === item.prescriptionId? "lightgreen" : ""}} onClick={() => isSuggested && setTempSelected(item)}>
            {isSuggested && 
              <td>
                <Input type="radio" value={item.prescriptionId} name="substitute" checked={tempSelected?.prescriptionId === item.prescriptionId}/>  
              </td>}
            <td>{item.medicationName}</td>
            <td>{item.strength}</td>
            <td>{item.dispense}</td>
            <td>{item.refills}</td>
            <td>{item.direcionsForUse}</td>
            <td>{item.pharmacyNotes}</td>
            <td>{item.DaysSupply}</td>
            <td>{moment(item.EffectiveDate).format("MM/DD/YYYY")}</td>
            <td>{item.NoSubstitutions === false? "Yes" : "No"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  return (
    <>
    {isPriorAuth && renderCommentModal()}
    <Modal isOpen={isOpen} toggle={props.toggleModal} size="lg" style={{maxWidth:'80rem'}}>
      <ModalHeader toggle={props.toggleModal}>
        <h5 id="rxChangeModal" className="modal-title">RxChange Request: {props.rxChangeRequest.rxRequest.RxChangeType}</h5>
      </ModalHeader>
      <ModalBody>
          <h6>Current Prescription</h6>
          {renderMedicationsTable([{
            medicationName: prescribedMedication?.DisplayName,
            strength: prescribedMedication?.Strength,
            dispense: prescribedMedication?.Quantity,
            refills: prescribedMedication?.Refills,
            direcionsForUse: prescribedMedication?.Directions,
            pharmacyNotes: prescribedMedication?.PharmacyNotes || "",
            prescriptionId: prescribedMedication?.PrescriptionId,
            DaysSupply: prescribedMedication.DaysSupply,
            EffectiveDate: prescribedMedication.EffectiveDate,
            NoSubstitutions: prescribedMedication.NoSubstitutions
            }], false)
          }
          <hr />
          <h6>Requested Prescription(s)</h6>
          <small>Please select one substitution</small>
          {renderMedicationsTable(prescriptionSubstitutions?.map(item => {
            return {
              medicationName: item.DisplayName,
              strength: item.Strength,
              dispense: item.Quantity,
              refills: item.Refills,
              direcionsForUse: item.Directions,
              pharmacyNotes: item.PharmacyNotes || "",
              prescriptionId: item.PrescriptionId,
              DaysSupply: prescribedMedication.DaysSupply,
              EffectiveDate: prescribedMedication.EffectiveDate,  
              NoSubstitutions: prescribedMedication.NoSubstitutions
              }
            }), true)
          }
      </ModalBody>
      <ModalFooter>
        <Button color={tempSelected?"success":undefined} onClick={onPrescriptionAccepted} disabled={tempSelected === undefined}>Accept</Button>
        <Button color="danger" onClick={onDenyClicked}>Reject RxChange</Button>
      </ModalFooter>
    </Modal>
    </>
  );
};
