import React from 'react';
import { RouteComponentProps } from "@reach/router";
import { Card, CardBody, CardTitle, Label } from "reactstrap";
import Switch from "react-switch";
import { setPhysicianAvailability } from '../../../core/service/services';

interface IProps extends RouteComponentProps {
  setAvailability: (available: boolean) => void,
  available: boolean
}

interface IState {
  toggleAvailablity: boolean
}

export class PhysicianAvailability extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      toggleAvailablity: this.props.available 
    };

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.available !== this.props.available) {
      this.setState({
        toggleAvailablity: this.props.available 
      });
    }
  }

  handleChange() {
    setPhysicianAvailability(!this.state.toggleAvailablity)
    this.props.setAvailability(!this.state.toggleAvailablity)
  }

  render() {

    return (
      <>
        <h5 className="text-white mx-3"><i className="fa fa-clock"></i> Availability</h5>
        <Card className="card-telecare mt-3 mb-5">
          <CardBody>
            <CardTitle className="mb-3">
              Change your availability when ready to see patients.
            </CardTitle>
            <div className="tc-switch">
              <Switch
                onChange={this.handleChange}
                checked={this.state.toggleAvailablity}
                className="react-switch"
                id="availabilitySwitch"
                onColor="#007bff"
              />
              <Label htmlFor="availabilitySwitch" className="tc-control-label ">
                {this.state.toggleAvailablity? "Ready to See Patients" : "Not Currently Seeing Patients"}
              </Label>
            </div>
          </CardBody>
        </Card>    
      </>

    );
  }
}
