import React from 'react';
import telmdcareLogoWht from "../../img/telemdcare-logo-wht.webp";
import { Routes } from '../config/Routes';

export const LandingPageHeader=()=>{
    return(
        <nav className="navbar navbar-expand-lg navbar-light telecare-header-landing fixed-top">
        <div className="container new-container">
          <a className="navbar-brand mb-0 logo-font" href="/#">
            <img src={telmdcareLogoWht}/>
          </a>
          <ul className="navbar-nav navbar-nav-static ml-auto">

            <li className="nav-item">
              <a className="nav-link font-weight-bold" href="tel:800-230-2050">
                <i className="fa fa-phone fa-1-5x d-lg-none"></i> 
                <span className="d-none d-lg-inline-block">(800) 230-2050</span>
              </a>
            </li>
            <li>
              <a className="btn-landing btn-primary-landing a-landing" href={Routes.patientSignin}>
                {/* <i className="fa fa-sign-in-alt fa-1-5x d-lg-none"></i>  */}
                <span className="d-lg-inline-block">Sign In</span>
              </a>
            </li>
          </ul>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">

            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/map">Where is TelMDCare available</a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="/#how-it-works">How It Works</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#what-we-treat">What We Treat</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#see-our-reviews">See Our Reviews</a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    )
}