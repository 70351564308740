import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { IAppointmentInfo } from "../../book-appointment/AppointmentModels";
import { AppointmentListingItemCard } from "../appointment/AppointmentListingItemCard";
import {
  getAppointments,
  getUSStatesLookup,
} from "../../../core/service/services";
import { IUSState } from "../../settings/PhysicianSettings";
import { AppointmentsSearchBar } from "./AppointmentsSearchBar";
import { CustomedPagination } from "./Pagination";
import { AdminExpandingButton } from "./AdminExpandingButton";

export interface IAppointments {
  items: IAppointmentInfo[];
  length: number;
  pageIndex: number;
  pageSize: number;
}
interface IProps {}

export const AppointmentsOverview: React.FC<IProps> = (props) => {
  const [appointments, setAppointments] = useState<IAppointmentInfo[]>([]);
  const [uSStates, setUSStates] = useState<IUSState[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [searchOptions, setSearchOptions] = useState();

  const [appointmentExpansion, setAppointmenExpansion] = useState<boolean>(false);
  const toggleAppointmentExpansion = ()=> setAppointmenExpansion(!appointmentExpansion);

  useEffect(() => {
    fetchData_States();
  }, []);

  const fetchData_States = () => {
    getUSStatesLookup().then((states) => {
      setUSStates(states);
    });
  };

  const fetchAllAppointments = async (index:number, size:number, search?: any) => {
    
    let app;
    if (_.valuesIn(search).toString().replace(",", "") != "") {
      app = await getAppointments(index, size, search);
    } else {
      app = await getAppointments(index, size);
    }
    setPageIndex(app.pageIndex)
    setPageSize(app.pageSize)
    setPageCount(Math.ceil(app.length/pageSize))
    setAppointments(app.items);
  };

  return (
    <>
      <h5 className="text-white mx-3">
        <i className="fa fa-calendar-check"></i> Appointments Overview
      </h5>
      <Card className="card-telecare mt-3 mb-5">
        <CardBody>
          <CardTitle className="mb-3">
            Overview Appointments history
            <AdminExpandingButton toggle={toggleAppointmentExpansion} expanded={appointmentExpansion}/>
          </CardTitle>
          {appointmentExpansion &&
          <> 
          <AppointmentsSearchBar
            uSStates={uSStates}
            searchFilters={(e: any) => {
              setSearchOptions(e);
              fetchAllAppointments(0,pageSize,e);
            }}
          />
          <section className="section-content-block">
            <div id="apptBody" className="section-content-body">
              <ListGroup className="timeline upcoming pre-appt-scheduled ">
                {appointments.map((item) => (
                  <ListGroupItem
                    style={{ borderTopWidth: "thin", borderColor: "#007bff" }}
                  >
                    <AppointmentListingItemCard
                      appointment={item}
                      disabled={true}
                    />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </section>
          </>}
        </CardBody>
        {appointmentExpansion && pageCount > 0 ?
        <CustomedPagination
          pageCount={pageCount}
          pageNum={pageIndex}
          onPaginate={(e:any)=>{setPageIndex(e); fetchAllAppointments(e, pageSize,searchOptions)}}
        /> : null}
      </Card>
    </>
  );
};
