import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { TextField } from '../../../components/commons/TextField';
import { PrimaryRegistartionForm } from './../PrimaryRegistrationForm';
import { Routes } from '../../../config/Routes';
import { SmallCardFrame } from '../../../components/layout/SmallCardFrame';
import { CardBody, CardTitle } from 'reactstrap';
import { useAgentRegistrationAPI } from '../../../core/service/services';
import { toastError } from '../../../App';
import { validateStr, validateEmail } from './../PhysicianRegistration';

export interface IAgentRegistrationInfo {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string,
  lastName: string
}

interface IProps extends RouteComponentProps {
  email?: string;
}

export const AgentRegistration: React.FC<IProps> = (props: IProps) => {
  const signUpService = useAgentRegistrationAPI();
  let email = (props.location?.state as any)?.email;

  const controls = {
    firstName: React.createRef<TextField>(),
    lastName: React.createRef<TextField>(),
    email: React.createRef<TextField>(),
    password: React.createRef<TextField>(),
    passwordConfirmation: React.createRef<TextField>(),
  };

  React.useEffect(() => {
    if (signUpService.service.status == "validationError") {
    } else {
      if (signUpService.service.status == "loaded") {
        navigate(Routes.successfulSignup);
      } else if (signUpService.service.status == "error") {
        toastError("Please use a different email address");
      }
    }
  });

  const validateForm=():string[]=>{
    let errors: string[] = [];
    let pass1 = controls.password.current?.getValue();
    let pass2 = controls.passwordConfirmation.current?.getValue();

    if (!validateStr(controls.firstName.current?.getValue())) {
      let message = "A valid first name is expected"
      errors.push(message);
      controls.firstName.current?.setErrors([message])
    }
    if (!validateStr(controls.lastName.current?.getValue())) {
      let message = "A valid last name is expected"
      errors.push(message);
      controls.lastName.current?.setErrors([message])
    }

    if (!validateEmail(controls.email.current?.getValue())) {
      let message = "A valid email is expected"
      errors.push(message);
      controls.email.current?.setErrors([message])
    }

    if ((pass1?.length || 0) < 8) {
      let message = "Password must be more than 8 letters of length"
      errors.push(message);
      controls.password.current?.setErrors([message])
    }
    if (pass2 != pass1) {
      let message = "Passwords must match"
      errors.push(message);
      controls.passwordConfirmation.current?.setErrors([message])
    }
    return errors;
  }
  const submitRegistrationForm = async (e: React.FormEvent) => {
    e.preventDefault();
    let errors:string[] = validateForm();

    if (errors.length > 0) {
      return;
    }
    let agent:IAgentRegistrationInfo = {
      email: controls.email.current?.getValue() || "",
      firstName: controls.firstName.current?.getValue() || "",
      lastName: controls.lastName.current?.getValue() || "",
      password: controls.password.current?.getValue() || "",
      confirmPassword: controls.passwordConfirmation.current?.getValue() || "",
    }

    agent && await signUpService.callService(agent).catch((error) => {
      console.log("Error: ",error);
    });

  }

  return (
    <>
      <SmallCardFrame bodyClassName="tc-bg" navItem={null}>
        
          <div className="card-img-left register d-none d-md-flex">
          </div>
        
        <CardBody>
          <CardTitle className="text-center">Agent Registration</CardTitle>
          <PrimaryRegistartionForm
            email={email}
            submitPrimaryInfo={submitRegistrationForm}
            controls={controls}
            page={1}
            routesTo={Routes.agentLogin}
            userType="Agent"
          />
        </CardBody>
      </SmallCardFrame>
    </>
  );
};