import * as React from 'react'
import { Button, ButtonProps } from 'reactstrap'

interface ILoadingButtonProps extends ButtonProps{
    isLoading: boolean; // should be a state in parent
}

export const LoadingButton: React.FC<ILoadingButtonProps> = (props) => {

    return (
        <Button {...props} disabled={props.isLoading}>
            {props.isLoading ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading... </> : <> {props.children} </>
            }
        </Button>
    )
}