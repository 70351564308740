import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { IInsuranceTemEntity, IPhotoToken, ISubscriberObject, IInsuranceResponse } from '../../types/interfaces/bookingInterfaces';
import { bookingCleared } from './bookingSlice';

export interface IInsuranceStepState {
   insuranceAttachments?: string[];
   insuranceId?: string;
   copaymentToken?: string;
   copaymentAmount?: number;
   isCoveredByInsurance?: boolean;
   insuranceTemEntity: IInsuranceTemEntity
   isPrimarySubscriber?: boolean;
   primarySubscriber?: ISubscriberObject;
   isInsuranceClicked?: boolean,
   displayedImages?: Array<string>;
}

const initialState: IInsuranceStepState = {
   insuranceTemEntity: {},
   isInsuranceClicked: undefined
}

const insuranceStepSlice = createSlice({
   name: 'insuranceStep',
   initialState,
   reducers: {
      insuranceAttachmentsChanged: (state, action: PayloadAction<Array<string>>) => {
         state.insuranceAttachments = action.payload.map(attachment => attachment)
      },
      insuranceIdChanged: (state, action: PayloadAction<string>) => {
         state.insuranceId = action.payload
      },
      copaymentTokenChanged: (state, action: PayloadAction<string | undefined>) => {
         state.copaymentToken = action.payload
      },
      copaymentAmountChanged: (state, action: PayloadAction<number | undefined>) => {
         state.copaymentAmount = action.payload
      },
      isCoveredByInsuranceChanged: (state, action: PayloadAction<boolean>) => {
         state.isCoveredByInsurance = action.payload
      },
      insuranceTemEntityChanged: (state, action: PayloadAction<IInsuranceTemEntity>) => {
         state.insuranceTemEntity = action.payload
      },
      isPrimarySubscriberBooleanChanged: (state, action: PayloadAction<boolean | undefined>) => {
         state.isPrimarySubscriber = action.payload
         state.primarySubscriber = undefined
      },
      primarySubscriberUpdated: (state, action: PayloadAction<ISubscriberObject>) => {
         state.primarySubscriber = { ...action.payload }
      },
      haveInsuranceChanged: (state, action: PayloadAction<boolean>) => {
         state.insuranceTemEntity.haveInsurance = action.payload
      },
      insuranceDetailsChanged: (state, action: PayloadAction<IInsuranceResponse | undefined>) => {
         state.insuranceTemEntity.insuranceDetails = action.payload
      },
      showInsuranceDialogChanged: (state, action: PayloadAction<boolean | undefined>) => {
         state.insuranceTemEntity.showInsuranceDialog = action.payload
      },
      insuranceNotListedChanged: (state, action: PayloadAction<boolean | undefined>) => {
         state.insuranceTemEntity.insuranceNotListed = action.payload
      },
      showGalleryChanged: (state, action: PayloadAction<boolean | undefined>) => {
         state.insuranceTemEntity.showGallery = action.payload
      },
      attachmentAdded: (state, action: PayloadAction<string>) => {
         if (state.insuranceTemEntity.attachments !== undefined) {
            state.insuranceTemEntity.attachments = state.insuranceTemEntity.attachments.concat(action.payload)
         } else {
            state.insuranceTemEntity.attachments = [action.payload]
         }
      },
      attachmentsCleared: (state) => {
         state.insuranceTemEntity.attachments = undefined
      },
      photoTokensChanged: (state, action: PayloadAction<Array<IPhotoToken> | undefined>) => {
         if (action.payload !== undefined) {
            state.insuranceTemEntity.photoTokens = action.payload.map(photoToken => photoToken)
         } else {
            state.insuranceTemEntity.photoTokens = action.payload
         }
      },
      isInsuranceClickedBooleanUpdated: (state, action: PayloadAction<boolean>) => {
         state.isInsuranceClicked = action.payload
      },
      insuranceStepCleared: () => {
         return initialState
      },
      displayedImageAdded: (state, action: PayloadAction<string>) => {
         if (state.insuranceTemEntity.displayedImages !== undefined) {
            state.insuranceTemEntity.displayedImages = state.insuranceTemEntity.displayedImages.concat(action.payload)
         } else {
            state.insuranceTemEntity.displayedImages = [action.payload]
         }
      },
   },
   extraReducers: (builder: ActionReducerMapBuilder<IInsuranceStepState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   insuranceAttachmentsChanged,
   insuranceIdChanged,
   copaymentTokenChanged,
   copaymentAmountChanged,
   isCoveredByInsuranceChanged,
   insuranceTemEntityChanged,
   isPrimarySubscriberBooleanChanged,
   primarySubscriberUpdated,
   haveInsuranceChanged,
   insuranceDetailsChanged,
   showInsuranceDialogChanged,
   insuranceNotListedChanged,
   showGalleryChanged,
   attachmentAdded,
   attachmentsCleared,
   photoTokensChanged,
   isInsuranceClickedBooleanUpdated,
   insuranceStepCleared,
   displayedImageAdded,
} = insuranceStepSlice.actions

export const selectInsuranceStep = (state: RootState) => state.insuranceStep
export const selectInsuranceDetails = (state: RootState) => state.insuranceStep.insuranceTemEntity?.insuranceDetails
export const selectInsuranceTemEntity = (state: RootState) => state.insuranceStep.insuranceTemEntity
export const selectIsPrimarySubscriber = (state: RootState) => state.insuranceStep.isPrimarySubscriber
export const selectCopaymentToken = (state: RootState) => state.insuranceStep.copaymentToken
export const selectCopaymentAmount = (state: RootState) => state.insuranceStep.copaymentAmount
export const selectIsCoveredByInsurance = (state: RootState) => state.insuranceStep.isCoveredByInsurance
export const selectPrimarySubscriber = (state: RootState) => state.insuranceStep.primarySubscriber
export const selectIsInsuranceClicked = (state: RootState) => state.insuranceStep.isInsuranceClicked

export default insuranceStepSlice.reducer
