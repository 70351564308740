import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  FormGroup,
  Form,
  CustomInput,
  FormFeedback
} from 'reactstrap';
import { IDPatient } from '../../../components/patientSettings/PatientSettings';
import _ from 'lodash';
import { toastError } from '../../../App';
import 'react-datepicker/dist/react-datepicker.css';
import NewDatePicker from '../../../components/NewDatePicker';
import { areThereErrors } from '../../../core/service/ErrorService';
import { errorsDisplayed, selectShowErrors } from '../../../redux/slices/booking/errorSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { isInputDefined } from '../../../core/service/Validators';
import { ContactErrorMessages } from '../../../core/service/ErrorMessages';
import CustomErrorMessage from '../../../components/CustomErrorMessage';

interface AddNewDependentModalProps {
  openModal: boolean
  toggleModal: () => any;
  onSubmitingForm: (modal: IDPatient) => Promise<IDPatient | void>;
}

const AddNewDependentModal = ({
  openModal,
  toggleModal,
  onSubmitingForm
}: AddNewDependentModalProps) => {
  const showErrors = useAppSelector(selectShowErrors);

  const [formData, setFormData] = useState<IDPatient>({});
  const [dob, setDob] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [firstRender, setFirstRender] = useState<boolean>(true)

  const dispatch = useAppDispatch();

  const initializeStates = () => {
    setFormData({})
    setDob(undefined)
    setIsLoading(false)
    setFirstRender(true)
  }

  ///TODO: UNSAFE
  const onInputChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const saveAndSelectDependent = () => {
    if (!_.isEmpty(formData)) {
      setIsLoading(true)
      onSubmitingForm(formData)
        .catch((error) => {
          let err = returnErrorMessage(error);
          toastError(err);
        })
        .finally(() => {
          toggleModal()
          initializeStates()
        })
    }
  };

  const returnErrorMessage = (error: any) => {
    let errorObj = JSON.parse(JSON.stringify(error));
    if (errorObj && errorObj.json) {
      let errorMessageStr = errorObj.json.message;
      let errorMessage = "";
      if (errorMessageStr.split(',').length > 1)
        errorMessage = errorMessageStr.split(',')[1]
      else errorMessage = errorMessageStr
      return errorMessage;
    }
    else {
      return error;
    }
  }

  const handleSubmit = (e: any) => {
    setFirstRender(false)
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true))
    }
    else {
      dispatch(errorsDisplayed(true))
      saveAndSelectDependent();
    }
  }


  const renderForm = () => {
    return (
      <fieldset disabled={isLoading}>
        <Form >
          <FormGroup>
            <Label for="firstName">First Name:</Label>
            <Input
              onChange={(e: any) => onInputChange('firstname', e.target.value)}
              type="text"
              id="firstName"
              placeholder="Enter member's first name"
              name="firstName"
              invalid={!isInputDefined(formData.firstname) && showErrors && !firstRender}
            />
            <FormFeedback hidden={isInputDefined(formData.firstname)} className='error'> {ContactErrorMessages.invalidFirstName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="middleName">Middle Name:</Label>
            <Input
              onChange={(e: any) => onInputChange('middleName', e.target.value)}
              type="text"
              id="middleName"
              placeholder="Enter member's middle name"
              name="middleName"
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name:</Label>
            <Input
              onChange={(e: any) => onInputChange('lastname', e.target.value)}
              type="text"
              id="lastName"
              placeholder="Enter member's last name"
              name="lastName"
              invalid={!isInputDefined(formData.lastname) && showErrors && !firstRender}
            />
            <FormFeedback hidden={isInputDefined(formData.lastname)} className='error'> {ContactErrorMessages.invalidLastName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="familyRelation">Choose Relationship</Label>
            <div id="familyRelation">
            <CustomInput
                onChange={() => onInputChange('relationshipToDependable', 'Spouse')}
                type="radio"
                id="spouse"
                name="familyRelation"
                label="Spouse"
              />
              <CustomInput
                type="radio"
                id="familyMember"
                name="familyRelation"
                label="Family Member"
                onChange={() => onInputChange('relationshipToDependable', 'FamilyMember')}
              />
              <CustomInput
                onChange={() => onInputChange('relationshipToDependable', 'NonFamilyMember')}
                type="radio"
                id="nonfamilymember"
                name="familyRelation"
                label="Patient Referral"
              />
              <CustomErrorMessage
                showValidation={showErrors && !firstRender}
                errorMessage={ContactErrorMessages.enterRelation}
                invalid={!isInputDefined(formData.relationshipToDependable)}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="age">Date of Birth:</Label>
            <NewDatePicker
              selectedDate={dob}
              onChange={(date: string) => { onInputChange('dateOfBirth', date) }}
              showValidation={showErrors && !firstRender}
            />
          </FormGroup>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
            <Button
              color="primary"
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Save And Select
            </Button>
          </ModalFooter>
        </Form>
      </fieldset>
    );
  };

  return (
    <div>
      <Modal isOpen={openModal}>
        <ModalHeader>
          Add An Account Member
        </ModalHeader>
        <ModalBody>
          {renderForm()}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewDependentModal;
