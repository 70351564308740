import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Label
} from 'reactstrap';
import { IPatientInfoSettings } from '../PatientSettings';
import {
    getPayersListForPatient,
    deleteInsurancePatient,
    getUserHasInsurance,
} from '../../../core/service/services';
import { strings } from '../../../Constants';
import { AlertMessage } from '../../../core/alert/Alerts';
import {
    payersListFetched,
    payerCodeSelectedChanged,
    payerNameSelectedChanged,
    payerSelectedAfterConfChanged,
    selectPayersList,
    selectPayerCodeSelected,
    selectPayerSelectedAfterConf,
    insuranceIdSelectedChanged
} from '../../../redux/slices/general/insuranceDetailsSlice'
import {
    insuranceDetailsChanged,
    selectInsuranceDetails
} from '../../../redux/slices/booking/insuranceStepSlice'
import { useAppDispatch, useAppSelector } from '../../../../appredux/hooks';
import ConfigurationInsuranceModalRedux from './configurationsComponents/ConfigurationsInsuranceModalRedux';
import { selectDependent } from '../../../redux/slices/booking/choosePatientStepSlice';

interface IProps {}

const InsuranceSectionRedux = (props: IProps) => {
    const payersList = useAppSelector(selectPayersList)
    const payerCodeSelected = useAppSelector(selectPayerCodeSelected)
    const payerSelectedAfterConf = useAppSelector(selectPayerSelectedAfterConf)
    const insurance = useAppSelector(selectInsuranceDetails)
    const dependent = useAppSelector(selectDependent)

    const [isAlert, setIsAlert] = useState<boolean>(false);
    const toggleAlert = () => { setIsAlert(!isAlert); }
    const [userHasInsurance, setUserHasInsurance] = useState<boolean>(false)
    const [isConfigModalOpen, setIsConfigModalOpen] = useState<boolean>(false)

    const toggleConfigModal = () => {
        setIsConfigModalOpen(!isConfigModalOpen)
    }

    const dispatch = useAppDispatch()

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        getPayersListForPatient()
            .then((res) => {
                dispatch(payersListFetched(res))
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (insurance) {
            dispatch(payerSelectedAfterConfChanged({
                name: insurance.payerName,
                id: insurance.id,
                code: insurance.payerCode,
                description: ""
            }))
            setUserHasInsurance(true)
        } else if (dependent?.id) {
            getUserHasInsurance(dependent?.id).then((res) => {
                if (Object.entries(res).length === 0) {
                    return
                }
                dispatch(payerSelectedAfterConfChanged({
                    name: res.payerName,
                    id: res.id,
                    code: res.payerCode,
                    description: ""
                }))
                setUserHasInsurance(true)
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [props])


    const createSelectItems = () => {
        if (payersList) {
            return payersList.map((payer, idx) => {
                return (
                    <option key={idx} value={payer.code} >
                        {payer.name}
                    </option>
                );
            });
        }
    };

    const handlePayerSelection = (code: string) => {
        dispatch(payerCodeSelectedChanged(code))
        if (payersList) {
            payersList.forEach((item) => {
                if (item.code == code) {
                    dispatch(payerNameSelectedChanged(item.name))
                }
            })
        }
        toggleConfigModal()
    }

    const deleteInsurance = (response: boolean) => {
        if (response) {
            deleteInsurancePatient(dependent?.id)
                .then(() => {
                    dispatch(payerSelectedAfterConfChanged(undefined))
                    dispatch(payerCodeSelectedChanged(strings.patientSettings.select_insurance))
                    dispatch(insuranceDetailsChanged(undefined))// => so that it would be removed in the booking process 
                    setUserHasInsurance(false)
                    toggleAlert()
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            toggleAlert()
        }
    }

    const renderInsuranceSelected = () => {
        if (userHasInsurance) {
            return (
                <>
                    <dl>
                        <dt>
                            <Label>{payerSelectedAfterConf?.name}</Label>
                            {"    "}
                            <Button
                                data-toggle="modal"
                                onClick={toggleAlert}
                                size="sm"
                                color="link"
                            >
                                <span className="sr-only">Remove</span>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </dt>
                    </dl>
                </>
            )
        } else {
            return (
                <>
                    <Label for="state">{strings.patientSettings.please_select_insurance}</Label>
                    <Input
                        type="select"
                        id="insurance"
                        name="insurance"
                        required
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handlePayerSelection(e.target.value) }}
                        value={payerCodeSelected}
                    >
                        <option value="">{strings.patientSettings.select_insurance}</option>
                        {createSelectItems()}
                    </Input>
                    <br></br>
                </>
            )
        }
    }

    const renderAlertRemoveInsurance = () => {
        return (
            <AlertMessage isOpen={isAlert} toggleOpen={toggleAlert} message="Are you sure you want to delete your insurance ?" setResponse={deleteInsurance} options={["Yes", "No"]} />
        )
    }

    return (
        <div>
            {renderAlertRemoveInsurance()}
            <h6 className="mb-3">
                <Label>{strings.patientSettings.insurance}</Label>
            </h6>
            <ConfigurationInsuranceModalRedux
                isModalOpen={isConfigModalOpen}
                toggleModal={toggleConfigModal}
            />
            {renderInsuranceSelected()}
        </div>
    );
};

export default InsuranceSectionRedux;
