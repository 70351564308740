import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from 'reactstrap';
import { CustomedPagination } from '../Pagination';
import { PhysiciansSearchBar } from '../PhysiciansSearchBar';
import { getAllBillers } from '../../../../core/service/services';
import _ from 'lodash';
import { AgentItemCard } from '../AgentItemCard';
import { AdminExpandingButton } from '../AdminExpandingButton';
interface IProps { }

export interface IBiller {
    id: number;
    firstname: string;
    lastname: string;
    status: "Active" | "Disabled" | "New";
    joinedAt: Date
}
export const BillerOverview: React.FC<IProps> = (props) => {
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [searchOptions, setSearchOptions] = useState<any>()
    const [billers, setBillers] = useState<IBiller[]>([]);
    const [newBillers, setNewBillers] = useState<IBiller[]>([]);
    const [oldBillers, setOldBillers] = useState<IBiller[]>([]);
    const [billerExpansion, setBillerExpansion] = useState<boolean>(false);

    const toggleAgentExpansion = () => setBillerExpansion(!billerExpansion);

    useEffect(() => {
        fetchAllBillers(pageIndex, pageSize, undefined)
    }, [])

    const fetchAllBillers = (pageIndex: number, pageSize: number, searchOptions: any) => {
        const obj = _.valuesIn(searchOptions).toString().replace(",", "") != "" ? searchOptions : undefined
        getAllBillers(pageIndex, pageSize, obj).then(res => {
            setPageIndex(res.pageIndex)
            setPageSize(res.pageSize)
            res.pageSize != 0 && setPageCount(Math.ceil(res.length / res.pageSize))
            setBillerData(res.items);
            console.log("output", res)
        }).catch(err => console.log(err))
    }

    const setBillerData = (res: IBiller[]) => {
        setBillers(res);
        let newBillersArray: IBiller[] = [];
        let oldBillersArray: IBiller[] = [];
        res.map((biller) => {
            if (biller.status === "New") {
                newBillersArray = [...newBillersArray, biller];
            } else {
                oldBillersArray = [...oldBillersArray, biller];
            }
        })
        setNewBillers(newBillersArray);
        setOldBillers(oldBillersArray);
    }
    const billerItemStyle = (status: string) => {
        let style = { borderTopWidth: "thin", borderColor: "#007bff", padding: "0.5rem" }
        switch (status) {
            case "Active":
                style.borderColor = "#007bff"
                break;
            case "Disabled":
                style.borderColor = "#dc3545"
                break;
            case "New":
                style.borderColor = "#28a745"
                break;
        }
        return style
    }
    const applySearch = (searchOpts: any) => {
        setSearchOptions(searchOpts)
        fetchAllBillers(0, 10, searchOpts)
    }

    const updateItem = (newItem: IBiller) => {
        // let updatedAgent = agents.map((agent)=>{
        //   if(newItem.id === agent.id)
        //     return newItem;
        //   return agent;
        // })
        // setAgents(updatedAgent);
        fetchAllBillers(pageIndex, pageSize, {});
    }

    const renderNewBillerItems = () => {
        return (
            <ListGroup className="timeline upcoming pre-appt-scheduled">
                {newBillers.map(item => (
                    <ListGroupItem style={billerItemStyle(item.status)} >
                        <AgentItemCard data={item} updateItem={updateItem} isAgent={false} />
                    </ListGroupItem>
                ))}
            </ListGroup>
        )
    }

    const renderOldBillerItems = () => {
        return (
            <ListGroup className="timeline upcoming pre-appt-scheduled">
                {oldBillers.map(item => (
                    <ListGroupItem style={billerItemStyle(item.status)} >
                        <AgentItemCard data={item} updateItem={updateItem} isAgent={false} />
                    </ListGroupItem>
                ))}
            </ListGroup>
        )
    }
    return (
        <>
            <h5 className="text-white mx-3"><i className="fa fa-user-tie m-auto"></i> Biller Overview</h5>
            <Card className="card-telecare mt-3 mb-5">
                <CardBody>
                    <CardTitle className="mb-3">
                        Manage Biller approval
                        <AdminExpandingButton toggle={toggleAgentExpansion} expanded={billerExpansion} />
                    </CardTitle>
                    {billerExpansion && <>
                        <PhysiciansSearchBar
                            userType="biller"
                            searchFilters={applySearch}
                        />
                        <section className="section-content-block">
                            <div id="apptBody" className="section-content-body">
                                {newBillers.length > 0 &&
                                    <>
                                        <h6 className="text-muted ml-3">
                                            <span>Pending Approval</span>
                                        </h6>
                                        {renderNewBillerItems()}
                                        <hr />
                                    </>
                                }
                                {oldBillers.length > 0 &&
                                    <>
                                        <h6 className="text-muted ml-3">
                                            <span>Currently Existing</span>
                                        </h6>
                                        {renderOldBillerItems()}
                                    </>
                                }
                            </div>
                        </section>
                    </>}
                </CardBody>
                {billerExpansion && oldBillers.length > 0 && pageCount &&
                    <CustomedPagination
                        pageCount={pageCount}
                        pageNum={pageIndex}
                        onPaginate={(idx: any) => { setPageIndex(idx); fetchAllBillers(idx, 10, searchOptions) }}
                    />}
            </Card>
        </>

    );
}