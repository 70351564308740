import React, { ReactNode, useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import AddHouseholdMemberModal from './AddHouseholdMemberModal';
import { IPatientInfoSettings, IDPatient } from './PatientSettings';
import {
  putPatientDependent,
  postPatientDependent,
  deleteDependent,
} from '../../core/service/services';
import moment from 'moment';
import { toastSuccess, toastError } from '../../App';
import { AlertMessage } from '../../core/alert/Alerts';

interface IProps {
  info?: IPatientInfoSettings;
  fetchData?: ()=> void;
  isLoading?:boolean
}

const MembersInHousehold = (props: IProps) => {
  const [templateModal, setTemplateModal] = useState(false);
  const toggleTemplateModal = () => setTemplateModal(!templateModal);
  const [editMode, setEditMode] = useState(false);
  const editTemplateModal = () => setEditMode(!editMode);
  const [editedModalData, setEditModalDataState] = useState<IDPatient>();
  const [indexInArrayState, setIndexInArrayState] = useState<number>();
  const [addNewDependents, setAddNewDependents] = useState<boolean>();
  const [memberId, setMemberId] = useState<number>();
  const [isAlert,setIsAlert] = useState<boolean>(false);
  const toggleAlert = () =>{ setIsAlert(!isAlert);}
  const setEditModalData = (index: number | undefined, d: IDPatient | null) => {
    //setIndexInArrayState(index == undefined ? undefined : index);
    setEditModalDataState(d || undefined);
  };

  const [dependentsState, setDependentsState] = useState<IDPatient[]>([]);
  const [deletedDependent, setDeletedDependent] = useState<number>();

  useEffect(() => {
    setDependentsState(props.info?.dependents?.current || []);
    setDeletedDependent(undefined);
    canAddDependent();
  }, [props.info]);

  const canAddDependent=()=>{
    if(props.info?.address?.city === undefined || props.info?.address?.country === undefined 
        || props.info?.address?.stateId === undefined || props.info?.address?.zipCode === undefined){
          setAddNewDependents(false);
          return false;
        }
        setAddNewDependents(true);
        return true;
  }
  const validateEditedForm = (modal: IDPatient): IDPatient => {
    let data: IDPatient = modal;
    if (!modal.hasOwnProperty('gender')) {
      data.gender = undefined;
    }
    if (!modal.hasOwnProperty('firstname')) {
      data.firstname = undefined;
    }
    if (!modal.hasOwnProperty('lastname')) {
      data.lastname = undefined;
    }
    if (!modal.hasOwnProperty('relationshipToDependable')) {
      data.relationshipToDependable = undefined;
    }
    return data;
  };

  const onSubmitingForm = (modal: IDPatient, indexInArrar: number | null) => {
    if (indexInArrar != null) {
      let data: IDPatient = validateEditedForm(modal);
      return putPatientDependent(data, data.id)
        .then((res: IDPatient) => {
          setMemberId(res.id);
          // setNewDependents({ ...newDependents, res });
          dependentsState[indexInArrar] = res;
          setDependentsState([...dependentsState]);
          setIndexInArrayState(indexInArrar);

          setEditModalData(indexInArrar, res);

          return res;
        })
        // .catch((e) => console.log(e));
    } else {
      return postPatientDependent(modal)
        .then((res: IDPatient) => {
          setMemberId(res.id);
          // setNewDependents({ ...newDependents, res });
          dependentsState.push(res);
          setDependentsState([...dependentsState]);
          let i = dependentsState.length - 1;
          setIndexInArrayState(i);
          setEditModalData(indexInArrayState, res);

          return res;
        })
        // .catch((e) => console.log(e));
    }
  };

  const getModal = (): ReactNode => {
    return (
      <AddHouseholdMemberModal
        setGenderOnDependent={setGenderOnDependent}
        onSubmitingForm={onSubmitingForm}
        templateModal={templateModal}
        toggleTemplateModal={toggleTemplateModal}
        editMode={editMode}
        editedModalData={editedModalData}
        memberId={memberId}
        indexInArray={indexInArrayState == null ? null : indexInArrayState}
      />
    );
  };
  const setGenderOnDependent = (index: number | null, gender?: 'm' | 'f') => {
    if (index != null) {
      dependentsState[index].gender = gender;
      setDependentsState(dependentsState);
    }
  };
  const renderTableRows = () => {
    let items = dependentsState ? [...dependentsState] : [];

    return items?.map((record, index) => {
      return (
        <tr id={`${record.id}`} key={index}>
          <td>{`${record.firstname} ${record.lastname}`}</td>
          <td>
            {record.gender != null
              ? record.gender == 'm'
                ? 'Male'
                : 'Female'
              : 'Not Set'}
          </td>
          <td>
              {record.relationshipToDependable
        ? (record.relationshipToDependable === 'FamilyMember'
            ? 'Family Member'
            : record.relationshipToDependable === 'Spouse' 
                ? 'Spouse'
                : 'Patient Referral')
        : 'Not Set'
          }
          </td>
          <td>{ record.dateOfBirth && 
          moment(record.dateOfBirth, "YYYY-MM-DD").format("MM-DD-YYYY")
          }</td>
          {/* <td>
            <span>Yes</span>
          </td> */}
          <td>
            <Button
              disabled={props.isLoading===true}
              data-toggle="modal"
              onClick={() => onEditButtonClick(record.id!, index)}
              size="sm"
              // className="text-muted ml-auto"
              color="link"
              // alt="Edit template"
            >
              <span className="sr-only">Edit</span>
              <i className="fa fa-edit"></i>
            </Button>
          </td>
          <td>
            <Button disabled={props.isLoading===true} color="link" size="sm" onClick={(e) => onRemoveClicked(record.id!)}>
              <span className="sr-only">Remove</span>{" "}
              <i className="fa fa-trash"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };
  const onEditButtonClick = (id: number, index: number) => {
    const data = dependentsState[index];
    setIndexInArrayState(index)
    setEditModalData(index, data);
    editTemplateModal();
    toggleTemplateModal();
  };

  const onAlertResponse=(response: boolean)=>{
    if(response === true){
      deletedDependent && deleteDependent(deletedDependent).then(()=>{
         toastSuccess("Dependent removed successfully");
         props.fetchData && props.fetchData();
         setDependentsState(props.info?.dependents?.current || []);
       }).catch((error) => {
         toastError('The dependent has previous appointments')
        });
    }
    toggleAlert();
    setDeletedDependent(undefined);
  }
  
  const onRemoveClicked=(id: number)=>{
    toggleAlert();
    setDeletedDependent(id);
  }

  const renderAlertOnRemovingDependednt=()=>{
    return(
      <AlertMessage  isOpen={isAlert} toggleOpen={toggleAlert} message="Are you sure you want to delete this dependent?" setResponse={onAlertResponse} options={["Yes","No"]}/>
    )
  }
  const onClickAddMemberToHouseHold=()=>{
    if(addNewDependents == true){
      setEditModalDataState(undefined);
      setIndexInArrayState(undefined);
      setEditModalData(undefined, null);
      toggleTemplateModal();
    }else{
      toastError("Please provide your address information first!")
    }
  }
  return (
    <div>
      {getModal()}
      {renderAlertOnRemovingDependednt()}
      <h6 className="mb-3">Members in Account</h6>
      <Table responsive className="table-sm">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Gender</th>
            <th>Relationship</th>
            <th>Date of Birth</th>
            {/* <th>Head of Household?</th> */}
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </Table>

      <Button
      disabled={props.isLoading===true}
        data-toggle="modal"
        onClick={onClickAddMemberToHouseHold}
        size="sm"
        outline
        color="primary"
        className="btn-block w-50 m-auto"
        // alt="Edit template"
      >
        Add Member to Account
      </Button>
    </div>
  );
};
export default MembersInHousehold;
