import * as React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { LoadingButton } from '../../components/commons/LoadingButton';
import { Routes } from '../../config/Routes';
import { usePasswordResetFulfilltService } from '../../core/service/services';
import { TextField } from '../../components/commons/TextField';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';

import { toastError, toastSuccess } from '../../App';
import { Form } from 'reactstrap';

interface IProps extends RouteComponentProps {
    userType: string
}

export const ResetPasswordFulfilmentPage: React.FC<IProps> = (props) => {

    const { service, callService } = usePasswordResetFulfilltService(props.userType)





    React.useEffect(() => {
        switch (service.status) {
            case "init":
                break;
            case "error":
                toastError('Connection Error')
                break;
            case "loading":
                break;
            case "validationError":
                toastError('Please validate your input data')
                break;
            case "loaded":
                toastSuccess(service.payload.message)
                props.userType === "patient" ? navigate(Routes.patientSignin) : props.userType === "agent" ? navigate(Routes.agentLogin) :
                    (props.userType === "physician" ? navigate(Routes.physicianSignin) : (props.userType === "biller" ? navigate(Routes.billerLogin) : navigate(Routes.adminLogin)))
                break;
        }
    });

    const urlInvalid = () => {
        toastError("No valid code url");
        navigate(Routes.home);
        return (<> </>);
    }
    if (!props.location?.search) {
        return urlInvalid();
    }

    let search = new URLSearchParams(props.location.search);
    let code = search.get("code");
    let codeId = search.get("codeId");
    let email = search.get("email");

    let pass1Ref = React.createRef<TextField>();
    let pass2Ref = React.createRef<TextField>();

    if (!code || !codeId || !email) {
        return urlInvalid();
    }

    const buttonClicked = (e: React.FormEvent) => {
        e.preventDefault()
        let pass1 = pass1Ref.current?.getValue()
        let pass2 = pass2Ref.current?.getValue()

        if (!pass1 || (pass1 && pass1.length < 8)) {
            toastError("Password Length must be 8 characters or more")
            return
        }
        if (pass1 != pass2) {
            toastError("Password Mismatch")
            return
        }

        callService({
            code: code!,
            codeId: codeId!,
            email: email!,
            password: pass1!
        }).then((resData) => {
        }).catch((error) => {
            console.log(error);
        });
    }


    return (
        <SmallCardFrame navItem={null} bodyClassName="tc-bg">
            <div className="card-body">

                <div className="form-pw-reset">
                    <fieldset disabled={service.status == "loading"} >
                        <Form className="form-signin" onSubmit={buttonClicked}>
                            <h5 className="card-title text-muted mb-1">Enter New Password</h5>
                            <p>Enter and confirm your new password:</p>
                            <hr />

                            <TextField ref={pass1Ref} labelText="Password" type="password" placeholder="Password" />
                            <TextField ref={pass2Ref} labelText="Confirm password" type="password" placeholder="Password" />
                            <LoadingButton type="submit" isLoading={service.status == "loading"}
                                color="primary" block={true} className="w-50 m-auto text-uppercase mt-4">Save Changes</LoadingButton>
                        </Form>
                    </fieldset>
                </div>

            </div>
        </SmallCardFrame>

    )
}