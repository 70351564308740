import React, { useState, useEffect, useRef } from "react";
import { IFullAppointment } from "../../book-appointment/AppointmentModels";
import { Col, Label, Row, Button, Spinner, Alert, Input, FormGroup } from "reactstrap";
import { LoadingState, setAppointmentCpt } from "../../../core/service/services";
import { toastError, toastSuccess } from "../../../App";
interface IProps {
    appointment: IFullAppointment;
}
const listOfCpt =
    [
        {
            "code": "99202",
            "name": "Office Visit Initial - Limited"
        },
        {
            "code": "99203",
            "name": "Office Visit Initial - Intermediate"
        },
        {
            "code": "99204",
            "name": "Office Visit Initial - Extended"
        },
        {
            "code": "99205",
            "name": "Office Visit Initial - Comprehensive"
        },
        {
            "code": "99212",
            "name": "Office Visit Established - Limited"
        },
        {
            "code": "99213",
            "name": "Office Visit Established - Intermediate"
        },
        {
            "code": "99214",
            "name": "Office Visit Established - Extended"
        },
        {
            "code": "99215",
            "name": "Office Visit Established - Comprehensive"
        }]


export const PhysicianCPT: React.FC<IProps> = (props) => {

    const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.nuteral);
    const [selectedCPT, setSelectedCPT] = useState<string>("")
    const [selectedCPTName, setSelectedCPTName] = useState<string>("")

    const diagnosisController = useRef<AbortController>();



    const isActiveAppointment = () => {


        return (props.appointment && (props.appointment.status === "Completed" ||
            props.appointment.status === "Started"))
    }
    useEffect(() => {
        setSelectedCPT(props.appointment.cpt.code)
    }, [props.appointment]);






    const renderLoadingState = () => {
        if (isLoading === LoadingState.loading) {
            return <Spinner color="primary" />;
        } else if (isLoading === LoadingState.failed) {
            return (
                <Alert color="danger">
                    No matching found!
                </Alert>
            )
        }
        return null;
    };


    const onsave = () => {
        if (selectedCPT === "") {
            toastError("Please Select CPT")
            return
        }
        setAppointmentCpt(selectedCPT, selectedCPTName, props.appointment.appointmentId).then((result) => {
            console.log("result")
            toastSuccess('CPT saved successfully');
        }).catch((error) => {
            toastError(error);
        })

    }


    const renderFormButtons = () => (

        <>
            <Button color="link" onClick={onsave} size="sm" className="pt-0">
                <i className="far fa-save fa-lg"></i>{" "}
                <span>Save</span>
            </Button>

        </>

    )


    const handleCPTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("e.target.calue", e.target.value)
        if (e.target.value === "") {
            setSelectedCPT("")
            setSelectedCPTName("")

        } else {
            setSelectedCPT(e.target.value)

            listOfCpt.forEach((cptItem) => {
                if (cptItem.code === e.target.value) {
                    setSelectedCPTName(cptItem.name)
                    console.log(cptItem.name);

                    return
                }
            })
        }
        // const index = indexOf (e.target.value)
        console.log(selectedCPTName);
    }


    return (
        <>
            <Col sm="12" className="mb-3">
                <dl>
                    <dt>
                        <Label>Insurance CPT</Label>
                        {isActiveAppointment() && renderFormButtons()}
                    </dt>
                    {/* TODO should handle here the cpt already chosen  */}
                    {!isActiveAppointment() && <h6>None</h6>}
                    <dd>
                        <Row>
                            {isActiveAppointment() &&
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Select a CPT</Label>
                                        <Input type="select" name="sTemplates" onChange={e => { handleCPTChange(e) }} disabled={!isActiveAppointment()} value={selectedCPT}   >
                                            <option value="">Please Select</option>

                                            {listOfCpt.map(item => (
                                                <option value={item.code} title={item.name}  >
                                                    {item.name}
                                                </option>
                                            ))}

                                        </Input>
                                    </FormGroup>
                                </Col>
                            }

                        </Row>
                    </dd>
                </dl>
            </Col>
        </>
    )
};
