import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
import { IAbsenceNotesForReport } from "../../../core/service/services";
import moment from 'moment';
import {
  IFullAppointment,
  IPInfo,
} from "../../book-appointment/AppointmentModels";

import RockSaltegularFont from "../../../../css/fonts/RockSalt-Regular.ttf";
import RobotoItalicFont from "../../../../css/fonts/Roboto-Italic.ttf";

interface IProps {
  absenceNote?: IAbsenceNotesForReport;
  data?: IFullAppointment;
  patient?: IPInfo;
}

Font.register({ family: "RockSalt Regular", src: RockSaltegularFont });
Font.register({ family: "Roboto Italic", src: RobotoItalicFont });

const styles = StyleSheet.create({
  page: {
      backgroundColor: "#ffffff",
      padding: 50,
  },
  details: {
      display: "flex",
      marginLeft: 5,
      fontSize: 13,
      marginBottom: 5,
      fontWeight:'thin',
      color:'#0c5fb8'
  },
  title: {
      fontSize: 13,
      marginBottom: 5,
      fontWeight: 'bold',
  },
  vote: {
      display: "flex",
      flexDirection: "row"
  },
  italicFont: {
    fontSize: 13,
    marginBottom: 5,
    fontFamily: 'Roboto Italic'
  },
  signature:{
    fontFamily: 'RockSalt Regular'
  }
});

export const SignatureFooter = (props: IProps) => {
  return (
    <View>
      <View style={styles.vote}>
        <Text style={styles.signature}>
          {`${props.data?.physician.name ?? ""},${
            props.data?.physician.title ?? ""
          }`}
        </Text>
      </View>
      <View style={styles.vote}>
        <Text style={styles.italicFont}>{`${props.data?.physician.name ?? ""},${
          props.data?.physician.title ?? ""
        }`}</Text>
      </View>
      <View style={styles.vote}>
        <Text style={styles.title}>Electronically signed on</Text>
        {props.data && (
          <Text style={styles.details}>
            {moment(props.data.when.completedAt ?? "").format("MM-DD-YYYY ")}
          </Text>
        )}
        {props.absenceNote && (
          <Text style={styles.details}>
            {moment(props.absenceNote.creationDate ?? "").format("MM-DD-YYYY ")}
          </Text>
        )}
      </View>
      <View style={styles.vote}>
        <Text style={styles.title}>NPI:</Text>
        <Text style={styles.details}>{props.data?.physician.npi ?? ""}</Text>
      </View>
    </View>
  );
};

export const NotesReport = (props: IProps) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={{ alignItems: "center", marginBottom: "5px" }}>
          <Text>DOCTOR'S NOTE</Text>
        </View>
        <View style={{ alignItems: "center", marginBottom: "5px" }}>
          <Text style={{ fontSize: 13 }}>
            {`${props.absenceNote?.physician.name ?? ""}`}
            {`, ${props.absenceNote?.physician.title ?? ""}`}
          </Text>
        </View>
        <View style={{ alignItems: "center", marginBottom: "5px" }}>
          <Text style={{ fontSize: 13, fontWeight: "bold" }}>
            JME Medical service
          </Text>
        </View>
        <View style={{ alignItems: "center", marginBottom: "5px" }}>
          <Text style={{ fontSize: 13 }}>TelMDCare.com</Text>
        </View>
        <View style={{ alignItems: "center", marginBottom: "30px" }}>
          <Text style={{ fontSize: 13 }}>
            P.O BOX 577 42 Stephen St Lemont, IL 60439
          </Text>
          <Text
            style={{ fontSize: 13 }}
          >{`Phone: 1-800-230-2050 | Fax: 630-480-4567 | Support@TelMDCare.com `}</Text>
        </View>
        {props.absenceNote?.visitDate && (
          <View style={styles.vote}>
            <Text style={styles.title}>Visit Date:</Text>
            <Text style={styles.details}>
              {moment(props.absenceNote.visitDate).format("MM-DD-YYYY")}
            </Text>
          </View>
        )}
        <View style={styles.vote}>
          <Text style={styles.title}>Provider:</Text>
          <Text style={styles.details}>{`${
            props.absenceNote?.physician.name ?? ""
          },${props.absenceNote?.physician.title ?? ""}`}</Text>
        </View>

        <View style={styles.vote}>
          <Text style={styles.title}>Patient:</Text>
          <Text style={styles.details}>
            {props.absenceNote?.patient.name ?? ""}
          </Text>
        </View>
        {props.absenceNote?.reasonForVisit && (
          <View style={styles.vote}>
            <Text style={styles.title}>Reason for Visit:</Text>
            <Text style={styles.details}>
              {props.absenceNote.reasonForVisit}
            </Text>
          </View>
        )}
        {props.absenceNote?.from && props.absenceNote?.to && (
          <View style={styles.vote}>
            <Text style={styles.title}>Absent from:</Text>
            <Text style={styles.details}>
              {moment(props.absenceNote.from).format("MM-DD-YYYY")}
            </Text>
            <Text style={styles.title}> To:</Text>
            <Text style={styles.details}>
              {moment(props.absenceNote.to).format("MM-DD-YYYY")}
            </Text>
          </View>
        )}
        <View style={styles.vote}>
          <Text style={styles.title}>Comments:</Text>
          <Text style={styles.details}>{props.absenceNote?.comment ?? ""}</Text>
        </View>
        {/* {signatureFooter(props.absenceNote, props.patient)} */}
        <SignatureFooter
          absenceNote={props.absenceNote}
          patient={props.patient}
        />
        <View style={styles.vote}>
          <Text style={styles.italicFont}>
            Confidentiality Notice: This document is for the sole use of the
            intended recipient(s) and may contain confidential and privileged
            information. Any unauthorized review, use, disclosure or
            distribution of this information is prohibited, and may be
            punishable by law. If this was sent to you in error, please notify
            the sender and destroy all copies of the original message.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
