import React, { useState, useEffect } from "react";
import { ModalBody, ModalHeader, Button, Modal, Label } from "reactstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { IAppointmentInfo } from "../../book-appointment/AppointmentModels";
import { convertToTimezoneWithUsState } from "../../../core/service/TimeZone";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectDate } from "../../../redux/slices/booking/timeAndContactTypeStepSlice";
import { errorsDisplayed } from '../../../redux/slices/booking/errorSlice';
import { areThereErrors } from "../../../core/service/ErrorService";
import { DateTimeComponent } from "../../../components/layout/DateTimeComponent";


export interface IProps {
  oldTime: Date;
  setNewTime: (newTime: Date) => void;
  toggleModal: () => void;
  modalIsOpen: boolean,
  appointment: IAppointmentInfo
}

export const RescheduleAppointmentModal: React.FC<IProps> = props => {
  const date: Date | undefined | any = useAppSelector(selectDate)


  const [newTime, setNewTime] = useState<Date>(new Date())
  const [isOpen, setOpen] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    handleDate();
    setOpen(props.modalIsOpen)
  }, [props.modalIsOpen])

  const handleDate=()=>{
    let convertedTime = convertToTimezoneWithUsState(props.oldTime, props.appointment.patientState);
    let convertedTimeWithoutTimezone = new Date(convertedTime.slice(0, 19));
    if(isNaN(convertedTimeWithoutTimezone.getTime()))
      convertedTimeWithoutTimezone = props.oldTime
    setNewTime(convertedTimeWithoutTimezone);
  }

  const onReschedule=()=>{
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true))
    } else{
      if(date){
        props.setNewTime(date)
      }
    }
  }

  const renderDateField = () => {
    return (
      <Modal isOpen={isOpen} toggle={props.toggleModal}>
        <ModalHeader toggle={props.toggleModal}>
          <h5 id="rescheduleModal" className="modal-title">Schedule new date/time</h5>
        </ModalHeader>
        <ModalBody mt-1>
          <div>
            <DateTimeComponent 
            />
          </div>
          <Button color="primary" size="sm" block={true} className="w-50 m-auto" onClick={onReschedule}>Reschedule</Button>
        </ModalBody>
      </Modal>
    );
  }
  return (
    renderDateField()
  )
};