
export const strings = {
    adminConsoleStrings: {
        payer_button: 'Insurance List',
        payer_name: 'Payer Name',
        payer_list: 'Payers List',
        payer_code: 'Code ',
        payer_action: 'Actions ',
        payer_description: 'Description',
        addPayerButton: 'Add New Payer'

    },
    patientSettings: {
        insurance: "Insurance",
        add_insurance: "Add Insurance",
        select_insurance: "Select Insurance",
        please_select_insurance: "Please Select Insurance",

    },
    patientBooking: {
        insuranceTitle: "Payment Method",
        insuranceQuestionTitle: "What's your payment Method? ",
        addCardTitle: "Please attach 2 images picturing your insurance card front and back",
        errorMessagePleaseAttach: "Please attach two images"
    },
    biller: {
        billerName: "biller",
        billerLoginTitle: "Biller Login",
        patientLoginTitle: "Patient Login",
        agentLoginTitle: "Agent Login",

        doctorLoginTitle: "Doctor Login",



        insuranceQuestionTitle: "Do you have Insurance?",
        enableAgent: "Enable Agent",
        enableBiller: "Enable Biller",
        approveAgent: "Approve Agent",
        approveBiller: "Approve Biller",
        disableAgent: "Disable Agent",
        disableBiller: "Disable Biller",


    }
}