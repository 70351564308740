import * as React from 'react';
import { RouteComponentProps, navigate, Link } from '@reach/router';
import { Routes } from '../../config/Routes';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';
import { useActivateAccountService } from '../../core/service/services';
import { toastError, toastSuccess } from '../../App';

interface IProps extends RouteComponentProps {
    userType: "patient" | "physician" | "agent" | "biller"
}

export const AccountActivationPage: React.FC<IProps> = (props) => {
    const { service, callService } = useActivateAccountService(props.userType)

    React.useEffect(() => {
        switch (service.status) {
            case "init":
                break;
            case "error":
                toastError("Connection error");
                break;
            case "loading":
                break;
            case "loaded":
                toastSuccess(service.payload.message)
                break;
        }
    });

    React.useEffect(() => {
        callService({
            code: code!,
            email: email!
        }).then((resData) => {
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    const urlInvalid = () => {
        toastError("No valid code url");
        navigate(Routes.home);
        return (<> </>);
    }

    if (!props.location?.search) {
        return urlInvalid();
    }

    let search = new URLSearchParams(props.location.search);
    let code = search.get("code");
    let email = search.get("email");

    if (!code || !email) {
        return urlInvalid();
    }
    const getPath = () => {
        switch (props.userType) {
            case ("agent"): {
                return Routes.agentLogin
            }
            case ("patient"): {
                return Routes.patientSignin

            }
            case ("biller"): {
                return Routes.billerLogin

            }
            case ("physician"): {
                return Routes.physicianSignin

            }


        }
    }

    return (
        <SmallCardFrame navItem={null} bodyClassName="tc-bg" >
            <div className="card-body">
                <div className="pre-password-reset">
                    <h5 className="card-title text-muted mb-1">Account Activation</h5>
                    <p>Your account has been successfully activated <i className="fas fa-check-circle fa-1x"></i></p>
                    <hr />
                    <div className="radio-tile-group wide-tile">
                        <div className="form-text text-center mb-2">
                            <Link
                                to={getPath()}
                                className="btn btn-sm btn-block btn-outline-primary"
                            >
                                Sign In
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </SmallCardFrame>

    )
}