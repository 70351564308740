import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { strings } from "../../Constants";
import { IPayerPreview } from "./InsuranceAdmin";

interface IProps {
    modal: boolean;
    toggle: any;
    onSave: any;
    data: IPayerPreview | null;
    isAdd: boolean
}
const AddEditPayerModal = (props: IProps) => {
    const [payerInfo, setPayerInfo] = useState(props.data);
    useEffect(() => {
        const newPayer = {} as IPayerPreview
        setPayerInfo(props.isAdd ? newPayer : props?.data);
    }, [props]);
    return (
        <div>
            <Modal isOpen={props.modal}>
                <ModalHeader>{strings.adminConsoleStrings.addPayerButton}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>{strings.adminConsoleStrings.payer_name}</Label>
                            <Input
                                type="text"
                                defaultValue={props.data?.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (payerInfo)
                                        setPayerInfo({
                                            ...payerInfo,
                                            name: e.target.value,
                                        });
                                }}
                            ></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{strings.adminConsoleStrings.payer_code}</Label>
                            <Input
                                type="text"
                                defaultValue={props.data?.code}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (payerInfo)
                                        setPayerInfo({
                                            ...payerInfo,
                                            code: e.target.value,
                                        });
                                }}
                            ></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{strings.adminConsoleStrings.payer_description}</Label>
                            <Input
                                type="text"
                                defaultValue={props.data?.description ? props.data?.description : ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (payerInfo)
                                        setPayerInfo({
                                            ...payerInfo,
                                            description: e.target.value,
                                        });
                                }}
                            ></Input>
                        </FormGroup>

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            props.onSave(payerInfo);
                        }}
                    >
                        Save
                    </Button>{" "}
                    <Button color="secondary" onClick={props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
export default AddEditPayerModal;
