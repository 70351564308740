import React, { useEffect, useState } from "react";
import { ListGroup, CardTitle, ListGroupItem } from "reactstrap";
import { AppointmentListingItemCard } from "../appointment/AppointmentListingItemCard";
import { listRxChangeAppointments } from "../../../core/service/services";
import { IAppointmentInfo } from "../../book-appointment/AppointmentModels";

interface IProps {
  refetch: boolean,
  removeRefetch: () => void

}

export const RxChangeAppointments: React.FC<IProps> = props => {

  const [rxChangeAppointments, setRxChangeAppointments] = useState<IAppointmentInfo[]>([])
  const getData = () => {
    listRxChangeAppointments().then(res => {
      setRxChangeAppointments([...res.items])
    }).catch(err => console.log(err))
  }

  const renderHeader = () => (
    <div className="section-content-header d-flex flex-nowrap">
      <div className="order-1 mr-auto">
        <h5 className="text-muted">
          <span>RxChange Appointments</span>
        </h5>
      </div>
    </div>
  )

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (props.refetch) {
      getData()
      props.removeRefetch()
    }

  }, [props.refetch])
  const appItemStyle = () => {
    let style = { borderTopWidth: "thin", borderColor: "red" }
    return style
  }

  return (
    <>
      {renderHeader()}
      <div>
        {rxChangeAppointments.length > 0 ?
          <>
            <ListGroup className="timeline upcoming pre-appt-scheduled">
              {rxChangeAppointments.map(item => (
                <ListGroupItem style={appItemStyle()}>
                  <AppointmentListingItemCard key={item.appointmentId} appointment={item} disabled={true} />
                </ListGroupItem>
              ))}
            </ListGroup>
            <hr />
          </>
          :
          <CardTitle className="mb-3 pl-3">There are no current appointments with RxChange requests</CardTitle>
        }
      </div>
    </>
  );
};
