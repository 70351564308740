import {
  FormGroup,
  Row,
  Label,
  Input,
  Container,
  Col,
  Button,
  InputGroupAddon,
  InputGroup,
  Collapse,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { IUSState } from "../../settings/PhysicianSettings";
import { IListPatientsRequestQuery } from "./PatientsOverview";
import _ from "lodash";
import { AgentSessionConfig } from "../../../config/agentSessionConfig";
import { getActualId } from "../../../core/service/PatientIdPrefixGenerator";

interface ISearchBarProps {
  searchFilters: (searchOptions: any) => void;
  uSStates: IUSState[];
  filters?: IListPatientsRequestQuery;
}

export const AgentPatientSearchBar = (props: ISearchBarProps) => {
  const [search, setSearch] = useState<IListPatientsRequestQuery>();
  const [collapse, setCollapse] = useState<boolean>(false);

  useEffect(() => {
    setSearch(props.filters)
    setCollapse(AgentSessionConfig.getIsCollapsed());
  }, [props.filters])
  const applySearchFilters = (searchOptions: any) => {
    props.searchFilters(searchOptions);
  };

  const resetAgentSession = () => {
    AgentSessionConfig.setFiltersInfo(null);
    AgentSessionConfig.setPageIndex(null);
  }
  const resetFilter = () => {
    setSearch(undefined);
    props.searchFilters(undefined);
    resetAgentSession();
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach(
      input => (input.value = "")
    );
  }

  const resetInputDateById = (id: string) => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => {
        if (input.id === id)
          input.value = ""
      }
    );
  }
  const resetJoiningFromInput = () => {
    let searchTemp = search;
    delete searchTemp?.joinDateFrom
    resetInputDateById("joiningFrom")
    setSearch(searchTemp);
    applySearchFilters(searchTemp);
  }
  const resetJoiningToInput = () => {
    let searchTemp = search;
    delete searchTemp?.joinDateTo
    resetInputDateById("joiningTo")
    setSearch(searchTemp);
    applySearchFilters(searchTemp);
  }
  const resetDateOfBirthInput = () => {
    let searchTemp = search;
    delete searchTemp?.dob
    resetInputDateById("dateOfBirth")
    setSearch(searchTemp);
    applySearchFilters(searchTemp);
  }

  const onCollapseToggle = (e: any) => {
    e.preventDefault();
    const check = collapse;
    setCollapse(!check)
    AgentSessionConfig.setIsCollapsed(!check);
  }

  const renderAdvancedSearch = () => {
    return (
      <>
        <Row className="ml-auto">
          <Col md="8" lg="8" className="pl-0">
            <Label className="mb-0">Joining Date</Label>
            <Container className="d-flex">
              <Row>
                <Col>
                  <Row>
                    <Col md="12" lg="12" className="pl-0">
                      <small><Label className="mb-0">from</Label></small>
                    </Col>
                    <Col md="12" lg="12" className="pl-0">
                      <InputGroup>
                        <Input id="joiningFrom" type="date" placeholder="From" className="form-control form-control-sm"
                          defaultValue={props.filters?.joinDateFrom}
                          onChange={(e) => {
                            let searchObj: IListPatientsRequestQuery = { ...search, joinDateFrom: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                            setSearch(searchObj);
                            applySearchFilters(searchObj)
                          }}
                        />
                        <InputGroupAddon addonType="append" >
                          <Button
                            outline color="primary"
                            size="sm"
                            onClick={resetJoiningFromInput}
                            disabled={_.isEmpty(search?.joinDateFrom) && _.isEmpty(props.filters?.joinDateFrom)}
                            className="form-control form-control-sm">
                            <i className="fas fa-undo" style={{ color: 'black' }}></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md="12" lg="12" className="pl-0">
                      <small><Label className="mb-0">to</Label></small>
                    </Col>
                    <Col md="12" lg="12" className="pl-0">
                      <InputGroup>
                        <Input type="date" id="joiningTo" placeholder="To" className="form-control form-control-sm"
                          defaultValue={props.filters?.joinDateTo}
                          onChange={(e: any) => {
                            let searchObj: IListPatientsRequestQuery = { ...search, joinDateTo: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                            setSearch(searchObj);
                            applySearchFilters(searchObj)
                          }}
                        />
                        <InputGroupAddon addonType="append" >
                          <Button
                            outline
                            color="primary"
                            size="sm"
                            onClick={resetJoiningToInput}
                            disabled={_.isEmpty(search?.joinDateTo) && _.isEmpty(props.filters?.joinDateTo)}
                            className="form-control form-control-sm">
                            <i className="fas fa-undo" style={{ color: 'black' }}></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md="4" lg="4" className="pl-0">
            <Label className="mt-3">Status</Label>
            <Input
              type="select"
              className="form-control form-control-sm"
              value={props.filters?.appointmentStatus || ""}
              onChange={(e) => {
                let searchObj: IListPatientsRequestQuery = { ...search, appointmentStatus: e.currentTarget.value }
                setSearch(searchObj);
                applySearchFilters(searchObj)
              }}
            >
              <option value="">All</option>
              <option value="Have Appointment">Have Appointments</option>
              <option value="No Appointments yet">No Appointments yet</option>
            </Input>
          </Col>
        </Row>
        <Row className="ml-auto">
          <Col md="12" lg="12" className="pl-0">
            <small><Label className="mb-0">Date of Birth</Label></small>
          </Col>
          <Col md="6" lg="6" className="pl-0">
            <InputGroup>
              <Input id="dateOfBirth" type="date" placeholder="DoB" className="form-control form-control-sm "
                defaultValue={props.filters?.dob}
                onChange={(e) => {
                  let searchObj: IListPatientsRequestQuery = { ...search, dob: moment(e.target.value).format('YYYY-MM-DD') || undefined }
                  setSearch(searchObj);
                  applySearchFilters(searchObj)
                }}
              />
              <InputGroupAddon addonType="append" >
                <Button
                  outline
                  color="primary"
                  size="sm"
                  onClick={resetDateOfBirthInput}
                  disabled={_.isEmpty(search?.dob) && _.isEmpty(props.filters?.dob)}
                  className="form-control form-control-sm">
                  <i className="fas fa-undo" style={{ color: 'black' }}></i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="6" lg="6" className="pl-0">
            <Input type="tel" placeholder="Phone Number" className="form-control form-control-sm "
              defaultValue={props.filters?.phoneNumber}
              onChange={(e) => {
                const searchObj: IListPatientsRequestQuery = { ...search, phoneNumber: e.target.value || undefined }
                setSearch(searchObj);
                applySearchFilters(searchObj)
              }}
            />
          </Col>
          <Col md="12" lg="12" className="pl-0">
            <small><Label className="mb-0">Email address</Label></small>
          </Col>
          <Col md="6" lg="6" className="pl-0">
            <Input type="email" placeholder="Email address" className="form-control form-control-sm "
              defaultValue={props.filters?.emailAddress}
              onChange={(e) => {
                const searchObj: IListPatientsRequestQuery = { ...search, emailAddress: e.target.value || undefined }
                setSearch(searchObj);
                applySearchFilters(searchObj)
              }}
            />
          </Col>
        </Row>
      </>
    );
  };
  const renderPrimarySearch = () => {
    return (
      <Row>
        <Col>
          <Label>
            Patient Name
          </Label>
          <Input id="searchinput"
            placeholder="Patient Name"
            type="text" className="form-control form-control-sm"
            defaultValue={props.filters?.name}
            onChange={(e) => {
              const searchObj = { ...search, name: e.target.value }
              setSearch(searchObj);
              applySearchFilters(searchObj)
            }}
          />
        </Col>
        <Col md="3" lg="2">
          <Label>
            Patient ID
          </Label>
          <Input id="searchinput"
            placeholder="Patient Id"
            type="number" className="form-control form-control-sm"
            defaultValue={props.filters?.patientId}
            onChange={(e) => {
              const searchObj: IListPatientsRequestQuery = { ...search, patientId: getActualId(e.target.valueAsNumber) }
              setSearch(searchObj);
              applySearchFilters(searchObj)
            }}
          />
        </Col>
        <Col md="2" lg="2" >
          <Label>State</Label>
          <Input type="select" className="form-control form-control-sm"
            value={props.filters?.stateId || ""}
            onChange={(e) => {
              let searchObj: IListPatientsRequestQuery = { ...search, stateId: Number.parseInt(e.target.value) }
              setSearch(searchObj);
              applySearchFilters(searchObj)
            }}
          >
            <option value="">All</option>
            {props.uSStates?.map((state, key) => (
              <option key={key} value={state.id}>{state.name}</option>
            ))}
          </Input>
        </Col>
        <Col md="2" lg="2" >
          <Label>Reset All</Label>
          <Button
            outline color="primary"
            size="sm"
            className="form-control form-control-sm"
            onClick={resetFilter}
            disabled={_.isEmpty(search) && _.isEmpty(props.filters)}
          >
            <i className="fas fa-undo" style={{ color: 'blue' }}></i>
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <FormGroup className="px-3 mb-0">
      {renderPrimarySearch()}
      <Collapse
        isOpen={collapse}
        className="mt-1">
        {renderAdvancedSearch()}
      </Collapse>
      <div style={{ textAlign: "center" }}>
        <Button color="link" size="sm" id="phtoggler" onClick={onCollapseToggle}>
          <i className="fas fa-chevron-circle-down"></i> Advanced Search
        </Button>
      </div>
    </FormGroup>
  );
};
