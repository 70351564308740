import * as React from 'react';
import { IRadioButtonProps } from './RadioButton';
import RadioButton from './RadioButton'

interface IRadioGroupProps {
    radioButtons: Array<IRadioButtonProps>
}

const RadioGroup = ({
    radioButtons
}: IRadioGroupProps) => {

    const renderRadioButtons = () => {
        return (
            radioButtons.map((radioButton: IRadioButtonProps) => {
                return (
                    <RadioButton
                        {...radioButton}
                    />
                )
            })
        )
    }

    return (
        <>
            {renderRadioButtons()}
        </>
    )
}

export default RadioGroup
