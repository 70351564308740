import React, { useState, useEffect } from 'react'
import { IFullAppointment } from '../../book-appointment/AppointmentModels';
import { Col, Label, Row, Button } from 'reactstrap';
import { getPhysicianAddendums, IPhysicianAddendum, addAddendum } from '../../../core/service/services';
import { TextField } from '../../../components/commons/TextField';
import moment from 'moment';

interface IProps {
  appointment?: IFullAppointment
}

export const PhysicianAddendums: React.FC<IProps> = (props) => {

  const [addendum, setAddendum] = useState<string>("")
  const [addendums, setAddendums] = useState<IPhysicianAddendum[]>([])
  const [addMode, setAddMode] = useState<boolean>(false)

  useEffect(() => {
    props.appointment && getPhysicianAddendums(props.appointment.appointmentId).then(res => {
      setAddendums(res)
    })
  }, [props.appointment])

  const toggleAddMode = () => {
    setAddMode(!addMode)
  }

  const save = () => {
    props.appointment && addAddendum(props.appointment.appointmentId, addendum).then(res => {
      setAddendums([...addendums, res])
      toggleAddMode()
      setAddendum("")
    })
  }

  return (
    <Col sm="12" className="mb-3">
      <dl>
        <dt>
          <Label>Physician Addendums</Label>
        </dt>
        <dd>
          {addendums.length === 0 ?
          <h6>None</h6>
          :
          addendums.map(item => (
            <>
            <Row>
              <Col sm="12"><small style={{fontSize: "70%"}}>{moment(item.createdAt).format('ddd MMMM Do YYYY h:mm a')}</small></Col>
              <Col sm="12">{item.text}</Col>
            </Row>
            <hr/>
            </>
          ))}
          <Row>
            {addMode ? 
            <>
            <Col sm="12">
              <TextField labelText="Add Addendum" required type="textarea" defaultValue={addendum} textChanged={(text) => setAddendum(text)}/>
            </Col>
            <Button size="sm" block color="primary" className="w-50 m-auto" onClick={save}>Save</Button>
            </>
            :
            <Button size="sm" outline block color="primary" className="w-50 m-auto" onClick={toggleAddMode}>Add Addendum</Button>
            }
            
          </Row>
        </dd>
      </dl>
    </Col>
  )
}