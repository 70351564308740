import * as React from "react";
import { FormFeedback, FormGroup, Label, Input } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import Asterisk from "./Asterisk";

interface TextFieldProps {
  type: "email" | "text" | "password" | "phone" | "number" | "textarea" | "select" | "date";
  textChanged?: undefined | ((text: string) => void);
  labelText: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  name?: string;
  disabled?: boolean;
  style?: {};
  options?: string[];
  maxLength?: number;
  rows?: number;
  value?: string | number;
  onKeyDown?: ((text: string) => void) | null;
  invalid?:boolean,
  showValidation?:boolean,
  errorMessage?:string
}

interface ITextFieldState { fieldErrors: string[]; }

interface State { }

export class TextField extends React.Component<TextFieldProps, ITextFieldState> {
  private inputType: InputType;
  private inputElementRef!: React.RefObject<HTMLInputElement>;

  private required: boolean;

  public getValue(): string | undefined {
    return this.inputElementRef.current?.value;
  }

  public setErrors(errorTexts: string[]) {
    this.setState({ fieldErrors: errorTexts })
  }


  constructor(props: TextFieldProps) {
    super(props);
    let required = props.required;
    if (required === null || required === undefined) {
      required = false;
    }

    this.required = required;
    this.inputType = "password";
    switch (props.type) {
      case "text":
      case "email":
        this.inputType = "text";
        break;
      case "textarea":
        this.inputType = "textarea";
        break;
      case "select":
        this.inputType = "select";
        break;
      case "phone":
        this.inputType = "tel";
        break;
      case "number":
        this.inputType = "number";
        break;
      case "password":
        this.inputType = "password";
        break;
      case "date":
        this.inputType = "date";
        break;
    }

    this.inputElementRef = React.createRef<HTMLInputElement>();
  }

  readonly onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setErrors([])
    this.props.textChanged && this.props.textChanged(e.target.value)
  }

  readonly handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    this.setErrors([])
    this.props.onKeyDown && this.props.onKeyDown(e.key)
  }

  render() {
    return (
      <>
        <FormGroup className={this.props.rows ? "form-label-group additional" : "form-label-group"}>
          <Label>{this.props.labelText}<Asterisk hidden={!this.required} /></Label>
          <Input
            innerRef={this.inputElementRef}
            type={this.inputType}
            className={(this.state && this.state.fieldErrors.length > 0 ? "is-invalid" : "")}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            required={this.required}
            onChange={this.onChange}
            name={this.props.name}
            disabled={this.props.disabled}
            style={this.props.style}
            min={0}
            maxLength={this.props.maxLength}
            rows={this.props.rows}
            value={this.props.value}
            onKeyDown={this.handleKeyDown}
            invalid={this.props.invalid && this.props.showValidation}
          >
            {/* {this.inputType === "select" && this.props.options?.map(option => (
              <option>{option}</option>
            ))} */}
          </Input>
          <FormFeedback className="error" hidden={!this.props.invalid}>{this.props.errorMessage}</FormFeedback>
          {
            this.state && this.state.fieldErrors.map((e) => {
              return (
                <FormFeedback className="pl-2" invalid>{e}</FormFeedback>
              )
            })
          }
        </FormGroup>
      </>
    );
  }
}
