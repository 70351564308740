import { configureStore } from '@reduxjs/toolkit'
import patientInfoReducer from './slices/general/patientInfoSlice'
import medicalProfileReducer from './slices/general/medicalProfileSlice'
import creditCardsReducer from './slices/general/creditCardsSlice'
import USAStatesReducer from './slices/general/USAStatesSlice'
import bookingReducer from './slices/booking/bookingSlice'
import errorReducer from './slices/booking/errorSlice';
import choosePatientStepReducer from './slices/booking/choosePatientStepSlice'
import consultationStepReducer from './slices/booking/consultationStepSlice'
import timeAndContactTypeStepReducer from './slices/booking/timeAndContactTypeStepSlice'
import insuranceStepReducer from './slices/booking/insuranceStepSlice'
import paymentStepReducer from './slices/booking/paymentStepSlice'
import insuranceDetailsReducer from './slices/general/insuranceDetailsSlice'

const store = configureStore({
    reducer: {
        patientInfo: patientInfoReducer,
        medicalProfile: medicalProfileReducer,
        creditCards: creditCardsReducer,
        USAStates: USAStatesReducer,
        booking: bookingReducer,
        error: errorReducer,
        choosePatientStep: choosePatientStepReducer,
        consultationStep: consultationStepReducer,
        timeAndContactTypeStep: timeAndContactTypeStepReducer,
        insuranceStep: insuranceStepReducer,
        paymentStep: paymentStepReducer,
        insuranceDetails: insuranceDetailsReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store