import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { IContact, IAddress, IPharmacy, IDependents, IDependent, IInsurance } from '../../types/interfaces/patientInfoInterfaces'
import { bookingCleared } from '../booking/bookingSlice';

export interface IPatientInfoState {
    contact: IContact;
    address?: IAddress;
    pharmacy?: IPharmacy;
    dependents?: IDependents;
    insurance?: IInsurance;
}

const initialState: IPatientInfoState = {
    contact: {
        phone: ''
    }
}

const patientInfoSlice = createSlice({
    name: 'patientInfo',
    initialState,
    reducers: {
        patientInfoUpdated: (state, action: PayloadAction<IPatientInfoState>) => {
            return action.payload;
        },
        contactInfoUpdated: (state, action: PayloadAction<IContact>) => {
            state.contact = { ...action.payload }
        },
        addressUpdated: (state, action: PayloadAction<IAddress>) => {
            state.address = { ...action.payload }
        },
        pharmacyUpdated: (state, action: PayloadAction<IPharmacy | undefined>) => {
            state.pharmacy = { ...action.payload }
        },
        dependentsUpdated: (state, action: PayloadAction<Array<IDependent>>) => {
            if (state.dependents !== undefined) {
                state.dependents.current = action.payload.map(dependent => dependent)
            }
        },
        dependentAdded: (state, action: PayloadAction<IDependent>) => {
            if (state.dependents !== undefined) {
                state.dependents.current?.push(action.payload)
            }
        },
        insuranceUpdated: (state, action: PayloadAction<IInsurance | undefined>) => {
            state.insurance = { ...action.payload }
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<IPatientInfoState>) => {
        builder.addCase(bookingCleared, () => {
            return initialState
        })
    }
})

export const {
    patientInfoUpdated,
    contactInfoUpdated,
    addressUpdated,
    pharmacyUpdated,
    dependentsUpdated,
    dependentAdded,
    insuranceUpdated
} = patientInfoSlice.actions

export const selectPatientInfo = (state: RootState) => state.patientInfo
export const selectContactInfo = (state: RootState) => state.patientInfo.contact
export const selectAddress = (state: RootState) => state.patientInfo.address
export const selectPharmacy = (state: RootState) => state.patientInfo.pharmacy
export const selectDependents = (state: RootState) => state.patientInfo.dependents?.current
export const selectInsurance = (state: RootState) => state.patientInfo.insurance

export default patientInfoSlice.reducer
