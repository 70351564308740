import React from "react";
import { RouteComponentProps } from "@reach/router";
  
interface IStaticRouteProps extends RouteComponentProps {
  as: React.ComponentClass | React.FunctionComponent,
}

export class StaticRoutes extends React.Component<IStaticRouteProps> {
  render() {
    let {as : Component} = this.props;
    return (
    <Component /> )
  }
}