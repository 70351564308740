import * as React from "react";
import { Container } from "reactstrap";
import TCNavbar from "../components/layout/TCNavBar";
import Footer from "../components/layout/Footer";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { Helmet } from "react-helmet";
export const About = () => {
    
  return (
    <>
      <Helmet>
        <title>About - TelMDCare</title>
      </Helmet>
      <TCNavbar bodyClassName="tc-bg" />
      

      <div style={{ width: "100%"}}>
      <h3 className="text-white mx-3" style={{ textAlign: "center" }}>
        About TelMDCare
      </h3>
        <Container>
        <p
          className="text-white mx-3"
          style={{ textAlign: "center", fontSize: "1.2em" }}
        >
          TelMDCare is a facility which uses technology to deliver health care
          at a distance for acute non-emergency medical problems. Service can be
          rendered over a telephone call or video conference.
        </p>
        <LandingPageHeader />
        </Container>
      </div>
      <Footer />
    </>
  );
};
