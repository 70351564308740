import React, { RefObject } from "react";
import { IMessage } from "./MessageModel";
import { getMessagesFromServer } from "../../core/service/services";
import { MessageReceived } from "./MessageReceived";
import { MessageSent } from "./MessageSent";
import InfiniteScroll from "react-infinite-scroller";
import { UserConfig } from "../../config/userConfig";

interface IProps {
  appointementId?: string;
  addedMessage?: IMessage;
}

interface IState {
  messageList: IMessage[];
  hasMore: boolean;
  receivedNewMessage?: IMessage;
  messagesEndRef: RefObject<any>;
  appointementId?: string;
}

const pageSize = 50;
let pageIndex = 0;
let prevPageIndex = 0;

export class MessagingList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      messageList: [],
      hasMore: false,
      messagesEndRef: React.createRef()
    };
    pageIndex = 0;
    prevPageIndex = 0;
  }
  componentDidMount() {
    this.getData(0);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.addedMessage !== this.state.receivedNewMessage) {
      let newlist = this.state.messageList;
      newlist.push(nextProps.addedMessage);

      this.setState({
        receivedNewMessage: nextProps.addedMessage,
        messageList: newlist
      });
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.appointementId !== this.props.appointementId) {
      this.setState({
        appointementId: this.props.appointementId
      });
      this.getData(0);
    }
  }

  getData(page: number) {
    const appointmentId = this.props.appointementId ? this.props.appointementId : this.state.appointementId;
    if (appointmentId) {
      getMessagesFromServer(appointmentId, pageSize, pageIndex)
        .then(res => {
          if (res.items.length < pageSize) {
            this.setState({
              hasMore: false
            });
          }
          let newlist = this.state.messageList.concat(res.items.reverse());
          this.setState({
            messageList: newlist
          });
          this.scrollToBottom();
          pageIndex++;
        })
        .catch(err => console.log(err));
    }
  }

  isPhysician() {
    return UserConfig.getUserType() === "physician";
  }

  renderMessage = (message: IMessage) => {
    if (
      (this.isPhysician() && message.senderType == "doctor") ||
      (!this.isPhysician() && message.senderType == "patient")
    ) {
      return <MessageSent key={message.id} message={message}></MessageSent>;
    } else {
      return (
        <MessageReceived key={message.id} message={message}></MessageReceived>
      );
    }
  };

  scrollToBottom = () => {
    this.state.messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
  };

  render() {
    return (
        <InfiniteScroll
          pageStart={0}
          isReverse
          initialLoad={false}
          loadMore={this.getData.bind(this)}
          hasMore={this.state.hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
          style={{ height: '50vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse'
         }}
          useWindow={false}
          // threshold={550}
        >
          <div>
            {this.state.messageList &&
              this.state.messageList.map((message: IMessage) =>
                this.renderMessage(message)
              )}
            <div ref={this.state.messagesEndRef} />
          </div>
        </InfiniteScroll>
    );
  }
}
