import React, { useEffect, useState } from 'react';
import {
  requestPatientInfo,
  putPatientDependent,
  postPatientDependent,
} from '../../core/service/services';
import { CardBody } from 'reactstrap';
import { Form, Button } from 'reactstrap';
import { Routes } from '../../config/Routes';
import { IBookingProps } from './AppointmentModels';
import PreferredPharmacyRedux from './patientInfo/PreferredPharmacyRedux';
import BookingDependentInformation from './patientInfo/DependentInformation';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { toastError, navItem } from '../../App';
import PatientSettingsButton from '../../components/patientSettings/PatientSettingsButton';
import _ from 'lodash';
import PersonalInformationRedux from './patientInfo/PersonalInformationRedux';
import LoadingSpinner from '../../components/commons/LoadingSpinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  dependentsUpdated,
  selectPatientInfo,
  selectContactInfo,
  selectDependents,
  IPatientInfoState,
  dependentAdded
} from '../../redux/slices/general/patientInfoSlice';
import { dependentUpdated, selectApptForUser, selectDependent } from '../../redux/slices/booking/choosePatientStepSlice';
import { nextStepInitiated, previousStepInitiated, whoSectionUpdated } from '../../redux/slices/booking/bookingSlice';
import { IContact, IDependent } from '../../redux/types/interfaces/patientInfoInterfaces';
import { areThereErrors, returnErrorMessage } from '../../core/service/ErrorService';
import { IDPatient, IPatientInfoSettings } from '../../components/patientSettings/PatientSettings';
import { errorsDisplayed } from '../../redux/slices/booking/errorSlice';
import { ApptForUser } from '../../redux/types/enums/bookingEnums';
import { IWho } from '../../redux/types/interfaces/bookingInterfaces';

const BookingPatientInformationStep = (props: IBookingProps) => {
  const patientInfo: IPatientInfoState = useAppSelector(selectPatientInfo)
  const contactInfo: IContact | undefined = useAppSelector(selectContactInfo)
  const dependent: IDependent | undefined = useAppSelector(selectDependent)
  const dependents: Array<IDependent> | undefined = useAppSelector(selectDependents)
  const apptForUser = useAppSelector(selectApptForUser)

  const [initialPatientInfo] = useState<IPatientInfoState>(patientInfo)
  const [initialDependentInfo] = useState<IDependent | undefined>(dependent)
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNextClicked = async () => {
    setLoading(true)
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true))
      setLoading(false)
    }
    else if (selectNewDependent()) {
      updatePersonalInfoWithNewDependent()
    }
    else {
      const updatingPromises = updatePersonalInfo()
      consumeUpdatingPersonalInfoPromises(updatingPromises)
    }
  }

  const selectNewDependent = () => {
    return dependents?.length === 0 && apptForUser === ApptForUser.Dependent
  }

  const updatePersonalInfoWithNewDependent = async () => {
    try {
      await callUpdatePatientApi()
      const newDependent = await callAddDependentApi()
      dispatch(dependentAdded(newDependent as IDependent))
      dispatch(dependentUpdated(newDependent as IDependent))
      updateCreateApptPayload()
      dispatch(errorsDisplayed(false))
      dispatch(nextStepInitiated())
    }
    catch (err) {
      toastError(returnErrorMessage(err))
    }
    setLoading(false)
  }

  const callAddDependentApi = (): Promise<IDPatient> => {
    return postPatientDependent(dependent)
  }

  const updatePersonalInfo = () => {
    const updatingPromises: Array<Promise<any>> = []
    if (shouldPatientInfoUpdate()) {
      const updatePatientPromise = callUpdatePatientApi()
      updatingPromises.push(updatePatientPromise)
    }
    if (dependent && shouldDependentInfoUpdate()) { //change api for new dep call
      const updateDependentPromise = callUpdateDependentApi()
      updatingPromises.push(updateDependentPromise)
    }
    return updatingPromises;
  }

  const callUpdatePatientApi = (): Promise<IPatientInfoSettings> => {
    const patientInfoWithoutDependents = { ...patientInfo }
    delete patientInfoWithoutDependents.dependents
    return requestPatientInfo('POST', patientInfoWithoutDependents)
  }

  const callUpdateDependentApi = (): Promise<IDPatient> => {
    console.log('dependent', dependent)
    return putPatientDependent(dependent, dependent?.id)
  }

  const updateCreateApptPayload = () => {
    let newWhoSection: IWho = {
      patientId: updatePatientId(),
      isRequestedFor: apptForUser !== undefined
    }
    dispatch(whoSectionUpdated(newWhoSection))
  };

  const updatePatientId = () => {
    if (apptForUser === ApptForUser.Dependent) {
      return dependent?.id
    }
  }

  const shouldPatientInfoUpdate = () => {
    return !_.isEqual(initialPatientInfo, patientInfo)
  }

  const shouldDependentInfoUpdate = () => {
    return !_.isEqual(initialDependentInfo, dependent)
  }

  const consumeUpdatingPersonalInfoPromises = (promises: Array<Promise<any>>) => {
    Promise.all(promises)
      .then((result) => {
        if (promises.length !== 0) {
          updateDependentsInPatientInfo()
        }
        dispatch(errorsDisplayed(false))
        dispatch(nextStepInitiated())
      })
      .catch((error) => {
        toastError(returnErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateDependentsInPatientInfo = () => {
    const index = dependents?.findIndex((dep) => dep.id === dependent?.id)
    if (index && index !== -1 && dependent && dependents) {
      const updatedDependents = [...dependents]
      updatedDependents?.splice(index, 1, dependent)
      dispatch(dependentsUpdated(updatedDependents))
    }
  }

  const renderFlowNav = () => {
    return (
      <div className='flow-nav-tools'>
        <Button
          onClick={() => { dispatch(previousStepInitiated()) }}
          size='lg'
          className='control-nav ml-auto'
          outline
          color='secondary'
          disabled={loading}
        >
          Back
        </Button>
        <span className='text-muted mt-2'>Step 2 of 7</span>
        <Button
          onClick={handleNextClicked}
          color='primary'
          size='lg'
          className='control-nav mr-auto'
          disabled={loading}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderTitle = () => {
    let fullName = ''
    if (dependent) {
      fullName = `${dependent.firstname} ${dependent.lastname}`
    } else {
      fullName = `${contactInfo?.firstname} ${contactInfo?.lastname}`
    }
    return `Book an Appointment for ${fullName}`
  }

  const renderSubTitle = () => {
    let text = 'Please review your '
    if (dependent) {
      text += `and ${dependent.firstname}'s `
    }
    text += 'personal information below and edit any inconsistencies'
    return text
  }

  const renderDependentSection = () => {
    if (apptForUser === ApptForUser.Dependent) {
      return (
        <>
          <hr />
          <BookingDependentInformation />
        </>
      )
    }
  }

  const renderLoadingSpinner = () => {
    if (loading) {
      return (
        <LoadingSpinner text='Saving details before proceeding' />
      )
    }
  }

  return (
    <SideCardFrame
      navItem={navItem}
      flowNav={renderFlowNav()}
      bodyClassName='tc-bg'
    >
      <CardBody>
        <Form>
          <div className='d-flex px-2'>
            <h5 className='mb-0'>
              {renderTitle()}
            </h5>
            <PatientSettingsButton
              to={Routes.homePatient}
              label='Cancel'
              className='btn btn-link btn-sm ml-auto'
            />
          </div>
          <fieldset disabled={loading}>
            <CardBody>
              <hr className='mt-2 mb-4' />
              <h5 className='card-title'>
                {renderSubTitle()}
              </h5>
              <div className='mt-3'>
                <PersonalInformationRedux />
                <hr />
                <PreferredPharmacyRedux />
                {renderDependentSection()}
              </div>
            </CardBody>
            {renderLoadingSpinner()}
          </fieldset>
        </Form>
      </CardBody>
    </SideCardFrame>
  );
};

export default BookingPatientInformationStep;