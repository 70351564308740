import React, { useState, useEffect, useRef } from "react";
import { IFullAppointment } from "../../book-appointment/AppointmentModels";
import { Col, Row, Button, Modal, ModalHeader, ModalBody, Form, Spinner, Alert, ModalFooter } from "reactstrap";
import { LoadingState, searchDiagnosis, setAppointementDiagnosis } from "../../../core/service/services";
import { TextField } from "../../../components/commons/TextField";
import { toastError, toastSuccess } from "../../../App";
import { LoadingButton } from "../../../components/commons/LoadingButton";
interface IProps {
    appointment: IFullAppointment;
}
export interface IDiagnosis {
    code: string,
    title: string
}

export const PhysicianDiagnosis: React.FC<IProps> = (props) => {
    const MAX_CHAR = 255;

    const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.nuteral);
    const [modalIsOpen, setIsOpenModal] = useState<boolean>(false);
    const [diagnosisSearchItems, setDiagnosisSearchItems] = useState<IDiagnosis[]>([]);
    const [currentDiagnosis, setCurrentDiagnosis] = useState<IDiagnosis | undefined>();
    const [currentSecondaryDiagnosis, setCurrentSecondaryDiagnosis] = useState<string | undefined>();
    const [chosenPrimaryDiagnosis, setChosenPrimaryDiagnosis] = useState<IDiagnosis | undefined>();
    const [chosenSecondaryDiagnosis, setChosenSecondaryDiagnosis] = useState<string | undefined>();
    const [readOnly, setReadOnly] = useState(true)
    const [isLoadingButton, setLoadingButton] = useState<boolean>(false)

    const isActiveAppointment = () => {
        return (props.appointment && (props.appointment.status === "Completed" ||
            props.appointment.status === "Started"))
    }
    const diagnosisController = useRef<AbortController>();

    //   async function fetchPrescriptionStatusForPatient() {
    //     getPrescriptionStatusForPatient(props.appointment.appointmentId, props.appointment.who.patient.info.patientId)
    //       .then((res: any) => {
    //         setPrescriptionStatusList(res);
    //       }).catch(err => console.log(err));
    //   }

    useEffect(() => {
        setReadOnly(props.appointment?.status === "Archived" || props.appointment?.status === "Locked")

        if (props.appointment.diagnosis.code)
            setCurrentDiagnosis({ code: props.appointment.diagnosis.code, title: props.appointment.diagnosis.name })
        if (props.appointment.secondDiagnosis)
            setCurrentSecondaryDiagnosis(props.appointment.secondDiagnosis)
    }, [props.appointment.diagnosis.code, props.appointment.secondDiagnosis, props.appointment?.status]);


    const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
    }


    const toggleModal = () => {
        if (modalIsOpen) {
            setDiagnosisSearchItems([])
        }
        setIsOpenModal(!modalIsOpen)

    };

    const onAddClicked = () => {

        toggleModal();

    };


    const renderLoadingState = () => {
        if (isLoading === LoadingState.loading) {
            return <Spinner color="primary" />;
        } else if (isLoading === LoadingState.failed) {
            return (
                <Alert color="danger">
                    No matching found!
                </Alert>
            )
        }
        return null;
    };
    const onDiagnosisChosen = (diagnosis: IDiagnosis) => {
        console.log("diagnosis", diagnosis)
        setChosenPrimaryDiagnosis(diagnosis)
        setDiagnosisSearchItems([])
    }

    const renderDiagnosis = () => {
        let columns: any[] = new Array();
        if (diagnosisSearchItems.length > 0) {
            diagnosisSearchItems.map((item) => {
                return columns.push(
                    <Col md="6" lg="6">
                        <Button style={{ textAlign: "left" }} id={item.code} onClick={() => { onDiagnosisChosen(item) }} color="link">
                            {item.title}
                        </Button>
                    </Col>
                );
            });
        }
        return <Row>{columns}</Row>;
    };

    const handleChange = async (value: any) => {

        if (value.length >= 3) {
            setIsLoading(LoadingState.loading);
            if (diagnosisController.current) {
                diagnosisController.current.abort();
            }
            const controller = new AbortController()
            const signal = controller.signal
            diagnosisController.current = controller;
            let response = await searchDiagnosis(value, signal);

            if (response.length > 0) {
                setIsLoading(LoadingState.succeed);
            } else {
                setIsLoading(LoadingState.failed);
            }
            setDiagnosisSearchItems(response);
        }
    };

    const onSavingForm = () => {


        if (chosenPrimaryDiagnosis) {

            setAppointementDiagnosis(chosenPrimaryDiagnosis?.code, chosenPrimaryDiagnosis?.title, chosenSecondaryDiagnosis, props.appointment.appointmentId).then((result) => {
                console.log(result)
                toastSuccess("Diagnosis Added Successfully")
                setCurrentDiagnosis(chosenPrimaryDiagnosis)
                setCurrentSecondaryDiagnosis(result.secondDiagnosis)
                toggleModal()
                // close modal set the diagnosis outside
            }).catch((err) => {
                toastError(err)
            })
        } else {
            toastError("You Have To Chose a diagnosis")

        }

    }
    const renderChosenDiagnosis = () => {


        if (chosenPrimaryDiagnosis) {
            return (
                <><Row>

                    <Col md="8">
                        <div>{chosenPrimaryDiagnosis.title}</div>
                    </Col>


                </Row>
                    <br /></>
            )
        } else if (currentDiagnosis) {
            return (
                <><Row>

                    <Col md="8">
                        <div>{currentDiagnosis.title}</div>
                    </Col>


                </Row>
                    <br /></>
            )
        } else {
            return
        }
    }
    const renderDiagnosisModal = () => {
        return (
            <>

                {isActiveAppointment() &&
                    <Modal size="lg" isOpen={modalIsOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>
                            Diagnosis
                        </ModalHeader>
                        <ModalBody>


                            <Form >
                                <Row>

                                    <Col md="8">
                                        <TextField type="text" placeholder="Search for Diagnosis" labelText="Primary Diagnosis" name="Diagnosis" required
                                            defaultValue={""} textChanged={handleChange} /> {/*should handle the chosen diagnosis*/}
                                    </Col>
                                    <Col md="4">{renderLoadingState()}</Col>
                                </Row>
                                {renderChosenDiagnosis()}
                                <Row>

                                    <Col md="8">
                                        <TextField type="textarea" placeholder="Secondary Diagnosis " labelText="Secondary Diagnosis" name="Secondary Diagnosis" maxLength={MAX_CHAR}
                                            rows={2}
                                            defaultValue={currentSecondaryDiagnosis} value={chosenSecondaryDiagnosis}


                                            textChanged={(value) => {
                                                setChosenSecondaryDiagnosis(value);

                                            }}

                                        />



                                    </Col>

                                </Row>
                                <Col md="12">{renderDiagnosis()}</Col>




                            </Form>
                            <ModalFooter>

                                <LoadingButton type="submit" isLoading={isLoadingButton}
                                    onClick={onSavingForm}
                                    color="primary" block={true} className="w-50 m-auto text-uppercase mt-4">
                                    Save
                                </LoadingButton>{' '}
                            </ModalFooter>
                        </ModalBody>
                    </Modal>
                }
            </>

        )
    }


    const onClickEditButton = (e: any) => {
        e.preventDefault();
        // toastInfo("The New Chosen Diagnosis will Override the previous one")
        toggleModal();
    };
    const renderEditButton = () => {
        if (currentDiagnosis && !readOnly) {
            return (
                <Button color="link" size="sm" className="pt-0" onClick={onClickEditButton}>
                    <span className="sr-only">Edit</span>{" "}
                    <i className="fa fa-edit fa-lg"></i>
                </Button>
            );
        }
    };
    const renderDiagnosisHeader = () => {
        console.log("currentDiagnosis", currentDiagnosis)
        return (
            <>
                <dl>
                    <dt>Diagnosis  {renderEditButton()}</dt>

                </dl>

                {!currentDiagnosis ?

                    <h6>None</h6>
                    :

                    <dl>
                        <Row>
                            <Col sm="12"><strong>Primary Diagnosis:  </strong>{currentDiagnosis.title}</Col>
                        </Row>

                        {
                            currentSecondaryDiagnosis ?
                                <>

                                    <div style={{ margin: "4px" }} />
                                    <Row>
                                        <Col sm="12"><strong>Secondary Diagnosis: </strong>{currentSecondaryDiagnosis}</Col>
                                    </Row>
                                </> :
                                <></>
                        }

                    </dl>
                }

            </>
        )
    }


    return (
        <>
            <Col sm="12" className="mb-3">

                {renderDiagnosisHeader()}
                {renderDiagnosisModal()}
                {!currentDiagnosis && !readOnly ?
                    <Col sm="12">
                        <Button size="sm" outline block color="primary" className="w-50 m-auto" onClick={toggleModal}>Add Diagnosis</Button>
                    </Col>
                    : <></>
                }
            </Col>
        </>
    );
};
