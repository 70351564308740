import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "./App.css";

import { PatientSignIn } from "./page/auth/PatientSignIn";
// import { LandingPage } from "./page/LandingPage";
import { Routes } from "./config/Routes";
import { PatientSignUp } from "./page/auth/PatientSignUp";
import { MedicalHistory } from "./page/medical-history/MedicalHistory";
import { HomePatient } from "./page/dashboard/patient/HomePatient";
import { ResetPasswordRequestPage } from "./page/auth/ResetPasswordRequestPage";
import { ResetPasswordFulfilmentPage } from "./page/auth/ResetPasswordFulfilmentPage";
import { QuickMedicalHistory } from "./page/medical-history/QuickMedicalHistory";
import { AccountActivationPage } from "./page/auth/AccountActivationPage";
import { SuccessfullSignupPage } from "./page/auth/SuccessfullSignupPage";
import { PasswordResetConfirmPage } from "./page/auth/PasswordResetConfirmPage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext, logOut, isLoggedIn } from "./context/UserContext";
import { PrivateRoute } from "./context/component/PrivateRoute";
import { Welcome } from "./page/Welcome";
import { PhysicianLogin } from "./page/auth/PhysicianLogin";
import { PhysicianAuthCodePage } from "./page/auth/PhysicianAuthCodePage";
import { HomePhysician } from "./page/dashboard/physician/HomePhysician";
import { PhysicianTemplates } from "./page/settings/PhysicianTemplates";
import { PhysicianSettings } from "./page/settings/PhysicianSettings";
import { BookAppointment } from "./page/book-appointment/BookAppointment";
import { PatientSettings } from "./components/patientSettings/PatientSettings";
import { PatientAppointmentDetails } from "./page/dashboard/patient/PatientAppointmentDetails";
import { PhysicianAppointmentDetails } from "./page/dashboard/physician/PhysicianAppointmentDetails";
import { AdminLogin } from "./page/auth/AdminLogin";
import { PhysicianRegistration } from "./page/auth/PhysicianRegistration";
import { AdminConsole } from "./page/dashboard/admin/AdminConsole";
import { AdminConfigurationSettings } from "./page/dashboard/admin/AdminConfigurationSettings";
import { AppointmentHistory } from "./page/dashboard/admin/AppointmentHistory";
import { Button } from "reactstrap";
import { FAQ } from "./staticPages/FAQ";
import { StaticRoutes } from "./staticPages/StaticRoutes";
import { About } from "./staticPages/About";
import { Contact } from "./staticPages/Contact";
import { TermsOfUse } from "./staticPages/TermsOfUse";
import { PrivacyPolicy } from "./staticPages/PrivacyPolicy";
import { Help } from "./staticPages/Help";
import { AgentRegistration } from "./page/auth/agent/AgentRegistration";
import { AgentLogin } from "./page/auth/agent/AgentLogin";
import { HomeAgent } from "./page/dashboard/agent/HomeAgent";
import { AgentHistory } from "./page/dashboard/admin/AgentHistory";
import { PatientAuthentication } from "./page/auth/PatientAuthentication";
// import { LandingPageV2 } from "./page/LandingPageV2";
import { USMap } from "./page/USMap";
import { ErrorNotFoundPage } from "./page/notFound/ErrorNotFoundPage";
import { Unsubscribe } from "./page/UnSubscribe";
import { SendActivationEmail } from "./page/auth/SendActivationEmail"
import { DuplicateRegistryEmail } from "./page/auth/DuplicateRegisteryEmail";
import { InsuranceAdmin } from "./page/avility/InsuranceAdmin";
import { BillerRegistration } from "./page/auth/biller/BillerRegistration";
import { BillerLogin } from "./page/auth/biller/BillerLogin";
import { HomeBiller } from "./page/dashboard/biller/HomeBiller";
import { LandingPageV3 } from "./page/LandingPageV3";

interface IScrollToTopProps extends RouteComponentProps {
  children?: React.ReactNode;
}
export const ScrollToTop: React.FC<IScrollToTopProps> = (props) => {

  React.useEffect(() => {
    globalThis.window.scrollTo(0, 0);
    toast.configure();

  }, [props.location?.pathname]);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://telmdcare.com${props.location?.pathname}`}
        />
      </Helmet>
      {props.children}
    </>
  );
};

export const toastError = (message: String) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

export const toastSuccess = (message: String) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

export const toastInfo = (message: String) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

export const navItem = (
  <div>
    <UserContext.Consumer>
      {({ isLoggedIn, logOut }) => (
        <Button href="#" onClick={(e) => { logOut(); e.preventDefault(); return false; }} color="white">
          <span>Log out </span><i className="fas fa-sign-out-alt"></i>
        </Button>
      )}
    </UserContext.Consumer>
  </div>
);

const App = () => {
  return (
    <UserContext.Provider value={{ isLoggedIn: isLoggedIn, logOut: logOut }}>
      <link
        href="https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic"
        rel="stylesheet"
        type="text/css"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Poppins:400,700&amp;display=swap"
        rel="stylesheet"
        type="text/css"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap"
        rel="stylesheet">
      </link>
      <Router primary={false}>
        <ScrollToTop path="/">
          {/* Common Routes */}
          {/* <Redirect noThrow from="/" to={Routes.home} /> */}
          <Welcome path={Routes.welcome} />
          <ResetPasswordRequestPage path={Routes.requestReset}></ResetPasswordRequestPage>
          <SuccessfullSignupPage path={Routes.successfulSignup} />
          <PasswordResetConfirmPage path={Routes.passwordResetConfirm} />
          <DuplicateRegistryEmail path={Routes.duplicateRegistryEmail} />
          
          {/* Patient Routes */}
          <USMap path="/map" />
          <LandingPageV3 path={Routes.home} />
          <PatientSignIn path={Routes.patientSignin}></PatientSignIn>
          <PatientSignUp path={Routes.patientSignup}></PatientSignUp>
          <SendActivationEmail path={Routes.resendEmail} />
          <PatientAuthentication path={Routes.patientVerication}></PatientAuthentication>
          <PrivateRoute as={MedicalHistory} path={Routes.medicalHistory} roles={["patient", "agent"]} />
          <PrivateRoute as={HomePatient} path={Routes.homePatient} roles={["patient", "agent"]} />
          <PrivateRoute as={QuickMedicalHistory} path={Routes.quick} roles={["patient", "agent"]} />
          <BookAppointment  path={Routes.bookAppointment}/>
          <PrivateRoute as={PatientSettings} path={Routes.patientSettings} roles={["patient", "agent"]} />
          <PrivateRoute as={PatientAppointmentDetails} path={Routes.patientAppointmentDetails} roles={["patient", "agent"]} />
          <AccountActivationPage path={Routes.activateAccountPatient} userType="patient" />
          <ResetPasswordFulfilmentPage userType="patient" path={Routes.passwordReset} />

          {/* Physician Routes */}
          <PhysicianLogin path={Routes.physicianSignin} />
          <PhysicianRegistration path={Routes.physicianSignup} />
          <PhysicianAuthCodePage path={Routes.physicianAuthentication} />
          <PrivateRoute as={HomePhysician} path={Routes.homePhysician} roles={["physician"]} />
          <PrivateRoute as={PhysicianTemplates} path={Routes.physicianTemplates} roles={["physician"]} />
          <PrivateRoute as={PhysicianSettings} path={Routes.physicianSettings} roles={["physician"]} />
          <PrivateRoute as={PhysicianAppointmentDetails} path={Routes.physicianAppointmentDetails} roles={["physician"]} />
          <AccountActivationPage path={Routes.activateAccountPhysician} userType="physician" />
          <ResetPasswordFulfilmentPage userType="physician" path={Routes.physicianPasswordReset} />

          {/* Admin Routes */}
          <PrivateRoute as={AdminConsole} path={Routes.adminConsole} roles={["admin"]} />
          <PrivateRoute as={AdminConfigurationSettings} path={Routes.adminConfigurationSettings} roles={["admin"]} />
          <PrivateRoute as={AppointmentHistory} path={Routes.appointmentHistory} roles={["admin"]} />
          <PrivateRoute as={AgentHistory} path={Routes.agentHistory} roles={["admin"]} />
          <PrivateRoute as={PhysicianSettings} path={Routes.adminPhysicianSettings} roles={["admin"]} />
          <PrivateRoute as={InsuranceAdmin} path={Routes.insuranceAdmin} roles={["admin"]} />
          <ResetPasswordFulfilmentPage userType="admin" path={Routes.adminPasswordReset} />
          <AdminLogin path={Routes.adminLogin} />

          {/* Agent Routes */}
          <AgentRegistration path={Routes.agentSignup} />
          <AgentLogin path={Routes.agentLogin} />
          <PrivateRoute as={HomeAgent} path={Routes.homeAgent} roles={["agent"]} />
          <AccountActivationPage path={Routes.activateAccountAgent} userType="agent" />
          <ResetPasswordFulfilmentPage userType="agent" path={Routes.passwordResetAgent} />


          {/* Biller Routes */}
          <BillerRegistration path={Routes.billerSignup} />
          <BillerLogin path={Routes.billerLogin} />
          <PrivateRoute as={HomeBiller} path={Routes.homeBiller} roles={["biller"]} />
          <AccountActivationPage path={Routes.activateAccountBiller} userType="biller" />
          <ResetPasswordFulfilmentPage userType="biller" path={Routes.passwordResetBiller} />

          {/* Static pages Router */}
          <StaticRoutes as={FAQ} path={Routes.faq} />
          <StaticRoutes as={About} path={Routes.about} />
          <StaticRoutes as={Contact} path={Routes.contact} />
          <StaticRoutes as={TermsOfUse} path={Routes.termsOfUse} />
          <StaticRoutes as={PrivacyPolicy} path={Routes.privacyPolicy} />
          <StaticRoutes as={Help} path={Routes.help} />
          <ErrorNotFoundPage path={Routes.notFound} />
          <Unsubscribe path={Routes.unsubscribe} />
        </ScrollToTop>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
