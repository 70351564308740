import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import { uploadMultipleImagesForPatient } from '../../../core/service/services';
import { strings } from '../../../Constants';

import { toastError } from '../../../App';
import { LoadingButton } from '../../../components/commons/LoadingButton';
import { areThereErrors } from '../../../core/service/ErrorService';
import CustomErrorMessage from '../../../components/CustomErrorMessage';

interface IProps {
  isOpen: boolean | undefined;
  toggle: any;
  onSave: any;
}

const NotListedInsuranceModal = (props: IProps) => {
  const [dataToBeUploaded, setDataToBeUploaded] = useState<FormData | undefined>(undefined);
  const [isloading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [frontSubmitted, setFrontSubmitted] = useState(false);
  const [backSubmitted, setBackSubmitted] = useState(false);
  const [isFrontPDF, setIsFrontPDF] = useState<Boolean>(false);
  const [isBackPDF, setIsBackPDF] = useState<Boolean>(false);
  const [frontPDF,setFrontPDF] = useState<String>('')
  const [backPDF,setBackPDF] = useState<String>('')


  const onSubmitForm = (e: any) => {
    props.onSave();

    // todo api call to add the insurance
  };
  const convertToBase64= async (file:File)=>{
    const buffer = await file.arrayBuffer();
    return Buffer.from(buffer).toString('base64')
  }

  const uploadFile = async () => {
    setSubmitted(true);
    setIsLoading(true);
    if (!(frontSubmitted && backSubmitted)) {
      setIsLoading(false);
    } else if (dataToBeUploaded) {
      const photoTokens = await uploadMultipleImagesForPatient(dataToBeUploaded);
      setIsLoading(false);
      props.onSave(photoTokens);
      setDataToBeUploaded(undefined);
    }
  };

  const onDropImage = async (files:File[],side:string)=>{
    if (files.length === 0) {
        const data = dataToBeUploaded;
        data?.delete(side);
        side=='fileFront'?setFrontSubmitted(false):setBackSubmitted(false);
        return;
      }
      const newData = dataToBeUploaded || new FormData();
      newData.append(side, files[0]);
      setDataToBeUploaded(newData);
      if(files[0].type === 'application/pdf')
      {
        handlePDFSubmission(files,side)
        return
      }
      handleImageSubmission(side)
    }

  const handlePDFSubmission= async (files:File[],side:String)=>{
    const base64Img =  await convertToBase64(files[0])
    side=='fileFront'?handelFrontPDF(base64Img):handelBackPDF(base64Img)
    
  }
  const handleImageSubmission = (side:string) =>{
    if(side=='fileFront')
    {
      setIsFrontPDF(false)
      setFrontSubmitted(true);

    }
    else{
      setIsBackPDF(false);
      setBackSubmitted(true);
    }


  }
  const handelFrontPDF = (base64Image:string)=>{
    setFrontPDF(base64Image)
    setIsFrontPDF(true)
    setFrontSubmitted(true);
    
  }
  const handelBackPDF = (base64Image:string)=>{
    setBackPDF(base64Image)
    setIsBackPDF(true)
    setBackSubmitted(true);
  }

  const renderPDF = (pdfString:any) =>{
    return  <embed width={400} height={400} src={`data:application/pdf;base64,${pdfString}`} />
  }
  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader toggle={props.toggle}>
          <h5 id='templatesModal' className='modal-title'>
            {strings.patientBooking.addCardTitle}
          </h5>
        </ModalHeader>
        <Form onSubmit={onSubmitForm}>
          <ModalBody>
            <ImageUploader
              withIcon={true}
              buttonText='Upload Front File'
              onChange={(file)=>onDropImage(file,'fileFront')}
              imgExtension={['.jpg', '.gif', '.png', '.gif', '.pdf', '.jpeg']}
              maxFileSize={5242880}
              withPreview={!isFrontPDF}
              singleImage={true}
            />
            {isFrontPDF?renderPDF(frontPDF):undefined}
            <ImageUploader
              withIcon={true}
              buttonText='Upload Back File'
              onChange={(file)=>onDropImage(file,'fileBack')}
              imgExtension={['.jpg', '.gif', '.png', '.gif', '.pdf', '.jpeg']}
              maxFileSize={5242880}
              withPreview={!isBackPDF}
              singleImage={true}
              
            />
            {isBackPDF?renderPDF(backPDF):undefined}

            <CustomErrorMessage invalid={!(frontSubmitted && backSubmitted)} errorMessage='Please Upload Front and Back Photos' showValidation={submitted} />
            <LoadingButton color='primary' size='sm' block={true} className='w-50 m-auto' onClick={uploadFile} isLoading={isloading}>
              Save
            </LoadingButton>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
};

export default NotListedInsuranceModal;
