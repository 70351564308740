import React, { useState, ChangeEvent } from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import { Routes } from '../../config/Routes';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { CardBody, Container, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input, Label, FormGroup, Badge, Table, UncontrolledTooltip} from 'reactstrap';
import { getUSStatesLookup, uploadImage, savePhysicianInfo, savePhysicianEligibleStates, getPhysicianInfo, getImage, getPhysicianEligibleStates, approvePhysicianState} from '../../core/service/services';
import { TextField } from '../../components/commons/TextField';
import ImageUploader from 'react-images-upload';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { toastError, toastSuccess, navItem } from '../../App';
import moment from 'moment';
import Asterisk from '../../components/commons/Asterisk';
import { UserConfig } from '../../config/userConfig';

interface IProps extends RouteComponentProps {}

export interface IUSState {
  id: number;
  name: string;
  shortName: string;
}

export interface IEligibleState {
  id?: number;
  name?: string;
  shortName?: string;
  licenseNumber?: string;
  approvalStatus?: 'Approved' | 'Disapproved' | 'New'
}

export interface IPHInfo {
  contactInfo?: {
    lastname: string;
    firstname: string;
    mobilePhone: string;
    officePhone?: string;
    title: string;
    zoomAccount: string;
    photoToken?: string;
    email?: string;
    dob?: Date | string;
    middlename?: string;
    primaryFax: string;
    addressLine1: string,
    addressLine2? : string,
    city: string,
    stateId : number,
    zipCode: string,
  };
  physicianInfo?: {
    dea: string;
    npi: string;
    specialty: string;
  };
  banking?: {
    bankName?: string;
    bankAccountNumber?: string;
    bankAccountType?: string;
    bankAddress?: string;
    bankCity?: string;
    bankState?: string;
    bankZip?: string;
    routingNumber?: string;
  };
}

export const male_physician = require('../../../img/male_physician.webp');
export const female_physician = require('../../../img/female_physician.webp');
export const agent_image = require('../../../img/image.webp')
export const titleOptions = ["MD", "DO", "PA", "NP"]

export const PhysicianSettings: React.FC<IProps> = (props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [statesModal, setStatesModal] = useState(false);
  const [profileImageModal, setProfileImageModal] = useState(false);
  const [photoToken, setPhotoToken] = useState<string>('');
  const [profileImage, setProfileImage] = useState<string>();
  const [states, setStates] = useState<IUSState[]>([]);
  const [info, setInfo] = useState<IPHInfo>({});
  const [selectedStates, setSelectedStates] = useState<IEligibleState[]>([]);
  const [tempStates, setTempStates] = useState<IEligibleState[]>([]);
  const [editObj, setEditObj] = useState<boolean[]>([false, false, false]);
  const [dob, setDob] = useState<Date | string>();
  const [statesWizardStep, setStatesWizardStep] = useState<1 | 2>(1);

  const toggleStatesModal = () => {
    setStatesModal(!statesModal);
    setStatesWizardStep(1);
  };
  const toggleProfileImageModal = () =>
    setProfileImageModal(!profileImageModal);

  //references
  const firstNameRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const middleNameRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const lastNameRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const officePhoneRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const mobilePhoneRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const primaryFaxRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const addressLine1Ref: React.RefObject<TextField> = React.useRef<TextField>(null)
  const addressLine2Ref: React.RefObject<TextField> = React.useRef<TextField>(null)
  const cityRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const stateIdRef: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)
  const zipCodeRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const zoomAccountRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const titleRef: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)
  const deaRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const specialtyRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const npiRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const bankNameRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const bankAccountNumberRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const bankAccountTypeRef: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)
  const bankAddressRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const bankCityRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const bankStateRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const bankZipRef: React.RefObject<TextField> = React.useRef<TextField>(null)
  const routingNumberRef: React.RefObject<TextField> = React.useRef<TextField>(null)

  const accountTypeOptions = ['', 'Saving', 'Checking'];
  const search = new URLSearchParams(props.location?.search);
  const admin_phId = search.get("phId");
  
  const getData = () => {
    setIsAdmin(UserConfig.getUserType() === "admin")
    getUSStatesLookup()
      .then((res) => {
        setStates(res);
      })
      .catch((error) => console.error(error));
    getPhysicianEligibleStates(admin_phId)
      .then((res) => {
        setSelectedStates(res);
      })
      .catch((error) => console.error(error));
    async function fetchData() {
      const iPhInfo = await getPhysicianInfo(admin_phId);
      setInfo(iPhInfo);
      setDob(iPhInfo.contactInfo?.dob);
      if (iPhInfo.contactInfo?.photoToken != undefined) {
        setPhotoToken(iPhInfo.contactInfo?.photoToken);
        const image = await getImage(iPhInfo.contactInfo?.photoToken, admin_phId);
        setProfileImage(image.imageBase64);
      }
    }
    fetchData();
  };

  // const getValue = (str: string | undefined): string | undefined => {
  //   if (str && str != "") {
  //     return str
  //   }
  //   return undefined;
  // };

  const getValue = (arr: [string, FormDataEntryValue][], field: string): string => {
    for (let pair of arr) {
      if (pair[0] === field) {
        return pair[1].toString();
      }
    }
    return '';
  };

  const validateEmail = (email?: string| null) => {
    if (email == null || email.length  < 1){
      return false;
    }
    let re = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
    return re.test(email);
  }

  const validate = (
    formData: [string, FormDataEntryValue][],
    section: string
  ): boolean => {
    let errorsExist = false;
    switch (section) {
      case 'contactInfo':
        if (getValue(formData, "firstname") === '') {
          firstNameRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "lastname") === '') {
          lastNameRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "title") === '') {
          toastError('Title is a mandatory field');
          errorsExist = true;
        }
        let mobilePhone = getValue(formData, "mobilePhone");
        if (!mobilePhone || mobilePhone === '') {
          mobilePhoneRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        } 
        // else if (!mobilePhone?.startsWith('+')) {
        //   mobilePhoneRef.current?.setErrors(['Should start with country code']);
        //   errorsExist = true;
        // }
        if (moment(dob).format("YYYY-MM-DD") === '') {
          errorsExist = true;
        }
        const email = getValue(formData, "zoomAccount")
        if (!validateEmail(email)) {
          zoomAccountRef.current?.setErrors(['Invalid email format.']);
          errorsExist = true;
        }
        if (getValue(formData, "primaryFax") === '') {
          primaryFaxRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "addressLine1") === '') {
          addressLine1Ref.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "city") === '') {
          cityRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "state") === '') {
          toastError('State is a mandatory field');
          errorsExist = true;
        }
        if (getValue(formData, "zipCode") === '') {
          zipCodeRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        break;
      case 'physicianInfo':
        if (getValue(formData, "dea") === '') {
          deaRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "specialty") === '') {
          specialtyRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        if (getValue(formData, "npi") === '') {
          npiRef.current?.setErrors(['Mandatory Field']);
          errorsExist = true;
        }
        break;
      default:
        break;
    }
    // setErrorMsgs(errors)
    if (errorsExist) {
      return false;
    }
    return true;
  };

  const saveSettings = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: FormData = new FormData(e.currentTarget);
    const formData: [string, FormDataEntryValue][] = Array.from(data.entries());
    if (validate(formData, e.currentTarget.id)) {
      let phInfo: IPHInfo = {};
      switch (e.currentTarget.id) {
        case 'contactInfo':
          phInfo.contactInfo = {
            title: titleRef.current?.value || "",
            firstname: getValue(formData, 'firstname'),
            lastname: getValue(formData, 'lastname'),
            middlename: getValue(formData, 'middlename'),
            mobilePhone: getValue(formData, 'mobilePhone'),
            officePhone: getValue(formData, 'officePhone'),
            primaryFax: getValue(formData, 'primaryFax'),
            zoomAccount: getValue(formData, 'zoomAccount'),
            addressLine1: getValue(formData, 'addressLine1'),
            addressLine2: getValue(formData, 'addressLine2'),
            city: getValue(formData, 'city'),
            stateId: parseInt(getValue(formData, 'state')),
            zipCode: getValue(formData, 'zipCode'),
            dob: moment(dob).format("YYYY-MM-DD"),
            photoToken: (photoToken && photoToken != '')? photoToken : undefined
          };
          if (editObj[0]) {
            updateEditArr(0);
          }
          break;
        case 'physicianInfo':
          phInfo.physicianInfo = {
            dea: deaRef.current?.getValue() || "",
            npi: npiRef.current?.getValue() || "",
            specialty: specialtyRef.current?.getValue() || ""
          };
          updateEditArr(1);
          break;
        case 'bankingInfo':
          phInfo.banking = {};
          phInfo.banking.bankAccountNumber = bankAccountNumberRef.current?.getValue()
          phInfo.banking.bankAddress = bankAddressRef.current?.getValue()
          phInfo.banking.bankCity = bankCityRef.current?.getValue()
          phInfo.banking.bankAccountType = bankAccountTypeRef.current?.value
          phInfo.banking.bankName = bankNameRef.current?.getValue()
          phInfo.banking.bankState = bankStateRef.current?.getValue()
          phInfo.banking.bankZip = bankZipRef.current?.getValue()
          phInfo.banking.routingNumber = routingNumberRef.current?.getValue()
          updateEditArr(2);
          break;
        default:
          break;
      }
      savePhysicianInfo(phInfo, admin_phId)
        .then((res) => {
        })
        .catch((error) => console.log(error));
    }
  };

  const onDropImage = (files: File[], pictures: string[]) => {
    const data = new FormData();
    data.append('file1', files[0]);
    uploadImage(data)
      .then((res) => {
        setPhotoToken(res.photoToken);
      })
      .catch((error) => console.log(error));
  };

  const generateImage = (): any => {
    if (profileImage) {
      const imageUrl = 'data:image/jpeg;base64, ' + profileImage;
      return imageUrl;
    }
    return male_physician;
  };

  const uploadFile = () => {
    getImage(photoToken).then(res => {
      setProfileImage(res.imageBase64)
    }).catch(error => console.log(error))
    toggleProfileImageModal()
    let phInfo: IPHInfo = info
    if (phInfo.contactInfo) {
      phInfo.contactInfo.photoToken = photoToken;
    }
    savePhysicianInfo(phInfo).then(res => {
    }).catch(error => console.log(error))
  }

  const flowNav = (
    <div className="flow-nav-tools">
      <Button
        color="primary"
        size="lg"
        className="control-nav"
        style={{ marginRight: 'auto' }}
        onClick={() => navigate(isAdmin? Routes.adminConsole : Routes.homePhysician)}
      >
        Back
      </Button>
    </div>
  );

  const fillTempStatesObject = () => {
    let cloneArr: IEligibleState[] = [];
    selectedStates.forEach((element) => {
      cloneArr.push({
        id: element.id,
        name: element.name,
        licenseNumber: element.licenseNumber,
        approvalStatus: element.approvalStatus
      });
    });
    setTempStates(cloneArr);
  };

  const setEligibleStates = () => {
    let cloneArr: IEligibleState[] = [];
    tempStates.forEach((element) => {
      cloneArr.push({
        id: element.id,
        name: element.name,
        licenseNumber: element.licenseNumber,
        approvalStatus: element.approvalStatus
      });
    });
    setSelectedStates(cloneArr);
    savePhysicianEligibleStates(tempStates)
      .then((res) => {
      })
      .catch((error) => console.log(error));
    toggleStatesModal();
  };

  const toggleState = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.checked) {
      let newEState: IEligibleState = {};
      newEState.id = parseInt(e.target.id);
      newEState.name = e.target.name;
      newEState.approvalStatus = 'New'
      setTempStates([...tempStates, newEState]);
    } else {
      setTempStates(
        tempStates.filter((state) => {
          return e.target.id !== '' + state.id;
        })
      );
    }
  };

  React.useEffect(() => getData(), []);

  const eligibleStatesContains = (id: number) => {
    for (let index = 0; index < tempStates.length; index++) {
      let state = tempStates[index];
      if (state.id === id) {
        return true;
      }
    }
    return false;
  };

  const setLicenseForState = (e: ChangeEvent<HTMLInputElement>, stateId: number) => {
    let selected = tempStates;
    selected.map((state) => {
      if (state.id === stateId) {
        state.licenseNumber = e.target.value;
        state.approvalStatus = 'New'
      }
    });
    setTempStates(selected);
  };

  const getModals = (): React.ReactNode => {
    return (
      <>
      <Modal isOpen={statesModal} toggle={toggleStatesModal} className="modal-lg">
        <ModalHeader toggle={toggleStatesModal}>
          {statesWizardStep === 1 ? "Physician Settings > Service Eligible States" : "Physician Settings > Service Eligible States > License Numbers"}
        </ModalHeader>
        <ModalBody>
          {statesWizardStep === 1 ?
            <> 
            <p>Please choose from the list below:</p>
            <Row>
              {states.map(state => (
                <Col sm="6" md="4">
                  <div className="mb-2">
                    <div className="custom-control custom-checkbox">
                      <Input type="checkbox" className="custom-control-input" id={"" + state.id} name={state.name} 
                        onChange={(e) => toggleState(e)} checked={eligibleStatesContains(state.id)}/>
                      <Label className="custom-control-label" htmlFor={"" + state.id}>{state.name}</Label>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            </>
            :
            <>
            <p>Please fill in the license number for each eligible state:</p>
            <Form id="licenses" onSubmit={(e)=>{e.preventDefault(); setEligibleStates();}}>
            {tempStates.map(state => (
              <>
                <Label style={{marginTop: 10}}>{state.name} <Asterisk/></Label>
                <Input type="text" required={true} defaultValue={state.licenseNumber} onChange={e => setLicenseForState(e, (state.id || 0))}></Input>
              </>
            ))}
            </Form>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggleStatesModal}>Cancel</Button>
          {statesWizardStep === 1 && tempStates.length > 0 && 
            <Button color="success" onClick={() => setStatesWizardStep(2)}>Next</Button>
          }
          {statesWizardStep === 2 &&
            <Button color="success" type="submit" form="licenses">Save</Button>
          }
        </ModalFooter>
      </Modal>
      <Modal isOpen={profileImageModal} toggle={toggleProfileImageModal}>
        <ModalHeader toggle={toggleProfileImageModal}>
          <h5 id="templatesModal" className="modal-title">Update profile picure</h5>
        </ModalHeader>
        <ModalBody>
          <ImageUploader
            withIcon={true}
            buttonText='Choose image'
            onChange={onDropImage}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
            maxFileSize={5242880}
            withPreview={true}
            singleImage={true}
          />
          <Button color="primary" size="sm" block={true} className="w-50 m-auto" onClick={uploadFile}>Save</Button>
        </ModalBody>
      </Modal>
      </>
    );
  };

  const getSmallCard = () => (
    <CardBody>
      <div className="d-flex px-2">
        <h5 className="mb-0">Profile Picture</h5>
        {!isAdmin && <Button color="link" size="sm" className="ml-auto" onClick={toggleProfileImageModal}>
          Change
        </Button>}
      </div>
      <hr className="mt-2 mb-4" />
      <Container>
        <img src={generateImage()} style={{ width: '100%' }} />
      </Container>
    </CardBody>
  );

  const getApprovalBadge = (approvalStatus?: string) => {
    if (approvalStatus === 'Approved') {
      return (<Badge color="success">{approvalStatus}</Badge>)
    } else if (approvalStatus === 'Disapproved') {
      return (<Badge color="danger">Rejected</Badge>)
     } else {
      return (<Badge color="warning">Pending Approval</Badge>)
    }
  }

  const getApprovalAction = (state: IEligibleState) => {
    return (
      state.approvalStatus === 'Approved'? 
      <Link to="" onClick={(e) => state.id && approveState(e, state.id, false)} className="text-danger">
        <i className="far fa-times-circle" id="rejectState" />
        <UncontrolledTooltip placement="top" target="rejectState">Reject state</UncontrolledTooltip>
      </Link>
      :
      <Link to="" onClick={(e) => state.id && approveState(e, state.id, true)} className="text-success">
        <UncontrolledTooltip placement="top" target="approveState">Confirm approval request</UncontrolledTooltip>
        <i className="far fa-check-circle" id="approveState" />
      </Link>
    )
  }

  const approveState = (e: React.MouseEvent, stateId: number, approve: boolean) => {
    e.preventDefault()
    admin_phId !== null && approvePhysicianState(approve, admin_phId, stateId).then(res => {
      toastSuccess('State ' + (approve? 'approved!' : 'rejected!'))
      let cloneArr: IEligibleState[] = [];
      selectedStates.forEach((element) => {
        cloneArr.push({
          id: element.id,
          name: element.name,
          licenseNumber: element.licenseNumber,
          approvalStatus: stateId === element.id ? (approve? 'Approved' : "Disapproved") : element.approvalStatus
        });
      });
      setSelectedStates(cloneArr);
    }).catch(err => console.log(err))
  }

  const getEligibleStatesItems = () => (
    <Row>
    
      <Col md="12" lg="12">
        <Table responsive>
          <tbody>
          {selectedStates.map(state => (
            <tr>
              <td>{state.name}</td>
              <td>{"LIC: " + state.licenseNumber}</td>
              <td>{getApprovalBadge(state.approvalStatus)}</td>
              {isAdmin && <td>{getApprovalAction(state)}</td>}
            </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    
    </Row>
  );

  const updateEditArr = (idx: number) => {
    let newEditObj: boolean[] = [];
    editObj.forEach((obj, i) => {
      newEditObj.push(idx === i ? !obj : obj);
    });
    setEditObj(newEditObj)
  }

  const renderFormButtons = (formId: string, formIdx: number) => (
    editObj[formIdx] ?
    <>
    <Button color="link" type="submit" form={formId} size="sm" className="pt-0">
      <i className="far fa-save fa-lg"></i>{" "}
      <span>Save</span>
    </Button>
    <Button color="link" size="sm" className="pt-0" onClick={(e) => {updateEditArr(formIdx)}}>
      <i className="far fa-window-close fa-lg"></i>{" "}
      <span >Cancel</span>
    </Button>
    </>
    :
    <Button color="link" size="sm" className="pt-0" onClick={(e) => {e.preventDefault(); updateEditArr(formIdx)}}>
      <span className="sr-only">Edit</span>{" "}
      <i className="fa fa-edit fa-lg"></i>
    </Button>
  )

  const renderContactInfo = () => (
    <>
      <h6 className="mb-3">
        <Label>Contact Information</Label>
        {renderFormButtons('contactInfo', 0)}
      </h6>
      <Form id="contactInfo" onSubmit={saveSettings}>
        <Row>
          <Col md="3" lg="3">
              <FormGroup>
              <Input type="select" required name="title" disabled={!editObj[0]} innerRef={titleRef}>
                <option value="" selected={!info.contactInfo?.title || "" === info.contactInfo?.title}>Select Title</option>
                {titleOptions.map(option => (
                  <option value={option} selected={option === info.contactInfo?.title}>{option}</option>
                ))}
              </Input>
              </FormGroup>
          </Col>
          <Col md="5" lg="5">
            <TextField type="text" name="firstname" placeholder="First Name" labelText="First Name" required ref={firstNameRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.firstname}/>
          </Col>
          <Col md="4" lg="4">
            <TextField type="text" name="middlename" placeholder="Middle Name" labelText="Middle Name" ref={middleNameRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.middlename}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="lastname" placeholder="Last Name"  labelText="Last Name" required ref={lastNameRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.lastname}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="email" name="email" placeholder="you@example.com"  labelText="Email" required disabled={true} defaultValue={info.contactInfo?.email}/>
          </Col>
          <Col md="6" lg="6">
          <div className="form-label-group ">
            <DatePicker
              selected={typeof dob === "string"? moment(dob, "YYYY-MM-DD").toDate() : dob}
              onChange={(date) => {
                if (date) setDob(date);
              }}
              showYearDropdown={true}
              scrollableYearDropdown={true}
              yearDropdownItemNumber={90}
              dateFormat="MM-dd-yyyy"
              maxDate={new Date()}
              disabled={!editObj[0]}
              name="dob"
              required
              // placeholderText="yyyy-MM-dd"
            />
            <Label>Date of birth <Asterisk/></Label>

          </div>
          </Col>
          <Col md="6" lg="6">
              <TextField type="phone" name="officePhone" labelText="Office Phone" ref={officePhoneRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.officePhone}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="phone" name="mobilePhone" required labelText="Mobile Phone" ref={mobilePhoneRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.mobilePhone}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="primaryFax" required labelText="Primary Fax" ref={primaryFaxRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.primaryFax}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="zoomAccount" required labelText="Zoom Account Email" ref={zoomAccountRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.zoomAccount}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="addressLine1" required labelText="Address Line 1" ref={addressLine1Ref} disabled={!editObj[0]} defaultValue={info.contactInfo?.addressLine1}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="addressLine2" labelText="Address Line 2" ref={addressLine2Ref} disabled={!editObj[0]} defaultValue={info.contactInfo?.addressLine2}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="city" required labelText="City" ref={cityRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.city}/>
          </Col>
          <Col md="6" lg="6">
            <FormGroup>
              <Label>State:</Label>
              <Input type="select" className="form-control form-control-sm" innerRef={stateIdRef} disabled={!editObj[0]} name="state">
                <option value="">Select your state</option>
                {states.map((state) => (
                  <option value={state.id} selected={state.id === info.contactInfo?.stateId}>{state.name}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="zipCode" required labelText="Zip Code" ref={zipCodeRef} disabled={!editObj[0]} defaultValue={info.contactInfo?.zipCode}/>
          </Col>
          <Col md="6" lg="6">
            {!isAdmin && <Link to={Routes.requestReset}>Request Password reset</Link>}
          </Col>
        </Row>
      </Form>
    </>
  );

  const renderPhysicianInfo = () => (
    <>
      <h6 className="mb-3">Physician Credentials
        {renderFormButtons("physicianInfo", 1)}
      </h6>
      <Form id="physicianInfo" onSubmit={saveSettings}>
        <Row>
          <Col md="6" lg="6">
              <TextField type="text" name="dea" required labelText="DEA"  ref={deaRef} disabled={!editObj[1]} defaultValue={info.physicianInfo?.dea}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="npi" required labelText="NPI"  ref={npiRef} disabled={!editObj[1]} defaultValue={info.physicianInfo?.npi}/>
          </Col>
          <Col md="6" lg="6">
              <TextField type="text" name="specialty" required labelText="Specialty"  ref={specialtyRef} disabled={!editObj[1]} defaultValue={info.physicianInfo?.specialty}/>
          </Col>
        </Row>
      </Form>
    </>
  );

  const renderBankingInfo = () => (
    <>
      <h6 className="mb-3">
        <Label>Banking Information</Label>
        {renderFormButtons('bankingInfo', 2)}
      </h6>
      <Form id="bankingInfo" onSubmit={saveSettings}>
        <Row>
          <Col md="6">
              <TextField type="text" name="bankName" ref={bankNameRef} labelText="Bank Name" disabled={!editObj[2]} defaultValue={info.banking?.bankName}/>
          </Col>
          <Col md="6">
              <TextField type="text" name="bankAccountNumber" placeholder="Enter Account Number" ref={bankAccountNumberRef} labelText="Account Number" disabled={!editObj[2]} defaultValue={info.banking?.bankAccountNumber}/>
          </Col>
          <Col md="4" lg="4">
            <TextField type="text" name="routingNumber" ref={routingNumberRef} labelText="Routing number" disabled={!editObj[2]} defaultValue={info.banking?.routingNumber}/>
          </Col>
          <Col md="3" lg="3">
            <FormGroup>
              <Label>Account Type</Label>
              <Input type="select" name="bankAccountType" disabled={!editObj[2]} defaultValue={info.banking?.bankAccountType} innerRef={bankAccountTypeRef}>
                {accountTypeOptions.map((option) => (
                  <option
                    value={option}
                    selected={option === info.banking?.bankAccountType}
                  >
                    {option}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
              <TextField type="text" name="bankAddress" ref={bankAddressRef} labelText="Bank Address" disabled={!editObj[2]} defaultValue={info.banking?.bankAddress}/>
          </Col>
          <Col md="4" lg="5">
              <TextField type="text" name="bankCity" ref={bankCityRef} labelText="Bank City" disabled={!editObj[2]} defaultValue={info.banking?.bankCity}/>
          </Col>
          <Col md="4" lg="3">
              <TextField type="text" name="bankState" ref={bankStateRef} labelText="Bank State" disabled={!editObj[2]} defaultValue={info.banking?.bankState}/>
          </Col>
          <Col md="4" lg="4">
              <TextField type="text" name="bankZip" ref={bankZipRef} labelText="Bank Zip" disabled={!editObj[2]} defaultValue={info.banking?.bankZip}/>
          </Col>
        </Row>
      </Form>
    </>
  );

  return (
    <SideCardFrame
      flowNav={flowNav}
      navItem={navItem}
      bodyClassName="tc-bg"
      modal={getModals()}
      smallCard={getSmallCard()}
    >
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">Physician Settings{isAdmin? (":  Dr. " + info.contactInfo?.firstname + " " + info.contactInfo?.lastname) : ""}</h5>
          <Button color="link" size="sm" className="ml-auto" onClick={() => navigate(isAdmin? Routes.adminConsole : Routes.homePhysician)}> Cancel</Button>
        </div>
        <hr className="mt-2 mb-4" />
        <Container>
          <CardTitle>Please review your physician settings below and edit any inconsistencies</CardTitle>
            {renderContactInfo()}
            <hr />
            {renderPhysicianInfo()}
            <hr />
            <h6 className="my-3">Service Eligible States
              {!isAdmin && <Button color="link" size="sm" className="pt-0" onClick={() => {fillTempStatesObject(); toggleStatesModal()}}>
                <span className="sr-only">Edit</span>{" "}
                <i className="fa fa-edit fa-lg"></i>
              </Button>}
            </h6>
            <div>
              {getEligibleStatesItems()}
            </div>
            <hr />
            {renderBankingInfo()}
        </Container>
      </CardBody>
    </SideCardFrame>
  );
};
