import React, { useEffect, useState } from "react";
import { ListGroup, CardTitle, ListGroupItem } from "reactstrap";
import { IRxReconcile, listRxChangeReconcile } from "../../../core/service/services";
import { RxReconcileItem } from "./RxReconcileItem";
import { RxReconcileModal } from "./RxReconcileModal";

interface IProps {
    setRefetch: () => void

}

export const RxReconcileAppointements: React.FC<IProps> = props => {

    const [rxChangeAppointments, setRxChangeAppointments] = useState<IRxReconcile[]>([])
    const [rxReconcileModal, setRxReconcileModal] = useState<boolean>(false)
    const [chosenReconcile, setChosenReconcile] = useState<IRxReconcile | undefined>(undefined)

    const getData = () => {
        listRxChangeReconcile().then(res => {
            console.log(res)
            setRxChangeAppointments([...res.items])
        }).catch(err => console.log(err))
    }

    const toggleRxChangeModal = () => {
        setRxReconcileModal(!rxReconcileModal);
    }
    const renderHeader = () => (
        <div className="section-content-header d-flex flex-nowrap">
            <div className="order-1 mr-auto">
                <h5 className="text-muted">
                    <span>RxChange Reconcile</span>
                </h5>
            </div>
        </div>
    )
    const renderModalToShowReconcile = () => (

        <RxReconcileModal modalIsOpen={rxReconcileModal} toggleModal={toggleRxChangeModal} rxReconcile={chosenReconcile} onReconcile={() => {
            props.setRefetch()
            getData()
        }}
        />

    )

    useEffect(() => {
        getData()
    }, [])

    const appItemStyle = () => {
        let style = { borderTopWidth: "thin", borderColor: "red" }
        return style
    }
    const onItemClicked = (rxReconcileChosen: IRxReconcile) => {
        setChosenReconcile(rxReconcileChosen)
        toggleRxChangeModal()
    }
    return (
        <>
            {renderModalToShowReconcile()}
            {renderHeader()}
            <div>
                {rxChangeAppointments.length > 0 ?
                    <>
                        <ListGroup className="timeline upcoming pre-appt-scheduled">
                            {rxChangeAppointments.map(item => (
                                <ListGroupItem style={appItemStyle()}>

                                    <RxReconcileItem key={item.id} rxReconcile={item} onItemClicked={onItemClicked}
                                    />
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                        <hr />
                    </>
                    :
                    <CardTitle className="mb-3 pl-3">There are no current appointments with RxChange requests</CardTitle>
                }
            </div>
        </>
    );
};
