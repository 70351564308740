import React from 'react'
import { Modal, ModalBody, ModalFooter, Button, Table } from 'reactstrap'
import { useEffect, useState } from 'react'
import { IPrescription } from '../../../core/service/services'

interface IProps {
  isOpen: boolean,
  toggleOpen: () => void,
  setResponse: (res: boolean) => void,
  prescriptions: IPrescription[]
}

export const PrescriptionApprovalAlert: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect( () => setIsOpen(props.isOpen), [props.isOpen])

  return (
    <Modal isOpen={isOpen} toggle={props.toggleOpen} size="lg">
      <ModalBody>
        <h6>Are you sure you want to submit the following prescriptions?</h6>
        <Table size="sm" className="ml-2">
          <thead>
            <th>Name</th>
            <th>Strength</th>
            <th>Amount</th>
            <th>Refills</th>
            <th>Notes</th>
          </thead>
          <tbody>
            {props.prescriptions.map((item) => (
              <tr>
                <td>{item.medicationName}</td>
                <td>{item.strength}</td>
                <td>{item.dispense}</td>
                <td>{item.refills}</td>
                <td>{item.directionsForUse}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" size="lg" block className="ml-auto" onClick={() => props.setResponse(false)}>No, I want to make some changes</Button>
        <Button color="success" size="lg" block className="ml-auto" onClick={() => props.setResponse(true)}>Yes, submit now</Button>
      </ModalFooter>
    </Modal>
  )
}