import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { MessagingList } from "./MessagingList";
import { IMessage } from "./MessageModel";
import { SendMessageModule } from "./SendMessageModule";
import { UserConfig } from "../../config/userConfig";

interface IProps {
  modalOpen: boolean,
  toggleChatModal: () => void,
  appointmentId?: string,
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
  receiverName: string
}

export const ChatModule: React.FC<IProps> = props => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [addedMessage, setNewMessage] = useState<IMessage>();
  const [appointmentId, setAppointmentId] = useState<string>();

  useEffect(
    () => {
      setModalOpen(props.modalOpen)
      setNewMessage(props.addedMessage)
      setAppointmentId(props.appointmentId)
    }, [props.modalOpen, props.addedMessage, props.appointmentId]
  )

  return (
    <Modal isOpen={modalOpen} toggle={props.toggleChatModal} 
    style={{ maxHeight:'calc(100% - 80px)'}}
    >
      <ModalHeader toggle={props.toggleChatModal}>
        {(UserConfig.getUserType() == "patient" ? "Doctor Chat: " : "Patient Chat: ") + props.receiverName}
        {UserConfig.getUserType() == "patient" && <p style={{fontSize:'medium', fontWeight:"lighter"}}>If it is an emergency call 911 or go the nearest hospital. Provider will get back to you within 24 hrs.</p>}
      </ModalHeader>
      <ModalBody style={{ maxHeight:'calc(100vh - 125px)',  overflowY: "auto"}}>
        <MessagingList appointementId={appointmentId} addedMessage={addedMessage} ></MessagingList>
      </ModalBody>
      <SendMessageModule messageSent={props.onMessageReceived} appointementId={appointmentId} closeClicked={props.toggleChatModal} showClosed={true}></SendMessageModule>
    </Modal>
  );
};
