import React, { useState, useEffect } from "react";
import PatientSettingsHeader from "./PatientSettingsHeader";
import PreferredPharmacy from "./PreferredPharmacy";
import MembersInHousehold from "./MembersInHousehold";
import { requestPatientInfo, getUSStatesLookup, getAllUSStateLookUpForPatient } from "../../core/service/services";
import { IPatientInfoSettings } from "./PatientSettings";
import PaymentMethods from "./PaymentMethods";
import { IUSState } from "../../page/settings/PhysicianSettings";
import { CardBody } from "reactstrap";
import { PersonalInformation } from "./PersonalInformation";
import InsuranceSection from "./insurance/InsuranceSection";

const PatientSettingsBody = () => {
  const [currentInfo, setCurrentInfo] = useState<IPatientInfoSettings>({});
  const [usStates, setUSStates] = useState<IUSState[]>([])
  const [allUSStates, setAllUSStates] = useState<IUSState[]>([])

  useEffect(() => {
    fetchData();
  }, [""]);

  async function fetchData() {
    const iPatientInfo = await requestPatientInfo("GET").catch(console.log)
    if (iPatientInfo) setCurrentInfo(iPatientInfo);
    const states = await getUSStatesLookup()
    setUSStates(states)
    getAllUSStateLookUpForPatient().then((res) => {
      setAllUSStates(res);
    })
  }

  return (
    <CardBody>
      <PatientSettingsHeader title="Patient Settings" />
      <hr className="mt-2 mb-4" />
      <h5 className="card-title">Update your settings and details below:</h5>

      <div className="mt-3">
        <PersonalInformation info={currentInfo} usStates={allUSStates} fetchData={fetchData} />
        <hr />
        <PaymentMethods info={currentInfo} usStates={allUSStates} />
        <hr />
        <PreferredPharmacy info={currentInfo} fetch={fetchData} physician={false} pharmacy={currentInfo.pharmacy} onRegister={false} />
        <hr />
        <MembersInHousehold info={currentInfo} fetchData={fetchData} />
        <hr />
        <InsuranceSection info={currentInfo} />

      </div>
    </CardBody>
  );
};

export default PatientSettingsBody;
