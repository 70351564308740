import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes, CRoutes } from "../config/Routes";
import cbsLogo from "../../img/cbs-logo.webp";
import foxNews from "../../img/fox-news-logo-color.webp";
import nbc from "../../img/nbc-logo-color.webp";
import usaToday from "../../img/usa-today-logo-color.webp";
import onlineDoctorVisit from "../../img/online-doctor-visit-mobile.webp";
import headshotDrIbrahim from "../../img/headshot-dr-ibrahim.webp";
import headshotDrTawfeek from "../../img/headshot-dr-tawfeek.webp";
import schedulingVisit from "../../img/scheduling-visit.webp";
import dApple from "../../img/download-apple-store.webp";
import dGoogle from "../../img/download-google-play.webp";
import patientDoctorDesktop from "../../img/patient-doctor-desktop.webp";
import pharmacyMap from "../../img/pharmacy-map.webp";
import telehealth from "../../img/telehealth.webp";
import insuranceLogoBcbs from "../../img/insurance-logo-bcbs.webp";
import insuranceLogoCigna from "../../img/insurance-logo-cigna.webp";
import insuranceLogoAetna from "../../img/insurance-logo-aetna.webp";
import insuranceLogoUhc from "../../img/insurance-logo-uhc.webp";
import insuranceLogoMedicare from "../../img/insurance-logo-medicare.webp";
import compareAverage from "../../img/compare-average-copay-comparison.svg";
import compareWTimes from "../../img/compare-wait-times.svg";
import comparePtSatisfaction from "../../img/compare-patient-satisfaction.svg";
import telmdcareLogoWht from "../../img/telemdcare-logo-wht.webp";
import googleLogo from "../../img/google-logo.webp";
import facebookLogo from "../../img/facebook-logo.webp";
import Footer from "../components/layout/Footer";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from "react-helmet";
import { Modal, ModalBody } from "reactstrap";
// import '../../css/landing-page.css';
import '../../css/home-page-new.css';
import { LandingPageHeader } from "./LandingPageHeader";
import { HowItWorksVideo } from "../components/layout/HowItWorksVideo";

interface IProps extends RouteComponentProps {
  // children?: React.ReactNode
}

interface IState {
  discountModal: boolean;
}

const responsivePh = {
  0:{
    items:1
  },
  880:{
    items:2
  }
}

const responsiveTs = {
  0:{
    items:1
  },
  600:{
    items:2
  },
  992:{
    items:3
  }
}
export class LandingPageV3 extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      discountModal: false,
    };

    this.toggleDiscountModal = this.toggleDiscountModal.bind(this)
  }

  componentDidMount() {
    this.setState({
      discountModal: true
    })
  }

  toggleDiscountModal() {
    this.setState({discountModal: !this.state.discountModal})
  }

  render() {
    return (
      <>
      <Helmet>
      <title>Find A Doctor Online | Family Care Doctors | Affordable Online Healthcare - TelMDCare</title>
      <meta name="description" content="Want to limit your in-person doctor visits? Book a consultation with our COVID online doctor at TelMDCare today and have all your concerns answered in detail."/>
      <meta name="title" content="Find A Doctor Online | Family Care Doctors | Affordable Online Healthcare - Telmdcare"/>
      </Helmet>
      <LandingPageHeader />
      {/* COVID MSG */}
      <div className="alert alert-special" role="alert">
          <div className="container">
              <h4 className="generalClass-h mt-5 pt-2">We now offer <strong>FDA authorized COVID-19 Anti-viral Treatment</strong> (Molnupiravir and Paxlovid)</h4>
              <a href="https://telmdcare.com/c/molnupiravir-and-paxlovid.html" className="text-white">Talk with a doctor to find out more &gt;&gt;</a>
          </div>
      </div>
      {/*  masthead  */}
        
      <header className="masthead  ">
        <div className="container">
            <h1 className="sm-and-down mb-4 generalClass-h">Affordable health care <span>when you need it.</span></h1>
            <div className="d-flex flex-column-reverse flex-lg-row flex-md-row flex-sm-column-reverse ">
                <div className="flex-grow">
                    <h1 className="md-and-up generalClass-h">Affordable health care when you need it.</h1>
                    <p>Schedule your next physician visit without ever leaving your home. Each visit is an affordable, flat rate. <strong>No insurance necessary, but we do accept coverage from many popular health insurance providers</strong>. </p>
                    <a className="btn-landing btn-lg btn-primary-landing a-landing" href={Routes.patientSignup}>Book an Appointment for Only <del>$79</del> $43</a>

          <div className="mt-5 as-seen-logo-brands md-and-up">
            <h4 className="text-dark font-weight-bold generalClass-h h4-class h4-h5-h6">As seen on</h4>
            <div className="d-flex flex-row align-items-start">
            <div className="bg-white rounded-circle shadow p-3 mr-2">
              <img className="m-auto" src={cbsLogo} />
            </div>
            <div className="bg-white rounded-circle shadow p-3 mr-2">
              <img className="m-auto" src={foxNews} />
            </div>
            <div className="bg-white rounded-circle shadow p-3 mr-2">
              <img className="m-auto" src={nbc} />
            </div>
            <div className="bg-white rounded-circle shadow p-3">
              <img className="m-auto" src={usaToday} />
            </div>
            
            </div>
          </div>
          <div className="my-5 download-app">
              <h6 className="text-dark">Download the App</h6>
              <div className="d-flex">
              <a target="blank" href="https://apps.apple.com/eg/app/telmd-care/id1575126713"><img src={dApple} className="img-fluid"/></a>
               <a target="blank" href="https://play.google.com/store/apps/details?id=org.tgitek.telmdcare"><img src={dGoogle} className="img-fluid"/></a>
              </div>
          </div>
          </div>
          <div className="d-flex flex-row">
            <img src={onlineDoctorVisit} className="hero-img" />
            <div className="mt-3 mx-auto as-seen-logo-brands sm-and-down">
              <h4 className="text-dark font-weight-bold generalClass-h h4-class h4-h5-h6">As seen on</h4>
              <div className="d-flex flex-column">
                <div className="bg-white rounded-circle shadow p-3 mb-2">
                  <img className="m-auto" src={cbsLogo} />
                </div>
                <div className="bg-white rounded-circle shadow p-3 mb-2">
                  <img className="m-auto" src={foxNews} />
                </div>
                <div className="bg-white rounded-circle shadow p-3 mb-2">
                  <img className="m-auto" src={nbc} />
                </div>
                <div className="bg-white rounded-circle shadow p-3 mb-2">
                  <img className="m-auto" src={usaToday} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="bg-white pb-5">
            <div className="container">
                <h2 className="pt-5 text-center text-red generalClass-h">TelMDCare is covered by many popular insurance plans</h2>
                <div className="d-flex flex-wrap justify-content-center align-items-start">
                    <div className="bg-white mr-2">
                        <img className="insurance-logos" src={insuranceLogoBcbs} />
                    </div>
                    <div className="bg-white mr-2">
                        <img className="insurance-logos" src={insuranceLogoCigna} />
                    </div>
                    <div className="bg-white mr-2">
                        <img className="insurance-logos" src={insuranceLogoAetna} />
                    </div>
                    <div className="bg-white mr-2">
                        <img className="insurance-logos" src={insuranceLogoUhc} />
                    </div>
                    <div className="bg-white ">
                        <img className="insurance-logos" src={insuranceLogoMedicare} />
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
        <div className="physicians">
          <div>
            <h3 className="mb-0 generalClass-h">Our Acute and Urgent Care Physicians</h3>
            <p>Patients often return to these doctors for their care</p>
          </div>
          <OwlCarousel className="owl-theme physician-carousel" loop margin={10} dots={false} autoplay={true} autoplayTimeout={6000} autoplayHoverPause={true} 
          responsiveClass responsive={responsivePh}>
          <div className="item">
            <div className="physician-item mx-auto mb-5 mb-lg-0">
            <div className="physician-img-wrapper">
              <img src={headshotDrIbrahim} alt=""/>
            </div>
            <div className="d-flex flex-column px-3 py-2 w-100">
              <h5 className="mb-1 generalClass-h h4-h5-h6">Dr. John Ibrahim</h5>
              <small className="mb-1">Internal Medicine American Board certified</small>  
              <span className="font-weight-light mb-0">Outstanding experience in Telemedicine and Hospital Medicine.</span>  
              <button className="btn-landing btn-outline-primary btn-sm-landing mt-auto" onClick={() => navigate(Routes.patientSignup)}>Book an Appointment</button>
            </div>
            </div>
          </div>
          <div className="item">
            <div className="physician-item mx-auto mb-5 mb-lg-0">
            <div className="physician-img-wrapper">
              <img src={headshotDrTawfeek} alt=""/>
            </div>
            <div className="d-flex flex-column px-3 py-2 w-100">
              <h5 className="mb-1 generalClass-h h4-h5-h6">Dr. Kerolos Tawfeek</h5>
              <small className="mb-1">Family Medicine American Board Certified</small>  
              <span className="font-weight-light mb-0">Telemedicine and Urgent Care experience</span>
              <button className="btn-landing btn-outline-primary btn-sm-landing mt-auto" onClick={() => navigate(Routes.patientSignup)}>Book an Appointment</button>
            </div>
            </div>
          </div>
          </OwlCarousel>
        </div>
        </div>
      </header>

  
      <section className="showcase custom-gradient-bg-landing fade-up" id="how-it-works">
        <div className="container">
          <h2 className="text-white pt-5 pb-2 fade-up generalClass-h">How it works</h2>
          <div className="card shadow">
            <div className="img-holder">
              <img src={schedulingVisit} alt=""/>
            </div>
            <div className="card-body">
              <div className="card-block px-2">
                <h3 className="card-title-landing generalClass-h h3-class">Book an appointment that works with your schedule</h3>
                <p className="card-text-landing">Sign up is easy and painless. Each visit is only $43. No hidden fees. No
                  memberships. No insurance needed. Satisfaction guaranteed or your money back.</p>
                <a href={Routes.patientSignup} className="btn-landing btn-primary-landing btn-lg a-landing">Book an Appointment for Only <del>$79</del> $43</a>
              </div>
            </div>
          </div>
          <div className="card shadow">
            <div className="img-holder">
              <img src={patientDoctorDesktop} alt=""/>
            </div>
            <div className="card-body">
              <div className="card-block px-2">
                <h3 className="card-title-landing generalClass-h h3-class">Speak directly with our certified physicians</h3>
                <p className="card-text-landing">Our network of experienced physicians are ready and waiting to resolve your urgent and
                  acute care needs. Each visit offers complimentary follow up with the physician.</p>
                <a href={CRoutes.virtualAppointments} className="btn-landing btn-primary-landing btn-lg btn-block-landing a-landing">Speak with a Certified Physician Now</a>
              </div>
            </div>
          </div>
          <div className="card shadow">
            <div className="img-holder">
              <img src={pharmacyMap} alt=""/>
            </div>
            <div className="card-body">
              <div className="card-block px-2">
                <h3 className="card-title-landing generalClass-h h3-class">Fill your prescription and pick it up from your local pharmacy</h3>
                <p className="card-text-landing">We work directly with your local pharmacy to fulfill your prescriptions. Pick up is easy
                  and your prescription will be ready and waiting for you.</p>
                <a href={CRoutes.onlinePrescriptions} className="btn-landing btn-primary-landing btn-lg btn-block-landing a-landing">Get your Presription Filled</a>
              </div>
            </div>
          </div>
          <HowItWorksVideo />
        </div>
      </section>
  
      <section className="services fade-up" id="what-we-treat">
        <div className="container">
          <h2 className="pt-5 pb-2 generalClass-h fade-up">What we treat</h2>

          <div className="row">
              <div className="col-lg-4 col-md-12">
                  <div className="d-flex flex-column flex-lg-column flex-md-row">
                      <div className="bg-dark shadow mb-3 rounded">
                          <img className="img-fluid rounded" src={telehealth}/>
                      </div>

                      <div className="d-flex flex-column pl-lg-0 pl-md-4 ">
                          <p>We treat a variety of acute and chronic conditions and provide expert medical advice and guidance for our patients.</p>
                          {/** 
                            <div className="alert alert-info alert-icon">
                              <i className="fa fa-info"></i> 
                              <h5 className="text-white">Need a doctors note? No worries!</h5>
                              <span>A doctor/medical work/school excuse note can be provided for all physician visits, upon request</span> 
                            </div>
                            <div className="alert alert-info lg-and-down" role="alert">
                              <h4>Now offering <strong>Oral, Anti-viral Treatments for Covid-19</strong> <small className="d-block">(Molnupiravir and Paxlovid)</small> </h4>
                              <a href="https://telmdcare.com/c/molnupiravir-and-paxlovid.html" className="text-white">Want to learn more? Find out here >></a>
                            </div>
                        */}
                      </div>
                  </div>
              </div>
            
            <div className="col-lg-8">
              
              <h3 className="generalClass-h h3-class">Acute Care</h3>
              <ul className="list-grid-landing">
                <li><a href={CRoutes.allergies}>Allergies</a></li>
                <li><a href={CRoutes.bronchitis}>Bronchitis</a></li> 
                <li><a href={CRoutes.coldSores}>Cold Sores</a></li>
                <li><a href={CRoutes.covidQuestions}>Covid Questions</a></li>
                <li><a href={CRoutes.dentalInfection}>Dental Infection</a></li>
                <li><a href={CRoutes.earInfections}>Ear Infection</a></li>
                <li><a href={CRoutes.goutAttack}>Gout Attack</a></li>
                <li><a href={CRoutes.motionSickness}>Motion Sickness</a></li>
                <li><a href={CRoutes.nauseaVomiting}>Nausea /Vomiting</a></li>
                <li><a href={CRoutes.oralThrush}>Oral Thrush</a></li>
                <li><a href={CRoutes.painRelief}>Pain Relief</a></li>
                <li><a href={CRoutes.pinkEye}>Pink Eye</a></li>
                <li><a href={CRoutes.bronchitis}>Respiratory Infection</a></li>
                <li><a href={CRoutes.sinusInfection}>Sinus Infection</a></li>
                <li><a href={CRoutes.strepThroat}>Strep Throat</a></li>
                <li><a href={CRoutes.urinaryTractInfection}>Urinary Tract Infection</a></li>
              </ul>
              <h3 className="generalClass-h h3-class">Chronic Care</h3>
              <ul className="list-grid-landing">
                <li><a href={CRoutes.acidReflux}>Acid Reflux</a></li>
                <li><a href={CRoutes.asthma}>Asthma</a></li>
                <li><a href={CRoutes.antiDepression}>Anti Depression / Anxiety (refill medication)</a></li>
                <li><a href={CRoutes.diabetes}>Diabetes Type 2 Medication</a></li>
                <li><a href={CRoutes.highCholestrol}>High Cholesterol</a></li>
                <li><a href={CRoutes.hypertensionTreatments}>Hypertension Treatments (refill medication)</a></li>
                <li><a href={CRoutes.hypothyroidism}>Hypothyroidism</a></li>
                <li><a href={CRoutes.medicationRefill}>Medication Refill</a></li>
                <li><a href={CRoutes.migraine}>Migraine</a></li>
                <li><a href={CRoutes.smokeCessation}>Smoke Cessation</a></li>
              </ul>
              <h3 className="generalClass-h h3-class"><a href={CRoutes.sexuallyTransmittedDiseases}>Sexually Transmitted Diseases (STI)</a></h3>
              <ul className="list-grid-landing">
                <li><a href={CRoutes.chlamydiaGonorrhea}>Chlamydia/Gonorrhea</a></li>
                <li><a href={CRoutes.genitalHerpes}>Genital Herpes</a></li>
                <li><a href={CRoutes.trichomonas}>Trichomonas</a></li>
              </ul>
              <h3 className="generalClass-h h3-class">Women's Health</h3>
              <ul className="list-grid-landing">
                <li><a href={CRoutes.acuteVaginitis}>Bacterial Vaginosis</a></li>
                <li><a href={CRoutes.birthControl}>Birth Control (Refills)</a></li>
                <li>Menstrual Suppression</li>
                <li><a href={CRoutes.acuteVaginitis}>Vaginal yeast infection</a></li>
                <li><a href={CRoutes.urinaryTractInfection}>Urinary Tract Infection</a></li>
              </ul>
              <h3 className="generalClass-h h3-class">Men's Health</h3>
              <ul className="list-grid-landing">
                <li><a href={CRoutes.balanitis}>Balanitis</a></li>
                <li><a href={CRoutes.erectileDysfunctions}>Erectile Dysfunction</a></li>
                <li><a href={CRoutes.jockItch} >Jock Itch</a></li>
                <li><a href={CRoutes.prematureEjaculation}>Premature Ejaculation</a></li>
                <li><a href={CRoutes.urethritis}>Urethritis</a></li>
                <li><a href={CRoutes.urinaryTractInfection}>Urinary Tract Infection</a></li>
              </ul>
              <h3 className="generalClass-h h3-class">Dermatology</h3>
              <ul className="list-grid-landing">
                <li><a href={CRoutes.acne}>Acne</a></li>
                <li><a href={CRoutes.dandruff}>Dandruff</a></li>
                <li><a href={CRoutes.eczema}>Eczema</a></li>
                <li><a href={CRoutes.excessiveSweating}>Excessive Sweating</a></li>
                <li><a href={CRoutes.hairLoss}>Hair Loss</a></li>
                <li><a href={CRoutes.psoriasis}>Psoriasis</a></li>
                <li><a href={CRoutes.scabies}>Scabies</a></li>
                <li><a href={CRoutes.skinInfection}>Skin Infection</a></li>
                <li><a href={CRoutes.stye}>Stye</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-white py-5 doctors-note-cover fade-up">
            <div className="container">
                <h2 className="text-center text-dark mt-4">Need a Doctors Note? No Worries!</h2>
                <h4 className="text-center">A doctor/medical work/school excuse note can be provided for all physician visits, upon request</h4>
            </div>
        </div>
      </section>

      <section className="compare">
        <div className="container fade-up">

            <div className="card shadow">
                <div className="card-body">
                    <h3 className="text-white text-center mb-4 generalClass-h">How do we compare to other healthcare options?</h3>
                    <div className="row text-center">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <h5 className="text-white generalClass-h">Average Copay Comparisons</h5>
                            <img src={compareAverage} className="mb-2"/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <h5 className="text-white generalClass-h">Average Wait Times</h5>
                            <img src={compareWTimes} className="mb-2"/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <h5 className="text-white generalClass-h">Patient Satisfaction</h5>
                            <img src={comparePtSatisfaction} className="mb-2"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
    <section className="testimonials bg-light" id="see-our-reviews">
      <div className="container fade-up">
          <h2 className="pt-5 generalClass-h">Over 100k patients cared for by our physicians</h2>
          <h4 className="text-secondary mb-4 generalClass-h">Schedule your next physician visit without ever leaving your home. No insurance needed.</h4>
          <h4 fade-up="true" className="generalClass-h">Read what our patients are saying</h4>
          <OwlCarousel className="owl-theme testimonial-carousel" loop margin={5} autoplay={true} autoplayTimeout={6000} autoplayHoverPause={true} 
            responsive={responsiveTs} dots={false}>
            <div className="item">
              <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                  <h5>Sheryl S</h5>
                  <div className="d-flex py-2">
                      <div className="review-rating">
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                      </div>
                      <small>2 weeks ago</small>
                  </div>
                  <p className="font-weight-light mb-0">"I received a COVID diagnosis and struggled to find a provider able to assist with managing my symptoms. He was caring , detailed and offered options. Thank you Dr Ibrahim."</p>
                  <a href="https://www.google.com/search?q=telmdcare&amp;oq=telmdcare&amp;aqs=chrome.0.69i59j35i39j69i60l4.6977j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880e4517e5e783b3:0x8f74103cc0dfc51,1,,," className="btn btn-link btn-sm btn-block mt-auto" target="_blank">See Review on Google <span><img src={googleLogo}/></span></a>
              </div>
          </div>
          <div className="item">
              <div className="testimonial-item">
                  <h5>Jennifer</h5>
                  <div className="d-flex py-2">
                      <div className="review-rating">
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                      </div>
                      <small>A month ago</small>
                  </div>
                  <p className="font-weight-light mb-0">"Absolutely worth the time and the small fee! Me and my son were "seen" (call) by a doctor. The doctor was very kind, very attentive and listed to everything I had to say. No problems 😊 we both got our prescriptions filled same
                      day and on the road to feeling better! We will be using this service again."</p>
                  <a href="https://www.google.com/search?q=telmdcare&amp;oq=telmdcare&amp;aqs=chrome.0.69i59j35i39j69i60l4.6977j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880e4517e5e783b3:0x8f74103cc0dfc51,1,,," className="btn btn-link btn-sm btn-block mt-auto" target="_blank">See Review on Google <span><img src={googleLogo}/></span></a>
              </div>
          </div>
          <div className="item">
              <div className="testimonial-item">
                  <h5>Amy M.</h5>
                  <div className="d-flex py-2">
                      <div className="review-rating">
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                      </div>
                      <small>a month ago</small>
                  </div>
                  <p className="font-weight-light mb-0">"I have very much enjoyed going to Dr. Ibrahim online these past several months. I even started my sons seeing him as well! TelMDCare, and especially Dr. Ibrahim has become an integral part of our lives and I am so grateful to
                      have found him!"</p>
                  <a href="https://www.google.com/search?q=telmdcare&amp;oq=telmdcare&amp;aqs=chrome.0.69i59j35i39j69i60l4.6977j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880e4517e5e783b3:0x8f74103cc0dfc51,1,,," className="btn btn-link btn-sm btn-block mt-auto" target="_blank">See Review on Google <span><img src={googleLogo}/></span></a>
              </div>
          </div>
          <div className="item">
              <div className="testimonial-item">
                  <h5>Lance C.</h5>
                  <div className="d-flex py-2">
                      <div className="review-rating">
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                      </div>
                      <small>3 weeks ago</small>
                  </div>
                  <p className="font-weight-light mb-0">"Best online doctor I've ever used I really appreciate everything they have done they go above and beyond."</p>
                  <a href="https://www.google.com/search?q=telmdcare&amp;oq=telmdcare&amp;aqs=chrome.0.69i59j35i39j69i60l4.6977j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880e4517e5e783b3:0x8f74103cc0dfc51,1,,," className="btn btn-link btn-sm btn-block mt-auto" target="_blank">See Review on Google <span><img src={googleLogo}/></span></a>
              </div>
          </div>
          <div className="item">
              <div className="testimonial-item">
                  <h5>Laura H.</h5>
                  <div className="d-flex py-2">
                      <div className="review-rating">
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                      </div>
                      <small>a month ago</small>
                  </div>
                  <p className="font-weight-light mb-0">"They walked me through booking the appointment. The phone visit was easy and immediately had my medicine sent to my pharmacy."</p>
                  <a href="https://www.google.com/search?q=telmdcare&amp;oq=telmdcare&amp;aqs=chrome.0.69i59j35i39j69i60l4.6977j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880e4517e5e783b3:0x8f74103cc0dfc51,1,,," className="btn btn-link btn-sm btn-block mt-auto" target="_blank">See Review on Google <span><img src={googleLogo}/></span></a>
              </div>
          </div>
          <div className="item">
              <div className="testimonial-item">
                  <h5>Steve W.</h5>
                  <div className="d-flex py-2">
                      <div className="review-rating">
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                          <span className="fa fa-star star-selected"></span>
                      </div>
                      <small>2 months ago</small>
                  </div>
                  <p className="font-weight-light mb-0">"I used the service and it is very easy and doctor is very helpful and nice. Recommend it and will use it again."</p>
                  <a href="https://www.facebook.com/TelMDCare/reviews/?ref=page_internal" className="btn btn-link btn-sm btn-block mt-auto" target="_blank">See Review on Facebook <span><img src={facebookLogo}/></span></a>
              </div>
          </div>
          </OwlCarousel>
          <h4 className="pt-5 generalClass-h">Tired of driving halfway across the city to a medical clinic, or sitting in the waiting area at your doctor's office for too long? With TelMDCare, you no longer have to worry about that.</h4>
            <p>Our virtual platform allows patients to speak to a doctor (Internal medicine and family practice doctors) online without the hassle of a long commute or extended waiting time. Instead of worrying about arriving at the clinic
                on time or making adjustments to your daily schedule to account for the lost time, you can simply schedule a telemedicine doctor visit. You can have an online chat after each visit with a physician from the comfort of your home. We
                have licensed and authorized telemedicine doctors onboard who'll listen to your queries and answer them in detail. With our video conferencing feature, you can also directly speak to doctor online, and that too for just $43. <br/><br/>Scheduling walk-in
                appointments and visiting medical faculties can be time-consuming and challenging for most patients. Get online doctor assistance at home with TelMDCare. We provide virtual doctor consultations for acute non-emergency medical problems
                that require immediate attention. Patients have the option of booking online video doctor consultation or through a voice call. At TelMDCare, you don't have to spend days in pain waiting for an appointment with your doctor; just schedule
                an online doctor appointment with our virtual doctors. <br/><br/>Our team at TelMDCare consists of experienced and trained professionals to provide the most accurate solutions to your health problems. Consult a doctor online for the first
                few check-ups and schedule follow-ups with our virtual doctors from the comfort of your home. We aim to provide online doctor appointments to make the process as hassle-free as possible. Your comfort is our number one priority. Find a
                doctor online by visiting our website and book your instant virtual doctor's appointment!</p>
            <p>So, what are you waiting for? Sign up as a registered user on our platform today and get started! You can also consult our online COVID doctor for questions related to the coronavirus.</p>
        </div>
      </section>

      <Footer />

      {/* <div className="chatbot">
        <a className="btn btn-primary-landing rounded-circle">
          <i className="fa fa-comments fa-3x"></i>
        </a>
      </div> */}

      <Modal id="discountModal" tabindex="-1" aria-labelledby="discountModalLabel" aria-hidden="true" isOpen={this.state.discountModal}
        className="modal-dialog-centered" contentClassName="custom-gradient-bg modal-sm mx-auto" toggle={this.toggleDiscountModal}>
        <ModalBody>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleDiscountModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="logo-font">
            <img className="d-block ml-4 mx-auto mb-3" src={telmdcareLogoWht}/>
          </div>
          <h2 className="text-white text-center mb-2 generalClass-h h2-class">Save Big by Scheduling NOW!!! </h2>
          <h5 className="text-white text-center mb-4 generalClass-h h4-h5-h6">Book an appointment today for only <strong>$43</strong> from our regularly priced $79. </h5>
          <button type="button" className="btn-landing btn-primary-landing btn-lg mx-auto mb-n5 d-block" onClick={() => navigate(Routes.patientSignup)}>Book an Appointment Now!</button>
        </ModalBody>
      </Modal>
      </>

      
    );
  }
}
