// import { render } from "@testing-library/react";
import React, { useState, FormEvent } from 'react';
import { TextField } from "../../components/commons/TextField";
import { SmallCardFrame } from "../../components/layout/SmallCardFrame";
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes } from "../../config/Routes";
import { usePhysicianAuthService } from "../../core/service/services";
import { EmptyButton } from '../../components/commons/EmptyButton';
import { CardBody, Form, CardTitle } from 'reactstrap';
import { LoadingButton } from '../../components/commons/LoadingButton';

interface IProps extends RouteComponentProps {
  targetPath?: string
}

export const PhysicianLogin: React.FC<IProps> = (props) => {

  const [errorMessage, setErrorMessage] = useState('');

  let email = (props.location?.state as any)?.email;
  let password = (props.location?.state as any)?.password;

  const emailField = new TextField({
    type: "text",
    labelText: "Email address",
    placeholder: "Email address",
    textChanged: () => { },
    defaultValue: email
  });
  const passwordFiled = new TextField({
    type: "password",
    labelText: "Password",
    placeholder: "Password",
    textChanged: () => { },
    defaultValue: password
  });

  const authService = usePhysicianAuthService();

  const data = {
    email: "",
    password: "",
  };

  React.useEffect(() => {

    if (authService.service.status == "loaded") {
      const authenticationId = authService.service.payload.authenticationId;
      navigate(Routes.physicianAuthentication, { 
        state: {
          email: emailField.getValue(),
          password: passwordFiled.getValue(),
          authenticationId: authenticationId,
          targetPath: props.targetPath,
        } 
      })
    } else if (authService.service.status == "error") {
      setErrorMessage("Password is wrong or does not exist");
    } else if (authService.service.status == "custormError" ){
      setErrorMessage(authService.service.errorResponse.details);
    }

  });

  const callLoginService = (e: FormEvent) => {
    e.preventDefault()
    data.email = emailField.getValue() || "";
    data.password = passwordFiled.getValue() || "";

    authService
      .callService(data)
      .then((resData) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SmallCardFrame navItem bodyClassName="tc-bg">
      <CardBody>
        <CardTitle className="text-center">Physician Login</CardTitle>
        <hr />
        <fieldset disabled={authService.service.status == "loading"}>
        <Form className="form-signin" onSubmit={(e) => callLoginService(e)}>
          {emailField.render()}
          {passwordFiled.render()}
          <div className="text-center mb-4 mt-4" style={{color: "red"}}>
            {errorMessage}
          </div>
          
          <LoadingButton type="submit" isLoading={authService.service.status == "loading"}
            color="primary" block={true} className="w-50 m-auto text-uppercase mt-4">Sign In</LoadingButton>

          <div className="text-center mb-4 mt-4">
            Forgot Password? {" "}
            <a onClick={(e) => { navigate(Routes.requestReset, { state: { email: emailField.getValue(), userType: "physician" } }); e.preventDefault(); return false; }} href="#" className=" mt-2"> Reset Here</a>
          </div>
          <hr />
          <div className="form-text mt-2 text-center mb-2 mt-4">
            Not yet a member?
          </div>

          <EmptyButton label="Sign Up" to={Routes.physicianSignup} />
        </Form>
        </fieldset>
      </CardBody>
    </SmallCardFrame>
  );
};
