import * as React from 'react';
import { CustomInput, Container, Row, Label } from 'reactstrap';

export enum BootstrapTextColor { //location of this enum should be changed later to another file (if used anywhere besides the ratiobutton)
    Primary = 'text-primary',
    Secondary = 'text-secondary',
    Success = 'text-success',
    Danger = 'text-danger',
    Warning = 'text-warning',
    Info = 'text-info',
    Light = 'text-light',
    Dark = 'text-dark'
}

export interface IRadioButtonProps {
    name: string;
    id: string;
    label: string
    checked?: boolean | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: string;
    color?: BootstrapTextColor;
}

const RadioButton = ({
    name,
    id,
    label,
    checked,
    onChange,
    icon,
    color
}: IRadioButtonProps) => {

    const iconClassName = `${icon} text-white`
    const circleClassName = `fa fa-circle fa-stack-2x ${color}`
    const labelClassName = `${color} font-weight-bold`

    const renderIcon = () => {
        if (icon) {
            return (
                <div className='icon'>
                    <div className='fa-stack fa-lg center mr-2' style={{ top: '-8px' }}>
                        <i className={circleClassName}></i>
                        <i className={iconClassName} style={{ fontSize: "0.85em" }}></i>
                    </div>
                </div>
            )
        }
    }

    return (
        <Container>
            <Row className='mb-3'>
                <CustomInput
                    type="radio"
                    name={name}
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    required
                />
                {renderIcon()}
                <Label
                    for={id}
                    className={labelClassName}
                    style={{ fontSize: "1.05em" }}
                >
                    {label}
                </Label>
            </Row>
        </Container>
    )
}

export default RadioButton