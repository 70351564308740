import React, { useState, useEffect } from 'react'
import { IFullAppointment } from '../../book-appointment/AppointmentModels';
import { Col, FormGroup, Label, Input, Row, Button } from 'reactstrap';
import { IPhysicianTemplate, getPhysicianNotesForPhysician, setPhysicianNotes } from '../../../core/service/services';
import { TextField } from '../../../components/commons/TextField';
import { toastError, toastSuccess } from '../../../App';

interface IProps {
  appointment?: IFullAppointment,
  templates: IPhysicianTemplate[]
}

export const PhysicianNotes: React.FC<IProps> = (props) => {
  const MAX_CHAR = 1024 * 2;
  const [initialNote, setInitialNote] = useState<string>("")
  const [note, setNote] = useState<string>("")
  const [editMode, setEditMode] = useState<boolean>(false)
  const [noneSelected, setNoneSelected] = useState<boolean>(false)
  const [charLeft, setCharLeft] = useState<number>(MAX_CHAR);
  
  useEffect(() => {
    props.appointment && getPhysicianNotesForPhysician(props.appointment.appointmentId).then(res => {
      if(res.text){
        setNote(res.text)
        setCharLeft(MAX_CHAR - res.text.length);
        setInitialNote(res.text)
      }
    })
  }, [props.appointment])

  const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setNote("")
      setNoneSelected(true)
    } else if (e.target.value === "new") {
      setNote("")
      setNoneSelected(false)
    } else {
      for (let item of props.templates) {
        if (item.id === e.target.value) {
          setNote(item.text)
          break;
        }
      }
      setNoneSelected(false)
    }
  }

  const save = () => {
    if (!note || note.length === 0) {
      toastError("Please fill the physician notes")
      return
    }
    props.appointment && setPhysicianNotes(props.appointment.appointmentId, note).then((result)=>{
      toastSuccess('Physician Notes saved successfully');
    }).catch((error)=>{ 
      toastError(error);
    })
    setInitialNote(note)
    setEditMode(false)
  }
  const isActiveAppointment = () => {
    return (props.appointment && (props.appointment.status === "Completed" ||
      props.appointment.status === "Started"))
  }

  const renderFormButtons = () => (
    // editMode ?
    <>
    <Button color="link" onClick={save} size="sm" className="pt-0">
      <i className="far fa-save fa-lg"></i>{" "}
      <span>Save</span>
    </Button>
    {/* <Button color="link" size="sm" className="pt-0" onClick={() => {setNote(initialNote); setEditMode(false)}}>
      <i className="far fa-window-close fa-lg"></i>{" "}
      <span >Cancel</span>
    </Button> */}
    </>
    // :
    // <Button color="link" size="sm" className="pt-0" onClick={() => setEditMode(true)}>
    //   <span className="sr-only">Edit</span>{" "}
    //   <i className="fa fa-edit fa-lg"></i>
    // </Button>
  )

  return (
    <Col sm="12" className="mb-3">
      <dl>
        <dt>
          <Label>Physician Notes</Label>
          {isActiveAppointment() && renderFormButtons()}
        </dt>
        <dd>
          <Row>
            {isActiveAppointment() && 
            <Col sm="6">
              <FormGroup>
                <Label>Select a template</Label>
                <Input type="select" name="sTemplates" onChange={e => handleTemplateChange(e)} disabled={!isActiveAppointment()}>
                  <option value="">Please Select</option> 
                  {props.templates.map(item => (
                    <option value={item.id}>
                      {item.label}
                    </option>
                  ))}
                  <option value="new">
                    Custom Note
                  </option>
                </Input>
              </FormGroup>
            </Col>
            }
            {!noneSelected && 
              <Col sm="12">
                <TextField maxLength={MAX_CHAR} labelText="Notes" required type="textarea" defaultValue={note} disabled={!isActiveAppointment()} textChanged={(text) => { 
                  setNote(text); 
                  setCharLeft(MAX_CHAR-text.length);
                   }}/>
                <p style={{fontSize:"80%"}}>Characters Left: {charLeft}</p>
              </Col>
            }
          </Row>
        </dd>
      </dl>
    </Col>
  )
}