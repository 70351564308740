import React, { useState, useEffect } from 'react';
import { getPrescriptionPharmacyInformation, IPharmacyDoseSpotInfo } from '../../../core/service/services';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { IPrescriptionStatusInfo } from './AppointmentPrescriptions';
interface IPharmacyProps {
  currentPrescritpionInfo?: IPrescriptionStatusInfo,
  appointmentId?: any
}
export const PharmacyInfoModal = (props: IPharmacyProps) => {
  const [modal, setModal] = useState(false);
  const [currentPharmacy, setCurrentPharmacy] = useState<IPharmacyDoseSpotInfo>();
  const toggle = () => setModal(!modal);
  useEffect(() => {
    fetchPrescriptionPharmacyInformation();
  },[props.currentPrescritpionInfo])
  async function fetchPrescriptionPharmacyInformation() {
    if (props.currentPrescritpionInfo?.pharmacyDoseSpotId) {
      getPrescriptionPharmacyInformation(props.appointmentId, props.currentPrescritpionInfo?.pharmacyDoseSpotId)
        .then((res: any) => {
          setCurrentPharmacy(res);
        }).catch((error)=>{
          console.log(error)
        })
    }
  }

  return (
    <div>
      <Button color="link" onClick={toggle}>{currentPharmacy?.name}</Button>
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>Pharmacy Details</ModalHeader>
        <ModalBody >
          {currentPharmacy?.name} <br />
          {currentPharmacy?.address} <br />
          {currentPharmacy?.city}, {currentPharmacy?.state} {currentPharmacy?.zipCode} <br />
          {currentPharmacy?.phone} <br />
          Fax: {currentPharmacy?.fax}        
          </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Okay</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
