import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PatientSignIn } from "../../page/auth/PatientSignIn";
import { UserContext, isAllowed } from "../UserContext";
import { PhysicianLogin } from "../../page/auth/PhysicianLogin";
import { AdminLogin } from "../../page/auth/AdminLogin";
import { AgentLogin } from "../../page/auth/agent/AgentLogin";

  
interface IPrivateRouteProps extends RouteComponentProps {
  // children: React.ReactNode
  as: React.ComponentClass | React.FunctionComponent,
  roles: string[]
}

export class PrivateRoute extends React.Component<IPrivateRouteProps> {
  // static contextType = UserContext;
  static contextType = UserContext;
  // constructor(props: IPrivateRouteProps){
  //   super(props);
  // }
  render() {
    let {as : Comp, ...props } = this.props;
    const roles = this.props.roles
    const targetPath = this.props.path? (this.props.path + this.props.location?.search) : undefined
    return (this.context.isLoggedIn() && isAllowed(roles)) ? 
    <Comp {...props} />    
    : (roles.includes("patient") ? <PatientSignIn targetPath={targetPath}/> : 
      (roles.includes("physician") ? <PhysicianLogin targetPath={targetPath}/> : 
      (roles.includes("agent") ? <AgentLogin targetPath={targetPath}/>:
      <AdminLogin targetPath={targetPath}/>)));
  }
}