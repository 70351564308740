import {
  FormGroup,
  Row,
  Label,
  Input,
  Container,
  Col,
  UncontrolledCollapse,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { IUSState } from "../../settings/PhysicianSettings";
import moment from "moment";

interface ISearchBarProps {
  uSStates: IUSState[];
  searchFilters: any;
}

export const AppointmentsSearchBar = (props: ISearchBarProps) => {
  const [search, setSearch] = useState({});
  useEffect(() => searchFilters(), [search]);

  const searchFilters = () => {
    props.searchFilters(search);
  };

  const renderSearchForm = () => {
    return (
      <Row style={{marginLeft: 'auto'}}>
        <Col md="5" lg="5" className="pl-0">
          <Label className="mb-0">Date Range</Label>
          <Container className="d-flex pl-0">
            <Row>
              <Col md="6">
                <small><Label className="mb-0">from</Label></small>
                <Input type="date" placeholder="From" className="form-control form-control-sm"
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      from: moment(e.target.value).format('YYYY-MM-DD') || undefined,
                    });
                  }}
                />
              </Col>
              <Col md="6">
                <small><Label className="mb-0">to</Label></small>
                <Input type="date" placeholder="To" className="form-control form-control-sm"
                  onChange={(e) => {
                    setSearch({ ...search, to: moment(e.target.value).format('YYYY-MM-DD') || undefined });
                  }}
                />
              </Col>
            </Row>
            
          </Container>
        </Col>
        <Col md="2" lg="2" className="pl-0">
          <Label>State</Label>
          <Input type="select" className="form-control form-control-sm"
            onChange={(e) => {
              setSearch({ ...search, stateId: e.target.value });
            }}
          >
            <option value={undefined}>All</option>
            {props.uSStates?.map((state) => (
              <option value={state.id}>{state.name}</option>
            ))}
          </Input>
        </Col>
        <Col md="2" lg="2" className="pl-0">
          <Label>Status</Label>
          <Input type="select" className="form-control form-control-sm"
            onChange={(e) => {
              setSearch({ ...search, status: e.currentTarget.value });
            }}
          >
            <option value={undefined}>All</option>
            <option>Completed</option>
            <option>Archived</option>
            <option>Waiting</option>
            <option>Canceled By Patient</option>
            <option>Canceled By System</option>
            <option>Canceled By Physician</option>
          </Input>
        </Col>
        <Col className="pl-0">
          <Label>Appointment Number</Label>
          <Input id="searchinput" placeholder="Search here..." type="text" className="form-control form-control-sm"
            onChange={(e)=>{
                setSearch({...search, appointmentNumber: e.target.value})
            }}
          >
          </Input>
        </Col>
      </Row>
    );
  };
  const renderSearchField = (isDoctor: boolean) => {
    return (
      <Col>
        <Label>
          {isDoctor ? "Search doctors" : "Search patients"}
        </Label>
        <Input id="searchinput" placeholder="Search here..." type="text" className="form-control form-control-sm"
          onChange={(e) => {
            if (isDoctor)
              setSearch({ ...search, physicianName: e.target.value });
            else setSearch({ ...search, patientName: e.target.value });
          }}
        />
      </Col>
    );
  };
  return (
    <FormGroup className="px-3 mb-0">
      <Row>
        {renderSearchField(true)}
        {renderSearchField(false)}
      </Row>

      <UncontrolledCollapse toggler="#toggler" className="mt-1">
        {renderSearchForm()}
      </UncontrolledCollapse>
      <div style={{ textAlign: "center" }}>
        <Button color="link" size="sm" id="toggler">
          <i className="fas fa-chevron-circle-down"></i> Advanced Search
        </Button>
      </div>
    </FormGroup>
  );
};
