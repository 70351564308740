import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import {
   ICreateAppointmentPayload, IWhy, IContact, IWho, IWhen, ICreditCard,
   IBillingAddress, IInsuranceTemEntity, ISubscriberObject
} from '../../types/interfaces/bookingInterfaces'
import { IFullAppointment } from '../../../page/book-appointment/AppointmentModels' //TO-DO: Move this to Redux
import { ContactType } from '../../types/enums/bookingEnums'

export interface IBookingState {
   currentStep: number,
   createApptPayload: ICreateAppointmentPayload;
   createdAppointment?: IFullAppointment;
   paymentAmount?: number;
   transactionFee?: number;
}

const initialState: IBookingState = {
   currentStep: 1,
   createApptPayload: {
      contact: {
         type: ContactType.Phone,
         phone: ''
      },
      when: {
         time: null
      }
   }
}

const bookingSlice = createSlice({
   name: 'booking',
   initialState,
   reducers: {
      nextStepInitiated: (state) => {
         state.currentStep += 1;
      },
      previousStepInitiated: (state) => {
         state.currentStep -= 1;
      },
      appointmentCreated: (state, action: PayloadAction<IFullAppointment>) => {
         state.createdAppointment = { ...action.payload }
      },
      whySectionUpdated: (state, action: PayloadAction<IWhy>) => {
         state.createApptPayload.why = { ...action.payload }
      },
      contactSectionUpdated: (state, action: PayloadAction<IContact>) => {
         state.createApptPayload.contact = { ...action.payload }
      },
      whoSectionUpdated: (state, action: PayloadAction<IWho>) => {
         state.createApptPayload.who = { ...action.payload }
      },
      whenSectionUpdated: (state, action: PayloadAction<IWhen>) => {
         state.createApptPayload.when = { ...action.payload }
      },
      creditCardUpdated: (state, action: PayloadAction<string | ICreditCard>) => {
         state.createApptPayload.creditCard = (typeof action.payload === 'string') ? action.payload : { ...action.payload }
      },
      billingAddressUpdated: (state, action: PayloadAction<IBillingAddress | 'use on file'>) => {
         state.createApptPayload.billingAddress = (typeof action.payload === 'string') ? action.payload : { ...action.payload }
      },
      insuranceAttachmentsUpdated: (state, action: PayloadAction<Array<string>>) => {
         state.createApptPayload.insuranceAttachments = action.payload.map(item => item)
      },
      insuranceIdUpdated: (state, action: PayloadAction<string>) => {
         state.createApptPayload.insuranceId = action.payload
      },
      copaymentTokenUpdated: (state, action: PayloadAction<string>) => {
         state.createApptPayload.copaymentToken = action.payload
      },
      copaymentAmountUpdated: (state, action: PayloadAction<number>) => {
         state.createApptPayload.copaymentAmount = action.payload
      },
      isCoveredByInsuranceBooleanUpdated: (state, action: PayloadAction<boolean>) => {
         state.createApptPayload.isCoveredByInsurance = action.payload
      },
      insuranceTemEntityUpdated: (state, action: PayloadAction<IInsuranceTemEntity>) => {
         state.createApptPayload.insuranceTemEntity = { ...action.payload }
      },
      isNotMainSubscriberUpdated: (state, action: PayloadAction<boolean>) => {
         state.createApptPayload.isNotMainSubscriber = action.payload
      },
      primarySubscriberUpdated: (state, action: PayloadAction<ISubscriberObject>) => {
         state.createApptPayload.primarySubscriber = { ...action.payload }
      },
      paymentAmountFetched: (state, action: PayloadAction<number>) => {
         state.paymentAmount = action.payload
      },
      transactionFeeFetched: (state, action: PayloadAction<number>) => {
         state.transactionFee = action.payload
      },
      bookingCleared: () => {
         return initialState
      }
   }
})

export const {
   nextStepInitiated,
   previousStepInitiated,
   appointmentCreated,
   whySectionUpdated,
   contactSectionUpdated,
   whoSectionUpdated,
   whenSectionUpdated,
   creditCardUpdated,
   billingAddressUpdated,
   insuranceAttachmentsUpdated,
   insuranceIdUpdated,
   copaymentTokenUpdated,
   copaymentAmountUpdated,
   isCoveredByInsuranceBooleanUpdated,
   insuranceTemEntityUpdated,
   isNotMainSubscriberUpdated,
   primarySubscriberUpdated,
   paymentAmountFetched,
   transactionFeeFetched,
   bookingCleared
} = bookingSlice.actions

export const selectCreateApptPayload = (state: RootState) => state.booking.createApptPayload
export const selectCreatedAppointment = (state: RootState) => state.booking.createdAppointment
export const selectPayloadWhoSection = (state: RootState) => state.booking.createApptPayload.who
export const selectPayloadWhySection = (state: RootState) => state.booking.createApptPayload.why
export const selectCurrentStep = (state: RootState) => state.booking.currentStep
export const selectPaymentAmount = (state: RootState) => state.booking.paymentAmount
export const selectTransactionFee = (state: RootState) => state.booking.transactionFee

export default bookingSlice.reducer
