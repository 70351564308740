import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { PhysicianItemCard } from './PhysicianItemCard';
import { getAllPhysicians, getUSStatesLookup } from '../../../core/service/services';
import { PhysiciansSearchBar } from './PhysiciansSearchBar';
import { IUSState } from '../../settings/PhysicianSettings';
import { CustomedPagination } from './Pagination';
import { AdminExpandingButton } from './AdminExpandingButton';

interface IProps {}

export interface IPhysician {
  id: number;
  firstname: string;
  lastname: string;
  title?: string;
  status: "Active" | "Disabled" | "New";
  photoToken?: string;
  dea?: string;
  npi?: string;
  specialty?: string;
  joinedAt: Date
}

export const PhysiciansOverview: React.FC<IProps> = (props) => {

  const [physicians, setPhysicians] = useState<IPhysician[]>([])
  const [uSStates, setUSStates] = useState<IUSState[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [searchOptions, setSearchOptions] = useState<any>()

  const [physicianExpansion, setPhysicianExpansion] = useState<boolean>(false);
  const togglePhysicianExpansion = ()=> setPhysicianExpansion(!physicianExpansion);

  useEffect(() => {
    fetchAllPhysicians(pageIndex, pageSize, undefined)
  }, [])

  const fetchAllPhysicians = (pageIndex: number, pageSize: number, searchOptions: any) => {
    const obj = _.valuesIn(searchOptions).toString().replace(",", "") != "" ? searchOptions : undefined
    getAllPhysicians(pageIndex, pageSize, obj).then(res => {
      setPageIndex(res.pageIndex)
      setPageSize(res.pageSize)
      res.pageSize != 0 && setPageCount(Math.ceil(res.length/res.pageSize))
      setPhysicians(res.items)
    }).catch(err => console.log(err))
  }


  useEffect(() => {
    getUSStatesLookup().then((states) => {
      setUSStates(states);
    });
  }, []);

  const applySearch = (searchOpts: any) => {
    setSearchOptions(searchOpts)
    fetchAllPhysicians(0, 10, searchOpts)
  }

  const updateItem = (newItem: IPhysician) => {
    let itemsList = physicians
    const index = getPhIdx(newItem.id);
    if (index === -1) { //new ph
      itemsList.push(newItem)
    } else {
      itemsList[index] = newItem
    }
    //bad implementation .. to be enhanced
    setPhysicians([])
    setPhysicians(itemsList)
  }

  const getPhIdx = (id: number) => {
    for (let i = 0; i < physicians.length; i++) {
      const currentItem = physicians[i]
      if (currentItem.id === id) {
        return i
      }
    }
    return -1
  }

  const phItemStyle = (status: string) => {
    let style = {borderTopWidth: "thin", borderColor: "#007bff", padding: "0.5rem"}
    switch (status) {
      case "Active":
        style.borderColor = "#007bff"
        break;
      case "Disabled":
        style.borderColor = "#dc3545"
        break;
      case "New":
        style.borderColor = "#28a745"
        break;
    }
    return style
  }

  const newPhs: IPhysician[] = []
  const oldPhs: IPhysician[] = []
  for (let ph of physicians) {
    if (ph.status === "New") {
      newPhs.push(ph)
    } else {
      oldPhs.push(ph)
    }
  }

  return (
    <>
      <h5 className="text-white mx-3"><i className="fa fa-user-md"></i> Physicians Overview</h5>
      <Card className="card-telecare mt-3 mb-5">
        <CardBody>
          <CardTitle className="mb-3">
            Manage physicians registration approval and data entry
            <AdminExpandingButton toggle={togglePhysicianExpansion} expanded={physicianExpansion}/>
          </CardTitle>
          {physicianExpansion && 
          <>
          <PhysiciansSearchBar
            userType="physician"
            uSStates={uSStates}
            searchFilters={applySearch}
          />
          <section className="section-content-block">
            <div id="apptBody" className="section-content-body">
              {newPhs.length > 0 &&
                <>
                <h6 className="text-muted ml-3">
                  <span>Pending Approval</span>
                </h6>
                <ListGroup className="timeline upcoming pre-appt-scheduled">
                  {newPhs.map(item => (
                    <ListGroupItem style={phItemStyle(item.status)} >
                      <PhysicianItemCard data={item} updateItem={updateItem}/>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <hr />
                </>
              }
              {oldPhs.length > 0 &&
                <>
                <h6 className="text-muted ml-3">
                  <span>Currently Existing</span>
                </h6>
                <ListGroup className="timeline upcoming pre-appt-scheduled">
                  {oldPhs.map(item => (
                    <ListGroupItem style={phItemStyle(item.status)} >
                      <PhysicianItemCard data={item} updateItem={updateItem}/>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                </>
              }
            </div>
          </section>
          </>}
        </CardBody>
        {physicianExpansion && pageCount &&
        <CustomedPagination
          pageCount={pageCount}
          pageNum={pageIndex}
          onPaginate={(idx :any)=>{setPageIndex(idx); fetchAllPhysicians(idx, 10, searchOptions)}}
        />}
      </Card>
    </>

  );
}
