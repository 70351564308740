import moment from "moment-timezone";

export function localizeTimezone(dateStr: string, format?: string) {
  let date = new Date(dateStr);
  let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let localDate = date?.toLocaleString("en-US", {
    timeZone: localTimeZone,
  });
  let parsedDate = moment(localDate)
    .format(format)
  return parsedDate;
}

export function convertToTimezoneWithUsState(dateStr: string | Date, usState: string, format?: string) {
  let date = new Date(dateStr);
  let zone = mapUsStatesToSupportedTZValues(usState);
  let convertedDate = moment.tz(dateStr, zone).format(format);
  return convertedDate;
}

export function removeTimeZoneOffsetFromDate(date?: Date){
  return date?.toString().slice(0, 24);
}
export function changeTimezone (date:Date, region:string) {

  var regionDate = region? new Date(date.toLocaleString('en-US', {
    timeZone: region
  })): date;
  return regionDate;
}

export function dateWithTimeZone (localTimeIsoString: string, usState:string) {
  let dateStr = convertToTimezoneWithUsState(new Date(),usState);
  let timeZoneStr = dateStr.substring(19, dateStr.length+1);
  let datWithTimeZone = localTimeIsoString.substring(0,19)+timeZoneStr;
  return moment(datWithTimeZone).toDate();
};

export function mapUsStatesToSupportedTZValues(usState: string): string {
  if (isAtlanticZone(usState))
    return "America/Puerto_Rico";
  if (isEasternZone(usState))
    return "US/Eastern";
  if (isCentralZone(usState))
    return "US/Central";
  if (isMountainZone(usState))
    return 'US/Mountain';
  if (isPacificZone(usState))
    return "US/Pacific";
  if (isAlaskaZone(usState))
    return "US/Alaska";
  if (isHawaiiZone(usState))
    return "US/Hawaii"
  return '';
}

function isAtlanticZone(usState: string) {
  return usState === 'Puerto Rico' || usState === 'Virgin Islands'
}

function isEasternZone(usState: string) {
  return usState === 'Connecticut'
    || usState === 'Delaware'
    || usState === 'Georgia'
    || usState === 'Maine'
    || usState === 'Maryland'
    || usState === 'Massachusetts'
    || usState === 'New Hampshire'
    || usState === 'New Jersey'
    || usState === 'New York'
    || usState === 'North Carolina'
    || usState === 'Ohio'
    || usState === 'Pennsylvania'
    || usState === 'Rhode Island'
    || usState === 'South Carolina'
    || usState === 'Vermont'
    || usState === 'Virginia'
    || usState === 'West Virginia'
    || usState === 'Florida'
    || usState === 'Indiana'
    || usState === 'Kentucky'
    || usState === 'Michigan'
    || usState === 'Tennessee'
}

function isCentralZone(usState: string) {
  return usState === 'Alabama'
    || usState === "Arkansas"
    || usState === 'Illinois'
    || usState === 'Iowa'
    || usState === 'Louisiana'
    || usState === 'Minnesota'
    || usState === 'Mississippi'
    || usState === 'Missouri'
    || usState === 'Oklahoma'
    || usState === 'Wisconsin'
    || usState === 'Florida'
    || usState === 'Indiana'
    || usState === 'Kansas'
    || usState === 'Kentucky'
    || usState === 'Michigan'
    || usState === 'Nebraska'
    || usState === 'North Dakota'
    || usState === 'South Dakota'
    || usState === 'Tennessee'
    || usState === 'Texas'
}
function isMountainZone(usState: string) {
  return usState === 'Arizona'
    || usState === 'Colorado'
    || usState === 'Montana'
    || usState === 'New Mexico'
    || usState === 'Utah'
    || usState === 'Wyoming'
    || usState === 'Idaho'
    || usState === 'Kansas'
    || usState === 'Nebraska'
    || usState === 'Nevada'
    || usState === 'North Dakota'
    || usState === 'Oregon'
    || usState === 'South Dakota'
    || usState === 'Texas'
}

function isPacificZone(usState: string) {
  return usState === "California"
    || usState === "Washington"
    || usState === "Idaho"
    || usState === "Nevada"
    || usState === "Oregon"
}

function isAlaskaZone(usState: string) {
  return usState === "Alaska";
}

function isHawaiiZone(usState: string) {
  return usState === "Hawaii"
}