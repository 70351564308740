import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { Routes } from '../../../config/Routes'
import { UserConfig } from '../../../config/userConfig'
import { getPatientInfo } from '../../../core/service/services'
import { IPatientForAgents } from '../agent/PatientsOverview'

interface IProps {}

export const PatientMissingInfoCard: React.FC<IProps> = (props) => {

  const [display, setDisplay] = useState<boolean>(true)
  const agentAsPatient:IPatientForAgents = UserConfig.getPatientLoggedByAgent()
  useEffect(() => {
    
      getPatientInfo().then(res => {
        if (res.pharmacy && res.pharmacy.name) {
          setDisplay(false)
        }
      })
  }, [])

  if (display) {
    return (
      <div
        className="alert alert-warning my-5"
        role="alert"
        id="patientAccountSettingsAlert"
      >
        <span className="features-icons-icon d-inline-block h3 mr-2">
          <i className="fa fa-tasks m-auto"></i>
        </span>
        <span className="h5">Patient Profile Checklist</span>
        <button
          type="button"
          className="close"
          data-toggle="collapse"
          data-target="#accountHomeAlertMsgs"
          aria-expanded="true"
          aria-controls="accountHomeAlertMsgs"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="collapse show" id="accountHomeAlertMsgs">
          <p>
            Your account is missing some information. See below to add
            or update account details.
          </p>
          <ul>
            <li>Select your preferred Pharmacy</li>
            <li>Select your State/Location</li>
            <li>Add all members of your household</li>
          </ul>
          <Link
            to={Routes.patientSettings}
            className="alert-link d-block p-3"
          >
            Update Personal Settings
          </Link>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}