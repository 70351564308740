import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { ConsultationType } from '../../types/enums/bookingEnums'
import { bookingCleared } from './bookingSlice';

export interface IConsultationStepState {
   stateId: number | undefined;
   consultationType?: ConsultationType;
   reasonForVisit?: string;
   symptoms?: string;
   otherReason?: string
   attachments?: Array<string>;
   displayedImages?: Array<string>;
}

const initialState: IConsultationStepState = {
   stateId: undefined
}

const consultationStepSlice = createSlice({
   name: 'consultationStep',
   initialState,
   reducers: {
      stateSelected: (state, action: PayloadAction<number | undefined>) => {
         state.stateId = action.payload;
      },
      consultationTypeSelected: (state, action: PayloadAction<ConsultationType>) => {
         state.consultationType = action.payload;
      },
      reasonForVisitSelected: (state, action: PayloadAction<string | undefined>) => {
         state.reasonForVisit = action.payload;
      },
      symptomsUpdated: (state, action: PayloadAction<string>) => {
         state.symptoms = action.payload;
      },
      otherReasonUpdated: (state, action: PayloadAction<string | undefined>) => {
         state.otherReason = action.payload;
      },
      attachmentAdded: (state, action: PayloadAction<string>) => {
         if (state.attachments !== undefined) {
            state.attachments = state.attachments.concat(action.payload)
         } else {
            state.attachments = [action.payload]
         }
      },
      displayedImageAdded: (state, action: PayloadAction<string>) => {
         if (state.displayedImages !== undefined) {
            state.displayedImages = state.displayedImages.concat(action.payload)
         } else {
            state.displayedImages = [action.payload]
         }
      },
   },
   extraReducers: (builder: ActionReducerMapBuilder<IConsultationStepState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   stateSelected,
   consultationTypeSelected,
   reasonForVisitSelected,
   symptomsUpdated,
   otherReasonUpdated,
   attachmentAdded,
   displayedImageAdded
} = consultationStepSlice.actions

export const selectConsultationDetails = (state: RootState) => state.consultationStep
export const selectStateId = (state: RootState) => state.consultationStep.stateId
export const selectConsultationType = (state: RootState) => state.consultationStep.consultationType
export const selectReasonForVisit = (state: RootState) => state.consultationStep.reasonForVisit
export const selectSymptoms = (state: RootState) => state.consultationStep.symptoms
export const selectOtherReason = (state: RootState) => state.consultationStep.otherReason
export const selectAttachments = (state: RootState) => state.consultationStep.attachments
export const selectDisplayedImages = (state: RootState) => state.consultationStep.displayedImages

export default consultationStepSlice.reducer
