import * as React from 'react'
import { IItem } from './PreviousConditionsModal'
import shortid from 'shortid'
import MedicalAllergiesDoseSpotForm, { IFullAllergy, IAddAllergy } from '../../components/medicalHistory/MedicationAllergiesDoseSpot'
import { FormGroup, ListGroup, CustomInput } from 'reactstrap'
import MedicationsAllergiesList from '../../components/medicalHistory/MedicationsAllergiesList'

interface IMedicationsModalProps {
    saveData: (items: IItem[]) => any; // as done saving
    saveMedications: (medications: IAddAllergy[]) => any;
    cancelModal: () => void;
    items: IItem[];
    medicationsAllergies: any
    onMedicationsRemoved?: any
    onMedicationsEdited?: any
    medicationAllergy?:any;
    onChangeMedicationAllergyStatus:any;
}

export const MedicationsModal: React.FC<IMedicationsModalProps> = (props) => {

    const [inputDisabled, setInputDisabled] = React.useState<boolean>(true)
    const [newMedicationsAllergyList, setnewMedicationsAllergyList] = React.useState<IAddAllergy[]>();
    // creating a local copy of the state
    const [itemsState, setItemsState] = React.useState(props.items.map((v) => { return { ...v } }));
    const [editedMedications, setEditedMedications] = React.useState<IFullAllergy[]>();
    const [currentMedicationAllergyStatus, setCurrentMedicationAllergyStatus] = React.useState<boolean>(props.medicationAllergy);
    const deleteItem = (index: number) => {
        let state = itemsState.map (i => {return {...i}});
        state[index].checked = false;
        setItemsState(state);
    }
    const modifyItem = (index: number, name: string) => {
        let state = itemsState.map (i => {return {...i}})
        state[index].display = name;
        setItemsState(state);
    }
    const addItem = (name: string) => {
        let state = itemsState.map (i => {return {...i}})
        state.push({ display: name, id: shortid.generate(), checked: true, item: null });
        setItemsState(state);
    }

    const inputRef = React.useRef<HTMLInputElement>(null)
    const addButtonPressed = () => {
        const value = inputRef.current?.value
        if (value && value != "") {
            addItem(value);
            inputRef.current!.value = ""
        }
    }

    const savePressed = (allergy:IAddAllergy) => {
        newMedicationsAllergyList? setnewMedicationsAllergyList([allergy,...newMedicationsAllergyList]): setnewMedicationsAllergyList([allergy]);

        props.saveData(itemsState);
        props.saveMedications([allergy] || []);
        props.onChangeMedicationAllergyStatus(currentMedicationAllergyStatus);
    }
    const cancelPressed = () => {
        props.cancelModal()
    }
    const onMedicationsRemoved = (e: any) => {
      setnewMedicationsAllergyList(
        newMedicationsAllergyList?.filter((item) => {
          return item.Code !== e.Code;
        })
      );
      props.onMedicationsRemoved(e);
    };
    const onMedicationsEdited = (e: any) => {
      };
    const renderListGroupItems = () => {
      let medications = newMedicationsAllergyList
        ? [...newMedicationsAllergyList,...props.medicationsAllergies]
        : props.medicationsAllergies;
      return (
        <MedicationsAllergiesList
          onRemove={(e: any) => {
            onMedicationsRemoved(e);
          }}
          onEdit={(e: any) => {
            onMedicationsEdited(e);
          }}
          maxHeight={150}
          medicationsAllergy={newMedicationsAllergyList}
        />
      );
    };
    const onNoChoice=()=>{
        setCurrentMedicationAllergyStatus(false);
        props.onChangeMedicationAllergyStatus(false);
    }

    const closeModal = () => {
        $('#medsAllergicModal').modal('hide');
        $('.modal-backdrop').remove();
      };

    return <>

        <div id="medsAllergicModal" data-tabindex="-1" role="dialog" aria-labelledby="medsAllergicModalLabel"
            className="modal fade show" aria-modal="true" style={{ paddingRight: "15px", display: "block" }}>
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 id="medsAllergicModalLabel" className="modal-title">Medication Allergies</h5> <button type="button"
                            data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true"
                            onClick={cancelPressed}
                            >×</span></button>
                    </div>
                    <div className="modal-body">
                        <p>Are you allergic to any medication?</p>
                        <CustomInput
                        onChange={(e) => {
                            e.target.checked &&
                            setCurrentMedicationAllergyStatus(true);
                        }}
                        type="radio"
                        id="yes"
                        name="intro"
                        label="Yes"
                        defaultChecked={currentMedicationAllergyStatus}
                        />
                        <CustomInput
                        onChange={(e) => {e.target.checked &&
                            onNoChoice();
                            closeModal();
                        }}
                        type="radio"
                        id="no"
                        name="intro"
                        label="No"
                        defaultChecked={currentMedicationAllergyStatus === undefined? false: !currentMedicationAllergyStatus}
                        />
                  
                    {currentMedicationAllergyStatus?
                    <>
                    <MedicalAllergiesDoseSpotForm setNewMedication={savePressed} />
                                       
                    <FormGroup>
                        <ListGroup 
                        style={{ maxHeight: 100, overflow: "auto" }}
                        flush >
                        {renderListGroupItems()}
                        </ListGroup>
                    </FormGroup>
                    </>
                    :null} 
                    </div>
                </div>
            </div>
        </div>
    </>
}