import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  IFullAllergy,
  AllergyReactionType,
  AllergyStatusType,
} from "./MedicationAllergiesDoseSpot";
interface IProps {
  modal: boolean;
  data?: IFullAllergy;
  toggle: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onSave: (arg0: IFullAllergy | undefined) => void;
}
const EditAllergyModal = (props: IProps) => {
  const[editedMedication, setEditedMedication] = useState(props.data);
  useEffect(()=>{
      setEditedMedication(props?.data);
  }, [props]);
  return (
    <div>
      <Modal isOpen={props.modal}>
        <ModalHeader>Edit Medication Allergy</ModalHeader>
        <ModalBody>
          <Form>
            <Label>Medication Name: {editedMedication?.Name}</Label>
            <FormGroup>
              <Label>Reaction</Label>
              <Input
                type="text"
                defaultValue={editedMedication?.Reaction}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (editedMedication)
                    setEditedMedication({
                      ...editedMedication,
                      Reaction: e.target.value,
                    });
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Label>Reaction Type</Label>
              <Input
                type="select"
                required
                value={AllergyReactionType.find(item => item.name === editedMedication?.ReactionType)?.code.toString() || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const selectedReactionType = AllergyReactionType.find(item => item.code.toString() === e.target.value)?.name;
                  if (editedMedication && selectedReactionType) {
                    setEditedMedication({
                      ...editedMedication,
                      ReactionType: selectedReactionType,
                    });
                  }
                }}
              >
                {AllergyReactionType.map((item, key) => (
                  <option key={key} value={item.code}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Input
                type="select"
                required
                value={AllergyStatusType.find(item => item.name === editedMedication?.StatusType)?.code.toString() || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const selectedStatusType = AllergyStatusType.find(item => item.code.toString() === e.target.value)?.name;
                  if (editedMedication && selectedStatusType) {
                    setEditedMedication({
                      ...editedMedication,
                      StatusType: selectedStatusType,
                    });
                  }
                }}
              >
                {AllergyStatusType.map((item, key) => (
                  <option key={key} value={item.code}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>OnsetDate</Label>
              <Input
                type="date"
                // onChange={(e) => {
                //   if (editedMedication)
                //     setEditedMedication({
                //       ...editedMedication,
                //       OnsetDate: e.target.valueAsDate ?? undefined,
                //     });
                // }}
              ></Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props.onSave(editedMedication);
            }}
          >
            Save
          </Button>{" "}
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default EditAllergyModal;
