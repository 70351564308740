import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from 'reactstrap';
import { CustomedPagination } from './Pagination';
import { PhysiciansSearchBar } from './PhysiciansSearchBar';
import { getAllAgents } from '../../../core/service/services';
import _ from 'lodash';
import { AgentItemCard } from './AgentItemCard';
import { AdminExpandingButton } from './AdminExpandingButton';
interface IProps { }

export interface IAgent {
  id: number;
  firstname: string;
  lastname: string;
  status: "Active" | "Disabled" | "New";
  joinedAt: Date
}
export const AgentsOverview: React.FC<IProps> = (props) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [searchOptions, setSearchOptions] = useState<any>()
  const [agents, setAgents] = useState<IAgent[]>([]);
  const [newAgents, setNewAgents] = useState<IAgent[]>([]);
  const [oldAgents, setOldAgents] = useState<IAgent[]>([]);
  const [agentExpansion, setAgentExpansion] = useState<boolean>(false);
  const toggleAgentExpansion = () => setAgentExpansion(!agentExpansion);

  useEffect(() => {
    fetchAllAgents(pageIndex, pageSize, undefined)
  }, [])

  const fetchAllAgents = (pageIndex: number, pageSize: number, searchOptions: any) => {
    const obj = _.valuesIn(searchOptions).toString().replace(",", "") != "" ? searchOptions : undefined
    getAllAgents(pageIndex, pageSize, obj).then(res => {
      setPageIndex(res.pageIndex)
      setPageSize(res.pageSize)
      res.pageSize != 0 && setPageCount(Math.ceil(res.length / res.pageSize))
      setAgentsData(res.items);
    }).catch(err => console.log(err))
  }

  const setAgentsData = (res: IAgent[]) => {
    setAgents(res);
    let newAgentsArray: IAgent[] = [];
    let oldAgentsArray: IAgent[] = [];
    res.map((agent) => {
      if (agent.status === "New") {
        newAgentsArray = [...newAgentsArray, agent];
      } else {
        oldAgentsArray = [...oldAgentsArray, agent];
      }
    })
    setNewAgents(newAgentsArray);
    setOldAgents(oldAgentsArray);
  }
  const agentItemStyle = (status: string) => {
    let style = { borderTopWidth: "thin", borderColor: "#007bff", padding: "0.5rem" }
    switch (status) {
      case "Active":
        style.borderColor = "#007bff"
        break;
      case "Disabled":
        style.borderColor = "#dc3545"
        break;
      case "New":
        style.borderColor = "#28a745"
        break;
    }
    return style
  }
  const applySearch = (searchOpts: any) => {
    setSearchOptions(searchOpts)
    fetchAllAgents(0, 10, searchOpts)
  }

  const updateItem = (newItem: IAgent) => {
    // let updatedAgent = agents.map((agent)=>{
    //   if(newItem.id === agent.id)
    //     return newItem;
    //   return agent;
    // })
    // setAgents(updatedAgent);
    fetchAllAgents(pageIndex, pageSize, {});
  }

  const renderNewAgentItems = () => {
    return (
      <ListGroup className="timeline upcoming pre-appt-scheduled">
        {newAgents.map(item => (
          <ListGroupItem style={agentItemStyle(item.status)} >
            <AgentItemCard data={item} updateItem={updateItem} isAgent />
          </ListGroupItem>
        ))}
      </ListGroup>
    )
  }

  const renderOldAgentItems = () => {
    return (
      <ListGroup className="timeline upcoming pre-appt-scheduled">
        {oldAgents.map(item => (
          <ListGroupItem style={agentItemStyle(item.status)} >
            <AgentItemCard data={item} updateItem={updateItem} isAgent />
          </ListGroupItem>
        ))}
      </ListGroup>
    )
  }
  return (
    <>
      <h5 className="text-white mx-3"><i className="fa fa-user-secret"></i> Agents Overview</h5>
      <Card className="card-telecare mt-3 mb-5">
        <CardBody>
          <CardTitle className="mb-3">
            Manage agents registration approval and data entry
            <AdminExpandingButton toggle={toggleAgentExpansion} expanded={agentExpansion} />
          </CardTitle>
          {agentExpansion && <>
            <PhysiciansSearchBar
              userType="agent"
              searchFilters={applySearch}
            />
            <section className="section-content-block">
              <div id="apptBody" className="section-content-body">
                {newAgents.length > 0 &&
                  <>
                    <h6 className="text-muted ml-3">
                      <span>Pending Approval</span>
                    </h6>
                    {renderNewAgentItems()}
                    <hr />
                  </>
                }
                {oldAgents.length > 0 &&
                  <>
                    <h6 className="text-muted ml-3">
                      <span>Currently Existing</span>
                    </h6>
                    {renderOldAgentItems()}
                  </>
                }
              </div>
            </section>
          </>}
        </CardBody>
        {agentExpansion && oldAgents.length > 0 && pageCount &&
          <CustomedPagination
            pageCount={pageCount}
            pageNum={pageIndex}
            onPaginate={(idx: any) => { setPageIndex(idx); fetchAllAgents(idx, 10, searchOptions) }}
          />}
      </Card>
    </>

  );
}