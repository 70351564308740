import React, { useState } from 'react';
import { IPatientInfoSettings } from './PatientSettings';
import { Col, FormGroup, Label, Form, Row, Input, FormFeedback, Navbar } from 'reactstrap';
import { Link } from '@reach/router';
import { Routes } from '../../config/Routes';
import Asterisk from '../commons/Asterisk';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { IUSState } from '../../page/settings/PhysicianSettings';
import { requestPatientInfo, emailUnSubscribe, emailSubscribe } from '../../core/service/services';
import { toastSuccess, toastError } from '../../App';
import { UserConfig } from '../../config/userConfig';
import { LoadingButton } from '../commons/LoadingButton';
import NewDatePicker from '../NewDatePicker';
import { isDefined, isEmptyString, isInputDefined, isPhoneNumberValid } from '../../core/service/Validators';
import { ContactErrorMessages } from '../../core/service/ErrorMessages';
import { areThereErrors } from '../../core/service/ErrorService';

interface IProps {
	info?: IPatientInfoSettings;
	usStates: IUSState[],
	fetchData?:any
}

export interface IPatientInfoAddress {
	address?: {
		description?: string;
		addressLine2?: string;
		city?: string;
		state?: string;
		zipCode?: string;
	};
}

export const PersonalInformation = (props: IProps) => {
	const className = 'form-label-group';
	const [disabled, setDisabled] = useState(true);
	const [isEdit, setIsEdit] = useState(false);
	const [dob, setDob] = useState<Date | string>();
	const [patientInfo, setPatientInfo] = useState<IPatientInfoSettings>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubscribed, setIsSubscribed] = useState<boolean|undefined>();
	const [phoneNumber, setPhoneNumber] = useState<string>(patientInfo?.contact?.phone?? '');
    const [phoneError, setPhoneError] = useState(false);

	React.useEffect(()=>{
		setIsSubscribed(props.info?.contact?.subscribed);
		if (!dob) setDob(props.info?.contact?.dateOfBirth);
		setPatientInfo(props.info);
		setPhoneNumber(props.info?.contact?.phone ?? '')
	},[props.info])

	const setEditAndDisable = () => {
		setDisabled(!disabled);
		setIsEdit(!isEdit);
	};

	const createSelectItems = () => {
		return props.usStates.map((state, idx) => {
			return (
				<option key={idx} value={state.id} selected={state.id === props.info?.address?.stateId}>
					{state.name}
				</option>
			);
		});
	};
	const onSave = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data: FormData = new FormData(e.currentTarget);
		const formData: [string, FormDataEntryValue][] = Array.from(data.entries());
		let patientInfo: IPatientInfoSettings = {};

		patientInfo.contact = {};
		patientInfo.contact.firstname = getValue(formData, 'firstName');
		patientInfo.contact.lastname = getValue(formData, 'lastname');
		patientInfo.contact.phone = getValue(formData, 'phone');
		patientInfo.contact.dateOfBirth = dob
		patientInfo.contact.middlename = getValue(formData, 'middlename');
		patientInfo.address = {};
		patientInfo.address.description = getValue(formData, 'description');
		patientInfo.address.addressLine2 = getValue(formData, 'addressLine2');
		patientInfo.address.city = getValue(formData, 'city');
		patientInfo.address.stateId = getValue(formData, 'state');
		patientInfo.address.zipCode = getValue(formData, 'zipCode');
		patientInfo.address.country = 'US';

		return patientInfo;
	};

	const saveSettings = (e: React.FormEvent<HTMLFormElement>) => {
		setIsLoading(true);
		const patientInfo = onSave(e);
		requestPatientInfo('POST', patientInfo).then((result) => {
			setIsLoading(false);
			setEditAndDisable();
			toastSuccess("Saved Successfully!")
			if (UserConfig.getUserType() === "agent") {
				let patient = UserConfig.getPatientLoggedByAgent();
				patient = { ...patient, firstName: result.contact?.firstname, lastName: result.contact?.lastname }
				UserConfig.setPatientLoggedByAgent(patient);
			}
			props.fetchData();
			// setPatientInfo(patientInfo);
		}).catch((error) => {
			let err = returnErrorMessage(error);
			setIsLoading(false);
			toastError(err);

		});
	};
	const returnErrorMessage = (error: any) => {
		let errorObj = JSON.parse(JSON.stringify(error));
		if (errorObj && errorObj.json) {
			let errorMessageStr = errorObj.json.message;
			let errorMessage = "";
			if (errorMessageStr.split(',').length > 1)
				errorMessage = errorMessageStr.split(',')[1];
			else errorMessage = errorMessageStr
			return errorMessage;
		}
		else {
			return error;
		}
	}
	const getValue = (
		arr: [string, FormDataEntryValue][],
		field: string
	): any => {
		let value = arr.filter(function (record) {
			return record[0] === field;
		});

		return value[0][1];
	};
	
	const renderSavePersonalSettingsButton = () => {
		return (
			<div className="flow-nav-tools">
				<Navbar>
					<LoadingButton
						isLoading={isLoading}
						color="success"
						style={{ marginLeft: "auto", marginRight: "auto",color:"success", width:"40%" }}
						className="btn control-nav btn-success btn-lg" 
						>
						Save
					</LoadingButton>
				</Navbar>
			</div>
		)
	}

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value!;
		setPhoneNumber(value);
		setPhoneError(!isPhoneNumberValid(value));
	  };

	const renderPhoneNumbereErrorMessage = () => {
		if (!isInputDefined(phoneNumber)) {
			return ContactErrorMessages.enterPhone
		} else if (!isPhoneNumberValid(phoneNumber)) {
			return ContactErrorMessages.invalidPhoneNumber
		}
	}

	const onEmailSubscriptionClick=(e:any)=>{
		e.preventDefault();
		let id = UserConfig.getUserInfo()?.id;
		if(isSubscribed === true)
			id && emailUnSubscribe(Buffer.from(id.toString()).toString('base64')).then((res)=>{
				setIsSubscribed(res.subscribed);
			}).catch((error)=>{
				console.log(error)
			})
		else 
			emailSubscribe().then((res)=>{
				setIsSubscribed(res.subscribed);
			}).catch((error)=>{
				console.log(error)
			})
	}

	const handleSaveButton = async(e: React.FormEvent<HTMLFormElement>) => {
		if(!areThereErrors()) {
			saveSettings(e)
		} else {
			e.preventDefault();
		}
	}

	return (
		<div>
			<h6>
				<Label className="mb-3">
					Personal Information
        </Label>
			</h6>
			<fieldset disabled={isLoading} >
				<Form
					id="contact-form"
					onSubmitCapture={handleSaveButton}
				>
					<Row form>
						<Col md="6" lg="4">
							<FormGroup className={className}>

								<Input
									defaultValue={patientInfo?.contact?.firstname}
									type="text"
									id="firstName"
									name="firstName"
									required
								></Input>
								<Label for="firstName">First Name <Asterisk /></Label>
								<FormFeedback>"Valid first name is required."</FormFeedback>
							</FormGroup>
						</Col>

						<Col md="6" lg="4">
							<FormGroup className={className}>
								<Input
									defaultValue={patientInfo?.contact?.middlename}
									type="text"
									id="middlename"
									name="middlename"
								></Input>
								<Label for="middlename">Middle Name</Label>
							</FormGroup>
						</Col>

						<Col md="6" lg="4">
							<FormGroup className={className}>
								<Input
									defaultValue={patientInfo?.contact?.lastname}
									type="text"
									id="lastname"
									name="lastname"
									required
								></Input>
								<Label for="lastname">Last Name <Asterisk /></Label>
								<FormFeedback>"Valid last name is required."</FormFeedback>
							</FormGroup>
						</Col>

						<Col md="6" lg="6">
							<FormGroup className={className}>
								<Input
									type="text"
									defaultValue={patientInfo?.contact?.email}
									id="email"
									name="email"
									required
								></Input>
								<Label for="lastName">Email <Asterisk /></Label>
								<FormFeedback>"Please enter a valid email address."</FormFeedback>
							</FormGroup>
						</Col>
						<Col md="6" lg="6">
							<FormGroup className={className}>
								<Input
									defaultValue={phoneNumber}
									type="tel"
									id="phone"
									name="phone"
									onChange={handlePhoneChange}
									required
									invalid={phoneError}
								></Input>
								<Label for="phone">Cell Phone <Asterisk /></Label>
								<FormFeedback hidden={isInputDefined(phoneNumber!) && isPhoneNumberValid(phoneNumber)} className='error'>
									{renderPhoneNumbereErrorMessage()}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col md='6' lg='12'>
							<FormGroup name='dob'>
								<Label>
								Date of Birth:
								<Asterisk />
								</Label>
								<NewDatePicker
								selectedDate={dob}
								onChange={(date: string) => setDob(date)}
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row form>
						<Col md="6" lg="6">
							<FormGroup className={className}>
								<Input
									type="text"
									id="description"
									name="description"
									defaultValue={props.info?.address?.description}
									maxLength={35}
									required
								></Input>
								<Label for="address">Address Line 1  <Asterisk /></Label>
								<FormFeedback>Please enter your address.</FormFeedback>
							</FormGroup>
						</Col>
						<Col md="6" lg="6">
							<FormGroup className={className}>
								<Input
									type="text"
									id="addressLine2"
									name="addressLine2"
									defaultValue={props.info?.address?.addressLine2}
									maxLength={35}
								></Input>
								<Label for="address">Address Line 2 </Label>
							</FormGroup>
						</Col>

						<Col md="4" lg="5">
							<FormGroup className={className}>
								<Input
									type="text"
									id="city"
									name="city"
									defaultValue={props.info?.address?.city}
									maxLength={35}
									required
								></Input>
								<Label for="city">City  <Asterisk /></Label>
								<FormFeedback>Please enter your city.</FormFeedback>
							</FormGroup>
						</Col>

						<Col md="4" lg="3">
							<FormGroup>
								<Label for="state">State  <Asterisk /></Label>
								<Input
									type="select"
									id="state"
									name="state"
									defaultValue={props.info?.address?.stateId}
									required
								>
									<option value="">Select state</option>
									{createSelectItems()}
								</Input>
								<FormFeedback>Please choose a state.</FormFeedback>
							</FormGroup>
						</Col>

						<Col md="4" lg="4">
							<FormGroup className={className}>
								<Input
									type="text"
									id="zipCode"
									name="zipCode"
									defaultValue={props.info?.address?.zipCode}
									required
								></Input>
								<Label for="zipCode">Zip Code<Asterisk /></Label>
							</FormGroup>
						</Col>
					</Row>
					<Col md="6" lg="6">
						<FormGroup className={className} >
							<Link to={Routes.requestReset}>
								Request Password reset
							</Link>
						</FormGroup>
					</Col>
					{renderSavePersonalSettingsButton()}
				</Form>
			</fieldset>
		</div>
	);
}