import React, { useState, useEffect } from 'react'
import { IFullAppointment } from '../../book-appointment/AppointmentModels';
import { Col, FormGroup, Label, Input, Row, Button } from 'reactstrap';
import { IPhysicianTemplate, setFollowupNotes, getFollowupNotesForPhysician } from '../../../core/service/services';
import { TextField } from '../../../components/commons/TextField';
import { toastError, toastSuccess } from '../../../App';

interface IProps {
  appointment?: IFullAppointment,
  templates: IPhysicianTemplate[]
}

export const FollowupNotes: React.FC<IProps> = (props) => {

  const MAX_CHAR = 1024;
  const [initialNote, setInitialNote] = useState<string>("")
  const [note, setNote] = useState<string>("")
  const [editMode, setEditMode] = useState<boolean>(false)
  const [optionSelected, setOptionSelected] = useState<string>("")
  const [noneSelected, setNoneSelected] = useState<boolean>(false)
  const [charLeft, setCharLeft] = useState<number>(1024);
  const options = ["Routine", "ImmediateCare", "Custom"]
  
  useEffect(() => {
    props.appointment && getFollowupNotesForPhysician(props.appointment.appointmentId).then(res => {
      if(res.text){
        setNote(res.text)
        setCharLeft(MAX_CHAR - res.text.length);
        setInitialNote(res.text)
      }
    })
  }, [props.appointment])

  const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setNote("")
      setCharLeft(MAX_CHAR);
      setNoneSelected(true)
    } else if (e.target.value === "new") {
      setNote("")
      setCharLeft(MAX_CHAR);
      setNoneSelected(false)
    } else {
      for (let item of props.templates) {
        if (item.id === e.target.value) {
          setNote(item.text)
          break;
        }
      }
      setNoneSelected(false)
    }
  }

  const save = () => {
    if (!note || note.length === 0) {
      toastError("Please fill the followup notes")
      return
    }
    props.appointment && setFollowupNotes(props.appointment.appointmentId, note).then((result)=>{
      toastSuccess('Follow Up Notes saved successfully');
    }).catch((error)=>{ 
      toastError(error);
    })
    setInitialNote(note)
    setEditMode(false)
  }

  const isActiveAppointment = () => {
    return (props.appointment && (props.appointment.status === "Completed" ||
      props.appointment.status === "Started"))
  }

  const renderFormButtons = () => (
    <>
    <Button color="link" onClick={save} size="sm" className="pt-0">
      <i className="far fa-save fa-lg"></i>{" "}
      <span>Save</span>
    </Button>
    </>
  )

  const handleOptionChange = (value: string) => {
    setOptionSelected(value)
    switch (value) {
      case options[0]:
        setNote("Please follow-up with a physician in person immediately for any unexpected worsening, new symptoms or not feeling better within 24-48 hours. " + 
                "If you are receiving any medication refill with or without dose adjustment , based on the information you have provided us, additional refills " +
                "may be provided for your convenience. However the dosage (s) may need additional adjustments before stabilization. You should follow up with a " +
                "doctor immediately anytime you experience medication ineffectiveness, worsening conditions, new or worsening side effects or adverse reactions. " +
                "In these situations, do not obtain the remaining refills without checking with your doctor first.")
        break;
      case options[1]:
        setNote("Visit with a doctor in person ASAP for a physical exam and/or further diagnostic testing for definitive diagnosis and treatment. Any suggested empirical treatment or prescription today is aimed at a trial therapy or symptomatic relief based on limited available information through a telemedicine visit.")
        break;
      case options[2]:
        setNote("")
        break;
      default:
        break
    }
    setNoneSelected(false)
  }

  const renderOption = (value: string) => (
    <Col md="4">
      <div className="mb-2">
      <div className="custom-control custom-radio">
        <Input type="radio" name="followUpMsgOptionsRadios" id={value.trim()} className="custom-control-input"
            value={value} onChange={() => handleOptionChange(value)}/>
        <Label for={value.trim()} className="custom-control-label">{value}</Label>
      </div>
      </div>
    </Col>
  )

  return (
    <Col sm="12" className="mb-3">
      <dl>
        <dt>
          <Label>Follow Up Notes</Label>
          {isActiveAppointment() && renderFormButtons()}
        </dt>
        <dd>
          <Row>
            {isActiveAppointment() &&
            <>
            <Col sm="12">
              <Row>
                {options.map(value => (
                  renderOption(value)
                ))}
              </Row>
            </Col> 
            {optionSelected === options[2] &&
              <Col sm="6">
                <FormGroup>
                  <Label>Select a template</Label>
                  <Input type="select" name="fTemplates" onChange={e => handleTemplateChange(e)} disabled={!isActiveAppointment()}>
                    <option value="">Please Select</option>
                    {props.templates.map(item => (
                      <option value={item.id}>
                        {item.label}
                      </option>
                    ))}
                    <option value="new">
                      Custom Note
                    </option>
                  </Input>
                </FormGroup>
              </Col>
            }
            </>
            }
            {!noneSelected && 
              <Col sm="12">
                <TextField maxLength={MAX_CHAR} labelText="Notes" required type="textarea" defaultValue={note} disabled={!isActiveAppointment()} textChanged={text => {setCharLeft(MAX_CHAR-text.length); setNote(text)}}/>
                <p style={{fontSize:"80%"}}>Characters Left: {charLeft}</p>
              </Col>
            }
          </Row>
        </dd>
      </dl>
    </Col>
  )
}