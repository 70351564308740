import React, { useState, useEffect } from 'react'
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { Routes } from '../../../config/Routes';
import { Button, CardBody, Container, CardTitle, FormGroup, Label } from 'reactstrap';
import { BigCardFrame } from '../../../components/layout/BigCardFrame';
import { IConfigurationItem, getAdminConfigurationSettings, updateAdminConfigurationSettings, getAdminAllStates, getSystemStates, addSystemStates, removeSystemStates } from '../../../core/service/services';
import Select, { ActionMeta } from "react-select"
import { ConfigurationItem } from './ConfigurationItem';
import { toastSuccess, toastError, navItem } from '../../../App';

interface IProps extends RouteComponentProps {}

export const AdminConfigurationSettings: React.FC<IProps> = (props) => {

  interface IOption {
    value: number;
    label: string;
  }

  const [allStates, setAllStates] = useState<IOption[]>([])
  const [selectedStates, setSelectedStates] = useState<IOption[]>([])
  const [configItems, setConfigItems] = useState<IConfigurationItem[]>([])

  useEffect(() => {
    async function fetchStates() {
      const res = await getAdminAllStates()
      let all: IOption[] = []
      res.items.map(item => {
        all.push({label: item.name, value: item.id})
      })
      setAllStates(all)
      let selected: IOption[] = []
      const systemStates = await getSystemStates()
      systemStates.items.map(item => {
        selected.push(all[item - 1])
      })
      setSelectedStates(selected)
    }
    fetchStates()
    getAdminConfigurationSettings().then(res => {
      setConfigItems(res.items)
    }).catch(err => console.log(err))
  }, [])

  const save = (key: string, value: string) => {
    updateAdminConfigurationSettings(key, value).then(res => {
      toastSuccess("Saved Successfully!")
    }).catch(err => toastError("An unexpected error occured!"))
  }

  const handleStateSelectionChange = (value: any, action: ActionMeta<IOption>) => {
    const currentValues = value as IOption[]
    if (action.action === "select-option") {
      const added = currentValues.filter(item => !selectedStates.includes(item))
      if (added.length > 0) {
        addSystemStates(added[0].value).then(res => {
          toastSuccess("Added " + added[0].label + " to system eligible States")
          setSelectedStates(currentValues)
        }).catch(err => toastError("Cannot add " + added[0].label + " to system eligible States"))
      }
    } else if (action.action === "remove-value") {
      const removed = selectedStates.filter(item => !currentValues.includes(item))
      if (removed.length > 0) {
        removeSystemStates(removed[0].value).then(res => {
          toastSuccess("Removed " + removed[0].label + " from system eligible States")
          setSelectedStates(currentValues)
        }).catch(err => toastError("Cannot remove " + removed[0].label + " from system eligible States"))
      }
    }
  }
  
  const flowNav = (
    <div className="flow-nav-tools">
      <Button color="primary" size="lg" onClick={() => navigate(Routes.adminConsole)} className="control-nav">
        Back
      </Button>
    </div>
  );

  return (
    <BigCardFrame
      flowNav={flowNav}
      navItem={navItem}
      bodyClassName="tc-bg"
    > 
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">System Configurations</h5>
          <Link to={Routes.adminConsole} className="btn-sm ml-auto">
            Cancel
          </Link>
        </div>
        <hr className="mt-2 mb-4" />
        <Container>
          <CardTitle>Configure general system settings</CardTitle>
          <FormGroup>
            <Label>Select the eligibles states</Label>
            <Select options={allStates} isMulti={true} isSearchable={true} value={selectedStates} onChange={handleStateSelectionChange}/>
          </FormGroup>
          {configItems.map(item => (
            <>
            <ConfigurationItem configItem={item} onSave={save}/>
            <hr />
            </>
          ))}
        </Container>
      </CardBody>
    </BigCardFrame>
  )
}