import React from 'react';
import { Link } from "@reach/router";
import { Form, Button } from 'reactstrap';
import { TextField } from '../../components/commons/TextField';

interface IPrimaryRegisrationProps {
	controls: any,
	page: any,
	email: any,
	routesTo: any;
	submitPrimaryInfo: any
	userType: "Agent"|"Physician",
	signUpService?: any 
}
export interface IRegistrationPrimaryInfo {
  email: string;
  password : string;
  passwordConfirmation: string;
  firstname: string;
  lastname: string;
  middlename?: string;
  
}
export const PrimaryRegistartionForm = (props: IPrimaryRegisrationProps) => {
	return (
		<Form name="regForm1" onSubmit={props.submitPrimaryInfo} className={props.page === 1 ? "" : "d-none"}>
			<TextField name="firstName" ref={props.controls.firstName} required type="text" labelText="First Name" placeholder="First Name" />
			{(props.userType !=="Agent" ) && <TextField name="middleName" ref={props.controls.middleName} type="text" labelText="Middle Name" placeholder="Middle Name" />}
			<TextField name="lastName" ref={props.controls.lastName} required type="text" labelText="Last Name" placeholder="Last Name" />
			<TextField name="email" ref={props.controls.email} required type="text" labelText="Email address" placeholder="Email address" defaultValue={props.email} />
			<hr />
			<TextField name="password" ref={props.controls.password} type="password" labelText="Password" placeholder="Password" required />
			<TextField name="passwordConfirmation" ref={props.controls.passwordConfirmation} type="password" labelText="Confirm password" placeholder="Password" required />
			<Button type="submit" size="lg" color="primary" block className="text-uppercase mt-4"
				isLoading={props.signUpService && props.signUpService.service.status == "loading"}
			>
				{props.userType==="Physician" ?"Next":"Register"}
			</Button>
			<div className="form-text mt-2 text-center mb-2 mt-4">
				Already a member?{" "}
				<Link to={props.routesTo} className="btn btn-sm btn-block btn-outline-primary mt-2">
					Sign In
        </Link>
			</div>
		</Form>
	)
}