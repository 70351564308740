import React, { useState } from "react";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import EditAllergyModal from "../../../components/medicalHistory/EditAllergyModal";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  medicationAllergyEdited,
  medicationAllergyRemoved,
  selectMedicationAllergies,
  selectNewMedicationAllergies
} from '../../../redux/slices/general/medicalProfileSlice';
import { IFullAllergy } from "../../../components/medicalHistory/MedicationAllergiesDoseSpot";

export interface IMedicalAllergyProps {
  maxHeight: number;
}

const MedicationsAllergiesListRedux = (
  props: IMedicalAllergyProps
) => {
  const medicationAllergies = useAppSelector(selectMedicationAllergies)
  const newMedicationAllergies = useAppSelector(selectNewMedicationAllergies);

  const [selectedMedication, setSelectedMedication] = useState<IFullAllergy>();
  const [isEditAllergyModalOpen, setIsEditAllergyModalOpen] = useState(false);
  const toggleEditAllergyModal = () => setIsEditAllergyModalOpen(!isEditAllergyModalOpen);

  const dispatch = useAppDispatch()

  console.log("logging from redux")

  const onSaveEditedMedication=(editedMedication: IFullAllergy)=>{
    dispatch(medicationAllergyEdited(editedMedication))
    toggleEditAllergyModal();
  }

  const returnMedicationAllergies = () => {
    if (newMedicationAllergies.length !== 0) {
      return medicationAllergies.concat(newMedicationAllergies);
    }
    return medicationAllergies;
  }

  const handleRemove = (medication: any) => {
    dispatch(medicationAllergyRemoved(medication))
  }

  return (   
    <ListGroup style={{ maxHeight: props.maxHeight, overflow: "auto" }}>
      {returnMedicationAllergies().map((medication: any, i:number) => {
        return (
          <div key={i}>
          <EditAllergyModal modal={isEditAllergyModalOpen} data={selectedMedication} toggle={toggleEditAllergyModal} onSave={(e:any)=>{onSaveEditedMedication(e)}}/>
          <ListGroupItem>
            <span> {medication.Name}</span>

            <Button
              type="button"
              color="link"
              data-toggle="tooltip"
              className="btn btn-link text-muted ml-auto"
              data-original-title="Delete Medication"
              onClick={() => handleRemove(medication)}
              style={{float: "right"}}
            >
              <span className="sr-only">Remove</span>{" "}
              <i className="fa fa-trash"></i>
            </Button>
            <Button
              type="button"
              color="link"
              data-toggle="tooltip"
              className="btn btn-link text-muted ml-auto"
              data-original-title="Edit Medication"
              onClick={()=>{toggleEditAllergyModal(); setSelectedMedication(medication);} }
              style={{float: "right"}}
            >
              <span className="sr-only">Edit</span>{" "}
              <i className="fa fa-edit"></i>
            </Button>
          </ListGroupItem>
          </div>
        );
      })}
    </ListGroup>
    
  );
};
export default MedicationsAllergiesListRedux;
