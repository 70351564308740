import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { useAppDispatch } from '../../../../../appredux/hooks';
import { strings } from '../../../../Constants';
import { addInsuranceForPatient, getConfigurationForPayer } from '../../../../core/service/services';
import { useAppSelector } from '../../../../redux/hooks';
import { selectDependent } from '../../../../redux/slices/booking/choosePatientStepSlice';
import {
    payerSelectedAfterConfChanged,
    payerCodeSelectedChanged,
    selectPayerNameSelected,
    selectPayerCodeSelected,
    selectInsuranceIdSelected,
    insuranceIdSelectedChanged
} from '../../../../redux/slices/general/insuranceDetailsSlice';
import {
    insuranceDetailsChanged,
    insuranceNotListedChanged,
    photoTokensChanged,
    showGalleryChanged,
    attachmentsCleared,
    selectInsuranceDetails
} from '../../../../redux/slices/booking/insuranceStepSlice';
import Asterisk from '../../../commons/Asterisk';
import { LoadingButton } from '../../../commons/LoadingButton';
import DateConfType from './DateConfType';
import DropDownConfType from './DropDownConfType';
import TextConfType from './TextConfType';
import { IDPatient } from '../../PatientSettings';

export interface IDropDownValues {
    code: string;
    value: string;
}

export interface IConfElement {
    type: string;
    label: string;
    order: number;
    allowed: boolean;
    required: boolean;
    errorMessage: string;
    defaultValue: string;
    defaultSystemValue: string;
    values: IDropDownValues[] | null;
    keyToBeSent: string,
    min: string | null,
    max: string | null
}
enum configTypes {
    Unsupported = "Unsupported",
    Date = "Date",
    Collection = "Collection",
    Text = "Text",
    Enumeration = "Enumeration"
}

export interface IConfigurationInsuranceModalReduxProps {
    isModalOpen: boolean;
    toggleModal: () => void;
}

const ConfigurationInsuranceModalRedux = ({
    isModalOpen,
    toggleModal
}: IConfigurationInsuranceModalReduxProps) => {
    const payerCode: string = useAppSelector(selectPayerCodeSelected);
    const payerName: string = useAppSelector(selectPayerNameSelected);
    const dependentId: number | undefined = useAppSelector(selectDependent)?.id
    const insuranceId: any = useAppSelector(selectInsuranceIdSelected)
    const insurance = useAppSelector(selectInsuranceDetails)

    const [confList, setConfList] = useState<IConfElement[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [errorMessageMap, setErrorMessageMap] = useState<Map<string, string>>(new Map());
    // error message map is used to keep track of the dynamically injected views so that if an issue happened we change the error message and it appears beneath the injected view 

    const dispatch = useAppDispatch()

    useEffect(() => {
        //get configurations
        if (isModalOpen) {
            if (payerCode) {
                getConfigurationForPayer(payerCode).then((result) => {
                    var newArr: IConfElement[] = []
                    const keyToBeSent: string[] = [];
                    const messageMap = new Map;
                    const keys = Object.entries(result.configurations)
                    keys.map(([key, value], idx) => {
                        var tempOneToChangeUnknown: any = value;
                        var tempOfConfType: IConfElement = { ...tempOneToChangeUnknown, keyToBeSent: key }
                        newArr = [...newArr, tempOfConfType]
                        if (tempOfConfType.required && tempOfConfType.type !== configTypes.Collection) {
                            keyToBeSent.push(key)
                            messageMap.set(key, "")
                            console.log("keys to be sent", key)
                        }
                    });
                    setConfList(newArr)
                    setErrorMessageMap(messageMap)
                }).catch((err) => {
                    console.log(err)
                });
            } else {
                console.log("no payer code")
            }
        }
    }, [payerCode]);

    const renderDynamicConf = () => {
        return (
            <>
                {confList.map((configElement, idx) => {
                    if (configElement.required) {
                        switch (configElement.type) {
                            case configTypes.Text: {
                                return (
                                    <TextConfType confElement={configElement} errorMessage={errorMessageMap.get(configElement.keyToBeSent)} />
                                )
                            }
                            case configTypes.Date: {
                                return (
                                    <DateConfType confElement={configElement} errorMessage={errorMessageMap.get(configElement.keyToBeSent)} />
                                )
                            }
                            case configTypes.Enumeration: {
                                return (
                                    <DropDownConfType confElement={configElement} errorMessage={errorMessageMap.get(configElement.keyToBeSent)} />
                                )
                            }
                        }
                    }
                })
                }
            </>
        )
    }

    const clearErrorMap = () => {
        const tempMap = new Map()
        errorMessageMap.forEach((value, key) => {
            tempMap.set(key, "")
        })
        setErrorMessageMap(tempMap)
    }

    const onSubmitForm = (e: any) => {
        setLoading(true)
        clearErrorMap()
        const submittedObj: any = {}
        e.preventDefault()
        let keys = Array.from(errorMessageMap.keys());           
        keys.map((key) => {
            console.log(key)
            const temp: string = e.target[key].value
            submittedObj[key] = temp;
        })
        submittedObj["payerCode"] = payerCode
        submittedObj["payerName"] = payerName
        addInsuranceForPatient(submittedObj, dependentId)
            .then((res: IDPatient) => {
                setLoading(false)
                onSave({...submittedObj, id: res.id})
            })
            .catch((err) => {
                console.log(err)
                console.log(err.message)
                let errorObj = JSON.parse(JSON.stringify(err))
                let message = JSON.parse(errorObj.json.message)
                console.log("message", message)
                message.map((jsonObject: any) => {
                    setErrorMessageMap(new Map(errorMessageMap.set(jsonObject.field, jsonObject.errorMessage)))
                    console.log(jsonObject.errorMessage)
                    console.log(jsonObject.field)
                    console.log(errorMessageMap)
                })
                console.log(err);
                setLoading(false)
            });
    }

    const onSave = (insuranceDetails: any) => {
        dispatch(payerSelectedAfterConfChanged({
            name: insuranceDetails.payerName,
            id: insuranceDetails.id,
            code: insuranceDetails.payerCode,
            description: ""
        }))
        dispatch(insuranceIdSelectedChanged(insuranceDetails.id))
        dispatch(insuranceDetailsChanged(insuranceDetails))
        dispatch(showGalleryChanged(false))
        dispatch(insuranceNotListedChanged(false))
        dispatch(photoTokensChanged(undefined))
        dispatch(attachmentsCleared())
        toggleModal()
    }

    const onCancel = () => {
        toggleModal()
        dispatch(payerCodeSelectedChanged(strings.patientSettings.select_insurance))
    }

    return (
        <div>
            <Modal isOpen={isModalOpen}>
                <ModalHeader>Please fill the following info</ModalHeader>
                <Form onSubmit={onSubmitForm} >
                    <ModalBody>
                        <FormGroup>
                            {renderDynamicConf()}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <FormGroup>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                isLoading={isLoading}
                            >
                                Save
                            </LoadingButton>
                            {"  "}
                            <Button color="secondary" onClick={onCancel}>
                                Cancel
                            </Button>
                        </FormGroup>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default ConfigurationInsuranceModalRedux;



