import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { bookingCleared } from './bookingSlice';

export interface IErrorState {
   showErrors: boolean
}

const initialState: IErrorState = {
   showErrors: false
}

const errorSlice = createSlice({
   name: 'error',
   initialState,
   reducers: {
      errorsDisplayed: (state, action: PayloadAction<boolean>) => {
         state.showErrors = action.payload
      }
   },
   extraReducers: (builder: ActionReducerMapBuilder<IErrorState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   errorsDisplayed
} = errorSlice.actions

export const selectShowErrors = (state: RootState) => state.error.showErrors

export default errorSlice.reducer
