import React from "react";
import { useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, CardBody, Container, Spinner } from "reactstrap";
import { getImage } from "../../../core/service/services";
import { IAppointmentBiller } from "./HomeBiller";

interface IInsuranceInfoModal {
    appointement?: IAppointmentBiller;
    isOpen: boolean
    toggleModal: any
}
export const InsuranceInfoModal = (props: IInsuranceInfoModal) => {

    const [attachments, setAttachements] = useState<{name:string,imageBase64:string}[] | undefined>([])
    const [isLoading, setIsloading] = useState<boolean>(false)

    useEffect(() => {
        if (props.isOpen) {
            if (props.appointement?.insuranceAttachments) {
                setAttachements(undefined)
                fetchBase64Images(props.appointement?.insuranceAttachments)
                setIsloading(true)
            }
        }

    }, [props])

    const fetchBase64Images = (photoTokens: string[]) => {
        if (photoTokens) {
            var att: {name:string,imageBase64:string}[] = []
            photoTokens.map(async (photo) => {
                try {
                    const res = await getImage(photo)
                    att = [...att, res]

                    setAttachements(att)
                    setIsloading(false)
                } catch (err) {
                    setIsloading(false)

                    console.log(err)
                }

            })

        }
    }
    const renderAttachments = () => {

                return (
                    <CardBody>
                        <Container>
                            {attachments?.map((file:any)=>{
                                if(file.name.split('.').pop()=='pdf'){
                                    return renderPDF(file.imageBase64)
                                }
                                else{
                                    return renderImage(file.imageBase64)
                                }
                            })}
                        </Container>
                    </CardBody>
                )


    }

    const renderImage = (imageBase64:string) =>{
        const image = [{
            src:generateImage(imageBase64),
            width:1,
            height:1
        }]
        return <Gallery photos={image} columns={8}  />
    }
     const renderPDF = (pdfStringBase64:string) =>{
    return  <embed width={400} height={400} src={`data:application/pdf;base64,${pdfStringBase64}`} />
     }
     const generateImage = (file: string): string => {
            const imageUrl = 'data:image/jpeg;base64, ' + file;
            return imageUrl;
    };
    const renderPrimarySubscriber = () => {
        if (props.appointement?.primarySubscriber) {
            return (
                <> <br />
                    <strong>Primary Subscriber Info</strong>
                    <br />

                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"First  Name" + " : "}</strong>
                            {props.appointement?.primarySubscriber?.firstName}
                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Last  Name" + " : "}</strong>
                            {props.appointement?.primarySubscriber?.lastName}
                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Date of Birth" + " : "}</strong>
                            {props.appointement?.primarySubscriber?.dateOfBirth}
                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Relation" + " : "}</strong>
                            {props.appointement?.primarySubscriber?.relation}
                        </div>
                    </li>
                    <br />
                </>
            )
        } else {
            return (
                <>
                    <div>The User is the <strong>Primary Subscriber</strong></div>
                </>
            )
        }
    }

    const renderInsruances = () => {

        if (props.appointement?.insuranceAttachments) { // not listed insurance
            return (
                <>   <li className="list-inline-item mx-0">
                    <div>
                        <strong>{"Payer's Card" + " : "}</strong>

                    </div>
                </li>
                    <br />
                    {renderAttachments()}
                    {renderPrimarySubscriber()}
                </>
            )
        }
        else {
            return (
                <>
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Payer  Name" + " : "}</strong>
                            {props.appointement?.insurance?.payerName}
                        </div>
                    </li>
                    <br />
                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Provider  Name" + " : "}</strong>
                            {props.appointement?.insurance?.providerName}
                        </div>
                    </li>
                    <br />

                    <li className="list-inline-item mx-0">
                        <div>
                            <strong>{"Member Id " + " : "}</strong>
                            {props.appointement?.insurance?.memberId}
                        </div>
                    </li>
                    <br />


                    {renderPrimarySubscriber()}
                </>
            )
        }
    }
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} >
                <ModalHeader toggle={props.toggleModal}>Insruance Details</ModalHeader>
                <ModalBody >
                    {isLoading && <Spinner color="primary" />}
                    {renderInsruances()}

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.toggleModal}>Okay</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}