import * as React from "react";
import { RouteComponentProps} from "@reach/router";
// const USAMap = require("@jlev/react-usa-map");
// @ts-ignore
import USAMap from "@jlev/react-usa-map";
import TCNavbar from "../components/layout/TCNavBar";
import { Container } from "reactstrap";
import Footer from "../components/layout/Footer";
import { LandingPageHeader } from "./LandingPageHeader";
import { CRoutes } from "../config/Routes";
import { Helmet } from "react-helmet";

interface IProps extends RouteComponentProps {
  // children?: React.ReactNode
}

interface IState {
}

export class USMap extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
    };

  }

  openState(stateUrl: string) {
    window.open(stateUrl, '_self')
  };

  statesCustomConfig(){
    return {
      "IL": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.illions)
      },
      "AL":{
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.alabama)
      },
      "AZ": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.arizona)
      },
      "CO": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.colorado)
      },
      "ID": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.idaho)
      },
      "IN": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.indiana)
      },
      "IA": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.iowa)
      },
      "KS": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.kansas)
      },
      "KY": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.kentucky)
      },
      "MI": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.michigan)
      },
      "MN": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.minnesota)
      },
      "NE": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.nebraska)
      },
      "NJ": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.newJersey)
      },
      "NV": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.nevada)
      },
      "ND": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.northDakota)
      },
      "OK": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.oklahoma)
      },
      "SD": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.southDakota)
      },
      "TN": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.tennessee)
      },
      "UT": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.utah)
      },
      "WA": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.washington)
      },
      "WI": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.wisconsin)
      },
      "FL": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.florida)
      },
      "OR": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.oregon)
      },
      "ME": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.maine)
      },
      "MD": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.maryland)
      },
      "MT": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.montana)
      },
      "LA": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.louisiana)
      },
      "VT": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.vermont)
      },
      "MS": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.mississippi)
      },
      "NH": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.newHampshire)
      },
      "WV": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.westVirginia)
      },
      "WY": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.wyoming)
      },
      "GA": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.georgia)
      },
      "GU": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.guam)
      },
      "TX": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.texas)
      },
      "DE": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.delaware)
      },
      "OH": {
        fill: "#28a745",
        clickHandler: (event: any) => this.openState(CRoutes.ohio)
      }
    };
  };
  


  render() {

    const nav = (
      <TCNavbar bodyClassName="tc-bg">
        {null}
      </TCNavbar>
    );

    return (
      <>
        <Helmet>
          <title>Map - TelMDCare</title>
        </Helmet>

        {nav}
        <div>
          <h2 className="text-white mx-3" style={{ textAlign: "center" }}>
            {" "}
            Our service is now available in the following states
          </h2>
        </div>
        <Container style={{maxWidth: 2000, textAlign: "center"}}>
          <USAMap customize={this.statesCustomConfig()} />
          <LandingPageHeader />
        </Container>
        <Footer />
      </>

      
    );
  }
}
