import React, { useState, useEffect } from "react";
import { ModalBody, ModalHeader, Modal } from "reactstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { toastError, toastSuccess } from "../../../App";
import { IRxReconcile, getALlAppointmentsOfRxReconcile, IPrescriptionReconcile, reconcileMedicine } from "../../../core/service/services";
import { Link } from "@reach/router";

export interface IProps {
    toggleModal: () => void;
    modalIsOpen: boolean;
    rxReconcile?: IRxReconcile,
    onReconcile: () => void
}

export const RxReconcileModal: React.FC<IProps> = props => {
    const [isOpen, setOpen] = useState<boolean>(false)
    const [medicationList, setMedicationList] = useState<IPrescriptionReconcile[]>([])



    useEffect(() => {
        setOpen(props.modalIsOpen)
    }, [props.modalIsOpen])

    useEffect(() => {
        // setPrescribedMedication(props.rxChangeRequest.rxRequest.PrescribedMedication)
        // setPrescriptionSubstitutions(props.rxChangeRequest.rxRequest.RequestedMedications)
        // setIsPriorAuth(props.rxChangeRequest.rxRequest.RxChangeType === "Prior Authorization")
        if (props.rxReconcile?.id) {
            getALlAppointmentsOfRxReconcile(props.rxReconcile?.id).then((res) => {
                setMedicationList(res.prescriptions)
            }).catch((err) => {
                toastError("Something went wrong while fetching the medication list of the current user")
                props.toggleModal()
                console.log(err)
            })
        }

    }, [props.rxReconcile])




    const closeModalsAndRefetchPrescriptions = (message: string) => {
        toastSuccess(message)

        props.onReconcile()
        props.toggleModal()
    }

    return (
        <>

            <Modal isOpen={isOpen} toggle={props.toggleModal} size="lg" style={{ maxWidth: '80rem' }}>
                <ModalHeader toggle={props.toggleModal}>
                    <h5 id="rxreconcile" className="modal-title">RxChange Reconcile: {props.rxReconcile?.doseSpotDisplayName}</h5>
                </ModalHeader>
                <ModalBody>
                    <h6>Please Choose the Prescription for  {props.rxReconcile?.patientFirstName + " " + props.rxReconcile?.patientFirstName}  </h6>

                    {medicationList.map((medication) => {
                        return (
                            <Link key={medication.id} to="#" onClick={(e) => {
                                e.preventDefault()
                                if (props.rxReconcile) {
                                    console.log(props.rxReconcile)
                                    reconcileMedicine(props.rxReconcile.id, medication.dose_spot_id).then((res) => {
                                        props.onReconcile()
                                        props.toggleModal()
                                        toastSuccess("The medication is currently reconciled")
                                        // todo should refresh page 
                                    }).catch((err) => {
                                        toastError("Something went wrong while reconciling the medication")
                                        props.toggleModal()
                                        console.log(err)
                                    })
                                }


                            }}>
                                {medication.medication}
                            </Link>
                        )
                    })}
                </ModalBody>

            </Modal>
        </>
    );
};
