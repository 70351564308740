import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Row, Col, Container } from "reactstrap";
import { Routes } from "../config/Routes";

interface IProps extends RouteComponentProps { }

export class Welcome extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <>
        <section className="features-icons bg-light text-center">
          <Container>
            <Row>
              <Col md="3" lg="3">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <a
                    href="/patient-signin"
                    className="features-icons-icon d-flex"
                  >
                    <i className="fa fa-user m-auto"></i>
                  </a>
                  <h3>Patient</h3>
                  <p className="lead mb-0">Flow for patient scheduling. </p>
                </div>
              </Col>
              <Col md="3" lg="3">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <a
                    href="/physician-signin"
                    className="features-icons-icon d-flex"
                  >
                    <i className="fa fa-briefcase-medical m-auto"></i>
                  </a>

                  <h3>Physician</h3>
                  <p className="lead mb-0">Flow for physician waiting room. </p>
                </div>
              </Col>
              <Col md="3" lg="3">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <a
                    href={Routes.adminLogin}
                    className="features-icons-icon d-flex"
                  >
                    <i className="fa fa-users-cog m-auto"></i>
                  </a>

                  <h3>Admin</h3>
                  <p className="lead mb-0">Administration Panel </p>
                </div>
              </Col>
              <Col md="3" lg="3">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <a
                    href={Routes.agentLogin}
                    className="features-icons-icon d-flex"
                  >
                    <i className="fa fa-user-secret m-auto"></i>
                  </a>

                  <h3>Agent</h3>
                  <p className="lead mb-0">Agent Panel </p>
                </div>
              </Col>
              <Col md="3" lg="3">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <a
                    href={Routes.billerLogin}
                    className="features-icons-icon d-flex"
                  >
                    <i className="fa fa-user-tie m-auto"></i>
                  </a>

                  <h3>Biller</h3>
                  <p className="lead mb-0">Biller Panel </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
