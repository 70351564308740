import * as React from 'react';
import { useState } from 'react';
import moment from "moment";
import { IAddress } from '../../redux/types/interfaces/patientInfoInterfaces'
import { mapUsStatesToSupportedTZValues } from '../../core/service/TimeZone'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectAddress } from '../../redux/slices/general/patientInfoSlice'
import { dateChosen, selectDate } from "../../redux/slices/booking/timeAndContactTypeStepSlice";
import CustomErrorMessage from "../../components/CustomErrorMessage";
import { isDefined, isTimeValid } from "../../core/service/Validators";
import { BookingTimeContactErrorMessages } from "../../core/service/ErrorMessages";
import { selectShowErrors } from '../../redux/slices/booking/errorSlice';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Label } from 'reactstrap';

const getNextTimeWindow = (dateNow: Date | string): Date => {
  const date = new Date(dateNow);
  const remainder = date.getMinutes() % 15;
  return moment(date).subtract(remainder, 'minutes').add(15, 'minutes').toDate();
}

/**
 * This function creates a fake date for the inability of DatePicker component to display time in different timezones
 * The date will be the time at a given state but with the user's local time offset
 * 
 * @param stateName name of the user's address state
 * @returns gets the time at the given state with the user's local time offset
 */
const getStateCurrentTime = (stateName: string): Date => {
  const zone = mapUsStatesToSupportedTZValues(stateName);

  const dateNow = new Date();
  if(!zone) return dateNow;

  const browserMoment = moment(dateNow);
  const stateMoment = moment(dateNow).tz(zone);
  const fakeMoment = moment(browserMoment);

  fakeMoment.subtract(browserMoment.utcOffset() - stateMoment.utcOffset(), 'minutes');

  return fakeMoment.toDate();
}

/**
 * It converts the fake date to the correct state time offset
 * 
 * @param fakeDate the fake date that was used for display purposes
 * @param stateName the state that the fake date belongs to
 * @returns the correct date that the user would schedule with the state's time offset
 */
const getScheduledTime = (fakeDate: Date, stateName: string): Date => {
  const zone = mapUsStatesToSupportedTZValues(stateName);
  if(!zone) return fakeDate;
    
  const fakeMoment = moment(fakeDate).tz(zone, true);
  return fakeMoment.toDate();
}

export const DateTimeComponent = () => {
  const date: Date | undefined = useAppSelector(selectDate)
  const patientAddress: IAddress | undefined = useAppSelector(selectAddress)
  const showErrors = useAppSelector(selectShowErrors);
  
  const [startDate, setStartDate] = useState<Date>(getNextTimeWindow(getStateCurrentTime(patientAddress?.stateName ?? '')));
  const [minTime, setMinTime] = useState<Date>(startDate);
  const [maxTime, setMaxTime] = useState<Date>(moment(startDate).endOf('day').toDate())
  
  const dispatch = useAppDispatch()

  const calculateMinTime = (date: Date) => {
    const minDateNow = getNextTimeWindow(getStateCurrentTime(patientAddress?.stateName ?? ''));
    const selectedDate = moment(date);
    return selectedDate.isSame(minDateNow, 'day') ? minDateNow : selectedDate.startOf('day').toDate();
  }

  const setScheduledDate = (date: Date) => {
    setStartDate(date)
    setMinTime(calculateMinTime(date))
    setMaxTime(moment(calculateMinTime(date)).endOf('day').toDate());

    const scheduledTime = getScheduledTime(date, patientAddress?.stateName ?? '');
    dispatch(dateChosen(scheduledTime))
  }

  const renderDateField = () => {
      return (
        <div>
          <div className='mt-3 w-75' id='contactLaterSelected'>
            <div className='form-label-group-datePicker'>
              <DatePicker
                selected={startDate}
                placeholderText="Choose a date and time"
                onChange={setScheduledDate}
                showTimeSelect
                calendarClassName='booking-datepicker'
                timeFormat='HH:mm'
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='EEE MMMM do yyyy h:mm a'
                useWeekdaysShort={true}
                minDate={getNextTimeWindow(getStateCurrentTime(patientAddress?.stateName ?? ''))}
                minTime={minTime}
                maxTime={maxTime}
                popperPlacement='top'
                popperModifiers={{
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
              <CustomErrorMessage invalid={!isDefined(date)} showValidation={showErrors} errorMessage={BookingTimeContactErrorMessages.selectDate} />
              <CustomErrorMessage invalid={!isTimeValid(startDate, minTime)} showValidation={showErrors} errorMessage={BookingTimeContactErrorMessages.enterValidTime} />
              <Label>{`Time is based on ${patientAddress?.stateName},  your address state.`}</Label>
            </div>
          </div>
        </div>
      );
  };
  return (
      <>
          {renderDateField()}
      </>
      
  )
}