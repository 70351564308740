// import { render } from "@testing-library/react";
import * as React from "react";
import _ from 'lodash';
import { Link } from "@reach/router";
import { Routes } from "../../config/Routes";
import { PreviousConditionsModalRedux } from "./medicalProfile/PreviousConditionsModalRedux";
import { MedicationsModalRedux } from "./medicalProfile/MedicationsModalRedux";
import { IBookingProps } from "./AppointmentModels"
import {
  getSelectedConditions,
  getAllConditionsLookup,
  setPatientMeasurments,
  getPatientMeasurments,
  getMedicationAllergies,
  addMedicationAllergies,
  deleteMedicationAllergies,
  updateMedicationAllergies,
  getMedicationsAllergyStatus,
  editMedicationsAllergyStatus,
  getPreviousConditionsStatus,
  addSelfReportedMedicationService,
  removeSelfReportedMedicationService,
  IPatientPreviousConditions,
  updatePatientPreviousConditions
} from "../../core/service/services";
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem, FormGroup, Label, Button, Form } from 'reactstrap';
import { SideCardFrame } from "../../components/layout/SideCardFrame"
import { TextField } from "../../components/commons/TextField";
import Asterisk from '../../components/commons/Asterisk';
import MedicationsAllergiesListRedux from "./medicalProfile/MedicationsAllergiesListRedux";
import { toastError, navItem } from "../../App";
import { SelfReportedMedicationsRedux } from "./medicalProfile/SelfReportedMedicationsRedux";
import LoadingSpinner from '../../components/commons/LoadingSpinner';
import { useAppDispatch, useAppSelector } from './../../redux/hooks';
import {
  measurementsUpdated,
  pregnantUpdated,
  breastfeedingUpdated,
  genderUpdated,
  previousConditionsUpdated,
  medicationAllergiesUpdated,
  hasPreviousConditionsBooleanChanged,
  hasMedicationAllergiesBooleanChanged,
  updatingArraysCleared,
  selectMeasurements,
  selectPreviousConditions,
  selectNewMedicationAllergies,
  selectEditedMedicationAllergies,
  selectDeletedMedicationAllergies,
  selectSelfReportedMedicationsActions,
  selectHasPreviousConditions,
  selectHasMedicationAllergies,
} from './../../redux/slices/general/medicalProfileSlice';
import { selectDependent } from './../../redux/slices/booking/choosePatientStepSlice'
import { selectContactInfo } from "../../redux/slices/general/patientInfoSlice";
import {
  IMeasurements,
  IPatientMeasurments,
  IItem,
  ISelfReportedMedicationsAction
} from './../../redux/types/interfaces/medicalProfileInterfaces';

import { nextStepInitiated, previousStepInitiated } from "../../redux/slices/booking/bookingSlice";
import { SelfReportedMedicationAction, Gender } from './../../redux/types/enums/medicalProfileEnums'
import { IContact, IDependent } from "../../redux/types/interfaces/patientInfoInterfaces";
import { areThereErrors, returnErrorMessage } from "../../core/service/ErrorService";
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { errorsDisplayed, selectShowErrors } from '../../redux/slices/booking/errorSlice';
import { isDefined, isNumber } from '../../core/service/Validators';
import { MeasurementsErrorMessages } from '../../core/service/ErrorMessages';
import { IAddAllergy, IFullAllergy } from "../../components/medicalHistory/MedicationAllergiesDoseSpot";

const BookingMedicalProfileStep = (props: IBookingProps) => {
  const measurements: IMeasurements = useAppSelector(selectMeasurements)
  const previousConditions: Array<IItem> = useAppSelector(selectPreviousConditions)
  const hasPreviousConditions: boolean = useAppSelector(selectHasPreviousConditions)
  const newMedicationAllergies: Array<IAddAllergy> = useAppSelector(selectNewMedicationAllergies)
  const editedMedicationAllergies: Array<IFullAllergy> = useAppSelector(selectEditedMedicationAllergies)
  const deletedMedicationAllergies: Array<IFullAllergy> = useAppSelector(selectDeletedMedicationAllergies)
  const hasMedicationAllergies: boolean = useAppSelector(selectHasMedicationAllergies)
  const selfReportedMedicationsActions: Array<ISelfReportedMedicationsAction> = useAppSelector(selectSelfReportedMedicationsActions)
  const dependent: IDependent | undefined = useAppSelector(selectDependent)
  const contactInfo: IContact | undefined = useAppSelector(selectContactInfo)
  const showErrors = useAppSelector(selectShowErrors);

  const [modal, setModal] = React.useState<React.ReactNode>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [initialMeasurements, setInitialMeasurements] = React.useState<IMeasurements | undefined>(undefined)
  const [initialPreviousConditions, setInitialPreviousConditions] = React.useState<Array<IItem>>([])
  const [initialHasMedicationAllergies, setInitialHasMedicationAllergies] = React.useState<boolean>(false)

  let ftRef = React.useRef<TextField>(null);
  let lbsRef = React.useRef<TextField>(null);
  let inchRef = React.useRef<TextField>(null);
  let pregnantRef = React.useRef<HTMLInputElement>(null);
  let breastfeedingRef = React.useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [])

  let conditions: { name: string, id: number }[] = [];
  let selectedConditions: number[] = [];

  const getData = () => {
    getPatientMeasurments(dependent?.id)
      .then(res => {
        dispatch(measurementsUpdated(modifyMeasurementsResponse(res as IPatientMeasurments)))
        setInitialMeasurements(modifyMeasurementsResponse(res as IPatientMeasurments))
      })
      .catch(error => console.error(error));

    getSelectedConditions(dependent?.id)
      .then(res => {
        selectedConditions = res.symptoms;
        updatePreviousConditions();
      })
      .catch(error => console.error(error));

    getAllConditionsLookup()
      .then(res => {
        conditions = res;
        updatePreviousConditions();
      })
      .catch(error => console.error(error));

    getPreviousConditionsStatus(dependent?.id)
      .then((result: any) => {
        if (result.exist) {
          dispatch(hasPreviousConditionsBooleanChanged(result.hasPreviousConditions))
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getMedicationAllergies(dependent?.id)
      .then((result) => {
        dispatch(medicationAllergiesUpdated(result))
      })
      .catch((error) => {
        console.log(error);
      })

    getMedicationsAllergyStatus(dependent?.id)
      .then((result: any) => {
        if (result.exist === true) {
          dispatch(hasMedicationAllergiesBooleanChanged(result.hasMedicationsAllergy))
          setInitialHasMedicationAllergies(result.hasMedicationsAllergy)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const modifyMeasurementsResponse = (res: IPatientMeasurments): IMeasurements => {
    return {
      ...res,
      gender: modifyGenderValue(res.gender),
      pregnant: res.pregnant || false,
      breastfeeding: res.breastfeeding || false
    }
  }

  const modifyGenderValue = (gender: "f" | "m" | undefined): Gender | undefined => {
    if (gender) {
      if (gender === "m") {
        return Gender.Male
      }
      return Gender.Female
    }
  }

  const updatePreviousConditions = () => {
    const updatedConditions = conditions.map(c => {
      return {
        id: c.id,
        display: c.name,
        checked: selectedConditions.includes(c.id),
        item: c
      }
    })
    dispatch(previousConditionsUpdated(updatedConditions))
    setInitialPreviousConditions(updatedConditions)
  }

  let errorArr: any[] = [];

  const confirmChanges = async () => {
    setIsLoading(true);
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true));
      setIsLoading(false);
    } else {
      dispatch(errorsDisplayed(false))
      const updatingPromises = updateMedicalProfile();
      consumeUpdatingMedicalProfilePromises(updatingPromises);
    }
  };

  const updateMedicalProfile = () => {
    const updateMeasurementsPromise = callUpdateMeasurementsApi()
    const updatePreviousConditionsPromise = callUpdatePreviousConditionsApi()
    const addMedicationAllergiesPromise = callAddMedicationAllergiesApi()
    const updateMedicationAllergiesPromise = callUpdateMedicationAllergiesApi()
    const deleteMedicationAllergiesPromise = callDeleteMedicationAllergiesApi()
    const editMedicationsAllergyStatusPromise = callEditMedicationAllergiesStatusApi()
    const updateSelfReportedMedsPromise = callUpdateSelfReportedMedsApi()
    return [
      updateMeasurementsPromise,
      updatePreviousConditionsPromise,
      addMedicationAllergiesPromise,
      updateMedicationAllergiesPromise,
      deleteMedicationAllergiesPromise,
      editMedicationsAllergyStatusPromise,
      updateSelfReportedMedsPromise
    ]
  }

  const callUpdateMeasurementsApi = async (): Promise<any> => {
    if (shouldMeasurementsUpdate()) {
      const data: IPatientMeasurments = {
        ...measurements,
        gender: measurements.gender === Gender.Male ? 'm' : 'f',
        pregnant: measurements.pregnant || false,
        breastfeeding: measurements.breastfeeding || false
      }
      return await setPatientMeasurments(data, dependent?.id)
        .catch((error) => {
          let err = returnErrorMessage(error);
          toastError(err);
        });
    }
  }

  const shouldMeasurementsUpdate = () => {
    return !_.isEqual(initialMeasurements, measurements)
  }

  const callUpdatePreviousConditionsApi = async (): Promise<any> => {
    if (shouldPreviousConditionsUpdate()) {
      const data: IPatientPreviousConditions = {
        hasPreviousCondition: hasPreviousConditions,
        conditions: previousConditions.filter((v) => v.checked).map((v) => v.id as number)
      }
      return await updatePatientPreviousConditions(data, dependent?.id)
        .catch((error) => { logError(error, 'Previous Conditions cannot be updated!') })
    }
  }

  const shouldPreviousConditionsUpdate = () => {
    return !_.isEqual(initialPreviousConditions, previousConditions)
  }

  const callAddMedicationAllergiesApi = async (): Promise<any> => {
    let addMedicationAllergiesPromise;
    if (newMedicationAllergies.length !== 0) {
      addMedicationAllergiesPromise = addMedicationAllergies(newMedicationAllergies, dependent?.id)
        .catch((error) => {
          let obj: any = Object.values(error)[2]
          logError(error, obj.message)
        })
    }
    return addMedicationAllergiesPromise
  }

  const callUpdateMedicationAllergiesApi = async (): Promise<any> => {
    let updateMedicationAllergiesPromise
    if (editedMedicationAllergies.length !== 0) {
      updateMedicationAllergiesPromise = await updateMedicationAllergies(editedMedicationAllergies, dependent?.id)
        .catch((error) => { console.log(error) })
    }
    return updateMedicationAllergiesPromise
  }

  const callDeleteMedicationAllergiesApi = async (): Promise<any> => {
    let deleteMedicationAllergiesPromise;
    if (deletedMedicationAllergies.length !== 0) {
      deleteMedicationAllergiesPromise = await deleteMedicationAllergies(deletedMedicationAllergies, dependent?.id)
        .catch((error) => { console.log(error) })
    }
    return deleteMedicationAllergiesPromise
  }

  const callEditMedicationAllergiesStatusApi = async (): Promise<any> => {
    if (shouldEditMedicationAllergiesStatusUpdate()) {
      const data = {
        hasMedicationsAllergy: hasMedicationAllergies
      };
      return await editMedicationsAllergyStatus(data.hasMedicationsAllergy, dependent?.id)
        .catch((error) => {
          logError(error, 'Medications Allergies status cannot be changed!');
        })
    }
  }

  const shouldEditMedicationAllergiesStatusUpdate = () => {
    return initialHasMedicationAllergies !== hasMedicationAllergies
  }

  const callUpdateSelfReportedMedsApi = async (): Promise<any> => {
    const updateSelfReportedMedsPromise = selfReportedMedicationsActions.forEach(async (item: ISelfReportedMedicationsAction) => {
      if (item.action === SelfReportedMedicationAction.Add) {
        return await addSelfReportedMedicationService(item.medication, dependent?.id)
          .catch(err => console.log(err))
      } else if (item.medication.id) {
        return await removeSelfReportedMedicationService(item.medication.id, dependent?.id)
          .catch(err => console.log(err))
      }
    });

    return updateSelfReportedMedsPromise
  }

  const logError = (e: any, message?: string) => {
    errorArr.push(e);
    if (errorArr.length > 0) {
      toastError(message ? message : 'Errors encountered');
      console.log(e);
    }
  };

  const consumeUpdatingMedicalProfilePromises = (promises: Array<Promise<any>>) => {
    Promise.all(promises)
      .then(() => {
        dispatch(updatingArraysCleared())
        dispatch(nextStepInitiated())
      })
      .catch(() => {
        toastError("Couldn't save your progress");
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const openPreviousSymptomsModal = () => {
    setModal(
      (
        <PreviousConditionsModalRedux
          closeModal={() => setModal(null)}
        />
      )
    )
  }

  const openMedicationsModal = () => {
    setModal(
      (
        <MedicationsModalRedux
          closeModal={() => setModal(null)}
        />
      )
    )
  }

  const flowNav = (
    <div className="flow-nav-tools">
      <Button
        onClick={() => { dispatch(previousStepInitiated()) }}
        size="lg"
        className="control-nav ml-auto"
        outline
        color="secondary"
      >
        Back
      </Button>
      <span className="text-muted mt-2">Step 3 of 7</span>
      <Button
        color='primary'
        size='lg'
        className='control-nav mr-auto'
        disabled={isLoading}
        onClick={confirmChanges}
      >
        Next
      </Button>
    </div>
  )

  const renderTitle = () => {
    let fullName = ''
    if (dependent) {
      fullName = `${dependent.firstname} ${dependent.lastname}`
    } else {
      fullName = `${contactInfo?.firstname} ${contactInfo?.lastname}`
    }
    return `Book an Appointment for ${fullName}`
  }

  const renderSubTitle = () => {
    let text = ''
    if (dependent) {
      text = `${dependent.firstname}'s`
    } else {
      text = 'your'
    }
    return `Please review ${text} medical profile below and edit any inconsistencies`
  }

  const renderPreviousConditionsUponStatus = () => {
    if (hasPreviousConditions) {
      return renderPreviousConditions()
    } else {
      return renderSpanNone()
    }
  }

  const renderPreviousConditions = () => {
    return previousConditions
      .filter((v) => v.checked)
      .map((con, index, arr) => {
        return (
          <span key={index}>
            {con.display}
            {index + 1 < arr.length && <span className="mr-1">, </span>}
          </span>
        );
      });
  };

  const renderMedicationAllergiesUponStatus = () => {
    if (hasMedicationAllergies) {
      return renderMedicationsAllergyList()
    } else {
      return renderSpanNone()
    }
  }

  const renderMedicationsAllergyList = () => {
    return (
      <MedicationsAllergiesListRedux
        maxHeight={150}
      />
    );
  };

  const renderSpanNone = () => {
    return <span>None</span>
  }

  const renderMeasurements = () => {
    return (
      <>
        <h6 className="mb-2">
          Measurements{" "}
        </h6>
        <fieldset disabled={isLoading}>
          <Form >
            <div className="row">
              <div className="col-md-4">
                <dl>
                  <dt>Height (Ft): <Asterisk /></dt> <dd>
                    {
                      <TextField
                        ref={ftRef}
                        type="number"
                        labelText=""
                        value={measurements.heightFt}
                        textChanged={(text: string) => {
                          dispatch(measurementsUpdated({ ...measurements, heightFt: parseFloat(text) }))
                        }}
                        style={{ padding: 8, width: "50%", marginTop: 10 }}
                      />
                    }
                    <CustomErrorMessage
                      invalid={!isDefined(measurements.heightFt) || !isNumber(measurements.heightFt!)}
                      showValidation={showErrors}
                      errorMessage={MeasurementsErrorMessages.enterHeightFt}
                    />
                  </dd>
                </dl>
              </div>
              <div className="col-md-4">
                <dl>
                  <dt>Height (In): <Asterisk /></dt> <dd>
                    <TextField
                      ref={inchRef}
                      type="number"
                      labelText=""
                      value={measurements.heightIn}
                      textChanged={(text: string) => {
                        dispatch(measurementsUpdated({ ...measurements, heightIn: parseFloat(text) }))
                      }}
                      style={{ padding: 8, width: "50%", marginTop: 10 }}
                    />
                    <CustomErrorMessage
                      invalid={!isDefined(measurements.heightIn) || !isNumber(measurements.heightIn!)}
                      showValidation={showErrors}
                      errorMessage={MeasurementsErrorMessages.enterHeightIn}
                    />
                  </dd>
                </dl>
              </div>
              <div className="col-md-4">
                <dl>
                  <dt>Weight (Lbs): <Asterisk /></dt> <dd>
                    <TextField
                      ref={lbsRef}
                      type="number"
                      labelText=""
                      value={measurements.weight}
                      textChanged={(text: string) => {
                        dispatch(measurementsUpdated({ ...measurements, weight: parseFloat(text) }))
                      }}
                      style={{ padding: 8, width: "50%", marginTop: 10 }}
                    />
                    <CustomErrorMessage
                      invalid={!isDefined(measurements.weight) || !isNumber(measurements.weight!)}
                      showValidation={showErrors}
                      errorMessage={MeasurementsErrorMessages.enterWeight}
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </Form>
        </fieldset>
        <h6 className="mb-3">Gender <Asterisk />
        </h6> <div>
          <fieldset disabled={isLoading}>
            <Form>
              <UncontrolledButtonDropdown>
                <DropdownToggle>{(measurements.gender) || "Set Gender"}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(genderUpdated(Gender.Male))
                      return false;
                    }}
                  >
                    Male
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(genderUpdated(Gender.Female))
                      return false;
                    }}
                  >
                    Female
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              {renderFemaleOption()}
              <CustomErrorMessage showValidation={showErrors} invalid={measurements.gender === undefined} errorMessage='Select Gender'></CustomErrorMessage>
            </Form>
          </fieldset>
        </div>
      </>
    )
  }

  const renderFemaleOption = () => {
    if (measurements.gender === Gender.Female) {
      return (
        <div>
          <FormGroup check inline>
            <div className="custom-checkbox custom-control">
              <input
                onChange={(e) => {
                  dispatch(pregnantUpdated(e.target.checked))
                }}
                ref={pregnantRef}
                id="pregnant"
                type="checkbox"
                checked={measurements.pregnant}
                defaultChecked={measurements.pregnant}
                className="custom-control-input"
              />
              <Label className="custom-control-label" for="pregnant">
                Pregnant
              </Label>
            </div>
          </FormGroup>
          <FormGroup check inline>
            <div className="custom-checkbox custom-control">
              <input
                onChange={(e) => {
                  dispatch(breastfeedingUpdated(e.target.checked))
                }}
                id="breastFeeding"
                ref={breastfeedingRef}
                type="checkbox"
                checked={measurements.breastfeeding}
                defaultChecked={measurements.breastfeeding}
                className="custom-control-input"
              />
              <Label className="custom-control-label" for="breastFeeding">
                Breast Feeding
              </Label>
            </div>
          </FormGroup>
        </div>
      );
    }
  };

  const renderLoadingSpinner = () => {
    if (isLoading) {
      return (
        <LoadingSpinner text='Saving details before proceeding' />
      )
    }
  }

  return (
    <SideCardFrame
      flowNav={flowNav} modal={modal} navItem={navItem} bodyClassName="tc-bg">
      <div className="card-body">
        <div className="d-flex px-2">
          <h5 className="mb-0">
            {renderTitle()}
          </h5>
          <Link
            to={Routes.homePatient}
            className="btn btn-link btn-sm ml-auto"
          >
            Cancel
          </Link>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <div className="container">
          <h5 className="card-title">
            {renderSubTitle()}
          </h5>
          <div className="mt-3">
            {renderMeasurements()}
            <hr></hr>
            <h6 className="mb-3">
              Previous Conditions{" "}
              <Button
                disabled={isLoading}
                onClick={(e) => { e.preventDefault(); openPreviousSymptomsModal(); return false; }}
                data-toggle="modal"
                data-target="#updateMedHistoryModal"
                data-med-history-type="Symptoms"
                className="btn btn-sm btn-link"
                color="link"
              >
                Add
              </Button>
            </h6>
            <div>
              {renderPreviousConditionsUponStatus()}
            </div>
            <hr></hr>
          </div>
          <h6 className="mb-3">
            Medication Allergies{" "}
            <Button disabled={isLoading} onClick={openMedicationsModal} color="link" size="sm" data-toggle="modal" data-target="#medsAllergicModal"
              data-med-history-type="Medication Allergies">
              Add
            </Button>
          </h6>
          <div className="w-75">
            <ul className="list-group list-group-flush">
              {renderMedicationAllergiesUponStatus()}
            </ul>
          </div>
          <hr></hr>
          <SelfReportedMedicationsRedux isLoading={isLoading} />
          <div className="mt-4">
            {renderLoadingSpinner()}
          </div>
        </div>
      </div>
    </SideCardFrame>
  );
}

export default BookingMedicalProfileStep;