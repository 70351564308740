import * as React from 'react';
import { Routes } from '../../config/Routes';
import { Link } from '@reach/router';
import {
  Card,
  CardBody,
  Button,
  Collapse,
  FormGroup,
  Input,
  Label,
  CardTitle,
  Container,
  Row,
  Col,
  Form,
  FormFeedback,
} from 'reactstrap';
import { IBookingProps } from './AppointmentModels';
import Asterisk from '../../components/commons/Asterisk';
import { SideCardFrame } from '../../components/layout/SideCardFrame';
import { navItem } from '../../App';
import 'react-datepicker/dist/react-datepicker.css';
import { BootstrapTextColor, IRadioButtonProps } from '../../components/layout/RadioButton';
import RadioGroup from '../../components/layout/RadioGroup';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  contactTypeSelected,
  scheduledTimeTypeSelected,
  phoneNumberUpdated,
  selectContactType,
  selectPhone,
  selectScheduledTimeType,
  selectDate
} from '../../redux/slices/booking/timeAndContactTypeStepSlice'
import {
  whenSectionUpdated,
  contactSectionUpdated,
  nextStepInitiated,
  previousStepInitiated
} from '../../redux/slices/booking/bookingSlice';
import { IContact as ICreateApptPayloadContact, IWhen } from '../../redux/types/interfaces/bookingInterfaces';
import { selectAddress, selectContactInfo } from '../../redux/slices/general/patientInfoSlice';
import { selectDependent } from '../../redux/slices/booking/choosePatientStepSlice';
import { ScheduledTimeType, ContactType } from '../../redux/types/enums/bookingEnums';
import { IAddress } from '../../redux/types/interfaces/patientInfoInterfaces';
import { IContact, IDependent } from '../../redux/types/interfaces/patientInfoInterfaces';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import { BookingTimeContactErrorMessages, ContactErrorMessages } from '../../core/service/ErrorMessages';
import { areThereErrors } from '../../core/service/ErrorService';
import { errorsDisplayed, selectShowErrors } from '../../redux/slices/booking/errorSlice';
import { isDefined, isInputDefined, isPhoneNumberValid } from '../../core/service/Validators';
import { DateTimeComponent } from '../../components/layout/DateTimeComponent';

const BookingTimeContactStep = ({
  smallCard,
}: IBookingProps) => {
  const contactType: ContactType | undefined = useAppSelector(selectContactType)
  const phoneNumber: string = useAppSelector(selectPhone)
  const date: Date | undefined = useAppSelector(selectDate)
  const scheduledTimeType: ScheduledTimeType | undefined = useAppSelector(selectScheduledTimeType)
  const patientAddress: IAddress | undefined = useAppSelector(selectAddress)
  const contactInfo: IContact | undefined = useAppSelector(selectContactInfo)
  const dependent: IDependent | undefined = useAppSelector(selectDependent)
  const showErrors = useAppSelector(selectShowErrors);

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (contactInfo.phone) {
      dispatch(phoneNumberUpdated(contactInfo.phone))
    }
  }, [])

  const getContactTypeRadioButtons = (): Array<IRadioButtonProps> => {
    return [
      {
        name: 'contactType',
        id: 'contactTypePhone',
        checked: contactType === ContactType.Phone,
        icon: 'fas fa-phone fa-stack-1x',
        color: BootstrapTextColor.Primary,
        label: 'Phone Consultation',
        onChange: handleContactByPhoneSelected
      },
      {
        name: 'contactType',
        id: 'contactTypeVideo',
        checked: contactType === ContactType.Conference,
        icon: 'fas fa-video fa-stack-1x',
        color: BootstrapTextColor.Primary,
        label: 'Video Conferencing',
        onChange: handleContactByConferenceSelected
      }
    ]
  }

  const handleContactByPhoneSelected = () => {
    dispatch(contactTypeSelected(ContactType.Phone))
  }

  const handleContactByConferenceSelected = () => {
    dispatch(contactTypeSelected(ContactType.Conference))
  }

  const getTimeRadioButtons = (): Array<IRadioButtonProps> => {
    return [
      {
        name: 'contactTiming',
        id: 'contactTimingNow',
        checked: isScheduledTimeIsNow(),
        icon: 'fas fa-user-check fa-stack-1x',
        color: BootstrapTextColor.Primary,
        label: 'As Soon as Doctor Available',
        onChange: handleTimeNowSelected
      },
      {
        name: 'contactTiming',
        id: 'contactTimingLater',
        checked: isScheduledTimeIsLater(),
        icon: 'fas fa-user-clock fa-stack-1x',
        color: BootstrapTextColor.Primary,
        label: 'Schedule for Later',
        onChange: handleTimeLaterSelected
      }
    ]
  }

  const isScheduledTimeIsNow = () => {
    return scheduledTimeType === ScheduledTimeType.Now
  }

  const isScheduledTimeIsLater = () => {
    return scheduledTimeType === ScheduledTimeType.Later
  }

  const handleTimeNowSelected = () => {
    dispatch(scheduledTimeTypeSelected(ScheduledTimeType.Now))
  }

  const handleTimeLaterSelected = () => {
    dispatch(scheduledTimeTypeSelected(ScheduledTimeType.Later))
  }

  const updateCreateApptPayload = () => {
    const updatedContactSection: ICreateApptPayloadContact = {
      type: contactType!,
      phone: phoneNumber || ''
    }
    const updatedWhenSection: IWhen = getUpdatedWhenSection()
    dispatch(contactSectionUpdated(updatedContactSection))
    dispatch(whenSectionUpdated(updatedWhenSection))
  }

  const getUpdatedWhenSection = (): IWhen => {
    let time: ScheduledTimeType.Now | Date | null = null;
    if (isScheduledTimeIsNow()) {
      time = ScheduledTimeType.Now
    } else if (isScheduledTimeIsLater()) {
      time = date ?? new Date()
    }
    return { time }
  }

  const handleNextClicked = () => {
    if (areThereErrors()) {
      dispatch(errorsDisplayed(true))
    }
    else {
      dispatch(errorsDisplayed(false))
      updateCreateApptPayload()
      dispatch(nextStepInitiated())
    }
  }
  const renderPhoneNumbereErrorMessage = () => {
    if (!isInputDefined(phoneNumber)) {
      return ContactErrorMessages.enterPhone
    } else if (!isPhoneNumberValid(phoneNumber)) {
      return ContactErrorMessages.invalidPhoneNumber
    }
  }
  const renderFlowNav = () => {
    return (
      <div className="flow-nav-tools">
        <Button
          onClick={() => { dispatch(previousStepInitiated()) }}
          size="lg"
          className="control-nav ml-auto"
          outline
          color="secondary"
        >
          Back
        </Button>
        <span className="text-muted mt-2">
          Step 5 of 7
        </span>
        <Button
          color="primary"
          size="lg"
          className="control-nav mr-auto"
          onClick={handleNextClicked}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderTitle = () => {
    let fullName = ''
    if (dependent) {
      fullName = `${dependent.firstname} ${dependent.lastname}`
    } else {
      fullName = `${contactInfo?.firstname} ${contactInfo?.lastname}`
    }
    return `Book an Appointment for ${fullName}`
  }

  const renderPhoneNumberField = () => {
    return (
      <Form>
        <div>
          <FormGroup>
            <Label>Please enter your mobile phone number:</Label>
            <Asterisk />
            <Row><Col md="1" className="mt-2 mr-.25 pr-0"><h6>+1</h6></Col>
              <Col md="8"  >
                <Input
                  type="tel"
                  className="form-control"
                  name="patientPhoneNumberInput"
                  placeholder="Enter phone number (Ex. 888-555-1212)"
                  value={phoneNumber}
                  invalid={(!isInputDefined(phoneNumber) || !isPhoneNumberValid(phoneNumber)) && showErrors}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(phoneNumberUpdated(e.target.value))
                  }}
                />
                <FormFeedback hidden={isInputDefined(phoneNumber) && isPhoneNumberValid(phoneNumber)} className='error'>
                  {renderPhoneNumbereErrorMessage()}
                </FormFeedback>
              </Col>
            </Row>
          </FormGroup>
        </div>
      </Form>
    );
  };

  const renderPreferredTimeField = () => {
    if (isScheduledTimeIsLater()) {
      return renderDateField()
    } else if (isScheduledTimeIsNow()) {
      return renderContactNowText()
    }
  }

  const renderDateField = () => {
    return (
      <div>
        <div className='mt-3 w-75' id='contactLaterSelected'>
          <Label>
            Enter a preferred date and time request for the physician:
          </Label>
          <Asterisk />
          <div className='form-label-group-datePicker'>
          <div>
            <DateTimeComponent 
            />
          </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContactNowText = () => {
    return (
      <div>
        <div className='mt-3 w-75'>
          <Label style={{ fontSize: '1.05em' }}>
            Doctor will call back within 30-60 minutes if booking is done from 7 am till 9 pm CTZ,
            otherwise it may take little bit longer
          </Label>
        </div>
      </div>
    )
  }

  return (
    <SideCardFrame
      flowNav={renderFlowNav()}
      navItem={navItem}
      bodyClassName="tc-bg"
      smallCard={smallCard}
    >
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">
            {renderTitle()}
          </h5>
          <Link
            to={Routes.homePatient}
            className="btn btn-link btn-sm ml-auto"
          >
            Cancel
          </Link>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <Container>
          <CardTitle>
            How would you prefer to be contacted:<Asterisk />
          </CardTitle>
          <div className="radio-tile-group">
            <RadioGroup
              radioButtons={getContactTypeRadioButtons()}
            />
            <CustomErrorMessage invalid={!isDefined(contactType)} showValidation={showErrors} errorMessage={BookingTimeContactErrorMessages.selectContactType} />
          </div>
          <Collapse isOpen={true}>
            <Card style={{ border: 'none', marginTop: '10px' }}>
              <CardBody className='pl-1 pt-1 pb-2'>
                {renderPhoneNumberField()}
              </CardBody>
            </Card>
          </Collapse>
          <hr className="mt-2 mb-4"></hr>
          <FormGroup>
            <CardTitle>
              Choose your preferred time to meet with a physican:<Asterisk />
            </CardTitle>
          </FormGroup>
          <div className='radio-tile-group mb-0'>
            <RadioGroup
              radioButtons={getTimeRadioButtons()}
            />
          </div>
          <Collapse isOpen={scheduledTimeType !== undefined}>
            <Card className='border-0'>
              <CardBody className='pl-1 pt-1'>
                {renderPreferredTimeField()}
              </CardBody>
            </Card>
          </Collapse>
          <CustomErrorMessage invalid={!isDefined(scheduledTimeType)} showValidation={showErrors} errorMessage={BookingTimeContactErrorMessages.choosePreferredTime} />
        </Container>
      </CardBody>
    </SideCardFrame>
  );
}

export default BookingTimeContactStep