import React, { useEffect, useRef, useState } from "react";
import {
  Row, Col, FormGroup, Input, Label, Form, Button, Modal, ModalHeader, ModalBody,
  ModalFooter, Alert, ListGroup, ListGroupItemHeading, ListGroupItemText, UncontrolledCollapse, ListGroupItem
} from "reactstrap";
import _ from "lodash";
import { IPatientInfoSettings, ISearchPharmacyParams, IPharmacySearch } from "../../../components/patientSettings/PatientSettings";
import { requestPatientInfo, searchPharmacy, LoadingState } from "../../../core/service/services";
import { LoadingButton } from "../../../components/commons/LoadingButton";
import { toastError } from "../../../App";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { pharmacyUpdated, selectPharmacy } from '../../../redux/slices/general/patientInfoSlice';
import { selectAvailableStates } from '../../../redux/slices/general/USAStatesSlice';
import { IPharmacy } from "../../../redux/types/interfaces/patientInfoInterfaces";
import { I_USAState } from '../../../redux/types/interfaces/otherInterfaces';
import { returnErrorMessage } from "../../../core/service/ErrorService";
import CustomErrorMessage from '../../../components/CustomErrorMessage';
import { errorsDisplayed, selectShowErrors } from '../../../redux/slices/booking/errorSlice';

interface IProps { }

export const PreferredPharmacyRedux = (props: IProps) => {
  const currentPharmacy: IPharmacy | undefined = useAppSelector(selectPharmacy)
  const availableStates: Array<I_USAState> = useAppSelector(selectAvailableStates)
  const showErrors = useAppSelector(selectShowErrors);

  const [searchValues, setSearchValues] = useState<ISearchPharmacyParams>({});
  const [pharmacies, setPharmacies] = useState<IPharmacySearch[]>();
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.nuteral);
  const [submitLoading, setSubmitLoadding] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const toggle = () => setIsModalOpen(!isModalOpen);

  const dispatch = useAppDispatch()

  const initializeStates = () => {
    setSearchValues({});
    setPharmacies([]);
    setIsLoading(LoadingState.nuteral);
  };

  const onClickEditButton = (e: any) => {
    e.preventDefault();
    toggle();
  };

  const onClickSearchButton = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search(e);
  };

  useEffect(() => {
    if (listRef.current && observer.current === null) {
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            loadMore(); // Pass currentPage to loadMore
          }
        },
        { threshold: 1.0 }
      );
      observer.current.observe(listRef.current);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current=null

      }
    };
  }, [hasMore,page]);

  const loadMore = () => {
    searchPharmacy(false, {...searchValues,pageNumber:page}).then((result) => {
      setPharmacies([...pharmacies!,...result.items]);
      if(result.totalPages==page){
        setHasMore(false)
      }
      else{
        setHasMore(true)
        setPage(prevPage => prevPage + 1);
      }
  
    })
  };
  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!_.isEmpty(searchValues)) {
      setIsLoading(LoadingState.loading);
      searchPharmacy(false, searchValues).then((result) => {
        if(result.pageNum===result.totalPages){
          setHasMore(false)
        }else{
          setHasMore(true)
          setPage(prevPage => prevPage + 1);
        }
        setPharmacies(result.items);
        result.items.length > 0
          ? setIsLoading(LoadingState.succeed)
          : setIsLoading(LoadingState.failed);
      }).catch(() => {
        setIsLoading(LoadingState.failed);
      });
    } else {
      toastError("Please Enter at least one field!")
    }
  };

  const submitPharmacy = (chosenPharmacy: IPharmacySearch) => {
    let patientInfo: IPatientInfoSettings = {};
    setSubmitLoadding(true);
    patientInfo.pharmacy = {
      ...chosenPharmacy,
    };
    requestPatientInfo("POST", patientInfo)
      .then((res) => {
        afterSubmit(res);
      })
      .catch((err) => {
        toastError(returnErrorMessage(err));
        setSubmitLoadding(false);
      });
  };

  const afterSubmit = (result: IPatientInfoSettings) => {
    dispatch(pharmacyUpdated(result.pharmacy))
    setSubmitLoadding(false);
    initializeStates();
    toggle();
  };

  const onCancelClick = () => {
    toggle();
    initializeStates();
  }

  const renderEditButton = () => {
    return (
      <Button color="link" size="sm" className="pt-0" onClick={onClickEditButton}>
        <span className="sr-only">Edit</span>{" "}
        <i className="fa fa-edit fa-lg"></i>
      </Button>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={isModalOpen} toggle={toggle} className="preferedPharmacy" size="lg">
          <ModalHeader toggle={toggle}>
            <h5>Search for Pharmacy</h5>
            <small>Search using any of the criteria below</small>
          </ModalHeader>
          <ModalBody>
            {renderForm()}
            {renderLoadingState()}
            {renderSearchItems()}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <fieldset disabled={isLoading === LoadingState.loading}>
        <Form id="preferredPharmacy" onSubmit={(e) => onClickSearchButton(e)}>
          <Row form>
            <Col md={4}>
              <FormGroup className="form-label-group">
                <Label for="exampleCity">City</Label>
                <Input type="text" name="city" id="exampleCity" onChange={(e) => setSearchValues({ ...searchValues, city: e.target.value })} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Input defaultValue="" type="select" name="state" id="exampleState"
                  onChange={(e) => setSearchValues({ ...searchValues, state: e.target.value })}
                >
                  <option value=''>
                    State
                  </option>
                  {renderStates()}
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-label-group">
                <Label for="exampleZip">Zip</Label>
                <Input type="text" name="zip" id="exampleZip" onChange={(e) => setSearchValues({ ...searchValues, zip: e.target.value })} />
              </FormGroup>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#toggler">
            <Row>
              <Col sm="6" mb="3">
                <FormGroup className="form-label-group">
                  <Input type="text" id="name" name="name" onChange={(e) => setSearchValues({ ...searchValues, name: e.target.value })}></Input>
                  <Label for="name">Pharmacy Name</Label>
                </FormGroup>
              </Col>
              <Col sm="6" mb="3">
                <FormGroup className="form-label-group">
                  <Input type="tel" id="phone" name="phone" placeholder="312-555-1212"
                    onChange={(e) => setSearchValues({ ...searchValues, phoneOrFax: parseInt(e.target.value) })}
                  ></Input>
                  <Label for="phone">Pharmacy Phone or Fax</Label>
                </FormGroup>
              </Col>
              <Col sm="12" mb="3" md="6">
                <FormGroup className="form-label-group">
                  <Input type="text" id="address" name="address" onChange={(e) => setSearchValues({ ...searchValues, address: e.target.value })}></Input>
                  <Label for="preferredPharamacyLocation">Pharmacy Address</Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="speciality">Select a Specialty</Label>
                  <Input type="select" name="specialty" id="speciality" onChange={(e) => setSearchValues({ ...searchValues, specialty: e.target.value })}>
                    <option value="">None</option>
                    <option value="Retail">Retail</option>
                    <option value="EPCS">EPCS</option>
                    <option value="TwentyFourHourPharmacy">
                      24 Hour Pharmacy
                    </option>
                    <option value="LongTermCarePharmacy">
                      Long-Term Care Pharmacy
                    </option>
                    <option value="MailOrder">Mail Order</option>
                    <option value="SpecialtyPharmacy">Specialty Pharmacy</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </UncontrolledCollapse>
          <div style={{ textAlign: "center" }}>
            <Button color="link" size="sm" id="toggler">
              <i className="fas fa-chevron-circle-down"></i> Advanced Search
            </Button>
          </div>
          <LoadingButton size="sm" isLoading={isLoading === LoadingState.loading} type="submit" for="preferredPharmacy" color="primary"
            block={true} className="w-50 m-auto text-uppercase mt-4">
            Search
          </LoadingButton>
        </Form>
      </fieldset>
    );
  };

  const renderStates = () => {
    return availableStates.map((state, idx) => {
      return (
        <option key={idx} value={state.shortName}>
          {state.name}
        </option>
      );
    });
  };

  const renderLoadingState = () => {
    if (isLoading === LoadingState.failed) {
      return (
        <Alert className="mt-2" color="danger">
          No matching found, please try again!
        </Alert>
      );
    }
    return null;
  };


  const renderSearchItems = () => {
    let content: any[] = new Array();
    pharmacies?.map((record) => {
      content.push(
        <ListGroupItem disabled={submitLoading} color="primary" action
          onClick={(e) => {
            e.preventDefault();
            submitPharmacy(record);
          }}
        >
          <ListGroupItemHeading>{record.name}</ListGroupItemHeading>
          <ListGroupItemText>
            {record.address}
            <br />
            {record.city}
            <br />
            {record.phone}
          </ListGroupItemText>
        </ListGroupItem>
      );
    });

    return (
      <ListGroup
        className={
          "mt-2 p-2" + (LoadingState.succeed != isLoading ? " d-none" : "")
        }
        style={{ maxHeight: "20rem", overflow: "auto" }}
      >
        <br />
        {content}
        <div ref={listRef} />

      </ListGroup>
    );
  };

  const renderCurrentPharmacy = () => {
    if (currentPharmacy) {
      return (
        <dl>
          <dt>
            <Label>{currentPharmacy.name}</Label>
          </dt>
          <dd>{currentPharmacy.address}</dd>
          <dd>{currentPharmacy.phone}</dd>
        </dl>
      )
    }
  };

  return (
    <div>
      <h6 className="mb-3">
        Pharmacy
        {renderEditButton()}
      </h6>
      {renderModal()}
      {renderCurrentPharmacy()}
      <CustomErrorMessage invalid={currentPharmacy === undefined} showValidation={showErrors} errorMessage='Please Select Preferred Pharmacy'></CustomErrorMessage>
    </div>
  );
};
export default PreferredPharmacyRedux;
