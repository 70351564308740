import React, { useEffect, useState } from 'react'
import { bookAppointment } from './HomePatient'
import { CardTitle, ListGroup, ListGroupItem } from 'reactstrap'
import { IAppointmentInfo } from '../../book-appointment/AppointmentModels'
import { getUpcomingAppointments, getAppointmentInfoForPatient } from '../../../core/service/services'
import { AppointmentListingItemCard } from '../appointment/AppointmentListingItemCard'
import { IMessage } from '../../chat-module/MessageModel'

interface IProps {
  onChatOpened: (appointmentId: string, receiverName: string) => void;
  addedMessage?: IMessage,
  onMessageReceived?: (msg: IMessage) => void,
   updatedAppointment?: {id: string, count: number}

}

export const UpcomingAppointments: React.FC<IProps> = (props) => {

  const [upcomingAppointments, setUpcomingAppointments] = useState<IAppointmentInfo[]>([])

  const getData = () => {
    getUpcomingAppointments().then(res => {
      setUpcomingAppointments(res.items) //res)
    }).catch(err => console.log(err))

  }

  const renderHeader = () => (
    <div className="section-content-header d-flex flex-nowrap">
      <div className="order-1 mr-auto">
        <h5 className="text-muted">
          <span>Upcoming Appointments</span>
        </h5>
      </div>
    </div>
  )

  const removeItem = (itemId: string) => {
    let itemsList = upcomingAppointments
    const index = getAppointmentIdx(itemId);
    if (index === -1) {
      return
    }
    itemsList.splice(index, 1)
    //bad implementation .. to be enhanced
    setUpcomingAppointments([])
    setUpcomingAppointments(itemsList)
  }

  const updateItem = (newItem: IAppointmentInfo) => {
    let itemsList = upcomingAppointments
    const index = getAppointmentIdx(newItem.appointmentId);
    if (index === -1) {
      return
    }
    itemsList[index] = newItem
    //bad implementation .. to be enhanced
    setUpcomingAppointments([])
    setUpcomingAppointments(itemsList)
  }

  const getAppointmentIdx = (appointmentId: string) => {
    for (let i = 0; i < upcomingAppointments.length; i++) {
      const currentItem = upcomingAppointments[i]
      if (currentItem.appointmentId === appointmentId) {
        return i
      }
    }
    return -1
  }

  useEffect(() => {
      getData()
  }, [])

  useEffect(() => {
    if (props.updatedAppointment) {
      getAppointmentInfoForPatient(props.updatedAppointment.id).then(res => {
        updateItem(res)
      }).catch(err => {
        //appointment should be removed
        removeItem(props.updatedAppointment?.id || "")
      })
    }
  }, [props.updatedAppointment])

  const appItemStyle = (status: string) => {
    let style = {borderTopWidth: "thin", borderColor: "#007bff"}
    if (status === "Locked" || status === "Started") {
      style.borderColor = "green"
    }
    return style
  }

  return (
    <>
    {renderHeader()}
    <div>
        {upcomingAppointments.length > 0 ?
        <ListGroup className="timeline upcoming pre-appt-scheduled">
          {upcomingAppointments.map((item, key)=> (
            <ListGroupItem style={appItemStyle(item.status)} key={key}>
              <AppointmentListingItemCard key={item.appointmentId} appointment={item} onChatOpened={props.onChatOpened}  onMessageReceived={props.onMessageReceived} addedMessage={props.addedMessage} 
                updateItem={updateItem} removeItem={removeItem}/>
            </ListGroupItem>
          ))}
        </ListGroup>
        :
        <CardTitle className="mb-3 pl-3">
          No appointments scheduled.{' '}
          <a href="#" onClick={bookAppointment}>
            Book Appointment
          </a>
        </CardTitle>
        }
      </div>
    </>
  )
}


