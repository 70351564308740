import React, { useState } from 'react';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';
import { CardBody, Form, Button } from 'reactstrap';
import { TextField } from '../../components/commons/TextField';
import { patientAuthenticationByAgent } from '../../core/service/services';
import { RouteComponentProps, navigate } from '@reach/router';
import { Routes } from '../../config/Routes';
import { toastSuccess, toastError } from '../../App';
import { UserConfig } from '../../config/userConfig';

export interface IProps extends RouteComponentProps {
  email?: string;
}

export const PatientAuthentication = (props:IProps) => {

  const inputElementRef = React.useRef<TextField>(null);
  const [verificationState, setVerificationState] = useState<{ loading: boolean, errorMessage: string }>({ loading: false, errorMessage: '' });
  const submitCode = (e: React.FormEvent) => {
    e.preventDefault()
    let authCode = inputElementRef.current?.getValue();
    if (!authCode) {
      authCode = '';
      setVerificationState({ ...verificationState, errorMessage: 'Please Enter the code!' })
    } else {
      setVerificationState({ loading: true, errorMessage: '' });
      let email = UserConfig.getEmailPatientRegisteredByAgent();
      patientAuthenticationByAgent({code: authCode, email: email})
        .then((result) => {
          setVerificationState({ loading: false, errorMessage: '' });
          UserConfig.setemailPatientRegisteredByAgent(null);
          toastSuccess("Patient Activated!")
          navigate(Routes.homeAgent);
        })
        .catch((error) => {
          setVerificationState({ loading: false, errorMessage: '' })
          toastError('Enter valid code');
        });
    }
  }
  return (
    <SmallCardFrame navItem bodyClassName="tc-bg" >
      <CardBody>
        <div className="pre-password-reset">
          <h5 className="card-title text-muted mb-1">Registered Successfully <i className="fas fa-check-circle fa-1x"></i></h5>
          <p>Please type the 4-digits authentication code sent to user mailbox:</p>
          <hr />
          <Form onSubmit={submitCode}>
            <TextField ref={inputElementRef} type="text" labelText="Authentication Code" />
            <div className="text-center mb-4 mt-4" style={{ color: "red" }}>
              {verificationState.errorMessage}
            </div>
            <Button type="submit" color="primary" size="lg" className="w-50 m-auto text-uppercase btn-forgot-pw d-block mt-4">
              {verificationState.loading ? <><span className="spinner-border spinner-border-sm"
                role="status" aria-hidden="true"></span> Loading... </> : <> Submit Code </>}
            </Button>
          </Form>
        </div>
      </CardBody>
    </SmallCardFrame>
  )
}