import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { ContactType, ScheduledTimeType } from '../../types/enums/bookingEnums'
import { bookingCleared } from './bookingSlice';
export interface ITimeAndContactTypeStepState {
   contactType: ContactType | undefined;
   scheduledTimeType: ScheduledTimeType | undefined;
   phoneNumber: string;
   date?: Date;
}

const initialState: ITimeAndContactTypeStepState = {
   contactType: undefined,
   scheduledTimeType: undefined,
   phoneNumber: ""
}

const timeAndContactTypeStepSlice = createSlice({
   name: 'timeAndContactTypeStep',
   initialState,
   reducers: {
      contactTypeSelected: (state, action: PayloadAction<ContactType>) => {
         state.contactType = action.payload;
      },
      scheduledTimeTypeSelected: (state, action: PayloadAction<ScheduledTimeType>) => {
         state.scheduledTimeType = action.payload;
      },
      phoneNumberUpdated: (state, action: PayloadAction<string>) => {
         state.phoneNumber = action.payload;
      },
      dateChosen: (state, action: PayloadAction<Date | undefined>) => {
         state.date = action.payload;
      }
   },
   extraReducers: (builder: ActionReducerMapBuilder<ITimeAndContactTypeStepState>) => {
      builder.addCase(bookingCleared, () => {
         return initialState
      })
   }
})

export const {
   contactTypeSelected,
   scheduledTimeTypeSelected,
   phoneNumberUpdated,
   dateChosen
} = timeAndContactTypeStepSlice.actions

export const selectContactType = (state: RootState) => state.timeAndContactTypeStep.contactType
export const selectPhone = (state: RootState) => state.timeAndContactTypeStep.phoneNumber
export const selectScheduledTimeType = (state: RootState) => state.timeAndContactTypeStep.scheduledTimeType
export const selectDate = (state: RootState) => state.timeAndContactTypeStep.date

export default timeAndContactTypeStepSlice.reducer
