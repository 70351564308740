import React, { useState, useEffect } from 'react'
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { Routes } from '../../../config/Routes';
import { Button, CardBody, Table } from 'reactstrap';
import { BigCardFrame } from '../../../components/layout/BigCardFrame';
import { localizeTimezone } from '../../../core/service/TimeZone';
import { navItem } from '../../../App';
import { getAgentHistory } from '../../../core/service/services';
import { CustomedPagination } from './Pagination';
import { appendPrefix } from '../../../core/service/PatientIdPrefixGenerator';

interface IProps extends RouteComponentProps {}
export interface IAgentAuditLogPreview {
    appointmentId: string | null;
    patientName: string | null;
    action: string;
    agentId: number;
    timestamp: Date;
    dependentName?: string;
    dependentId?: number;
    patientId?:number;
}

export const AgentHistory: React.FC<IProps> = (props) => {

  const [historyItems, setHistoryItems] = useState<IAgentAuditLogPreview[]>([])
  const [agentId, setAgentId] = useState<number>()
  const [agentName, setAgentName] = useState<string>("");
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    const search = new URLSearchParams(props.location?.search);
    const agentId = search.get("agentId");
    const name = search.get('name');
    name && setAgentName(name);
    if (agentId !== null) {
        let agentIdNum = Number.parseInt(agentId);
        setAgentId(agentIdNum);
        fetchHistory(agentIdNum, pageIndex, pageSize)
    }
  }, [])
  
  const fetchHistory = (id:number, index:number, size:number)=>{
    getAgentHistory(id, index, size).then((result)=>{
      setHistoryItems(result.logs);
      result.pageSize != 0 && setPageCount(Math.ceil(result.count/result.pageSize))
      setPageIndex(result.pageIndex)
      setPageSize(result.pageSize)

    }).catch((e)=>console.log(e));
    
  }
  const flowNav = (
    <div className="flow-nav-tools">
      <Button color="primary" size="lg" onClick={() => navigate(Routes.adminConsole)} className="control-nav">
        Back
      </Button>
    </div>
  );

  return (
    <BigCardFrame
      flowNav={flowNav}
      navItem={navItem}
      bodyClassName="tc-bg"
      horizontal={true}
    > 
      <CardBody>
        <div className="d-flex px-2">
          <h5 className="mb-0">Agent History: {agentName}</h5>
          <Link to={Routes.adminConsole} className="btn-sm ml-auto">
            Back
          </Link>
        </div>
        <hr className="mt-2 mb-4" />
        <Table>
          <thead>
            <th>Timestamp</th>
            <th>Action</th>
            <th>On behalf of</th>
            <th>Patient ID</th>
          </thead>
          <tbody>
            {historyItems.map(item => (
              <tr>
                <td>{localizeTimezone(item.timestamp.toString(),'ddd YYYY-MM-DD HH:mm:ss')}</td>
                <td>{item.action} {(item.dependentName&&item.dependentId )? `: ${item.dependentName} ${appendPrefix(item.dependentId)}`:''} </td>
                <td>{item.patientName||""}</td>
                <td>{item.patientId && appendPrefix(item.patientId)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
      { historyItems.length > 0 && pageCount &&
          <CustomedPagination
            pageCount={pageCount}
            pageNum={pageIndex}
            onPaginate={(idx: any) => { setPageIndex(idx); agentId && fetchHistory(agentId, idx, 10) }}
          />}
    </BigCardFrame>
  )
}