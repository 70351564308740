import React, { useState } from "react";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { IFullAllergy } from "./MedicationAllergiesDoseSpot";
import EditAllergyModal from "./EditAllergyModal";
export interface IMedicalAllergyProps {
  medicationsAllergy?: any;
  onRemove: any;
  maxHeight: number;
  onEdit:any;
}
const MedicationsAllergiesList = (
  props: IMedicalAllergyProps
)=> {
  const [selectedMedication, setSelectedMedication] = useState<IFullAllergy>();
  const [editAllergyModal, setEditAllergyModal] = useState(false);
  const toggleEditAllergyModal = () => setEditAllergyModal(!editAllergyModal);

  const onSaveEditedMedication=(e:any)=>{
    props.onEdit(e);
    toggleEditAllergyModal();
  } 

  return (   
    <ListGroup style={{ maxHeight: props.maxHeight, overflow: "auto" }}>
      {props.medicationsAllergy?.map((medication: any, i:number) => {
        return (
          <div key={i}>
          {editAllergyModal && selectedMedication?.id===medication.id?<EditAllergyModal modal={editAllergyModal} data={selectedMedication} toggle={toggleEditAllergyModal} onSave={(e:any)=>{onSaveEditedMedication(e)}}/>:<></>}
          <ListGroupItem>
            <span> {medication.Name}</span>

            <Button
              type="button"
              color="link"
              data-toggle="tooltip"
              className="btn btn-link text-muted ml-auto"
              data-original-title="Delete Medication"
              onClick={() => props.onRemove(medication)}
              style={{float: "right"}}
            >
              <span className="sr-only">Remove</span>{" "}
              <i className="fa fa-trash"></i>
            </Button>
            <Button
              type="button"
              color="link"
              data-toggle="tooltip"
              className="btn btn-link text-muted ml-auto"
              data-original-title="Edit Medication"
              onClick={()=>{toggleEditAllergyModal(); setSelectedMedication(medication);} }
              style={{float: "right"}}
            >
              <span className="sr-only">Edit</span>{" "}
              <i className="fa fa-edit"></i>
            </Button>
          </ListGroupItem>
          </div>
        );
      })}
    </ListGroup>
    
  );
};
export default MedicationsAllergiesList;
