import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SmallCardFrame } from '../../components/layout/SmallCardFrame';
import './style.css';
import { LandingPageHeader } from '../LandingPageHeader';
interface IProps extends RouteComponentProps {}
export const ErrorNotFoundPage = (props:IProps) => {
	return (

		<SmallCardFrame navItem={<LandingPageHeader />} bodyClassName="tc-bg">
		<div className="card-body">

			{/* <div id="notfound"> */}
			<div className="notfound">
				<div className="notfound-404">
					<h1>4<span>0</span>4</h1>
				</div>
				<h2>the page you requested could not be found<br /> </h2>
			</div>
			</div>
		{/* </div> */}
		</SmallCardFrame>
	)
}