import * as React from "react";
import { Table, Container } from "reactstrap";
import Footer from "../components/layout/Footer";
import TCNavbar from "../components/layout/TCNavBar";
import { LandingPageHeader } from "../page/LandingPageHeader";
import { Helmet } from "react-helmet";
export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact - TelMDCare</title>
      </Helmet>
      <TCNavbar bodyClassName="tc-bg"/>
      <h3 className="text-white mx-3" style={{ textAlign: "center" }}>
        Contact us
      </h3>
      <Container>
      <Table borderless style={{ width: "100%", color: "white"}} className="m-4">
        <tbody>
          <tr>
            <th>
              <i className="fa fa-phone-alt"></i> <span>Toll Free number</span>
            </th>
            <td><a href="tel:+1-800-230-2050" style={{color:"white"}}>+1-800-230-2050</a></td>
          </tr>
          <tr>
            <th>
              <i className="fa fa-fax"></i> <span>Fax Number</span>
            </th>
            <td>630-480-4567</td>
          </tr>
          <tr>
            <th>
              <i className="fa fa-envelope-square"></i> <span>Email:</span>
            </th>
            <td>
            <a>Support@TelMDCare.com</a>
          </td>
          </tr>
          
          <tr>
            <th>
              <i className="fa fa-map-marker-alt"></i> <span>Address:</span>
            </th>
            <td>
              JME Medical Service, P.O BOX 577 <br /> 42 Stephen St
              <br /> Lemont, IL 60439
            </td>
          </tr>
        </tbody>
      </Table>
      <LandingPageHeader />
      </Container>


      <Footer />
    </>
  );
};
