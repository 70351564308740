import * as React from "react";
// import { Link } from "@reach/router";
import { goHome, logOutAsPatient } from "../../context/UserContext"
import { UserConfig } from "../../config/userConfig";
import { Button, Badge } from "reactstrap";
import telmdcareLogoWht from "../../../img/telemdcare-logo-wht.webp";

export interface Props {
  children?: React.ReactNode;
  bodyClassName: null | "tc-bg" | "signup-bg";
}

export interface State {}

export default class TCNavbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    document.body.className = this.props.bodyClassName || "";
  }
  render() {
    return (
      // <nav className="navbar navbar-light telecare-header fixed-top">
      //   <div className="container">
      //     {/* 
      //       <!-- <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobileNavItems" aria-controls="mobileNavItems" aria-expanded="false" aria-label="Toggle navigation">
      //           <span className="navbar-toggler-icon"></span>
      //       </button> --> */}

      //     <Link className="navbar-brand mb-0 h1 logo-font" to="" onClick={(e) => goHome(e)}>
      //       <i className="fa fa-border-none"></i> TelMDCare
      //     </Link>

          // {(UserConfig.getPatientLoggedByAgent() !== null && UserConfig.getUserType() === "agent") && 
          // <div>
          //   {'Logged in as ' + UserConfig.getPatientLoggedByAgent().firstName + " " + UserConfig.getPatientLoggedByAgent().lastName}
            
          //   <Button color="link" onClick={()=>{logOutAsPatient()}}>
          //     Log out
          //   </Button>
          // </div>
          // }
      //     <div className="collapsed navbar-collapse d-none" id="mobileNavItems">
      //       <ul className="navbar-nav ml-auto">
      //         <li className="nav-item active">
      //           <a className="nav-link" href="#">
      //             Home <span className="sr-only">(current)</span>
      //           </a>
      //         </li>
      //         <li className="nav-item">
      //           <a className="nav-link" href="#">
      //             <i className="fa fa-phone text-primary fa-1-5x"></i>
      //           </a>
      //         </li>
      //         <li className="nav-item">
      //           <a className="nav-link" href="#">
      //             Pricing
      //           </a>
      //         </li>
      //       </ul>
      //     </div>

      //     {this.props.children}
      //   </div>
      // </nav>

<nav className="navbar navbar-expand-lg navbar-light telecare-header fixed-top">
<div className="container">
  <a className="navbar-brand mb-0 logo-font" href="#" onClick={(e) => goHome(e)}>
    <img src={telmdcareLogoWht}/>
  </a>
  {/* <ul className="navbar-nav navbar-nav-static ml-auto"> */}

    {(UserConfig.getPatientLoggedByAgent() !== null && UserConfig.getUserType() === "agent") && 
    <Badge color="warning" pill>
      <text style={{fontSize: "1rem"}}>{'Logged in as ' + UserConfig.getPatientLoggedByAgent().firstName + " " + UserConfig.getPatientLoggedByAgent().lastName}</text>
      
      <Button color="black" onClick={()=>{logOutAsPatient()}} className="pt-0">
        <u>Log off</u>
      </Button>
    </Badge>
    }
  {/* </ul> */}
  {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button> */}
  {this.props.children}
</div>
</nav>
    );
  }
}
