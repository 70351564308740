export const areThereErrors=()=>{
   let errors = document.getElementsByClassName('error');
   let counter = 0;
   while (counter < errors.length) {
     if (errors[counter].getAttribute('hidden') !== '') {
       return true;
     }
     counter += 1;
   }
   return false;
}

export const returnErrorMessage = (error: any) => {
   let errorObj = JSON.parse(JSON.stringify(error));
   if (errorObj && errorObj.json) {
     let errorMessageStr = errorObj.json.message;
     let errorMessage = '';
     if (errorMessageStr.split(',').length > 1)
       errorMessage = errorMessageStr.split(',')[1];
     else errorMessage = errorMessageStr;
     return errorMessage;
   } else {
     return error;
   }
 }